import axios from "axios";
import { useStore } from "../hooks/store";
import { GolbalConfig } from "./Config";
import { CheckingRefundTypes } from "../types/CheckingTypes";

export const UpdateStep01 = async (id: number, byAdmin : String) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/step0-1/${id}`, {
      statusPading: 1,
      byAdmin: byAdmin
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateStep23 = async (id: number,tackingNumber: string) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/step2-3/${id}`, {
      tackingNumber: tackingNumber
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateStep45 = async (id: number) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/step4-5/${id}`, {
      
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateStep56 = async (id: number ,data:CheckingRefundTypes) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/step5-6/${id}`,data
      
    ,{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateStep67 = async (id: number ) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/step6-7/${id}`, {
      
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};