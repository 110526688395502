import axios from "axios";
import { useStore } from '../hooks/store';
import { GolbalConfig } from "./Config";

export const UploadStep01 = async (formData: FormData) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error Form UPLOAD01",error);
    throw error;
  }
};

export const UploadStep2 = async (formData: FormData) => {
  try {

    const response = await axios.post(`${GolbalConfig.URL}/upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UploadStep45 = async (formData: FormData) => {
  try {
   
    const response = await axios.post(`${GolbalConfig.URL}/upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UploadStep67 = async (formData: FormData) => {
  try {
   
    const response = await axios.post(`${GolbalConfig.URL}/upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

