import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from './Config';
import { customerDepositrefundType } from '../types/CustomeDepositrefundTypes';
import { updateOrderTypes } from '../types/UpdateOrderTypes';
import { AxiosError } from 'axios';

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError && error.response && typeof error.response.data === 'object' && error.response.data !== null;
}


export const SelectOrderByID = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/findby/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const OderList = async (page: number, perPage: number, OrderStatusValue: string, search?: string, shop?: number, startDate?:Date | string, endDate?:Date|string,) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      startDate:startDate,
      endDate:endDate,
      cretedDate: '',
      statusPading: OrderStatusValue,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response && (error.response.data as any).statusCode === 401) {
      return { error: 'TOKEN_EXPIRED' };
    }
    throw error;
  }
};

export const OnHoldOders = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {

      cretedDate: '',
      statusPading: 0,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,

    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const PaidOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 1,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ProcessingOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 2,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ShipedOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 3,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,

    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ReturnOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 4,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,

    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const CheckingOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 5,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const WaitingOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/list`, {
      cretedDate: '',
      statusPading: 6,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const SuccessOrderList = async (page: number, perPage: number, search?: string, shop?: number, startDate?: Date|string, endDate?: Date|string,) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/success`, {
      startDate:startDate,
      endDate:endDate,
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const CancleOrder = async (id: number , username:string) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/cancel/${id}`, {
      status: 0,
      statusPading: 8,
      updateBy: username

    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateDetailChange = async (id: number, data: updateOrderTypes) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/update/${id}`, data

      , {
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const EncryptID = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/encrypt/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const CustomerOrderDetail = async (id: string) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/step/?id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const GetOTP = async (phone: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/deposit-custommer/addbank`, {
      phone: phone,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const VerifyOTP = async (data: customerDepositrefundType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/deposit-custommer/verifyOtp`, data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const CheckUserDeposit = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/checkDeposit/?id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



export const GetAllOrderList = async (page: number, perPage: number, search?: string, shop?: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/order/custommer`, {

      cretedDate: '',
      statusPading: '',
      search: search,
      shop: shop,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const GetOrder = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetOrder2 = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/pending`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const DeleteDetailOrder = async (id : number) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/order/deleteorderdetails/${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const DepositCheck = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/order/waitdeposit`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};