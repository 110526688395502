import { Card } from '@material-tailwind/react'
import SmsReport from '../components/sms/SmsReport'

const SMSreportDetail = () => {
  return (
    <>
      <Card className='p-8'>
        <SmsReport />

      </Card>
    </>
  )
}

export default SMSreportDetail