import { useState, useEffect } from "react";
import Clock from "react-live-clock";
import { Card, Button, Textarea, Dialog, Select, Option, Progress, IconButton, Tooltip, Tabs, TabsHeader, Tab, TabsBody, TabPanel } from "@material-tailwind/react";
import UserCheckin from "../../components/TimeCard/UserCheckin";
import DayoffandleavCheckin from "./DayoffandleavCheckin";
import CalendarComponent1mont from '../../components/CalendarComponent1mont';
import { GetTimeCardDetail } from "../../hooks/TimeCardCheckin";
import { useStore } from "../../hooks/store";
import { TimeCardData } from "../../types/TimeCardTypes";
import { faClock, faList12 } from "@fortawesome/free-solid-svg-icons";
import { CheckWorking } from "../../hooks/TimeCardCheckin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useForm, SubmitHandler } from "react-hook-form";
import { CheckoutTypes, WorkReportResponse, GetOTType, User } from "../../types/TimeCardTypes";
import { Checkout } from "../../hooks/TimeCardCheckin";
import { reportWork, GetOTData } from "../../hooks/TimeCardCheckin";
import { TimeCardDetailCard } from './TimeCardDetailCard';
import OvertimeLoglist from "./OvertimeLoglist";



const Checkin = () => {
  const { username } = useStore();
  const [timeCardList, setTimeCardList] = useState<TimeCardData[]>([]);
  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");
  const [checkWorking, setCheckWorking] = useState();
  const [openCheckout, setopenCheckout] = useState(false);
  const [timeCardID, setTimeCardID] = useState<number | undefined>();
  const [workReportData, setWorkReportData] = useState<WorkReportResponse>();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [myOTData, setMyOTData] = useState<User>();
  const [selectedID, setSelectedID] = useState<number>();

  const [openDetailCard, setOpenDetailCard] = useState(false);
  const handleOpenDetailCard = () => setOpenDetailCard(!openDetailCard);


  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: username,
      remarkCheckOut: '',
    },
  });

  const handleOpenCheckout = (timeCardId?: number) => {
    setTimeCardID(timeCardId);
    setopenCheckout(!openCheckout);
  };

  const handleDateChange = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDateChange2 = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };


  const workstatus = "";
  const statusPadding = "";
  const sort = "";
  const perPage = 100;
  const page = 1;

  const checkWorkingStatus = async (username: string) => {
    const response = await CheckWorking(username);
    setCheckWorking(response);
  }

  
  const GetTimeCardList = async () => {
    const timeCardDATA = await GetTimeCardDetail(startDate, endDate, username, workstatus, statusPadding ,sort,perPage,page);
    setTimeCardList(timeCardDATA.data);
  }

  useEffect(() => {
    GetTimeCardList();
    checkWorkingStatus(username);
  }, [startDate, endDate, username, workstatus, statusPadding,sort,perPage,page]);

  const getworkRepot = async () => {
    const workData = await reportWork(selectedMonth, selectedYear, username);
    setWorkReportData(workData);
  }

  const GetotTime = async () => {
    const OTRes = await GetOTData(username);
    setMyOTData(OTRes.user[0]);
  }

  useEffect(() => {
    GetotTime();
    getworkRepot();
  }, [selectedMonth, selectedYear, username])


  const renderWorkStatus = (workStatus: any) => {
    switch (workStatus) {
      case '1':
        return <span className="text-green-500">ทำงานปกติ</span>;
      case '2':
        return <span className="text-blue-500">OT</span>;
      case '3':
        return <span className="text-purple-500">หยุดตามสิทธิ์</span>;
      case '4':
        return <span className="text-red-500">ลางานไม่รับรายวัน</span>;
      default:
        return <span>สถานะไม่ทราบ</span>;
    }
  }

  const submitCheckOut: SubmitHandler<CheckoutTypes> = async (formData) => {
    if (timeCardID) {
      const saveCheckout = await Checkout(timeCardID, formData);
      if (saveCheckout.message === "Success") {
        Swal.fire({
          title: "สำเร็จ",
          text: "ลงเวลาออกงานสำเร็จ",
          icon: "success",
          didClose: () => window.location.reload()
        });
      } else {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำรายการซ้ำได้กรูณารอ Admin อนุมัติ",
          icon: "warning",
          didClose: () => window.location.reload()
        });
      }

    } else {
      console.error('No timeCardID set');
    }
  };

  const mount = [
    { value: "01", label: "มกราคม" },
    { value: "02", label: "กุมภาพัน" },
    { value: "03", label: "มีนาคม" },
    { value: "04", label: "เมษายน" },
    { value: "05", label: "พฤษภาคม" },
    { value: "06", label: "มิถุนายน" },
    { value: "07", label: "กรกฏาคม" },
    { value: "08", label: "สิงหาคม" },
    { value: "09", label: "กันยายน" },
    { value: "10", label: "ตุลาคม" },
    { value: "11", label: "พฤศจิกายน" },
    { value: "12", label: "ธันวาคม" },
  ];
  const year = ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];

  const handleMonthChange = (value: any) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value: any) => {
    setSelectedYear(value);
  };


  const monthWorkTime = workReportData?.workTimeData[username]?.monthWorkTime ?? 0;
  const totalWorkTime = workReportData?.workTimeData[username]?.totalWorkTime ?? 0;
  const progressPercentage = monthWorkTime ? (totalWorkTime / monthWorkTime) * 100 : 0;


  const renderStatus = (statusPadding: any) => {
    switch (statusPadding) {
      case '0':
        return <span className="text-red-500">รออนุมัติ</span>;
      case '1':
        return <span className="text-blue-500">อนุมัติแล้ว</span>;
      case '2':
        return <span className="text-green-500">เรียบร้อย</span>;
      default:
        return <span>สถานะไม่ทราบ</span>;
    }
  }



  return (
    <>
      <Card className="p-8 rounded-xl drop-shadow-xl  mt-2 lg:grid lg:grid-cols-2 z-10">
        <div className="flex flex-col gap-2 lg:flex lg:flex-col lg:gap-4">
          <div className="lg:flex gap-2 flex flex-col">
            <Select label='เลือกดูตามเดือน' onChange={(value) => handleMonthChange(value)}>
              {mount.map((item, index) => (
                <Option key={index} value={item.value}>{item.label}</Option>
              ))}
            </Select>

            <Select label="เลือกปี" onChange={(value) => handleYearChange(value)}>
              {year.map((item, index) => (
                <Option key={index} value={item}>{item}</Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <h1 className="font-semibold lg:text-base text-sm">เวลาทำงานปกติ</h1>
              <hr className="mb-2" />
              <div className="flex justify-between">
                <h6 className="text-xs lg:text-base">ทำไปแล้ว : {workReportData?.workTimeData[username]?.totalWorkTimeHours == null ? <span>ยังไม่มีเวลาทำงาน</span> : workReportData?.workTimeData[username]?.totalWorkTimeHours}</h6>
                <h6 className="text-xs lg:text-base">ทั้งหมด : {workReportData?.workTimeData[username]?.monthWorkTimeHours == null ? <span>ยังไม่มีข้อมูล</span> : workReportData?.workTimeData[username]?.monthWorkTimeHours}</h6>
              </div>

              <Progress
                value={progressPercentage}
                size="lg"
              />
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:p-4 flex flex-col gap-2 mt-2">
          <div className="border rounded-md p-4 flex flex-col gap-2 justify-center">
            <h1 className="text-center lg:text-start">เวลาที่ต้องทำ OT</h1>
            <h2 className="text-center lg:text-start lg:text-4xl text-amber-600 font-bold">{myOTData?.hoursOverTime}</h2>
          </div>
          <div className="flex flex-col justify-center border rounded-b-md p-4">
            <p className="mb-2 text-center lg:text-end">เวลาปัจจุบัน</p>
            <h1 className="lg:text-5xl text-cyan-500 font-bold text-center lg:text-end">
              <Clock format="HH:mm:ss" interval={1000} ticking={true} />
            </h1>
          </div>
        </div>
      </Card>
      <div className="lg:grid lg:grid-cols-2 my-2 lg:gap-2 flex flex-col gap-2">
        <Card className="p-8">
          <h1 className="text-xl font-semibold">ลงเวลาเข้างานปกติ / OT</h1>
          <hr className="my-4" />
          <UserCheckin />
        </Card>
        <Card className="p-8">
          <h1 className="text-xl font-semibold">ลงเวลาวันหยุด/วันลา</h1>
          <hr className="my-4" />
          <DayoffandleavCheckin />
        </Card>
      </div>
      <div>
        <Card className='my-4 p-8 flex flex-col flex-wrap overflow-auto'>
          <Tabs value={1}>
            <TabsHeader className="bg-green-100">
              <Tab value={1}  className='text-xs  lg:text-base ' >รายละเอียดเวลาเข้างาน และวันลา</Tab>
              <Tab value={2}  className='text-xs  lg:text-base ' >รายละเอียดเพิ่ม/ลด OT</Tab>
            </TabsHeader>
            <TabsBody>
              <TabPanel value={1}>
                <h1 className="text-xl font-semibold">รายละเอียดการลงเวลาและสถานะ</h1>
                <hr className="my-4" />
                <div>
                  <div className="grid grid-cols-2">
                    <span></span>
                    <CalendarComponent1mont onDateChange={handleDateChange} />
                  </div>
                  <div className="my-4">
                    <table className="w-full min-w-max table-auto text-center">
                      <thead>
                        <tr className="border-b border-blue-gray-100 bg-green-50 p-4 text-xs lg:text-sm">
                          <th className="p-3">#</th>
                          <th>วันเวลาเข้างาน</th>
                          <th>วันเวลาออกงาน</th>
                          <th>ประเภท</th>
                          <th>สถานะ</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeCardList?.map((item, index) => (
                          <tr key={index} className="even:bg-green-50/50 text-[10px] lg:text-sm" >
                            <td className='p-4'>{item.Rows}.</td>
                            <td>
                              <div className='flex flex-col'>
                                <span className='text-green-500'>{item.checkInDate}</span>
                                <span className='text-blue-500'>{item.checkInTime}</span>
                              </div>
                            </td>
                            <td>
                              <div className='flex flex-col'>
                                <span className='text-green-500'>{item.checkOutDate === "1 มกราคม 2513" ? <span> - </span> : item.checkOutDate}</span>
                                <span className='text-blue-500'>{item.checkOutTime === null ? <span> - </span> : item.checkOutTime}</span>
                              </div>
                            </td>
                            <td>{renderWorkStatus(item.workStatus)}</td>
                            <td>{renderStatus(item.statusPadding)}</td>
                            <td>
                              <div className="flex gap-2 justify-center">
                                <div>
                                  {
                                    (item.workStatus !== "1" && item.workStatus !== "2" || item.approveCheckOutTime !== "1 มกราคม 2513") ? null :
                                      <Tooltip content="ลงเวลาออกงาน">
                                        <IconButton color="amber" className="rounded-full drop-shadow-md" disabled={checkWorking == true || item.approveCheckInTime == '1 มกราคม 2513' || item.checkOutTime !== null} onClick={() => handleOpenCheckout(item.timeCardId)}>
                                          <FontAwesomeIcon icon={faClock} className="text-xl" />
                                        </IconButton>
                                      </Tooltip>
                                  }
                                </div>
                                <div>
                                  <Tooltip content="รายละเอียด">
                                    <IconButton size="sm" onClick={() => { setSelectedID(item.timeCardId); handleOpenDetailCard(); }}> <FontAwesomeIcon icon={faList12}></FontAwesomeIcon></IconButton>
                                  </Tooltip>

                                </div>

                              </div>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={2}>
                <h1 className="text-xl font-semibold">รายละเอียด เพิ่ม/ลด OT</h1>
                <hr className="my-4" />
                <div className="grid grid-cols-2 my-2">
                  <span></span>
                  <CalendarComponent1mont onDateChange={handleDateChange2} />
                </div>
                <OvertimeLoglist username={username} startDate={startDate} endDate={endDate} page={1} per_page={100} />
              </TabPanel>
            </TabsBody>
          </Tabs>

        </Card>
      </div>
      <Dialog open={openCheckout} handler={handleOpenCheckout} size='sm'>
        <form onSubmit={handleSubmit(submitCheckOut)}>
          <div className="p-4">
            <h1 className="text-xl font-semibold">ลงเวลาออกงาน</h1>
            <hr className="my-4" />
            <Textarea label="หมายเหตุลงเวลาออกงาน" {...register("remarkCheckOut")} />
          </div>
          <div className="p-4 flex justify-end">
            <Button type="submit" >ลงเวลาออกงาน</Button>
          </div>
        </form>
      </Dialog>

      <Dialog open={openDetailCard} handler={handleOpenDetailCard} size='sm'>
        <TimeCardDetailCard id={selectedID} />
      </Dialog>
    </>
  );
};

export default Checkin;
