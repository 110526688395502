
import axios from 'axios';
import { TransportType } from '../types/TranspotTypes';
import { useStore } from '../hooks/store';

export const GetTranspotList = async (): Promise<TransportType[]> => {
    try {
      const response = await axios.get('https://cmfashion.pro/transport/select',{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const AddTransport = async (transportName: string) => {
    try {
      const data = {
        "transportName": transportName,
        "createdBy": "system",
      };
      const response = await axios.post('https://cmfashion.pro/transport/newtransport', data,{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
  export const SelectTransportByID = async (id: number) => {
    try {
      const response = await axios.get(`https://cmfashion.pro/transport/findby/?id=${id}`,{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};

export const updateTransportById = async (id: number, transporName: string) => {
  try {
    const response = await axios.put(`https://cmfashion.pro/transport/update/${id}`, {
      transportName: transporName
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export const deleteTransportById = async (id: number) => {

  try {
    const response = await axios.put(`https://cmfashion.pro/transport/delete/${id}`,{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    console.log('Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};