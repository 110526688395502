// Pagination.tsx
import React from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

interface PaginationProps {
  total: number;
  activePage: number;
  onChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ total, activePage, onChange }) => {
  const getItemProps = (index: number) => ({
    variant: activePage === index ? "filled" : "text",
    color: activePage === index ? "blue" : "blue-gray",
    onClick: () => onChange(index),
  } as any);

  const next = () => {
    if (activePage === total) return;
    onChange(activePage + 1);
  };

  const prev = () => {
    if (activePage === 1) return;
    onChange(activePage - 1);
  };

  return (
    <div className="flex items-center gap-4">
      <Button
        variant="text"
        color="blue-gray"
        className="flex items-center gap-2"
        onClick={prev}
        disabled={activePage === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
        {Array.from({ length: total }, (_, i) => (
          <IconButton key={i} {...getItemProps(i + 1)}>
            {i + 1}
          </IconButton>
        ))}
      </div>
      <Button
        variant="text"
        color="blue-gray"
        className="flex items-center gap-2"
        onClick={next}
        disabled={activePage === total}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default Pagination;
