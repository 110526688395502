import axios from 'axios';
import { useStore } from '../hooks/store';
import { GolbalConfig } from './Config';
import { BnakIDTypes } from '../types/GetBankIdTypes';

export const GetBankID = async (): Promise<BnakIDTypes[]> => {
    try {
      const response = await axios.get(`${GolbalConfig.URL}/bank-deposit/select`,{
        
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };