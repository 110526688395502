import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const PushBulletNotif: React.FC = () => {
  const [cashData, setCashData] = useState<string | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const socketRef = useRef<any>(null);

  const initializeAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      audioContextRef.current.resume().catch(console.error);
    }
  };

  const playSound = async (): Promise<void> => {
    if (!audioContextRef.current) return;

    try {
      const response = await fetch('/sound/xxx.mp3');
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();

      return new Promise<void>((resolve) => {
        source.onended = () => resolve();
      });
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  };

  const speakText = (text: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'th-TH';
        utterance.onend = () => resolve();
        window.speechSynthesis.speak(utterance);
      } else {
        console.error('Text-to-speech not supported in this browser');
        resolve();
      }
    });
  };

  useEffect(() => {
    initializeAudioContext();
    socketRef.current = io('https://cmfashion.net/');

    socketRef.current.on('newAmount', async (data: { amount: string }) => {
      console.log("io Data", data);
      
      if (data.amount !== undefined) {
        const formattedData = `${data.amount} บาท`;
        setCashData(formattedData);

        if (audioContextRef.current) {
          await playSound();
          await speakText(formattedData);
        }
      }
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <h2 hidden>{cashData !== null ? cashData : 'ไม่มีข้อมูล'}</h2>
    </div>
  );
};

export default PushBulletNotif;