import { useEffect, useState } from "react";
import { fetcSendingList } from "../../hooks/Sms";
import { fetcSMSList } from "../../types/SMSSending/GetSmsListTypes";
import { Dialog } from "@material-tailwind/react";
import EditSendingSMSCard from "./EditSendingSMSCard";

const SendingList = () => {
  const [list, SetList] = useState<fetcSMSList>();
  // console.log("list", list);

  useEffect(() => {
    const SMSList = async () => {
      const resList = await fetcSendingList();
      SetList(resList);
    };

    SMSList();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [ID, setID] = useState<number | undefined>();

  return (
    <div>
      <table className="w-full min-w-max table-auto text-center">
        <thead>
          <tr className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
            <th>ลำดับ</th>
            <th>SMS Privider</th>
            <th>Status Padding</th>
            <th>สถานะ</th>
            <th> </th>
          </tr>
        </thead>
        <tbody className="text-xs">
          {list?.SmsSending &&
            list?.SmsSending.map((data, index) => (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className="p-2">{index + 1}.</td>
                <td
                  className="hover:text-blue-500 hover:underline cursor-pointer"
                  onClick={() => {
                    handleOpen();
                    setID(data.id);
                  }}
                >
                  {data.smsProviders === 1 ? (
                    <span>Thaibulksms</span>
                  ) : (
                    <span>thsms</span>
                  )}
                </td>
                <td>{data.statusPading}</td>
                <td>
                  {data.status === 1 ? (
                    <span className="text-xs text-green-500">Active</span>
                  ) : (
                    <span className="text-xs text-red-500">InActive</span>
                  )}
                </td>
                <td
                  className="hover:text-blue-500 hover:underline cursor-pointer"
                  onClick={() => {
                    handleOpen();
                    setID(data.id);
                  }}
                >
                  แก้ไข
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Dialog open={open} handler={handleOpen}>
        {ID !== undefined && <EditSendingSMSCard ID={ID}  closeDialog={handleOpen}/>}
      </Dialog>
    </div>
  );
};

export default SendingList;
