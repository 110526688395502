import React from "react";
import { Stepper, Step, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface StepData {
  title: string;
  description: string;
  icon: any;
  titleClassName?: string;
  descriptionClassName?: string;
  iconClassName?: string;
}

interface MyStepperProps {
  steps: StepData[];
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const MyStepper: React.FC<MyStepperProps> = ({
  steps,
  activeStep,
  setActiveStep,
}) => {
  return (
    <div className="w-full py-4 px-8">
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index} onClick={() => setActiveStep(index)}>
            <FontAwesomeIcon
              icon={step.icon}
              className={`h-5 w-5 ${step.iconClassName}`}
            />
            <div className="absolute -bottom-12 w-max text-center">
              <Typography
                variant="h6"
                color={activeStep === index ? "blue" : "blue-gray"}
                className={step.titleClassName}
              >
                {step.title}
              </Typography>
              <Typography
                color={activeStep === index ? "blue" : "gray"}
                className={`font-normal ${step.descriptionClassName}`}
              >
                {step.description}
              </Typography>
            </div>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default MyStepper;
