import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  GetProvince,
  GetDistricts,
  GetSubdistricts,
} from "../hooks/GetAddreesDetail";
import { Province, DistrictType, SubDistrictType } from "../types/AddressTypes";

interface AddressSelectorProps {
  onProvinceSelect: (province: Province | null) => void;
  onDistrictSelect: (district: DistrictType | null) => void;
  onSubdistrictSelect: (subDistrict: SubDistrictType | null) => void;
  ProviceValue: {
    label: string | undefined;
    value: number | undefined;
  } | null;
  DistrictValue: {
    label: string | undefined;
    value: number | undefined;
  } | null;
  SubDistrictValue: {
    label: string | undefined;
    value: number | undefined;
  } | null;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({ onProvinceSelect, onDistrictSelect, onSubdistrictSelect, ProviceValue = null, SubDistrictValue = null, DistrictValue = null }) => {

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [districts, setDistricts] = useState<DistrictType[]>([]);
  const [subdistricts, setSubdistricts] = useState<SubDistrictType[]>([]);

  // State for selected province, district, and subdistrict
  const [selectedProvince, setSelectedProvince] = useState<Province | null>(
    null
  );
  const [selectedDistrict, setSelectedDistrict] = useState<DistrictType | null>(
    null
  );
  const [selectedSubdistrict, setSelectedSubdistrict] =
    useState<SubDistrictType | null>(null);

  // Fetch provinces on component mount
  useEffect(() => {
    const fetchProvinces = async () => {
      const fetchedProvinces = await GetProvince();
      setProvinces(fetchedProvinces);
    };

    fetchProvinces();
  }, []);

  // Fetch districts when selectedProvince changes
  useEffect(() => {
    const fetchDistricts = async () => {
      if (selectedProvince) {
        const fetchedDistricts = await GetDistricts(selectedProvince.id);
        setDistricts(fetchedDistricts);
      } else {
        setDistricts([]);
      }
    };

    fetchDistricts();
  }, [selectedProvince]);

  // Fetch subdistricts when selectedDistrict changes
  useEffect(() => {
    const fetchSubdistricts = async () => {
      if (selectedDistrict) {
        const fetchedSubdistricts = await GetSubdistricts(selectedDistrict.id);
        setSubdistricts(fetchedSubdistricts);
      } else {
        setSubdistricts([]);
      }
    };

    fetchSubdistricts();
  }, [selectedDistrict]);


  return (
    <div className=" lg:flex lg:flex-row flex flex-col gap-2 my-3 text-sm">
      <div className="lg:w-[33.33%]">
        {/* Province dropdown */}
        <label className="text-xs">จังหวัด</label>
        <Select
          options={provinces.map(({ id, provinceName }) => ({
            value: id,
            label: provinceName,
          }))}
          onChange={(option) => {
            const selected = provinces.find((p) => p.id === option?.value) || null;
            setSelectedProvince(selected);
            onProvinceSelect(selected);
            setSelectedDistrict(null); // Reset district when province is changed
            setSelectedSubdistrict(null); // Reset subdistrict when province is changed
          }}
          placeholder="เลือกจังหวัด"
          value={
            selectedProvince
              ? {
                value: selectedProvince.id,
                label: selectedProvince.provinceName,
              }
              : ProviceValue ? ProviceValue : null
          }
        />
      </div>
      <div className="lg:w-[33.33%]">
        {/* District dropdown */}
        <label className="text-xs">อำเภอ/เขต</label>
        <Select
          options={districts.map(({ id, districtName }) => ({
            value: id,
            label: districtName,
          }))}
          onChange={(option) => {
            const selected = districts.find((d) => d.id === option?.value) || null;
            setSelectedDistrict(selected);
            onDistrictSelect(selected);
            setSelectedSubdistrict(null); // Reset subdistrict when district is changed
          }}
          placeholder="เลือกอำเภอหรือแขวง"
          value={
            selectedDistrict
              ? {
                value: selectedDistrict.id,
                label: selectedDistrict.districtName,
              }
              : DistrictValue ? DistrictValue : null
          }
          isDisabled={!selectedProvince}
        />
      </div>
      <div className="lg:w-[33.33%]">
        {/* Subdistrict dropdown */}
        <label className="text-xs">ตำบล/แขวง</label>
        <Select
          key={selectedDistrict ? selectedDistrict.id : "initial"}
          options={subdistricts.map(({ id, subDistrictName }) => ({
            value: id,
            label: subDistrictName,
          }))}
          onChange={(option) => {
            const selected = subdistricts.find((s) => s.id === option?.value) || null;
            setSelectedSubdistrict(selected);
            onSubdistrictSelect(selected);
          }}
          placeholder="เลือกตำบลหรือเขต"
          value={
            selectedSubdistrict
              ? {
                value: selectedSubdistrict.id,
                label: selectedSubdistrict.subDistrictName,
              }
              : SubDistrictValue ? SubDistrictValue : null
          }
          isDisabled={!selectedDistrict}
        />
      </div>


    </div>
  );
};

export default AddressSelector;
