import { useState, useEffect } from 'react'
import { GetOTTypes } from '../../types/TimeCardTypes';
import { GetOTDATA } from '../../hooks/TimeCardCheckin';
import { User } from '../../types/AdminListTypes';
import { fetchAdminList } from '../../hooks/UserAdmin';

const GetOT = () => {
    const [getOTdetail, setGetOTdetail] = useState<GetOTTypes[]>();
    const [selectedUser, setSelectedUser] = useState("");
    const [userList, setUserlist] = useState<User[]>([]);



    const fetchGetOT = async () => {
        const resData = await GetOTDATA(selectedUser);
        setGetOTdetail(resData.user);
    }
    useEffect(() => {
        fetchGetOT();
    }, [selectedUser]);

    const fetchUserList = async () => {
        const userListData = await fetchAdminList();
        setUserlist(userListData.user);
    };

    useEffect(() => {
        fetchUserList();
    }, []);

    const handleUserSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(event.target.value);
    };

    return (
        <>
            <div>
                <div className='flex justify-end mb-2'>
                    <select onChange={handleUserSelection} className='border-2 rounded-md p-1 w-48' >
                        <option value="">เลือก Admin</option>
                        {userList.map((user) => (
                            <option key={user.username} value={user.username}>{user.nickname}</option>
                        ))}
                    </select>
                </div>
                <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr className="border-b border-blue-gray-100 bg-green-100 p-4 text-sm">
                            <th className='p-4'>#</th>
                            <th>ID</th>
                            <th>username</th>
                            <th>เวลาทำ OT</th>
                            <th>วันที่อัพเดท</th>
                        </tr>
                    </thead>
                    {getOTdetail?.map((data, index) => (
                        <tr key={index} className="even:bg-green-50/50 text-sm" >
                            <td className='p-4'>{data.Rows}.</td>
                            <td>{data.overTimeId}</td>
                            <td>{data.username}</td>
                            <td>{data.hoursOverTime}</td>
                            <td>
                                <div className='flex flex-col items-center'>
                                    {data.updateDate}
                                    <span>โดย. {data.updateBy}</span>
                                </div>

                            </td>
                        </tr>
                    ))}
                    <tbody>

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default GetOT