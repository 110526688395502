import { useState,useEffect } from "react";
import { GetSumdeposit } from "../../hooks/Report";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { SumdepositTypes } from "../../types/ReportTypes";


const SumDepositCard = () => {
    const [Sumdeposit, setSumdeposit] = useState<SumdepositTypes>();
    const numberFormat = Intl.NumberFormat('en-US');


    const fetchData = async () => {
        const resSumDepositdata = await GetSumdeposit();
        setSumdeposit(resSumDepositdata);
    };
    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className='flex justify-around gap-2 lg:p-8 p-4 lg:flex lg:gap-4 lg:justify-between items-center text-white lg:w-64'>
            <div>
                <FontAwesomeIcon icon={faBuildingColumns} className='text-3xl lg:text-5xl text-white' />
            </div>
            <div className='text-center'>
                <h2>ยอดมัดจำในระบบ</h2>
                <span className='text-3xl font-bold'>{numberFormat.format(Sumdeposit?.SumDeposit || 0)}</span>
            </div>
        </div>
    )
}

export default SumDepositCard