import axios from 'axios';
import { CreateSetBankTypes } from '../types/CreateSetBankTypes';
import { GetBankDATA } from '../types/PushBulletTypes';

export const PushBulletOwner = async (
  nameAccount: string,
  bankName: string,
  statusType: string,
  currentPage: number,
  perPage: number,
  startDate: Date | string, 
  endDate: Date | string,
) => {
  try {

    const response = await axios.post('https://cmfashion.net/report/pushsmsall', {
      nameAccount: nameAccount,
      bankName: bankName,
      startDate: startDate,
      endDate: endDate,
      statusType: statusType,
      createdDate: "",   
      page: currentPage,         
      per_page:perPage
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

  

  export const getBankNameDATA = async () => {
    try {
      const response = await axios.get(`https://cmfashion.net/bank/selectbank`);
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const AddNewPushBullet = async (data:CreateSetBankTypes) => {
    try {
      const response = await axios.post(`https://cmfashion.net/bank/addbank`, data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
  export const DeletePushBullet = async (id:number) => {
    try {
      const response = await axios.put(`https://cmfashion.net/bank/deletebank?id=${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
  export const FindBank = async (id:number) => {
    try {
      const response = await axios.get(`https://cmfashion.net/bank/findbank?id=${id}`);
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const UpdatePushBullet = async (id:number , data:GetBankDATA) => {
    try {
      const response = await axios.put(`https://cmfashion.net/bank/updatebank?id=${id}`,data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };