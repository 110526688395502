import { useEffect, useState } from 'react'
import { OvertimeLoglistType, OvertimeLogItem } from '../../types/TimeCardTypes';
import { GetOverTimeLogList } from '../../hooks/TimeCardCheckin';

interface OverTimeLogProp {
    startDate: Date | string;
    endDate: Date | string;
    username: string;
    page: number;
    per_page: number;
}

const OvertimeLoglist: React.FC<OverTimeLogProp> = ({ startDate, endDate, username, page, per_page }) => {
    const [overTimeData, setOverTimeData] = useState<OvertimeLogItem[]>([]);

    const fetchOverTimeLog = async () => {
        const response = await GetOverTimeLogList(startDate, endDate, username, page, per_page);
        if (response && response.data) {
            setOverTimeData(response.data);
        }
    }

    useEffect(() => {
        fetchOverTimeLog();
    }, [startDate, endDate, username, page, per_page]);

    return (
        <>
            <div>
                <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr className="border-b border-blue-gray-100 bg-green-50 p-4 text-xs lg:text-sm">
                            <th className="p-3">#</th>
                            <th>ID</th>
                            <th>Username</th>
                            <th>OT ที่ถูกเพิ่ม</th>
                            <th>OT ที่ถูกลด</th>
                            <th>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overTimeData?.map((item, index) => (
                            <tr key={index} className="even:bg-green-50/50  lg:text-sm">
                                <td className="p-3  text-[10px] lg:text-sm">{item.Rows}.</td>
                                <td className="p-3   text-[10px] lg:text-sm">{item.overTimeLogId}</td>
                                <td className="p-3   text-[10px] lg:text-sm">{item.username}</td>
                                <td className="p-3   text-[10px] lg:text-sm">
                                    <div className='flex flex-col items-center'>
                                        <h1 className='text-red-500 font-bold'>{item.hoursAddOverTime == null ? <span> - </span> : item.hoursAddOverTime}</h1>
                                        {item.hoursAddOverTime != null ? <span className='flex gap-2 text-green-900'><h1 className=' text-[10px] lg:text-sm'>วันที่สร้าง</h1>{item.createdDate}</span> : null}
                                        {item.hoursAddOverTime != null ? <span className='flex gap-2 text-green-900'><h1 className=' text-[10px] lg:text-sm'>สร้างโดย</h1>{item.createdBy}</span> : null}
                                    </div >
                                </td>
                                <td className="p-3   text-[10px] lg:text-sm">
                                    <div className='flex flex-col items-center'>
                                        <span className='font-bold'>{item.hoursDisOverTime == null ? <span> - </span> : item.hoursDisOverTime}</span>
                                        {item.hoursDisOverTime != null ? <span className='flex gap-2 text-green-900'><h1 className=' text-[10px] lg:text-sm'>วันที่สร้าง</h1>{item.createdDate}</span> : null}
                                        {item.hoursDisOverTime != null ? <span className='flex gap-2 text-green-900'><h1 className=' text-[10px] lg:text-sm'>สร้างโดย</h1>{item.createdBy}</span> : null}
                                    </div>
                                </td>
                                <td className="p-3   text-[10px] lg:text-sm">{item.remark == "" ? <span> - </span> : item.remark}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default OvertimeLoglist