import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { AdminCommissionTypes } from '../../types/ReportTypes';
import { AdminCommissionAll } from '../../hooks/Report';
import { useStore } from '../../hooks/store';

interface PorpTypes {
    startDate: Date | string;
    endDate: Date | string;
    byAdmin: string;
}

const AdminCommission: React.FC<PorpTypes> = ({ startDate, endDate,byAdmin }) => {
    const [adminComData, setAdminComData] = useState<AdminCommissionTypes>();
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart>();

    const fetchAdminCommission = async () => {
        const resAdminComData = await AdminCommissionAll(startDate, endDate,byAdmin);
        setAdminComData(resAdminComData);
        if (resAdminComData.status === true) {
            useStore.getState().setadminCommission(resAdminComData.total);
        }
    }

    useEffect(() => {
        fetchAdminCommission();
    }, [startDate, endDate,byAdmin]);

    useEffect(() => {
        if (adminComData && chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                // Destroy the previous chart instance if it exists
                if (chartInstanceRef.current) {
                    chartInstanceRef.current.destroy();
                }

                // Construct datasets for all users
                const datasets = Object.entries(adminComData.data).map(([user, values]) => {
                    return {
                        label: user,
                        data: Object.values(values),
                        fill: false,
                        borderColor: '#' + Math.floor(Math.random() * 16777215).toString(16), // random color
                        tension: 0.1
                    };
                });

                // Store the chart instance so you can destroy it later
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                beginAtZero: true
                            },
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                });
            }
        }
    }, [adminComData]);

    return (
        <div className='p-4'>
            <div className='flex gap-2'>
                <h2>ค่าคอมฯ Admin</h2> <h2>ค่าคอมรวม {adminComData?.total} บาท</h2>
            </div>

            <canvas ref={chartRef}></canvas>
        </div>
    )
}

export default AdminCommission
