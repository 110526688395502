import { useState, useEffect } from "react";
import {
  Input,
  Button,
  Typography,
  Tooltip,
  Select,
  Option,
} from "@material-tailwind/react";
import { fetchSmsReport } from "../../hooks/Sms";
import { SmsData, smsFormType } from "../../types/SmsReportType";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Pagination from "../Pagination";

const SmsReport: React.FC = () => {
  const defaultValues = {
    phoneNumber: "",
    smsProviders: "",
    cretedDate: "",
    statusPading: "",
    page: 1,
    per_page: 50,
  };

  const [smsReport, setSmsReport] = useState<SmsData[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Add this state

  const [formValues, setFormValues] = useState({
    page: defaultValues.page.toString(),
    per_page: defaultValues.per_page.toString(),
  });

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<smsFormType>({ defaultValues });

  const TABLE_HEAD = [
    "ลำดับ",
    "ID",
    "ข้อความ",
    "เบอร์โทร",
    "SMS Provider",
    "สถานะ",
    "วันที่สร้าง",
  ];

  const getStatusText = (status: any) => {
    switch (status) {
      case 0:
        return (
          <span className="bg-yellow-400 px-3 py-1 text-[12px] rounded-full text-black">
            OnHold
          </span>
        );
      case 7:
        return (
          <span className="bg-green-400 px-3 py-1 text-[12px] rounded-full text-white">
            เรียบร้อย
          </span>
        );
      case 8:
        return (
          <span className="bg-pink-400 px-5 py-1 text-[12px] rounded-full text-white">
            OTP
          </span>
        );
      default:
        return "Unknown Status";
    }
  };

  const _onSubmit: SubmitHandler<smsFormType> = async (data) => {
    fetchData(data);
  };

  const onReset = () => {
    setTimeout(() => {
      reset(defaultValues);
      fetchData(defaultValues);
    }, 0);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    fetchData({
      ...defaultValues,
      page: newPage,
      per_page: defaultValues.per_page,
    });
  };

  const fetchData = async (data?: smsFormType) => {
    try {
      const smsresponse = await fetchSmsReport(data);
      setSmsReport(smsresponse.data);

      const total = smsresponse.total;
      const pages = Math.ceil(total / (data?.per_page ?? 20));

      setTotalPages(pages);
    } catch (err) {
      console.error("error sms report", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="my-4">
        <form
          onSubmit={handleSubmit(_onSubmit)}
          className="lg:grid lg:grid-cols-6 lg:gap-2 flex flex-col gap-1"
        >
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                crossOrigin="anonymous"
                {...field}
                label="ค้นหาจากเบอร์โทร"
              />
            )}
          />
          <Controller
            name="cretedDate"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input crossOrigin="anonymous" {...field} label="วันที่สร้าง" />
            )}
          />
          <Controller
            name="smsProviders"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select {...field} label="SMS Provider">
                <Option value="1">ThaibulkSms</Option>
                <Option value="2">ThSms</Option>
              </Select>
            )}
          />
          <Controller
            name="statusPading"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select {...field} label="ดูตามสถานะ">
                <Option value="0">OnHold</Option>
                <Option value="7">เรียบร้อย</Option>
                <Option value="8">OTP</Option>
              </Select>
            )}
          />
          <Button type="submit">ตกลง</Button>
          <Button type="button" color="gray" onClick={onReset}>
            Reset
          </Button>
        </form>
      </div>
      <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
        <table className="w-full min-w-max table-auto text-center overflow-scroll">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {smsReport.map((data, index) => (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {data.Rows}.
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {data.id}
                  </Typography>
                </td>
                <td className="p-4">
                  <Tooltip className="text-xs" content={data.message}>
                    <span className="bg-pink-300 text-xs rounded-full px-3 py-1 text-white pon cursor-pointer">
                      แสดงข้อความ
                    </span>
                  </Tooltip>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {data.phoneNumber}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {data.smsProviders}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {getStatusText(data.statusPading)}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs"
                  >
                    {data.cretedDate}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <div className="flex  flex-nowrap justify-center pt-8">
          <Pagination
            total={totalPages}
            activePage={page}
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

export default SmsReport;
