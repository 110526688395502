import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetDetailFromCalendar } from '../hooks/Studio/StudioAdd';
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Input,
    Spinner,
} from '@material-tailwind/react';

type ImageStudio = {
    fileName: string;
    filePath: string;
    fileSize: number;
    fileType: string;
    imageId: number;
    imageStatus: number;
};

type StudioDetailType = {
    createdBy: string;
    createdDate: string;
    customerName: string;
    hairdresserType: string;
    imageStudio: ImageStudio[];
    makeupName: string;
    makeupType: string;
    makeuptime: string;
    photographerName: string;
    photographerTime: string;
    photographerType: string;
    remark: string;
    startDate: string;
    startTime: string;
    statusPadding: string;
    statusType: string;
    studioId: number;
    studioPrice: number;
};

type ApiResponse = {
    data: StudioDetailType;
    message: string;
    status: string;
};

const StudioDetail2 = () => {
    const { id } = useParams<{ id: string }>();
    const [studioDetail, setStudioDetail] = useState<StudioDetailType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStudioFullDetail = async () => {
            if (!id) return;

            try {
                setLoading(true);
                const response: ApiResponse = await GetDetailFromCalendar(Number(id));
                if (response.status === "000" && response.message === "Success") {
                    setStudioDetail(response.data);
                } else {
                    throw new Error("Failed to fetch data");
                }
            } catch (err) {
                setError('Failed to fetch studio detail');
                console.error('Error fetching studio detail:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStudioFullDetail();
    }, [id]);

    if (loading) return <div className="flex justify-center items-center h-screen"><Spinner className="h-12 w-12" /></div>;
    if (error) return <div className="text-red-500 text-center">{error}</div>;
    if (!studioDetail) return <div className="text-center">No data available</div>;

    return (
        <Card className="m-8 w-full max-w-[80rem] mx-auto">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-4 flex items-center justify-between gap-8">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                            รายละเอียดการเข้ารับบริการของลูกค้า
                        </Typography>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="px-0 pt-0 pb-2">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
                    <Input crossOrigin={undefined} label="ชื่อลูกค้า" value={studioDetail.customerName} readOnly />
                    <Input crossOrigin={undefined} label="วันที่" value={studioDetail.startDate} readOnly />
                    <Input crossOrigin={undefined} label="เวลาแต่งหน้า" value={studioDetail.startTime} readOnly />
                    <Input crossOrigin={undefined} label="ชื่อช่างแต่งหน้า" value={studioDetail.makeupName} readOnly />
                    <Input crossOrigin={undefined} label="เวลาแต่งหน้า" value={studioDetail.makeuptime} readOnly />
                    <Input crossOrigin={undefined} label="ชื่อช่างภาพ" value={studioDetail.photographerName} readOnly />
                    <Input crossOrigin={undefined} label="เวลาถ่ายภาพ" value={studioDetail.photographerTime} readOnly />
                    <Input crossOrigin={undefined} label="ราคา" value={studioDetail.studioPrice.toString()} readOnly />
                    <Input crossOrigin={undefined} label="สถานะ" value={studioDetail.statusPadding === "1" ? "ชำระเงิน" : studioDetail.statusPadding === "2" ? "กำลังดำเนินการ" : studioDetail.statusPadding ==="3" ? "เรียบร้อย" : "ไม่ทราบ"} readOnly />
                    <Input crossOrigin={undefined} label="หมายเหตุ" value={studioDetail.remark || "ไม่มี"} readOnly />
                    <Input crossOrigin={undefined} label="สร้างโดย" value={studioDetail.createdBy} readOnly />
                    <Input crossOrigin={undefined} label="วันที่รับคิว" value={studioDetail.createdDate} readOnly />
                </div>
                <div className="mt-8">
                    <Typography variant="h6" color="blue-gray" className="mb-4 px-4">
                        ภาพตัวอย่างอ้างอิง(ref)
                    </Typography>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                        {studioDetail.imageStudio.map((image, index) => (
                            <img
                                key={index}
                                src={image.filePath}
                                alt={`Studio ${index + 1}`}
                                className="w-full h-auto rounded-lg shadow-lg"
                            />
                        ))}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default StudioDetail2;