import { useState } from "react";
import { Card, Button, Input, Tabs, TabsHeader, TabsBody, Tab, TabPanel, } from "@material-tailwind/react";
import Swal from "sweetalert2";
import { UploadStep2 } from "../../hooks/Uppload";
import { UpdateStep23 } from "../../hooks/ChangeStatus";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes23, UploadTypes } from "../../types/UploadSlipTypes";


interface Step23Types {
  // closeChagestepDialog: () => void;
  id: number | null;
}
const Step23addimageCard: React.FC<Step23Types> = ({ id }) => {

  // For the image upload form
  const {
    register: registerImage,
    handleSubmit: handleImageSubmit,
    formState: { errors: imageErrors }
  } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: id,
      imageStatus: 2,
    },
  });


  // State to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  // Update selected files when file input changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(e.target.files);
  };



  const ImageSave = async (data: UploadTypes) => {
    if (data.file && data.file.length <= 10) {
      const formData = new FormData();
      // Append all selected files to formData
      for (let i = 0; i < data.file.length; i++) {
        formData.append('file', data.file[i]);
      }
      formData.append('orderId', data.orderId ? data.orderId.toString() : "");
      formData.append('imageStatus', data.imageStatus.toString());
      try {
        const upload23response = await UploadStep2(formData);
        if (upload23response[0].status === true) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'อัฟโหลดรูปภาพสำเร็จ',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      } catch (error) {
        console.log("Upload 2-3 Error", error);
      }
    } else if (data.file && data.file.length > 10) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'คุณสามารถเพิ่มรูปได้สูงสุดไม่เกิน 10 รูป',
        confirmButtonText: 'OK'
      });
    } else {
      alert("Please select a file before submitting.");
    }
  };
  const OnUploadSubmit: SubmitHandler<UploadTypes> = async (data) => {
    Swal.fire({
      icon: 'question',
      title: 'อัฟโหลดรูปภาพ',
      text: 'คุณต้องการอัฟโหลดรูปภาพใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        ImageSave(data);
      }
    });

  }


  return (
    <>
      <Card className="p-8 flex gap-3">
      <h2 className="font-bold">อัฟโหลดรูปภาพสินค้า </h2>
              <div>
                <form onSubmit={handleImageSubmit(OnUploadSubmit)}>

                  <div className="my-2">
                    <input
                      type="file"
                      {...registerImage("file")}
                      accept="image/*"
                      multiple
                      className="border-2 rounded-lg p-1 w-full"
                      onChange={handleFileChange} // Call the handleFileChange function on change
                    />
                  </div>
                  {selectedFiles && (
                    <ul className="list-disc pl-5">
                      {Array.from(selectedFiles).map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  )}
                  {imageErrors.file && (
                    <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                      กรุณาเลือกไฟล์รุปภาพ
                    </div>
                  )}
                  <Button type="submit"> บันทึกรูปภาพ</Button>
                </form>

              </div>

      </Card>
    </>
  );
};

export default Step23addimageCard;
