import { useForm } from "react-hook-form";
import { useStore } from "../../hooks/store";
import { useEffect, useState } from "react";
import { fetchSMSproviderList } from "../../hooks/Sms";
import { ProviderListType } from "../../types/SMSProviderlistTypes";

const ChecCredit = () => {
  const { username } = useStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      smsProviders: null,
      statusPading: null,
      cretedBy: username,
    },
  });
  const [smsProvider, SetsmsProvider] = useState<ProviderListType[] | null>(
    null
  );
 
  useEffect(() => {
    const fetcProviderData = async () => {
      const proiverData = await fetchSMSproviderList();
      SetsmsProvider(proiverData);
    };

    fetcProviderData();
  }, []);

  return (
    <>
      <div className="h-full">
        <h1>Check SMS Credit</h1>
      <p>This Page will Avialable SOON!!!</p>
      </div>
    </>
  );
};

export default ChecCredit;
