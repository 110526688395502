import { Button, Input } from '@material-tailwind/react';
import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';
import { StudioSuccessType } from '../../types/StudioTypes';
import { StudioSuccess } from '../../hooks/Studio/StudioAdd';
import Swal from 'sweetalert2';

interface proptype {
    studioId: string;
    onSuccessfulUpdate: () => void;
}

const Success: React.FC<proptype> = ({ studioId, onSuccessfulUpdate }) => {
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            studioId: studioId,
            makeupPrice: 0,
            photographerPrice: 0,
        }
    });

    const onSubmit: SubmitHandler<StudioSuccessType> = async (data) => {
        try {
            const result = await Swal.fire({
                title: 'ยืนยันการบันทึกข้อมูล',
                text: "คุณแน่ใจหรือไม่ที่จะบันทึกข้อมูลนี้?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            });

            if (result.isConfirmed) {
                const resSubmit = await StudioSuccess(data);
                if (resSubmit.message === "Success") {
                    await Swal.fire(
                        'บันทึกสำเร็จ!',
                        'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
                        'success'
                    );
                    onSuccessfulUpdate();
                }

            }
        } catch (e) {
            console.log(e);
            await Swal.fire(
                'เกิดข้อผิดพลาด!',
                'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
                'error'
            );
        }
    };

    return (
        <div >
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className='flex gap-2'>
                    <Input
                        {...register("makeupPrice", { required: "กรุณากรอกราคาช่างแต่งหน้า" })}
                        crossOrigin={undefined}
                        size="lg"
                        label="ราคาช่างแต่งหน้า *"
                    />
                    {errors.makeupPrice && <p className="text-red-500 text-xs">{errors.makeupPrice.message}</p>}
                    <Input
                        {...register("photographerPrice", { required: "กรุณากรอกราคาช่างภาพ" })}
                        crossOrigin={undefined}
                        size="lg"
                        label="ราคาช่างภาพ *"
                    />
                    {errors.photographerPrice && <p className="text-red-500 text-xs">{errors.photographerPrice.message}</p>}
                </div>

                <div className='flex justify-end mt-2'>
                    <Button type='submit'>บันทึกข้อมูล</Button>
                </div>
            </form>
        </div>
    )
}

export default Success