import React from 'react'
import { Card } from '@material-tailwind/react';
import SmsPorviderlist from '../components/sms/SmsPorviderlist';
const smssetting = () => {
  return (
    <>
      <Card className=' p-8 overflow-auto'>
      <h1 className='pt-4'>SMS Provider</h1>
        <div className='py-4'>
          <SmsPorviderlist />
        </div>
      </Card>

    </>
  );
}

export default smssetting