import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Input } from "@material-tailwind/react";

import format from "date-fns/format";
import { addDays } from "date-fns";
import { th } from "date-fns/locale"; // Import the Thai locale

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

type Props = {
  onDateChange: (startDate: Date | string, endDate: Date | string) => void;
};

const CalendarComponent: React.FC<Props> = ({ onDateChange }) => {
  //// set Year ////

  const setToFirstDayOfYear = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setMonth(0); // January
    newDate.setDate(1); // 1st
    newDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
    return newDate;
  };

  const setToLastDayOfYear = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setMonth(11); // December
    newDate.setDate(31); // 31st
    newDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
    return newDate;
  };
  ////////////////////////////////////

  ///// set month ////

  const setToFirstDayOfMonth = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setDate(1); // Set day to the 1st
    newDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
    return newDate;
  };

  const setToLastDayOfMonth = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1); // Go to next month
    newDate.setDate(0); // Go back a day to the last day of the current month
    newDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
    return newDate;
  };

  ////////////////////

  const [range, setRange] = useState([
    {
      startDate: setToFirstDayOfYear(new Date()),
      endDate: setToLastDayOfYear(new Date()),
      key: "selection",
      // startDate: setToFirstDayOfMonth(new Date()),
      // endDate: setToLastDayOfMonth(new Date()),
      // key: "selection",
      // startDate: setToStartOfDay((addDays(new Date(), 0))),
      // endDate: setToEndOfDay((addDays(new Date(), 0))),
      //   key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);

  const refOne = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: MouseEvent) => {
    if (refOne.current && !refOne.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  const formatThaiDate = (date: Date) => {
    const thaiYear = Number(format(date, "yyyy")) + 543;
    return `${format(date, "dd MMMM", { locale: th })} ${thaiYear}`;
  };

  useEffect(() => {
    if (range[0].startDate && range[0].endDate) {
      onDateChange(
        format(new Date(range[0].startDate), "yyyy-MM-dd HH:mm:ss"),
        format(new Date(range[0].endDate), "yyyy-MM-dd HH:mm:ss")
      );
    }
  }, [range]);

  return (
    <div className="">
      <Input
        crossOrigin="anonymous"
        label="เลือกตามช่วงวันที่"
        value={`${formatThaiDate(range[0].startDate)} ถึง ${formatThaiDate(
          range[0].endDate
        )}`}
        readOnly
        className=" py-2 px-3 rounded-lg text-sm w-80"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      />
      <div ref={refOne}>
        {open && (
          <DateRangePicker
            onChange={(item: any) => {
              if (item.selection.startDate && item.selection.endDate) {
                setRange([item.selection]);
              }
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={2}
            direction="horizontal"
            className="calendarElement"
            locale={th}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarComponent;
