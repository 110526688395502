import { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Input, Textarea, Button } from '@material-tailwind/react';
import { fetchAdminList } from '../../hooks/UserAdmin';
import { useStore } from '../../hooks/store';
import { DisOTType } from '../../types/TimeCardTypes';
import { User } from '../../types/AdminListTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { SubmitDisOT } from '../../hooks/TimeCardCheckin';

const DisOT = () => {
  const { username } = useStore();
  const [userList, setUserlist] = useState<User[]>([]);
  const { register, handleSubmit, formState: { errors }, control } = useForm<DisOTType>({
    defaultValues: {
      disOverTime: 0,
      username: "",
      createdBy: username,
      remark: '',
    }
  });
  const fetchUserList = async () => {
    const userListData = await fetchAdminList();
    setUserlist(userListData.user);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const submitDisOT: SubmitHandler<DisOTType> = (data) => {
    const convertdata = {
      ...data,
      addOverTime: Number(data.disOverTime),
    }
    Swal.fire({
      title: 'เพิ่ม OT',
      text: 'คุณได้ตรวจสอบข้อมูลถูกต้องแล้วใช่หรือไม่',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion
        const resSubmit = await SubmitDisOT(convertdata);
        console.log("resSubmit", resSubmit);
        if (resSubmit.message == "Success") {
          Swal.fire(
            'สำเร็จ!',
            ' ลดOT ให้พนักงานสำเร็จ.',
            'success'
          );
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your time card is safe :)',
          'error'
        );
      }
    });
  };

  return (
    <>
      <div className='p-8'>
        <form onSubmit={handleSubmit(submitDisOT)}>
          <div className='border rounded-xl p-4 flex flex-col gap-2'>
            <h1 className='font-bold'>เพิ่ม OT พนักงาน</h1>
            <hr className='mb-4' />
            <div className='flex gap-2'>
              <div className='flex flex-col  w-[50%]'>
                <label className='text-sm text-green-500'>จำนวนนาที</label>
                <input className='border-2  rounded-md p-1' type='number' {...register('disOverTime', { required: true })} />
                {errors.disOverTime && (
                  <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                    กรุณากรอกจำนวนนาที
                  </div>
                )}
              </div>
              <div className='flex flex-col  w-[50%]'>
                <label className='text-sm text-green-500'>เลือก Admin</label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      className='border-2 rounded-md  p-1'
                    >
                      <option value="">Admin ที่ต้องการเพิ่ม OT</option>
                      {userList.map((user) => (
                        <option key={user.username} value={user.username}>{user.nickname}</option>
                      ))}
                    </select>
                  )}
                />
              </div>


            </div>
            <div>
              <Textarea label='หมายเหตุการเพิ่ม OT' {...register('remark')} />
            </div>
            <div className='flex justify-end'>
              <Button type='submit'>
                <FontAwesomeIcon icon={faSave} /> บันทึก
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default DisOT