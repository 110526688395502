import React, { useState } from "react";
import { AddNewShopIdList } from "../../hooks/GetShopId";
import { Button, Input } from "@material-tailwind/react";
import Swal from 'sweetalert2';

const AddNewShop: React.FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const [shopName, setShopName] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await AddNewShopIdList(shopName);
      if (response.statusCode === 200) {
        Swal.fire({
          icon: 'success',
          title: 'เพิ่มสาขาสำเร็จ',
        })
        closeDialog();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-2 mt-5">
          <Input
            crossOrigin="anonymous"
            label="ชื่อสาขา"
            type="text"
            value={shopName}
            onChange={(e) => {
              setShopName(e.target.value);
            }}
            required

          />
          <Button type="submit" size="sm" variant="gradient" className="w-40">
            เพิ่มสาขา
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddNewShop;
