import axios from 'axios';
import { useStore } from '../../hooks/store';
import { GolbalConfig } from '../Config';
import { StudioAddTypes,studioSearchResponse, StudioSuccessType} from '../../types/StudioTypes';


export const AddBookingStudio = async (data:StudioAddTypes) => {
    try {
      const response = await axios.post(`${GolbalConfig.URL}/studio/add`, data, {
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const AddIMAGE = async (formData: FormData) => {
    try {
        const response = await axios.post(`${GolbalConfig.URL}/upload/studio`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${useStore.getState().accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};

export const studioSearch = async (search:string,statusPadding:string,startDatetime:string,page:number,per_page:number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/studio/search`, {
      search:search,
      statusPadding:statusPadding,
      startDatetime:startDatetime,
      page:page,
      per_page:per_page
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const DeleteBooking = async (studioId: string) => {
  try {
      const response = await axios.post(`${GolbalConfig.URL}/studio/delete`, {
        studioId:studioId,
      }, {
          headers: {
              Authorization: `Bearer ${useStore.getState().accessToken}`
          }
      });

      return response.data;
  } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
  }
};



export const UpdateStudio = async (data:StudioAddTypes) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/studio/update`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const StudioSuccess = async (data:StudioSuccessType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/studio/success`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const GetStudioCalendar = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/studio/calender`,  {
      // headers: {
      //   Authorization: `Bearer ${useStore.getState().accessToken}`
      // }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const findByStuidoId = async (studioId : number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/studio/${studioId}`,  {
      // headers: {
      //   Authorization: `Bearer ${useStore.getState().accessToken}`
      // }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const GetDetailFromCalendar = async (id:number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/studio/${id}`,  {
      // headers: {
      //   Authorization: `Bearer ${useStore.getState().accessToken}`
      // }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



