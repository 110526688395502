// CreateNewOrder.ts
import axios from 'axios';
import { FormData} from '../types/NewOrderTypes';
import { useStore } from "../hooks/store";
import { GolbalConfig } from './Config';

export const CreateNewOrder = async (formData: FormData) => {
  try {
    const newFromData = {
      ...formData, 
      shopId: formData.shopId.value ,
      transportId: formData.transportId.value,
      custommer: {
        ...formData.custommer, 
        province: formData.custommer.province.id,
        district: formData.custommer.district.id,
        subDistrict: formData.custommer.subDistrict.id,
      }
  
    }
    const response = await axios.post(`${GolbalConfig.URL}/order/neworder`,newFromData,{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
