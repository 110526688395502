import { useForm, SubmitHandler } from 'react-hook-form';
import { useStore } from '../../hooks/store';
import { Textarea, Button, Input } from '@material-tailwind/react';
import { ApproveCheckOutType, ApproveDayoffType } from '../../types/TimeCardTypes';
import { ApproveCheckOut, ApproveDayoff, ApproveLeaveDay } from '../../hooks/TimeCardCheckin';
import Swal from 'sweetalert2';
import { DeleteTimeCard } from '../../hooks/TimeCardCheckin';


interface ApproveCardPropsTypes {
    usernames: string;
    checkInTime: string;
    timeCardId: number;
    remarkCheckIn: string;
    workStatus: string;
    checkInDate: string;
    refreshList: () => void;
    checkOutDate: string | null;
    checkOutTime: string | null;


}

const ApproveDayoffandLeaveDayCard: React.FC<ApproveCardPropsTypes> = ({ usernames, checkInTime, timeCardId, remarkCheckIn, workStatus, checkInDate, refreshList }) => {
    const { username } = useStore();
  
    const { register: register2, handleSubmit: handleSubmit2 } = useForm({
        defaultValues: {
            username: usernames,
            approveCheckOutBy: username,
            remarkApproveCheckOut: '',
        }
    });


    const approveDayoffSubmit: SubmitHandler<ApproveDayoffType> = async (data) => {
        if (workStatus === "3") {
            const DayoffRes = await ApproveDayoff(timeCardId, data);
            if (DayoffRes.message === 'Success') {
                Swal.fire({
                    title: 'เรียบร้อย',
                    text: 'อนุมัติวันลาสำเร็จ',
                    icon: 'success'
                });
                refreshList();
            }
            console.log('dayoffRes', DayoffRes)
        } else {
            const LeaveDayRes = await ApproveLeaveDay(timeCardId, data);
            console.log('LeaveDayRes', LeaveDayRes);
            if (LeaveDayRes.message === 'Success') {
                Swal.fire({
                    title: 'เรียบร้อย',
                    text: 'อนุมัติวันลาสำเร็จ',
                    icon: 'success'
                });
                refreshList();
            }
        }
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'ต้องการลบใช่หรือไม่',
            text: 'สถานะเรียบร้อยคุณต้องการลบใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // User confirmed the deletion
                const resDelete = await DeleteTimeCard(timeCardId);
                console.log("TimeCard Delete", resDelete);
                if (resDelete.message == "Success") {
                    Swal.fire(
                        'ลบแล้ว!',
                        'ลบการกระทำสำเร็จ.',
                        'success'
                    );
                }

                refreshList();


            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your time card is safe :)',
                    'error'
                );
            }
        });
    };


    return (
        <>
            <div className='p-4'>
                <h1 className='px-4 text-xl font-semibold text-center my-4'>อนุมัติวันลา</h1>
                <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr className="border-b border-blue-gray-100 bg-green-50 p-4 text-sm">
                            <th className='p-4'>พนักงาน</th>
                            <th>ประเภท</th>
                            <th>วัน/เวลา เข้างาน</th>
                            <th>วัน/เวลา ออกงาน</th>
                            <th>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody className='border-2'>
                        <tr>
                            <td className='p-4 text-sm'>{usernames}</td>
                            <td>{workStatus == '1' ? <span className='text-sm text-green-500'>ทำงานปกติ</span> : workStatus == '2' ? <span className='text-sm text-blue-500'>OT</span> : workStatus == '3' ? <span className='text-sm text-orange-500'>หยุดตามสิทธิ์</span> : <span className='text-sm text-red-500'>ลางานไม่รับรายวัน</span>}</td>
                            <td>
                                <div className='flex flex-col'>
                                    <span className='text-sm text-green-500'>{checkInDate}</span>
                                    <span className='text-sm text-blue-500'>{checkInTime}</span>
                                </div>
                            </td>
                            <td>
                                <div className='flex flex-col'>
                                    <span className='text-sm text-green-500'>{checkInDate}</span>
                                    <span className='text-sm text-orange-900'>{checkInTime}</span>
                                </div>
                            </td>
                            <td>
                                <span className='text-sm'>{remarkCheckIn}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='p-4'>
                <form onSubmit={handleSubmit2(approveDayoffSubmit)}>
                    <div className='flex flex-col gap-2'>
                        {workStatus === '3' || workStatus === '4' ? (
                            <Textarea label='หมายเหตุการอนุมัติ' {...register2('remarkApproveCheckOut')} />
                        ) : null}
                    </div>
                    <div className='flex gap-1 justify-end'>
                        <Button className=' w-24' type='submit'>อนุมัติ</Button>
                        <Button className='w-24 bg-red-500' onClick={handleDelete}>ไม่อนุมัติ</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ApproveDayoffandLeaveDayCard