import { useState, useEffect } from "react";
import { SuccessOrderList, DeleteDetailOrder } from "../hooks/order";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import {
  Card,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tab,
  Input,
  Select,
  Option,
  Button,
  Dialog,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faTShirt,
  faUserCircle,
  faFileInvoiceDollar,
  faBank,
  faMoneyBillTransfer,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { GetShopIdList } from "../hooks/GetShopId";
import { ShopIdTypes } from "../types/ShopIDTypes";
import Step67addimagesCard from "../components/AddImage/Step67submitCard";
import CalendarComponent from "../components/CalendarComponent";
import Swal from "sweetalert2";
import { GenPDF } from "../hooks/Bill";
import { useStore } from "../hooks/store";

const Success = () => {
  const [Oderlist, setupOderList] = useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [viewImage, setViewImage] = useState<string | null>(null);
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  // const numRefNumber = parseFloat(refNumber || "0");
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);
  const [open67AddImage, set67AddImage] = useState(false);
  const handle67AddImage = () => set67AddImage(!open67AddImage);
  const [IDSelect, setIDSelect] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");
  const { role } = useStore();

  const handleDateChange = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleImageClick = (imgSrc: string) => {
    setCurrentImage(imgSrc);
    setIsDialogOpen(true);
  };

  const fetchData = async (page: number) => {
    const dataPushbullet: OrderResponse = await SuccessOrderList(
      page,
      perPage,
      search,
      numSelectedShop,
      startDate,
      endDate
    );
    const formattedData = dataPushbullet.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setupOderList(formattedData);
    setCurrentPage(dataPushbullet.page);
    setTotalItems(dataPushbullet.total);
    setPerPage(dataPushbullet.per_page);
  };

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
    fetchData(currentPage);
  }, [currentPage, perPage, search, selectedShop, startDate, endDate]);

  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  //// explanablerow //////

  const handleRowExpand = (rowIndex: number) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null); // If the row is already expanded, collapse it.
    } else {
      setExpandedRow(rowIndex); // Otherwise, expand this row.
    }
  };
  /////////////////////////

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1); // It's often a good practice to reset to the first page when perPage changes
  };
  const numberOfPages = Math.ceil(totalItems / perPage);

  const handleGenPDF = async (id: number) => {
    try {
      Swal.fire({
        icon: "question",
        title: "ปริ้น PDF",
        text: `คุณต้องการออกใบเสร็จใช่หรือไม่`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
        allowOutsideClick: false, // Don't close on outside click
        allowEscapeKey: false, // Don't close on ESC key
        allowEnterKey: false, // Don't close on Enter key
      }).then(async (confirmResult) => {
        if (confirmResult.isConfirmed) {
          const genbillPDF = await GenPDF(id);
          window.open(`https://cmfashion.pro/bill/pdf?id=${id}`);
        }
      });
    } catch (error) {
      console.log("Error from Delete PushBullet", error);
    }
  };

  const handlerdeleteOrderDetail = async (id: number) => {
    try {
      Swal.fire({
        icon: "question",
        title: "ลบสินค้า",
        text: `คุณต้องการลบสินค้าใช่หรือไม่`,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
        allowOutsideClick: false, // Don't close on outside click
        allowEscapeKey: false, // Don't close on ESC key
        allowEnterKey: false, // Don't close on Enter key
      }).then(async (confirmResult) => {
        if (confirmResult.isConfirmed) {
          const deleteResponse = await DeleteDetailOrder(id);
          if (deleteResponse.status === true) {
            Swal.fire("สำเร็จ!", "ลบรายการนี้เรียบร้อยแล้ว.", "success");
            window.location.reload();
          }
        }
      });
    } catch (error) {
      console.log("Error from Delete PushBullet", error);
    }
  };

  return (
    <>
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center shadow-lg ">
        <div className="lg:flex lg:flex-row lg:flex-wrap lg:justify-end lg:px-2 ">
          <div className="lg:flex lg:flex-row  flex flex-col  gap-2">
            <div>
              <CalendarComponent onDateChange={handleDateChange} />
            </div>

            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearch(e.target.value)
              }
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ""}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>
                  {shopdata.shopName}
                </Option>
              ))}
            </Select>
            <div className="flex flex-col">
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
          <table className="w-full min-w-max table-auto text-center overflow-scroll">
            <thead>
              <tr className="border-y border-blue-gray-100 bg-blue-gray-50/50 text-sm">
                <td></td>
                <td className="p-4">ลำดับ</td>
                <td className="px-4">ID</td>
                <td className="px-4">คำสั่งซื้อ</td>
                <td className="px-4">ชื่อ-นามสกุล</td>
                <td className="px-4">วันที่สร้าง</td>
                <td className="px-4">สาขา</td>
                <td className="px-4">ราคาชุด</td>
                <td className="px-4">ผู้สร้าง</td>
                <td className="px-4">วันที่สำเร็จ</td>
                <td className="px-4">PDF</td>
              </tr>
            </thead>
            <tbody>
              {Oderlist.map((detail, index) => {
                return (
                  <>
                    <tr key={index} className="even:bg-blue-gray-50/50 text-xs">
                      <td
                        className="p-6 cursor-pointer text-sm "
                        onClick={() => handleRowExpand(index)}
                      >
                        {expandedRow === index ? "-" : "+"}
                      </td>
                      <td className="p-6">{detail.Rows}.</td>
                      <td>{detail.orderId}</td>
                      <td>{detail.refNumber}</td>
                      <td>
                        {detail.order.depositCustommer.depositFirstName}{" "}
                        {detail.order.depositCustommer.depositLastName}
                      </td>
                      <td>{detail.order.cretedDate}</td>
                      <td>{detail.order.shopName}</td>
                      <td>
                        {" "}
                        {`${detail.order.orderDetails.reduce(
                          (accumulator, detail) => accumulator + detail.price,
                          0
                        )}`}
                      </td>
                      <td>{detail.order.byAdmin}</td>
                      <td>{detail.order.completedDate}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className="text-2xl"
                          onClick={() => {
                            handleGenPDF(detail.orderId);
                          }}
                        />
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={11}>
                          <div className="border-2 rounded-lg p-4 my-2 bg-gray-300/50">
                            <span className="item-">หมายเลขคำสั่งซื้อ #</span>{" "}
                            {detail.order.refNumber}
                            <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-xs lg:text-sm ">
                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faUserCircle}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-2.5 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  ข้อมูลลูกค้า
                                </h2>
                                <span>
                                  ชื่อผู้เช่า:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.custommerFirstName}{" "}
                                    {detail.order.custommerLastName}
                                  </span>
                                </span>
                                <span>
                                  ที่อยู่:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.address}{" "}
                                    {detail.order.subDistrict}{" "}
                                    {detail.order.district}{" "}
                                    {detail.order.province}{" "}
                                    {detail.order.zipCode}
                                  </span>
                                </span>
                                <span>
                                  เบอร์โทร:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.telSend}
                                  </span>
                                </span>
                                <span>
                                  IG :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.IG}
                                  </span>
                                </span>
                                <span>
                                  Line:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.line}
                                  </span>
                                </span>
                                <span>
                                  Facebook:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.facebook}
                                  </span>
                                </span>
                              </Card>

                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faMapPin}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-4 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  รายละเอียดการเช่า (OrderID# {detail.orderId})
                                </h2>
                                <span>
                                  วันที่สร้าง :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.cretedDate}
                                  </span>
                                </span>
                                <span>
                                  วันเช่า/วันคืน :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.startDate} -{" "}
                                    {detail.order.endDate}
                                  </span>
                                </span>
                                <span>
                                  สาขา:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.shopName}
                                  </span>
                                </span>
                                <span>
                                  ขนส่ง:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.transportName}
                                  </span>{" "}
                                  เลขพัสดุ:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.tackingNumber}{" "}
                                  </span>
                                </span>
                                <span>
                                  หมายเหตุ :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.orderRemark}
                                  </span>
                                </span>
                              </Card>

                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faTShirt}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-2 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  รายละเอียดชุด
                                </h2>
                                <table className="w-full table-auto text-xs my-4">
                                  <thead className="bg-gray-300">
                                    <tr>
                                      <th>ลำดับ</th>
                                      <th>สินค้า</th>
                                      <th>ค่ามัดจำ</th>
                                      <th>ราคา</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {detail.order.orderDetails.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}.</td>
                                          <td>
                                            {data.detailName} x{data.quantity}
                                          </td>
                                          <td>{data.depositPrice}</td>
                                          <td>{data.price}</td>
                                          {role === "admin" ? (
                                            <td>
                                              <span
                                                className=" cursor-pointer hover:text-red-500"
                                                onClick={() => {
                                                  handlerdeleteOrderDetail(
                                                    data.orderDetailsId
                                                  );
                                                }}
                                              >
                                                x
                                              </span>
                                            </td>
                                          ) : null}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot className="bg-gray-300">
                                    <tr>
                                      <th colSpan={2}>รวม</th>
                                      <th className="py-1">
                                        {`${detail.order.orderDetails.reduce(
                                          (accumulator, detail) =>
                                            accumulator + detail.depositPrice,
                                          0
                                        )}`}
                                      </th>
                                      <th>
                                        {`${detail.order.orderDetails.reduce(
                                          (accumulator, detail) =>
                                            accumulator + detail.price,
                                          0
                                        )}`}
                                      </th>
                                      <th></th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Card>
                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faFileInvoiceDollar}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-3.5 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  ข้อมูลโอนมัดจำ
                                </h2>
                                <h3>
                                  <span className="font-bold">
                                    ชื่อ-นามสกุล :
                                  </span>{" "}
                                  <span className="text-pink-500">
                                    {
                                      detail.order.depositCustommer
                                        .depositFirstName
                                    }{" "}
                                    {
                                      detail.order.depositCustommer
                                        .depositLastName
                                    }
                                  </span>
                                </h3>
                                <h3>
                                  <span className="font-bold">
                                    ช่องทางรับเงิน :
                                  </span>{" "}
                                  {detail.order.depositCustommer.bankName ? (
                                    <span className="text-green-500">
                                      {detail.order.depositCustommer.bankName}
                                    </span>
                                  ) : (
                                    <span className="text-blue-500">
                                      พร้อมเพย์
                                    </span>
                                  )}
                                </h3>
                                <h3>
                                  <span className="font-bold">
                                    หมายเลขบัญชี/พร้อมเพย์:
                                  </span>
                                  {detail.order.depositCustommer.bankAccount ? (
                                    <span className="text-green-500">
                                      {
                                        detail.order.depositCustommer
                                          .bankAccount
                                      }
                                    </span>
                                  ) : (
                                    <span className="text-blue-500">
                                      {detail.order.depositCustommer.prompay}
                                    </span>
                                  )}{" "}
                                </h3>
                              </Card>
                              <Card className=" items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faBank}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-3 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  รายละเอียดคืนเงินลูกค้า
                                </h2>
                                <div className="flex flex-col items-start">
                                  <h3>
                                    <span className="font-bold">
                                      จำนวนชุด :
                                    </span>{" "}
                                    {detail.order.depositRefund.item} ชุด
                                  </h3>
                                  <h3>
                                    <span className="font-bold">
                                      รวมราคาชุด :
                                    </span>{" "}
                                    {detail.order.depositRefund.totalPrice} บาท
                                  </h3>
                                  <h3>
                                    <span className="font-bold">
                                      ราคารวมมัดจำ:
                                    </span>{" "}
                                    {
                                      detail.order.depositRefund
                                        .totalDepositPrice
                                    }{" "}
                                    บาท
                                  </h3>
                                  <h3>
                                    <span className="font-bold">
                                      ค่าบริการเพิ่มเติม:
                                    </span>{" "}
                                    <span className="text-red-500 text-xl">
                                      {detail.order.depositRefund.serviceFee}
                                    </span>{" "}
                                    บาท
                                  </h3>
                                  <h3 className="text-xl font-bold text-pink-500 underline underline-offset-2">
                                    <span className="font-bold">รวม</span>{" "}
                                    {detail.order.depositRefund.sumPriceOrder}{" "}
                                    บาท
                                  </h3>
                                </div>
                              </Card>
                              <div className="flex flex-col gap-2">
                                <div className="grid grid-col-3 grid-flow-col gap-2">
                                  <Card className="p-4">
                                    <FontAwesomeIcon
                                      icon={faMoneyBillTransfer}
                                      className="text-3xl text-green-500"
                                    />
                                    <span className="font-bold">
                                      คืนเงินลูกค้า
                                    </span>
                                    <span className="text-2xl text-green-500">
                                      {detail.order.depositRefund.refund}
                                    </span>
                                  </Card>
                                  <Card className="p-4">
                                    <FontAwesomeIcon
                                      icon={faMoneyBillTransfer}
                                      className="text-3xl text-red-500"
                                    />
                                    <span className="font-bold">
                                      หักค่ามัดจำ
                                    </span>
                                    <span className="text-2xl text-red-500">
                                      -{detail.order.depositRefund.withHold}
                                    </span>
                                  </Card>
                                  <Card className="p-4">
                                    <FontAwesomeIcon
                                      icon={faMoneyBillTransfer}
                                      className="text-3xl text-blue-500"
                                    />
                                    <span className="font-bold">
                                      คืนเงินค่ามัดจำ
                                    </span>
                                    <span className="text-2xl text-blue-500">
                                      {detail.order.depositRefund.sumRepay}
                                    </span>
                                  </Card>
                                </div>
                                <div>
                                  <Card className="p-4 items-start">
                                    <h2 className="text-pink-500 font-bold underline">
                                      หมายเหตุ
                                    </h2>
                                    {detail.order.depositRefund.refundRemark}
                                  </Card>
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <Card className="p-4">
                                <Tabs value="1">
                                  <TabsHeader>
                                    <Tab
                                      value="1"
                                      className="lg:text-sm text-xs"
                                    >
                                      สลิปลูกค้าโอนเงิน
                                    </Tab>
                                    <Tab
                                      value="2"
                                      className="lg:text-sm text-xs"
                                    >
                                      รูปสินค้าก่อนส่ง
                                    </Tab>
                                    <Tab
                                      value="3"
                                      className="lg:text-sm text-xs"
                                    >
                                      รูปสินค้าคืนจากลูกค้า
                                    </Tab>
                                    <Tab
                                      value="4"
                                      className="lg:text-sm text-xs"
                                    >
                                      สลิปโอนมัดจำ
                                    </Tab>
                                  </TabsHeader>
                                  <TabsBody>
                                    <TabPanel value="1">
                                      <div className="flex gap-2 object-scale-down h-48 w-96">
                                        {detail.order.paySlip.images &&
                                          detail.order.paySlip.images.length >
                                          0 ? (
                                          detail.order.paySlip.images.map(
                                            (data, index) => (
                                              <img
                                                key={index}
                                                src={data.filePath}
                                                alt={`Payslip Image ${index}`}
                                                className=""
                                                onClick={() =>
                                                  handleImageClick(
                                                    data.filePath
                                                  )
                                                }
                                              />
                                            )
                                          )
                                        ) : (
                                          <span>ยังไม่มีภาพสลิป</span>
                                        )}
                                      </div>
                                    </TabPanel>

                                    {/* Tab 2 */}
                                    <TabPanel value="2">
                                      <div className="flex gap-2 object-scale-down h-48 w-96">
                                        {detail.order.sendProductImage.images &&
                                          detail.order.sendProductImage.images
                                            .length > 0 ? (
                                          detail.order.sendProductImage.images.map(
                                            (data, index) => (
                                              <img
                                                key={index}
                                                src={data.filePath}
                                                alt={`Product Image ${index}`}
                                                className="w-48"
                                                onClick={() => {
                                                  if (data.filePath) {
                                                    handleImageClick(
                                                      data.filePath
                                                    );
                                                  }
                                                }}
                                              />
                                            )
                                          )
                                        ) : (
                                          <span>ยังไม่มีภาพ</span>
                                        )}
                                      </div>
                                    </TabPanel>

                                    {/* Tab 3 */}
                                    <TabPanel value="3">
                                      <div className="flex gap-2 object-scale-down h-48 w-96">
                                        {detail.order.returnProductImage
                                          .images &&
                                          detail.order.returnProductImage.images
                                            .length > 0 ? (
                                          detail.order.returnProductImage.images.map(
                                            (data, index) => (
                                              <img
                                                key={index}
                                                src={data.filePath}
                                                alt={`Return Image ${index}`}
                                                className="w-48"
                                                onClick={() =>
                                                  handleImageClick(
                                                    data.filePath
                                                  )
                                                }
                                              />
                                            )
                                          )
                                        ) : (
                                          <span>ยังไม่มีภาพ</span>
                                        )}
                                      </div>
                                    </TabPanel>

                                    {/* Tab 4 */}
                                    <TabPanel value="4">
                                      <div className="flex gap-2 object-scale-down h-48 w-96">
                                        {detail.order.refundSlip.images &&
                                          detail.order.refundSlip.images.length >
                                          0 ? (
                                          detail.order.refundSlip.images.map(
                                            (data, index) => (
                                              <img
                                                key={index}
                                                src={data.filePath}
                                                alt={`Refund Image ${index}`}
                                                className="w-48"
                                                onClick={() =>
                                                  handleImageClick(
                                                    data.filePath
                                                  )
                                                }
                                              />
                                            )
                                          )
                                        ) : (
                                          <span>ยังไม่มีภาพสลิป</span>
                                        )}
                                      </div>
                                      <div className="my-2">
                                        <Button
                                          onClick={() => {
                                            handle67AddImage();
                                            setIDSelect(detail.orderId);
                                          }}
                                        >
                                          เพิ่มรูปภาพ
                                        </Button>
                                      </div>
                                    </TabPanel>
                                  </TabsBody>
                                </Tabs>
                              </Card>

                              {/* Modal for viewing larger image */}
                              {viewImage && (
                                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                                  <img
                                    src={viewImage}
                                    className="max-w-screen-lg max-h-screen-lg"
                                    onClick={() => setViewImage(null)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-col mt-4 justify-center items-center">
            <div className="flex items-center">
              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>

              {[1, 2, 3, 4, 5].map(pageNum => (
                <button
                  key={pageNum}
                  className={`mx-1 px-3 py-1 text-sm rounded-md ${currentPage === pageNum ? 'bg-blue-500 text-white' : 'text-gray-500'}`}
                  onClick={() => handlePageChange(pageNum)}
                >
                  {pageNum}
                </button>
              ))}

              <span className="mx-1 text-sm text-gray-500">...</span>

              <button
                className="mx-1 px-3 py-1 text-sm rounded-md text-gray-500"
                onClick={() => handlePageChange(22)}
              >
                22
              </button>

              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === numberOfPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            </div>
            <div className="flex items-center">
              <select
                className="border rounded-md p-1 text-sm"
                value={perPage}
                onChange={handlePerPageChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="mt-2 text-sm text-gray-500">
              Results: 1 - 15 of 324
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open67AddImage} handler={handle67AddImage}>
        <Step67addimagesCard id={IDSelect} />
      </Dialog>
      <Dialog
        open={isDialogOpen}
        {...({ toggler: () => setIsDialogOpen(false) } as any)}
      >
        <div
          className="flex justify-center"
          onClick={() => setIsDialogOpen(false)}
        >
          <img src={currentImage} alt="Selected Image" />
        </div>
      </Dialog>
    </>
  );
};

export default Success;
