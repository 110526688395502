import React, { useEffect, useState, useCallback } from 'react';
import { FormData, Response, AdminUpdateTypes } from '../../types/AdminListTypes';
import { findUserbyID, UpdateUser } from '../../hooks/UserAdmin';
import { Input, Select, Option, Button } from '@material-tailwind/react';
import Swal from 'sweetalert2';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

interface EditUserCardTypes {
  userID: string | undefined;
  closeEditUserCard: () => void;
}

const EditUserCard: React.FC<EditUserCardTypes> = ({ userID, closeEditUserCard }) => {
  const { register, handleSubmit, setValue, control } = useForm<AdminUpdateTypes>({
    defaultValues: {
      nickname: '',
      username: '',
      password: '',
      role: '',
      commission: null,
      commissionType: '',
    }
  });

  const [userData, setUserData] = useState<Response>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await findUserbyID(String(userID));
        setUserData(data);
        setValue('username', data?.user.username);
        setValue('nickname', data?.user.nickname);
        setValue('password', data?.user.password);
        setValue('commission', data?.user.commission);
        setValue('role', data?.user.role);
        setValue('commissionType', data?.user.commissionType);

      } catch (err) {
        console.error('An error occurred while', err);
      }
    };

    fetchData();
  }, [userID, setValue]);

  const onSubmit: SubmitHandler<AdminUpdateTypes> = async (data) => {
    Swal.fire({
      icon: 'question',
      title: 'เปลี่ยนแปลงข้อมูล',
      text: 'ท่านต้องการเปลี่ยนแปลงข้อมูล Admin ใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then(async (confirmResult) => {
      if (confirmResult.isConfirmed) {
        try {
          if (userID !== undefined) {
            const response = await UpdateUser(userID, data);

            if (response && response.status === 200) {
              Swal.fire('Success!', 'เปลี่ยนแปลงข้อมูลสำเร็จ', 'success');
              closeEditUserCard();
              window.location.reload();

            }
          }
        } catch (error) {
          console.error('Error updating user:', error);
          Swal.fire('Error!', 'An error occurred while updating the user.', 'error');
        }
      }
    });

  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid lg:grid-cols-2 gap-3'>
          <Input
            crossOrigin="anonymous"
            label='Username'
            {...register('username')}
          />
          <Input
            crossOrigin="anonymous"
            label='ชื่อเรียก'
            {...register('nickname')}
          />

          <Controller
            name="role"
            control={control}  // Use control directly here
            render={({ field }) => (
              <Select
                label="ตำแหน่ง"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
              >
                <Option value="admin">Admin</Option>
                <Option value="manager">Manager</Option>
                <Option value="employee">Employee</Option>
              </Select>
            )}
            defaultValue={userData?.user.role}
          />


          <Input
            crossOrigin="anonymous"
            label='Password'
            {...register('password')}
          />
          <Input
            crossOrigin="anonymous"
            label='คอมมิสชั่น'
            {...register('commission')}
          />

          <Controller
            name="commissionType"
            control={control}  // And here
            render={({ field }) => (
              <Select
                label="หน่วยคอมมิสชั่น"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
              >
                <Option value="1">บาท</Option>
                <Option value="2">%</Option>
              </Select>
            )}
            defaultValue={userData?.user.commissionType}
          />

        </div>
        <div className='flex flex-col mt-3'>
          <Button type='submit'>บันทึกการเปลี่ยนแปลง</Button>
        </div>
      </form>

    </>
  )
}

export default EditUserCard
