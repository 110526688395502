import { useState, useEffect, useCallback } from 'react';
import { GetBankDATA } from '../../types/PushBulletTypes';
import { FindBank } from '../../hooks/pushbullet';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Input, Button } from '@material-tailwind/react';
import { UpdatePushBullet } from '../../hooks/pushbullet';
import Swal from 'sweetalert2';

interface EditPushDetailType {
    id: number | null;
    closeDialog: () => void;
}

const EditPushDetail: React.FC<EditPushDetailType> = ({ id, closeDialog }) => {
    const [detailData, setDetailData] = useState<GetBankDATA>();
    const { register, handleSubmit, setValue } = useForm<GetBankDATA>({
        defaultValues: {
            bankName: '',
            bankAccount: '',
            bankToken: '',
            bankRef: 0,
            bankThread: '',
            lineToken: '',
            withdrawlineToken: '',
            nameAccount: '',
        },
    });

    useEffect(() => {
        const fetchBankData = async () => {
            const data = await FindBank(Number(id));
            setDetailData(data.bankData);
        };

        fetchBankData();
    }, [id]);

    const NewBankData = useCallback(() => {
        try {
            setValue('bankName', detailData?.bankName ?? '');
            setValue('bankAccount', detailData?.bankAccount ?? '');
            setValue('bankToken', detailData?.bankToken ?? '');
            setValue('bankRef', detailData?.bankRef ?? 0);
            setValue('bankThread', detailData?.bankThread ?? '');
            setValue('lineToken', detailData?.lineToken ?? '');
            setValue('withdrawlineToken', detailData?.withdrawlineToken ?? '');
            setValue('nameAccount', detailData?.nameAccount ?? '');
        } catch (error) {
            console.log(error);
        }

    }, [detailData, setValue]);

    useEffect(() => {
        NewBankData();
    }, [NewBankData]);

    const SubmitUpdate: SubmitHandler<GetBankDATA> = async (data) => {
        Swal.fire({
            icon: 'question',
            title: 'อัพเดทข้อมูล PushBullet',
            text: 'คุณได้ตรวจสอบข้อมูลถูกต้องและต้องการอัพเดทข้อมูลใช่หรือไม่',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่',
            allowOutsideClick: false,    // Don't close on outside click
            allowEscapeKey: false,      // Don't close on ESC key
            allowEnterKey: false        // Don't close on Enter key
        }).then(async (confirmResult) => {
            if (confirmResult.isConfirmed) {
                try {
                    const confirmrestponse = await UpdatePushBullet(Number(id), data);
                    if (confirmrestponse.message === 'Success') {
                        Swal.fire({
                            icon: 'success',
                            title: 'สำเร็จ',
                            text: 'บันทึกการเปลี่ยนแปลงสำเร็จ',
                        }).then(() => {
                            closeDialog();
                        });
                    }
                    console.log("Response", confirmrestponse);
                } catch (error) {
                    console.log("form Update Bank", error);
                }
                console.log("Update Data", data);
            }
        });

    };

    return (
        <>
            <div className='px-4 pt-4'>
                <h2 className='text-xl font-bold underline underline-offset-2'>แก้ไข PushBullet</h2>
            </div>

            <form className='p-4 grid grid-cols-2 gap-2' onSubmit={handleSubmit(SubmitUpdate)}>
                <Input
                    crossOrigin="anonymous"
                    label='ชื่อธนาคาร'
                    {...register("bankName")}
                />
                <Input
                    crossOrigin="anonymous"
                    label='เลขบัญชี'
                    {...register('bankAccount')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='bankToken'
                    {...register('bankToken')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='bankRef'
                    {...register('bankRef')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='bankThread'
                    {...register('bankThread')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='lineToken'
                    {...register('lineToken')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='withdrawlineToken'
                    {...register('withdrawlineToken')}
                />
                <Input
                    crossOrigin="anonymous"
                    label='nameAccount'
                    {...register('nameAccount')}
                />

                <div>
                    <Button type='submit'>บันทึกข้อมูล</Button>
                </div>
            </form>


        </>

    )
}

export default EditPushDetail