import React, { useState } from "react";
import {
  Card,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { UploadStep45 } from "../../hooks/Uppload";
import { UpdateStep45 } from "../../hooks/ChangeStatus";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes } from "../../types/UploadSlipTypes";
import Swal from "sweetalert2";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Step45Types {
  closeChagestepDialog: () => void;
  row: OrderTableRow;
}

const Step45submitCard: React.FC<Step45Types> = ({
  row,
  closeChagestepDialog,
}) => {
  const [images, setImages] = useState<ImageListType>([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: row.orderId,
      imageStatus: 5,
    },
  });
  const onChange = (imageList: ImageListType) => {
    const fileList: File[] = [];
    imageList.forEach((img) => {
      if (img.file) {
        fileList.push(img.file);
      }
    });
    setValue("file", fileList as any); // Using 'as any' to bypass the type error for now
    setImages(imageList);
  };


  const onSubmit = async (data: UploadTypes) => {
    if (!data.file || data.file.length === 0) {
      Swal.fire(
        "Error",
        "Please select at least one image before submitting.",
        "error"
      );
      return;
    }

    try {
      const result = await Swal.fire({
        title: "อัฟโหลดและเปลี่ยนสถานะ?",
        text: "ต้องการอัฟสลิปและเปลี่ยนสถานะใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        for (let i = 0; i < data.file.length; i++) {
          formData.append("file", data.file[i]);
        }
        formData.append("orderId", data.orderId ? data.orderId.toString() : "");
        formData.append("imageStatus", data.imageStatus.toString());

        const response = await UploadStep45(formData);

        if (response[0].status === true) {
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "บันทึกการเปลี่ยนแปลงสำเร็จ",
          }).then(() => {
            window.location.reload();
          });
        }
      } else {
        Swal.fire("ยกเลิก", "ยกเลิกการกระทำแล้ว", "error");
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "อัฟโหลดไม่สำเร็จ",
      });
    }
  };

  const changeStatus45 = async (id: number) => {
    const response45 = await UpdateStep45(id);
    if (response45.status === true) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "อัฟโหลดรูปภาพสำเร็จ",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const onSubmitChageStatus = async (id: number) => {
    Swal.fire({
      icon: "question",
      title: "เปลี่ยนสถานะ",
      text: "ต้องการเปลี่ยนสถานะสำหรับออร์เดอร์นี้ใช่หรือไม่",
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่ใช่",
      allowOutsideClick: false, // Don't close on outside click
      allowEscapeKey: false, // Don't close on ESC key
      allowEnterKey: false, // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        changeStatus45(id);
      }
    });
  };

  return (
    <>
      <Card className="p-8 flex flex-col gap-3">
        <Tabs value="img">
          <TabsHeader>
            <Tab value={"img"} className="text-xs lg:text-sm">
              อัฟโหลดรูปภาพ
            </Tab>
            <Tab value={"update"} className="text-xs lg:text-sm">
              เปลี่ยนสถานะ
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel value={"img"} className="flex flex-col justify-center items-center">
              <h2 className="font-bold underline underline-offset-2 my-2 text-sm">
                อัฟโหลดรูปภาพ{" "}
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card className="lg:p-8 lg:flex gap-2 flex flex-col flex-wrap p-4 lg:w-[800px] lg:mt-8 ">
                  <div>
                    <h2 className="font-bold">
                      เปลี่ยนสถานะรอโอนมัดจำเป็นเรียบร้อย{" "}
                    </h2>
                    <p className="text-xs">
                      *** กรุณาอัฟโหลดรูปภาพสลิป ก่อนกดปุ่มเปลี่ยนสถานะ
                    </p>
                  </div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={10}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="flex flex-col  justify-center border p-8 lg:flex lg:flex-col items-center">
                        <div
                          style={isDragging ? { color: "red" } : undefined}
                          className="my-2 "
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <FontAwesomeIcon icon={faPlus} /> เลือกรูปภาพ
                        </div>
                        {imageList.map((image, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-center gap-1 my-2 lg:flex-col lg:items-center"
                          >
                            <img
                              src={image.dataURL}
                              alt=""
                              className="lg:w-[30%]"
                            />
                            <Button
                              className="lg:w-24 mt-2 lg:mt-0"
                              size="sm"
                              color="red"
                              onClick={() => onImageRemove(index)}
                            >
                              ลบรูปภาพ
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>

                  {/* Hidden input for react-hook-form */}
                  <input
                    {...register("file")}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <input
                    {...register("orderId")}
                    type="hidden"
                    value={row.orderId.toString()}
                  />
                  <input {...register("imageStatus")} type="hidden" value="1" />
                  <div className="flex flex-col ">
                    <Button size="sm" type="submit" color="green">
                      บันทึก
                    </Button>
                    ฺ
                    <Button
                      size="sm"
                      onClick={closeChagestepDialog}
                      className="-mt-5"
                    >
                      {" "}
                      ปิดหน้าต่างนี้
                    </Button>
                  </div>
                </Card>
              </form>
            </TabPanel>
            <TabPanel value={"update"}>
              <h2 className="font-bold underline underline-offset-2">
                เปลี่ยนสถานะ{" "}
              </h2>
              <div className="flex flex-col">
                <Button
                  onClick={() => {
                    onSubmitChageStatus(row.orderId);
                  }}
                >
                  {" "}
                  เปลี่ยนสถานะ
                </Button>
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>
        <div className="flex gap-2 flex-wrap justify-center">
          {row.order?.returnProductImage?.images.map((image, index) => (
            <img
              key={index}
              src={image.filePath}
              alt={image.fileName}
              className="w-60 rounded-lg shadow-lg"
            />
          ))}
        </div>
      </Card>
    </>
  );
};

export default Step45submitCard;
