import React from "react";
import { Card, Button, Dialog } from "@material-tailwind/react";
import Adminlist from "../components/User/Adminlist";
import AddNewuser from "../components/User/AddNewuser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AdminList = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Card className="p-8 my-4">
        <div className="flex flex-col gap-4">
          <Button className="w-48" onClick={handleOpen}> <FontAwesomeIcon icon={faPlus} className="mr-1" /> เพิ่มพนักงานใหม่</Button>
          <hr />
          <Adminlist />
        </div>
      </Card>
      <Dialog open={open} handler={handleOpen} className="p-8 ">
        <h1 className=" font-bold">เพิ่มพนักงานใหม่</h1>
        <AddNewuser />
      </Dialog>
    </>
  );
};

export default AdminList;
