import { useForm, SubmitHandler } from "react-hook-form";
import { SmsInfo } from '../../types/SMSProviderlistTypes';
import { Input,Button } from "@material-tailwind/react";
import { useEffect } from 'react';
import { findSmsbyID,UpdateSMS } from "../../hooks/Sms";



interface EditSMSProviderTypes {
  smsID: number | undefined;
  closeSMSedit: () => void;
}

const EditSMSProvider: React.FC<EditSMSProviderTypes> = ({ smsID, closeSMSedit }) => {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<SmsInfo>({
    defaultValues: {
      smsId: 0,
      smsProviders:'',
      token: null,
      apiKey:'',
      apiSecret:'',
      urlSendSms:'',
      smsSenderName:'',
      otpTimer: 0,
    },
  });

  const _onSubmit: SubmitHandler<SmsInfo> = async (data) => {
    if (typeof smsID !== 'undefined') {
      const updatedData = await UpdateSMS(smsID, data);
    }
    closeSMSedit();
  };

  const SmsDataDetail =  async () => {
    try {
      const data = await findSmsbyID(Number(smsID));
      setValue('smsId',data.sms.smsId)
      setValue('smsProviders',data.sms.smsProviders)
      setValue('token',data.sms.token)
      setValue('apiKey',data.sms.apiKey)
      setValue('apiSecret',data.sms.apiSecret)
      setValue('urlSendSms',data.sms.urlSendSms)
      setValue('smsSenderName',data.sms.smsSenderName)
      setValue('otpTimer',data.sms.otpTimer)
    } catch (err) {
      console.log("Error From SMS Dtail",err);
    }
  };

  useEffect(() => {
    SmsDataDetail();
  }, [smsID]);

  return (
    <>
      <form  onSubmit={handleSubmit(_onSubmit)} className="grid  md:grid-cols-1 lg:grid-cols-2 gap-2" >
        <Input
        crossOrigin="anonymous"
          label="ID"
          {...register("smsId")}
        />
        <Input
        crossOrigin="anonymous"
          label="Provider"
          {...register("smsProviders")}
        />
        <Input
        crossOrigin="anonymous"
          label="Token"
          {...register("token")}
        />
        <Input
        crossOrigin="anonymous"
          label="API Key"
          {...register("apiKey")}
        />
        <Input
        crossOrigin="anonymous"
          label="Secret Key"
          {...register("apiSecret")}
        />
        <Input
        crossOrigin="anonymous"
          label="URL"
          {...register("urlSendSms")}
        />
          <Input
          crossOrigin="anonymous"
          label="SMS Sender"
          {...register("smsSenderName")}
        />
           <Input
           crossOrigin="anonymous"
          label="OTP Timers"
          {...register("otpTimer")}
        />
        <div>
        <Button 
        type="submit"
        >บันทึกข้อมูล</Button>
        </div>
       
      </form>
    </>
  )
}

export default EditSMSProvider