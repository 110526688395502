import { useState } from "react";
import { Card } from "@material-tailwind/react"
import DailyOrderReport from '../components/Report/DailyOrderReport';
import DailySumrepayCard from '../components/Report/DailySumrepayCard';
import AdminCommission from "../components/Report/AdminCommission";
import CalendarComponent from "../components/CalendarComponent";
import AdminQuantity from '../components/Report/AdminQuantity';
import AllPriceAndAllSumreplay from '../components/Report/AllPriceAndAllSumreplay';
import AdminDailyOrderCard from '../components/Report/AdminDailyOrderCard';
import AdminDailyQuantityCard from '../components/Report/AdminDailyQuantityCard';
import SumDepositCard from "../components/Report/SumDepositCard";


const OwnerDashboard = () => {
  const [startDate, setStartDate] = useState<Date | string>('');
  const [endDate, setEndDate] = useState<Date | string>('');
  const handleDateChange = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <>
      <div className="flex flex-col lg:flex lg:flex-row p-4 gap-2 justify-end">
      <Card className="bg-green-200">
          <SumDepositCard />
        </Card>
        <Card className="bg-pink-200">
          <DailySumrepayCard />
        </Card>
        <Card className="bg-blue-200">
          <AdminDailyOrderCard />
        </Card>
        <Card className="bg-cyan-200">
          <AdminDailyQuantityCard />
        </Card>

      </div>
      <div className="flex justify-center lg:flex  lg:justify-start">
        <Card className="w-72">
          <CalendarComponent onDateChange={handleDateChange} />
        </Card>
      </div>


      <div className="lg:grid lg:grid-cols-2 lg:gap-2 my-4 flex flex-col gap-2">
        <div className="">
          <Card>
            <AdminCommission startDate={startDate} endDate={endDate} byAdmin={''} />
          </Card>
        </div>
        <div>
          <Card >
            <AdminQuantity startDate={startDate} endDate={endDate} byAdmin={''}/>
          </Card>

        </div>
        <Card>
          <DailyOrderReport />
        </Card>
        <Card>
          <AllPriceAndAllSumreplay startDate={startDate} endDate={endDate} />
        </Card>
      </div>
    </>
  )
}

export default OwnerDashboard