import React, { useState } from "react";
import { Card, Button, Tabs, TabsHeader, TabsBody, Tab, TabPanel, } from "@material-tailwind/react";
import { UploadStep45 } from "../../hooks/Uppload";
import { UpdateStep45 } from "../../hooks/ChangeStatus";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes } from "../../types/UploadSlipTypes";
import Swal from "sweetalert2";

interface Step45Types {
  // closeChagestepDialog: () => void;
  id: number | null;
}

const Step45addimagesCard: React.FC<Step45Types> = ({ id }) => {

  const { register, handleSubmit, formState: { errors } } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: id,
      imageStatus: 5,
    }
  });

  // State to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Function to handle file input changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
    }
  };

  const uploadfile = async (data: UploadTypes) => {
    if (data.file && data.file.length <= 10) {
      for (let i = 0; i < data.file.length; i++) {
        const formData = new FormData();
        // Append all selected files to formData
        for (let i = 0; i < data.file.length; i++) {
          formData.append('file', data.file[i]);
        }
        formData.append('orderId', data.orderId ? data.orderId.toString() : "");
        formData.append('imageStatus', data.imageStatus.toString());
        try {
          const upload45response = await UploadStep45(formData);
          if (upload45response[0].status === true) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'อัฟโหลดรูปภาพสำเร็จ',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
          console.log("Upload 45", upload45response);
        } catch (error) {
          console.log("Error Submit 4-5", error);
        }
      }
    } else if (data.file && data.file.length > 10) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'คุณสามารถเพิ่มรูปได้สูงสุดไม่เกิน 10 รูป',
        confirmButtonText: 'OK'
      });
    } else {
      alert("Please select a file before submitting.");
    }
  };

  const onImgSubmit: SubmitHandler<UploadTypes> = async (data) => {
    try {
      Swal.fire({
        icon: 'question',
        title: 'อัฟโหลดรูปภาพ',
        text: 'คุณต้องการอัฟโหลดรูปภาพใช่หรือไม่',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
        allowOutsideClick: false,    // Don't close on outside click
        allowEscapeKey: false,      // Don't close on ESC key
        allowEnterKey: false        // Don't close on Enter key
      }).then((confirmResult) => {
        if (confirmResult.isConfirmed) {
          uploadfile(data);
        }
      });
    } catch (error) {
      console.log("Error Submit", error);
    }
  };


  return (
    <>
      <Card className="p-8 flex flex-col gap-3">
        <h2 className="font-bold underline underline-offset-2 my-2">อัฟโหลดรูปภาพ </h2>
        <form onSubmit={handleSubmit(onImgSubmit)}>
          <div>
            <input
              type="file"
              {...register("file")}
              accept="image/*"
              multiple
              className="border-2 rounded-lg p-1 w-full"
              onChange={handleFileChange}
            />
          </div>
          {selectedFiles && (
            <ul className="list-disc pl-5">
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
          {errors.file && (
            <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
              กรุณาเลือกไฟล์รุปภาพ
            </div>
          )}
          <Button type="submit" className="w-full my-2"> บันทึกรูปภาพ</Button>
        </form>

      </Card>
    </>
  );
};

export default Step45addimagesCard;
