import { useState } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Card,
} from "@material-tailwind/react";
import ShopDetail from "../components/shop/ShopDetail";
import AddNewShop from "../components/shop/AddNewShop";
import TransportDetail from "../components/Transport/TransportDetail";
import AddTransport from "../components/Transport/AddTransport";
import EditShop from "../components/shop/EditShop";
import EditTransport from "../components/Transport/EditTransport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShop,
  faTruckFast,
  faSms,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import SmsPorviderlist from "../components/sms/SmsPorviderlist";
import CreateSetBank from "../components/PushBullet/CreateSetBank";
import EditPushBullet from "../components/PushBullet/EditPushBullet";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const navigate = useNavigate();

  //// เปิดปิด Dialog ////////
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const [openTransprot, setOpenTransport] = useState(false);
  const handleOpentransport = () => setOpenTransport(!openTransprot);

  const [openEditShop, setOpenEditShop] = useState(false);
  const handleOpenEditShop = () => setOpenEditShop(!openEditShop);

  const [openEditTransport, setOpenEditTransport] = useState(false);
  const handleOpenTransport = () => setOpenEditTransport(!openEditTransport);

  const [openSMSProvider, setOpenSMSProvider] = useState(false);
  const handleOpenSMSProvider = () => setOpenSMSProvider(!openSMSProvider);

  const [openCreateSetBank, setOpenCreateSetBank] = useState(false);
  const handleOpenCreateSetBank = () =>
    setOpenCreateSetBank(!openCreateSetBank);

  const [openEditPushBullet, setOpenEditPushBullet] = useState(false);
  const handleOpenEditPushBullet = () =>
    setOpenEditPushBullet(!openEditPushBullet);

  ////////////////////////////////

  const [selectedId, setSelectedId] = useState<number | null>(null);

  return (
    <>
      <div className=" bg-white py-6 px-6 rounded-xl drop-shadow-xl my-4 flex  flex-col flex-wrap">
        <div className="flex flex-col gap-2 px-5">
          <h1 className="text-xl font-semibold">System Setting</h1>
          <p className="text-xs">ตั้งค่าต่างๆของระบบ</p>
        </div>
        <hr className="my-4" />
        <div className="lg:grid lg:grid-cols-4 lg:gap-2 flex flex-col gap-2">
          <Card
            id="shopsetting"
            className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-blue-500 to-white text-white"
          >
            <FontAwesomeIcon icon={faShop} className="text-3xl text-blue-700" />
            <div className="flex flex-col">
              <h2>จัดการเกี่ยวกับสาขา:</h2>
              <Button
                onClick={handleOpen}
                variant="gradient"
                color="white"
                className="text-blue-500 border border-blue-500"
              >
                ตั้งค่าสาขา
              </Button>
            </div>
          </Card>
          <Card
            id="shopsetting"
            className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-blue-500 to-white text-white"
          >
            <FontAwesomeIcon
              icon={faTruckFast}
              className="text-3xl text-blue-700"
            />
            <div className="flex flex-col">
              <h2>จัดการเกี่ยวกับขนส่ง:</h2>
              <Button
                onClick={handleOpentransport}
                variant="gradient"
                color="white"
                className="text-blue-500 border border-blue-500"
              >
                ตั้งค่าขนส่ง
              </Button>
            </div>
          </Card>
          <Card
            id="shopsetting"
            className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-blue-500 to-white text-white"
          >
            <FontAwesomeIcon icon={faSms} className="text-3xl text-blue-700" />
            <div className="flex flex-col">
              <h2>SMS Provider:</h2>
              <Button
                onClick={handleOpenSMSProvider}
                variant="gradient"
                color="white"
                className="text-blue-500 border border-blue-500"
              >
                SMS Settings
              </Button>
            </div>
          </Card>
        </div>
        <div className="flex flex-col gap-2 px-5 my-4">
          <h1 className="text-xl font-semibold">PushBullet Setting</h1>
          <hr />
        </div>
        <div className="my-4 lg:grid lg:grid-cols-4 lg:gap-2 flex flex-col gap-2">
          <Card className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-cyan-500 to-white text-white">
            <FontAwesomeIcon icon={faSms} className="text-3xl text-cyan-700" />
            <div className="flex flex-col">
              <h2>ตั้งค่า PushBullet:</h2>
              <Button
                onClick={handleOpenCreateSetBank}
                variant="gradient"
                color="white"
                className="text-cyan-500 border border-cyan-500"
              >
                Add PushBullet
              </Button>
            </div>
          </Card>
          <Card className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-cyan-500 to-white text-white">
            <FontAwesomeIcon icon={faPen} className="text-3xl text-cyan-700" />
            <div className="flex flex-col">
              <h2>แก้ไข PushBullet:</h2>
              <Button
                onClick={handleOpenEditPushBullet}
                variant="gradient"
                color="white"
                className="text-cyan-500 border border-cyan-500"
              >
                Edit PushBullet
              </Button>
            </div>
          </Card>
        </div>
        <div>
          <div className="flex flex-col gap-2 px-5 my-4">
            <h1 className="text-xl font-semibold">SMS Sending</h1>
            <hr />
          </div>
          <div className="my-4 lg:grid lg:grid-cols-4 lg:gap-2 flex flex-col gap-2">
            <Card className="flex flex-row justify-evenly items-center gap-2 p-4 bg-gradient-to-b from-purple-500 to-white text-white">
              <FontAwesomeIcon
                icon={faPen}
                className="text-3xl text-purple-700"
              />
              <div className="flex flex-col">
                <h2>แก้ไข SMS Sending:</h2>
                <Button
                  onClick={() => navigate("/smssenddingsetting")}
                  variant="gradient"
                  color="white"
                  className="text-purple-500 border border-purple-500"
                >
                  Edit SMS Sending
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <Dialog
        open={openEditShop}
        handler={handleOpenEditShop}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <EditShop closeEditShop={handleOpenEditShop} selectedId={selectedId} />
      </Dialog>

      <Dialog
        open={openEditTransport}
        handler={handleOpenTransport}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <EditTransport
          closeEditTransport={handleOpenTransport}
          selectedId={selectedId}
        />
      </Dialog>

      <Dialog
        open={openSMSProvider}
        handler={handleOpenSMSProvider}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <DialogBody className="z-1">
          <SmsPorviderlist />
        </DialogBody>
      </Dialog>

      <Dialog
        open={open}
        handler={handleOpen}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <div className="mx-4 my-4">
          <span className="text-lg font-semibold p-4">สาขาทั้งหมด</span>
        </div>
        <DialogBody className="z-1">
          <ShopDetail
            closeDialog={handleOpen}
            openEditShop={handleOpenEditShop}
            setSelectedId={setSelectedId}
          />
          <AddNewShop closeDialog={handleOpen} />
        </DialogBody>
        <DialogFooter>
          <Button size="sm" variant="text" color="red" onClick={handleOpen}>
            <span>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        open={openTransprot}
        handler={handleOpentransport}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <div className="mx-4 my-4">
          <span className="text-lg font-semibold p-4">ขนส่งทั้งหมด</span>
        </div>
        <DialogBody>
          <TransportDetail
            closeDialog={handleOpentransport}
            openEditTransport={handleOpenTransport}
            setSelectedId={setSelectedId}
          />
          <AddTransport handleClose={handleOpentransport} />
        </DialogBody>
        <DialogFooter>
          <Button
            size="sm"
            variant="text"
            color="red"
            onClick={handleOpentransport}
          >
            <span>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={openCreateSetBank}
        handler={handleOpenCreateSetBank}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <div className="mx-4 my-4">
          <span className="text-lg font-semibold p-4">
            เพิ่มธนาคาร PushBullet
          </span>
        </div>
        <DialogBody>
          <CreateSetBank />
        </DialogBody>
        <DialogFooter>
          <Button
            size="sm"
            variant="text"
            color="red"
            onClick={handleOpenCreateSetBank}
          >
            <span>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        open={openEditPushBullet}
        handler={handleOpenEditPushBullet}
        size="xl"
        className="flex flex-col overflow-scroll items-center"
      >
        <div className="mx-4 my-4">
          <span className="text-lg font-semibold p-4">
            เพิ่มธนาคาร PushBullet
          </span>
        </div>
        <DialogBody>
          <EditPushBullet />
        </DialogBody>
        <DialogFooter>
          <Button
            size="sm"
            variant="text"
            color="red"
            onClick={handleOpenEditPushBullet}
          >
            <span>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Setting;
