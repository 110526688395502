import React, { useEffect, useState } from 'react';
import { OrderTableRow } from "../../types/AllOrderTypes";
import { Card, Input, Textarea, Button } from '@material-tailwind/react';
import { useForm, SubmitHandler } from "react-hook-form";
import { CheckingRefundTypes } from "../../types/CheckingTypes";
import { UpdateStep56 } from '../../hooks/ChangeStatus';
import { useStore } from '../../hooks/store';
import Swal from 'sweetalert2';

interface CheckingCardType {
    closeChagestepDialog: () => void;
    row: OrderTableRow;
}


const CheckingCard: React.FC<CheckingCardType> = ({ row, closeChagestepDialog }) => {
    const { username } = useStore();

    let initialTotalDeposit = 0;
    row?.order?.orderDetails?.forEach(detail => {
        const quantity = parseInt(detail.quantity, 10);
        initialTotalDeposit += detail.depositPrice * quantity;
    });

    let initialTotalPrice = 0;
    row?.order?.orderDetails?.forEach(detail => {
        const quantity = parseInt(detail.quantity, 10);
        initialTotalPrice += detail.price * quantity;
    });


    // Initialize other values
    let initialServiceFee = 0; // or any other default value you might have
    let initialWithHold = 0;   // or any other default value you might have
    let initialRefund = 0;
    let initialSumIncom = initialTotalPrice + initialServiceFee + initialWithHold;
    let initialSumRepay = initialTotalDeposit + initialRefund - initialWithHold;

    const { register, handleSubmit, watch, setValue } = useForm<CheckingRefundTypes>({
        defaultValues: {
            depositRefund: {
                item: 0,
                totalPrice: initialTotalPrice,
                totalDepositPrice: initialTotalDeposit,
                serviceFee: initialServiceFee,
                sumPriceOrder: null,
                refund: initialRefund,
                withHold: initialWithHold,
                sumRepay: initialSumRepay,
                sumIncome: initialSumIncom,
                refundRemark: "",
                cretedBy: username,
                depositStatus: "2",
            }
        }
    });



    const [totalDepositPrice, setTotalDepositPrice] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [serviceFee, setServiceFee] = useState<number | null>(null);
    const [refund, setrefund] = useState<number | null>(null);
    const [withHold, setwithHold] = useState<number | null>(null);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const currentSumRepay = totalDepositPrice + (refund || 0) - (withHold || 0);
    const currentSumIncome = totalPrice + (serviceFee || 0) + (withHold || 0);


    useEffect(() => {
        let totalQty = 0;

        row?.order?.orderDetails?.forEach(detail => {
            totalQty += parseInt(detail.quantity, 10);
        });

        setTotalQuantity(totalQty);
        setValue("depositRefund.item", totalQty); // set the total quantity in the form
    }, [row, setValue]);

    useEffect(() => {
        setValue("depositRefund.sumRepay", currentSumRepay);
    }, [currentSumRepay, setValue]);

    useEffect(() => {
        setValue("depositRefund.sumIncome", currentSumIncome);
    }, [currentSumRepay, setValue]);

    useEffect(() => {
        const watchedServiceFee = watch("depositRefund.serviceFee");
        setServiceFee(watchedServiceFee ? +watchedServiceFee : null);
    }, [watch]);

    useEffect(() => {
        let totalDeposit = 0;
        let total = 0;

        row?.order?.orderDetails?.forEach(detail => {
            const quantity = parseInt(detail.quantity, 10);
            totalDeposit += detail.depositPrice * quantity;
            total += detail.price * quantity;
        });

        setTotalDepositPrice(totalDeposit);
        setTotalPrice(total);

        const newSumPriceOrder = totalDeposit + total + Number(serviceFee);

        // Save the sumPriceOrder to the form state
        setValue("depositRefund.sumPriceOrder", newSumPriceOrder);
    }, [row, serviceFee, setValue]);



    const RefundDetail = async (data: CheckingRefundTypes) => {
        data.depositRefund.serviceFee = Number(data.depositRefund.serviceFee);
        data.depositRefund.refund = Number(data.depositRefund.refund);
        data.depositRefund.withHold = Number(data.depositRefund.withHold);
        try {
            const response = await UpdateStep56(row.orderId, data);
            if (response.status === true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'บันทึกข้อมูลคืนมัดจำลูกค้าสำเร็จ',
                    confirmButtonText: 'ตกลง'
                }).then((result) => {
                    if (result.isConfirmed) {
                        closeChagestepDialog();
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'ผิดพลาด',
                    text: response.message,
                    confirmButtonText: 'ตกลง'
                }).then((result) => {
                    if (result.isConfirmed) {
                        closeChagestepDialog();
                        window.location.reload();
                    }
                });
            }
        } catch (error) {
            console.log("Error From 5-6 Submit", error);
        }

    };

    const onSubmit: SubmitHandler<CheckingRefundTypes> = async (data) => {
        Swal.fire({
            icon: 'question',
            title: 'เปลี่ยนสถานะ',
            text: 'คุณได้ตรวจสอบข้อมูลถูกต้องและต้องการเปลี่ยนสถานะใช่หรือไม่',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่',
            allowOutsideClick: false,    // Don't close on outside click
            allowEscapeKey: false,      // Don't close on ESC key
            allowEnterKey: false        // Don't close on Enter key
        }).then((confirmResult) => {
            if (confirmResult.isConfirmed) {
                RefundDetail(data);
            }
        });

    }

    return (
        <>
            <Card className="p-8">
                <h1 className="font-bold text-xl my-4">ตรวจสอบยอดโอนมัดจำ</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <table className="w-full min-w-max table-auto text-center text-xs">
                        <thead className="bg-gray-300 my-4">
                            <tr>
                                <th>ลำดับ</th>
                                <th>สินค้า</th>
                                <th>จำนวน</th>
                                <th>ค่ามัดจำ</th>
                                <th>ราคาสินค้า</th>
                                <th>รวมค่ามัดจำ</th>
                                <th>รวมสินค้า</th>
                            </tr>
                        </thead>
                        <tbody>
                            {row?.order?.orderDetails?.map((detail, index) => {
                                const quantity = parseInt(detail.quantity, 10);
                                const sumDepositPrice = detail.depositPrice * quantity;
                                const sumPrice = detail.price * quantity;
                                return (
                                    <tr key={index} className="even:bg-blue-gray-50/50">
                                        <td>{index + 1}.</td>
                                        <td>{detail.detailName}</td>
                                        <td>{detail.quantity}</td>
                                        <td>{detail.depositPrice}</td>
                                        <td>{detail.price}</td>
                                        <td>{sumDepositPrice}</td>
                                        <td>{sumPrice}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot className="bg-gray-300 my-4 font-bold">
                            <tr>
                                <td colSpan={5}>รวม</td>
                                <td>{totalDepositPrice}</td>
                                <td>{totalPrice}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="flex flex-col mt-4 gap-2 p-2">
                        <div className='grid grid-cols-2' >
                            <div></div>
                            <div>
                                <Input crossOrigin="anonymous"
                                    label="ค่าบริการเพิ่มเติม"
                                    type="number"
                                    className='w-48'
                                    {...register("depositRefund.serviceFee")}
                                    value={serviceFee || ''}
                                    onChange={e => setServiceFee(e.target.value ? Number(e.target.value) : null)}
                                />
                            </div>

                        </div>
                        <div className='flex gap-2 justify-end '>
                            <span className="font-bold">ยอดรวมออร์เดอร์ : </span>
                            <span className='font-bold text-xl' {...register("depositRefund.sumPriceOrder")}> {totalDepositPrice + totalPrice + Number(serviceFee)} </span>
                            <span className="font-bold">บาท </span>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 gap-2 my-2'>
                        <Input crossOrigin="anonymous"
                            label='คืนเงินลูกค้า'
                            type='number'
                            {...register("depositRefund.refund")}
                            value={refund || ''}
                            onChange={e => setrefund(e.target.value ? Number(e.target.value) : null)}
                        />
                        <Input crossOrigin="anonymous" label="หักค่ามัดจำ" type='number'
                            {...register("depositRefund.withHold")}
                            value={withHold || ''} onChange={e => setwithHold(+e.target.value ? Number(e.target.value) : null)}
                        />
                    </div>
                    <div className='flex justify-end gap-2 bg-gray-300 px-3 py-2 rounded-lg'>
                        <span className='font-bold '>ยอดคืนมัดจำ : </span>
                        <span className='text-xl font-bold' {...register("depositRefund.sumRepay")} >{currentSumRepay} </span>
                        {/* <input
                            type="hidden"
                            {...register("depositRefund.sumRepay")}
                            value={totalDepositPrice + Number(refund) - Number(withHold)}
                        />
                        <span className='text-xl font-bold'>
                            {totalDepositPrice + Number(refund) - Number(withHold)}
                        </span> */}
                        <span className='font-bold '>บาท </span>
                    </div>
                    <hr className='my-2' />
                    <div>
                        <Textarea label='หมายเหตุ' {...register("depositRefund.refundRemark")} />
                    </div>
                    <div>
                        <Button type='submit'> บันทึกข้อมูล </Button>
                    </div>
                </form>

            </Card>
        </>
    );
};

export default CheckingCard;
