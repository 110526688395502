import axios from 'axios';
import { GolbalConfig } from '../Config';


export const GetConditionData = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/conditions/list`, {

    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};