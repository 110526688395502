import React, { useRef, useEffect, useState, useCallback } from 'react';
import Chart from 'chart.js/auto';
import { Allprice, AllSumrepay, AllSumIncome, StudiopriceReport } from '../../hooks/Report';
import { AllPriceTypes, AllSumincomeTypes, studioReportTypes } from '../../types/ReportTypes';


interface thisPropTypes {
    startDate: Date | string;
    endDate: Date | string;
}

const AllPriceAndAllSumreplay: React.FC<thisPropTypes> = ({ startDate, endDate }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [allPrice, setAllPrice] = useState<AllSumincomeTypes>();
    const [allSumRepay, setAllsumrepay] = useState<AllPriceTypes>();
    const [allSumIncome, setAllsumincome] = useState<AllSumincomeTypes>();
    const [studioPrice, setStudentPrice] = useState<studioReportTypes>();


    const fetcAllPrice = useCallback(async () => {
        const resAllpriceData = await Allprice(startDate, endDate);
        if (resAllpriceData.status === true) {
            setAllPrice(resAllpriceData);
        }
    }, [startDate, endDate]);

    const fetcAllSumreplay = useCallback(async () => {
        const resAllsumrepayData = await AllSumrepay(startDate, endDate);
        if (resAllsumrepayData.status === true) {
            setAllsumrepay(resAllsumrepayData);
        }
    }, [startDate, endDate]);

    const fetcAllSumIncome = useCallback(async () => {
        const resAllsumIncomeData = await AllSumIncome(startDate, endDate);
        if (resAllsumIncomeData.status === true) {
            setAllsumincome(resAllsumIncomeData);
        }
    }, [startDate, endDate]);


    const fetcAllStudioPrice = useCallback(async () => {
        const resStudioReport = await StudiopriceReport(startDate, endDate);
        if (resStudioReport.status === "000") {
            setStudentPrice(resStudioReport);
        }
    }, [startDate, endDate]);


    useEffect(() => {
        fetcAllPrice();
        fetcAllSumreplay();
        fetcAllSumIncome();
        fetcAllStudioPrice();
    }, [startDate, endDate,]);

    useEffect(() => {
        if (allPrice && canvasRef.current) {
            const AllPriceData = allPrice.data;
            const AllSumrePayData = allSumRepay?.data || {};
            const AllSumincomeData = allSumIncome?.data || {};
            const AllStudioReport = studioPrice?.data || {};

            const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const dataValues: number[] = Object.values(AllPriceData).map(value => Number(value));
            const dataValues2: number[] = Object.values(AllSumrePayData).map(value => Number(value));
            const dataValues3: number[] = Object.values(AllSumincomeData).map(value => Number(value));
            const dataValues4: number[] = Object.values(AllStudioReport).map(value => Number(value));


            const ctx = canvasRef.current.getContext('2d');

            if (ctx) {
                const chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: `ยอดขายรวม`,
                            data: dataValues,
                            backgroundColor: ['rgba(75, 192, 192, 1)'],
                            borderColor: ['rgba(75, 192, 192, 1)'],
                            borderWidth: 1
                        },
                        {
                            label: `ยอดคินมัดจำ`,
                            data: dataValues2,
                            backgroundColor: ['rgba(244, 162, 235, 1)'],
                            borderColor: ['rgba(244, 162, 235, 1)'],
                            borderWidth: 1
                        },
                        {
                            label: `ยอดรายได้จริง`,
                            data: dataValues3,
                            backgroundColor: ['rgba(100, 162, 235, 1)'],
                            borderColor: ['rgba(100, 162, 235, 1)'],
                            borderWidth: 1
                        },
                        {
                            label: `รายได้ Studio`,
                            data: dataValues4,
                            backgroundColor: ['rgba(63, 39, 245, 0.8)'],
                            borderColor: ['rgba(63, 39, 245, 0.8)'],
                            borderWidth: 1
                        }

                        ]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 2000,
                                    precision: 0
                                }
                            }
                        }
                    }
                });

                // Cleanup on unmount
                return () => chart.destroy();
            }
        }
    }, [allPrice, allSumRepay, allSumIncome]);

    return (
        <div className='p-4'>
            <h1>ยอดขายรวม ,ยอดคืนมัดจำรายปี และรายได้จริง</h1>
            <canvas ref={canvasRef}></canvas>
        </div>
    );
};

export default AllPriceAndAllSumreplay;
