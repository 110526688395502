import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Card, Button } from "@material-tailwind/react";
import { useState } from "react";
import { useStore } from "../../hooks/store";
import { AddconditionTypes } from "../../types/ConditionTypes/AddconditionTypes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AddnewCondition } from "../../hooks/Condition/AddNewCondition";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddCondition = () => {
  const navigate = useNavigate();
  const { username ,role} = useStore();
  const { handleSubmit, control } = useForm<AddconditionTypes>({
    defaultValues: {
      text: "",
      cretedBy: username,
    },
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ color: [] }, { background: [] }],
    ],
  };

  const onSubmit: SubmitHandler<AddconditionTypes> = async (data) => {
    Swal.fire({
      icon: "question",
      title: "บันทึกข้อมูล",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่",
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่ใช่",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then(async (confirmResult) => {
      if (confirmResult.isConfirmed) {
        const resData = await AddnewCondition(data);
        if (resData.status === true) {
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "บันทึกการเปลี่ยนแปลงสำเร็จ",
          }).then(() => {
            if (role === "admin") {
                navigate("/ownerdashboard");
            } else {
                navigate("/dashboard");
            }
           
          });
        }
      }
    });
  };

  return (
    <>
      <Card className=" my-4 p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-blue-500 font-bold my-2">
            เขียนเงื่อนไขการเช่าชุด
          </h1>
          <Controller
            name="text"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ReactQuill
                className="h-96 pb-20 lg:pb-12 rounded-lg mb-2"
                theme="snow"
                value={field.value}
                onChange={field.onChange}
                modules={modules}
              />
            )}
          />
          <div className="flex justify-end">
            <Button type="submit">บันทึก</Button>
          </div>
        </form>
        <span className="text-gray-500 text-sm">
          {"Token: {{StartDate}},{{EndDate}}"}
        </span>
      </Card>
    </>
  );
};

export default AddCondition;
