import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from './Config';


export const GetSendDateDetail = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/report/calender`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};