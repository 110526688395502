import { useForm, SubmitHandler } from "react-hook-form"
import { CreateSetBankTypes } from '../../types/CreateSetBankTypes';
import { Button, Input } from '@material-tailwind/react';
import { AddNewPushBullet } from "../../hooks/pushbullet";
import Swal from "sweetalert2";

const CreateSetBank = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<CreateSetBankTypes>({
    defaultValues: {
      bankName: "",
      bankAccount: "",
      bankToken: "",
      bankRef: 0,
      bankThread: "",
      lineToken: "",
      withdrawlineToken: "",
      nameAccount: "",
    },
  });

  const onSubmit: SubmitHandler<CreateSetBankTypes> = async (data) => {
    const convertdata = {
      ...data,
      bankRef: Number(data.bankRef),
    }
    try {
      Swal.fire({
        icon: 'question',
        title: 'ตรวจสอบข้อมูล',
        text: 'ตรวจสอบข้อมูลและต้องการเพิ่ม PushBullet ใช่หรือไม่',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
        allowOutsideClick: false,    // Don't close on outside click
        allowEscapeKey: false,      // Don't close on ESC key
        allowEnterKey: false        // Don't close on Enter key
      }).then(async (confirmResult) => {
        if (confirmResult.isConfirmed) {
          const response = await AddNewPushBullet(convertdata);
          if (response.message === 'Success') {
            Swal.fire({
              icon: 'success',
              title: 'สำเร็จ',
              text: 'เพิ่ม Pushbullet เรียบร้อย',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        }
      });

    } catch (error) {
      console.log("error from AddBankPushBullet", error)
    }
    console.log(convertdata);

  };

  return (
    <>
      <div >
        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-2 p-4">
          <Input
            crossOrigin="anonymous"
            label="ขื่อธนาคาร"
            {...register('bankName', { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="เลขบัญชี"
            {...register('bankAccount', { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="ชื่อเรียกบัญชี"
            {...register("nameAccount", { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="Bank Token"
            {...register("bankToken", { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="Bank Ref"
            {...register("bankRef", { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="Bank Thread"
            {...register("bankThread", { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="lineToken"
            {...register("lineToken", { required: true })}
          />
          <Input
            crossOrigin="anonymous"
            label="withdrawlineToken"
            {...register("withdrawlineToken", { required: true })}
          />
          <div><Button type="submit">บันทึกข้อมูล</Button></div>
        </form>


      </div>
    </>
  )
}

export default CreateSetBank