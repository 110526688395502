import { useState } from "react";
import Swal from "sweetalert2";
import { UploadStep01 } from "../../hooks/Uppload";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { Card ,Button} from "@material-tailwind/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes } from "../../types/UploadSlipTypes";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


interface Step01Types {
  // closeChagestepDialog: () => void;
  id: number | null;
}

const Step01addimagesCard: React.FC<Step01Types> = ({ id }) => {
  const [images, setImages] = useState<ImageListType>([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: id,
      imageStatus: 1,
    },
  });

  // State to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  // Update selected files when file input changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(e.target.files);
  };

 const Clicksubmit = async (data :UploadTypes) => {
  if (data.file && data.file.length <= 10) {
    const formData = new FormData();
    // Append all selected files to formData
    for (let i = 0; i < data.file.length; i++) {
      formData.append('file', data.file[i]);
    }
    formData.append('orderId', data.orderId ? data.orderId.toString() : "");
    formData.append('imageStatus', data.imageStatus.toString());

    try {
      const upload = await UploadStep01(formData);
      if (upload[0].status === true) {
        Swal.fire({
          icon: 'success',
          title: 'สำเร็จ',
          text: 'บันทึกการเปลี่ยนแปลงสำเร็จ',
        }).then(() => {
          window.location.reload();
        });

        // closeChagestepDialog();
      }
    } catch (error) {
      console.log("Error From Submit Upload 01", error);
    }
    if (data.file && data.file.length > 5) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'You can only select up to 10 images.',
        confirmButtonText: 'OK'
      });
    } else if (!data.file || data.file.length === 0) {
      alert("Please select a file before submitting.");
    }
  }
 };

  const onSubmit: SubmitHandler<UploadTypes> = async (data) => {
    Swal.fire({
      icon: 'question',
      title: 'เพิ่มรูปภาพ!!',
      text: 'คุณได้ตรวจสอบข้อมูลถูกต้องและต้องการอัพเดทข้อมูลใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        Clicksubmit(data);
      }
    });
  };

  return (
    <>
      <Card className="p-8 flex gap-3">

        <h2 className="font-bold">
          เพิ่มรูปภาพสลิปโอนเงิน
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col text-center">
          <label className="text-gray-600 text-sm">
            เลือกภาพได้สูงสุดไม่เกิน 10 ภาพรวมกับภาพเดิมที่มีอยู่แล้ว(JPEG, JPG, PNG)
          </label>
          <div className="my-2">
            <input
              type="file"
              {...register("file",{required:true})}
              accept="image/*,"
              multiple
              className="border-2 rounded-lg p-1 w-full"
              onChange={handleFileChange} // Call the handleFileChange function on change
            />
          </div>
          {selectedFiles && (
            <ul className="list-disc pl-5">
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
          {errors.file && (
            <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
              กรุณาเลือกไฟล์สลิปก่อน
            </div>
          )}
          <div className="flex justify-center gap-2">
            <Button type="submit" className=" w-48"> เพิ่มรูปภาพ</Button>
          </div>

        </form>
      </Card>
    </>
  );
};

export default Step01addimagesCard;