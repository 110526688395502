// Routes.ts
import OnHold from "./pages/OnHold";
import Sumary from "./pages/sumary";
import AllOrder from "./pages/AllOrder";
import PushBullet from "./pages/PushBullet";
import NewOrderForm from "./pages/NewOrderForm";
import Setting from "./pages/Setting";
import Paid from "./pages/Paid";
import Processing from "./pages/Processing";
import Shiped from "./pages/Shiped";
import ReturnOrder from "./pages/ReturnOrder";
import Checking from "./pages/Checking";
import Dashboard from "./pages/Dashboard";
import AdminList from "./pages/AdminList";
import SmsSetting from './pages/smssetting';
import Waiting from "./pages/Waiting";
import Success from "./pages/Success";
import RefundDetail from './pages/RefundDetail';
import Customerpage from './pages/Customerpage';
import SMSreportDetail from "./pages/SMSportDtail";
import Calendar from "./pages/Calendar";
import OwnerDashboard from "./pages/OwnerDashboard";
import AddCondition from "./pages/Condition/AddCondition";
import TransferReport from "./pages/TransferReport"
import Static from "./pages/Static";
import SmsSendingSeting from "./pages/SmsSendingSetting";
import Checkin from './pages/TimeCard/Checkin';
import AdminCheckTimeCard from "./pages/TimeCard/AdminCheckTimeCard";
import WorkandOTReport from "./pages/TimeCard/WorkandOTReport";
import StudioBooking from "./pages/StudioBooking";
import StudioDetail from './pages/StudioDetail';
import StudioUpdate from "./pages/StudioUpdate";

const backendRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/sumary", element: <Sumary /> },
  { path: "/allorder", element: <AllOrder /> },
  { path: "/onhold", element: <OnHold /> },
  { path: "/paid", element: <Paid /> },
  { path: "/pushbullet", element: <PushBullet /> },
  { path: "/neworderform", element: <NewOrderForm /> },
  { path: "/processing", element: <Processing /> },
  { path: "/shiped", element: <Shiped /> },
  { path: "/returnorder", element: <ReturnOrder /> },
  { path: "/checking", element: <Checking /> },
  { path: "/waiting", element: <Waiting /> },
  { path: "/success", element: <Success /> },
  { path: "/adminlist", element: <AdminList /> },
  { path: "/setting", element: <Setting /> },
  { path: "/smssetting", element: <SmsSetting /> },
  { path: "/refunddetail", element: <RefundDetail /> },
  { path: "/customer", element: <Customerpage /> },
  { path: "/smsreport", element: <SMSreportDetail /> },
  { path: "/setting", element: <Setting /> },
  { path: "/calendar", element: <Calendar /> },
  { path: "/pushbullet", element: <PushBullet /> },
  { path: "/ownerdashboard", element: <OwnerDashboard /> },
  { path: "/AddNewCondition", element: <AddCondition /> },
  { path: "/TransferReport", element: <TransferReport /> },
  { path: "/Static", element: <Static /> },
  { path: "/smssenddingsetting", element: <SmsSendingSeting /> },
  { path: "/checkin", element: <Checkin /> },
  { path: "/adminchecktimecard", element: <AdminCheckTimeCard /> },
  { path: "/workandotreport", element: <WorkandOTReport /> },
  { path: "/studiobooking", element: <StudioBooking /> },
  { path: "/studiodetail", element: <StudioDetail /> },
  { path: "/studioupdate", element: <StudioUpdate /> },
];

export default backendRoutes;
