// GetAddressDetails.ts

import axios from 'axios';
import { Province,DistrictType,SubDistrictType } from '../types/AddressTypes';
import { useStore } from "../hooks/store";


export const GetProvince = async (): Promise<Province[]> => {
  try {
    const response = await axios.get('https://cmfashion.pro/addresss/province', {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetDistricts = async (provinceId: number) => {
  try {
    const response = await axios.get('https://cmfashion.pro/addresss/district', {
      params: { provinceId },
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetSubdistricts = async (districtId: number) => {
  try {
    const response = await axios.get('https://cmfashion.pro/addresss/subdistrict', {
      params: { districtId },
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
