import { useState, useEffect } from "react";
import { TimeCardData } from "../../types/TimeCardTypes";
import { FindTimeCardByID } from "../../hooks/TimeCardCheckin";


interface TimeCardDetailCardptopType {
    id: number | undefined;
    
}

export const TimeCardDetailCard: React.FC<TimeCardDetailCardptopType> = ({ id }) => {
    const [timeCardDetail, setTimeCardDetail] = useState<TimeCardData>();
    console.log("TT", timeCardDetail)


    const fetchTimeCardDetail = async () => {
        if (typeof id === 'number') {
            const responseData = await FindTimeCardByID(id);
            setTimeCardDetail(responseData.timeCard);
        }
    }

    useEffect(() => {
        fetchTimeCardDetail();
    }, []);

    const renderworkStatus = (workStatus: any) => {
        switch (workStatus) {
            case '1':
                return <span className="text-green-500"> ทำงานปกติ</span>;
            case '2':
                return <span className="text-blue-500">OT</span>;
            case '3':
                return <span className="text-purple-500">หยุดตามสิทธิ์</span>;
            case '4':
                return <span className="text-red-500">ลางานไม่รับรายวัน</span>;
            default:
                return <span>สถานะไม่ทราบ</span>;
        }
    }

    const renderStatus = (statusPadding: any) => {
        switch (statusPadding) {
          case '0':
            return <span className="text-red-500">รออนุมัติ</span>;
          case '1':
            return <span className="text-blue-500">อนุมัติแล้ว</span>;
          case '2':
            return <span className="text-green-500">เรียบร้อย</span>;
          default:
            return <span>สถานะไม่ทราบ</span>;
        }
      }

    return (
        <>
            <div className="p-4">
                <div className="border border-gray-400 p-4 rounded-lg">

                    <h1>รายละเอียด ID: {timeCardDetail?.timeCardId}</h1>
                    <hr className="mb-2" />
                    <div className="text-xs lg:text-base">
                        <span className="flex gap-2"><h2 className="font-bold">ประเภทการทำงาน : </h2>{renderworkStatus(timeCardDetail?.workStatus)}</span>
                        <span className="flex gap-2"><h2 className="font-bold">สถานะ : </h2>{renderStatus(timeCardDetail?.statusPadding)}</span>
                        <span className="flex gap-2"><h2 className="font-bold">{timeCardDetail?.workStatus == "3" || timeCardDetail?.workStatus == "4" ? "วันลา" : "วันที่เข้างาน" } : </h2>{timeCardDetail?.checkInDate}</span>
                        <span className="flex gap-2"><h2 className="font-bold">เวลาเข้างาน : </h2>{timeCardDetail?.workStatus == "3" || timeCardDetail?.workStatus == "4" ? " - " :timeCardDetail?.checkInTime}</span>
                        <span className="flex gap-2"><h2 className="font-bold">หมายเหตุการเข้างาน : </h2>{timeCardDetail?.remarkCheckIn}</span>
                        <span className="flex gap-2"><h2 className="font-bold">ผู้อนุมัติ : </h2>{timeCardDetail?.workStatus == "1" || timeCardDetail?.workStatus == "2" ?  timeCardDetail?.approveCheckInBy  : timeCardDetail?.approveCheckOutBy }</span>
                        <span className="flex gap-2"><h2 className="font-bold">เวลาอนุมัติ : </h2>{timeCardDetail?.approveCheckInTime == "1 มกราคม 2513" ? "-" : timeCardDetail?.approveCheckInTime}</span>
                        <span className="flex gap-2"><h2 className="font-bold">หมายเหตุการอนุมัติ : </h2>{timeCardDetail?.remarkApproveCheckIn == '' ? <span> - </span> : timeCardDetail?.remarkApproveCheckIn}</span>
                        <hr className="my-2" />
                        <span className="flex gap-2"><h2 className="font-bold">วันที่ลงเวลาออกงาน : </h2>{timeCardDetail?.checkOutDate}</span>
                        <span className="flex gap-2"><h2 className="font-bold">เวลาออกงาน : </h2>{timeCardDetail?.checkOutTime}</span>
                        <span className="flex gap-2"><h2 className="font-bold">หมายเหตุ : </h2>{timeCardDetail?.remarkCheckOut}</span>
                        <span className="flex gap-2"><h2 className="font-bold">ผู้อนุมัติ : </h2>{timeCardDetail?.approveCheckOutBy}</span>
                        <span className="flex gap-2"><h2 className="font-bold">เวลาอนุมัติ : </h2>{timeCardDetail?.approveCheckOutTime}</span>
                        <span className="flex gap-2"><h2 className="font-bold">หมายเหตุการอนุมัติ : </h2>{timeCardDetail?.remarkApproveCheckOut}</span>
                        <hr className="my-2" />
                        <h1 className="font-bold text-green-600">รวมเวลาที่ทำงานวันนี้ : {timeCardDetail?.hoursWork}</h1>
                    </div>
                </div>

            </div>

        </>
    )
}
