import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from '../hooks/Config';
import { AxiosError } from 'axios';

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError && error.response && typeof error.response.data === 'object' && error.response.data !== null;
}


export const GetItemByStatusPadding = async () => {
    try {
      const response = await axios.get(`${GolbalConfig.URL}/report/statuspading`, {
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response && (error.response.data as any).statusCode === 401) {
        return { error: 'TOKEN_EXPIRED' };
      }
      throw error;
    }
  };

export const GetDailySumrepay = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/report/dayliSumrepay`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const DailyOrder = async () => {
  try {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);  // Setting the time to 00:00:00

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);  // Setting the time to 23:59:59.999

    const response = await axios.post(`${GolbalConfig.URL}/report/dayliorder`, {
      startDate: todayStart,
      endDate: todayEnd,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const dayliquantity = async () => {
  try {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);  // Setting the time to 00:00:00

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);  // Setting the time to 23:59:59.999

    const response = await axios.post(`${GolbalConfig.URL}/report/dayliquantity`, {
      startDate: todayStart,
      endDate: todayEnd,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const AdminCommissionAll = async (startDate?:Date | string, endDate?:Date | string ,byAdmin?:string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/report/admincommission`, {
      startDate: startDate,
      endDate: endDate,
      byAdmin:byAdmin,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const AdminQuantityAll = async (startDate?:Date | string, endDate?:Date | string ,byAdmin?:string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/report/adminquantity`, {
      startDate: startDate,
      endDate: endDate,
      byAdmin:byAdmin
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Allprice = async (startDate?:Date | string, endDate?:Date | string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/report/price`, {
      startDate: startDate,
      endDate: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const StudiopriceReport = async (startDate?:Date | string, endDate?:Date | string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/studio/report`, {
      startDate: startDate,
      endDate: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const AllSumrepay = async (startDate?:Date | string, endDate?:Date | string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/report/sumrepay`, {
      startDate: startDate,
      endDate: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const AllSumIncome = async (startDate?:Date | string, endDate?:Date | string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/report/sumincome`, {
      startDate: startDate,
      endDate: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetSumdeposit = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/report/sumdeposit`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response && (error.response.data as any).statusCode === 401) {
      return { error: 'TOKEN_EXPIRED' };
    }
    throw error;
  }
};

