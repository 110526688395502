import { IOrder } from "../../types/CustomerOrderDetailTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMapLocation, faMobile } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";


interface CCDetailTypes {
  cDetail: IOrder | undefined;

}
const CustomerCDetail: React.FC<CCDetailTypes> = ({ cDetail }) => {
  const formatDateToThai = (dateString: string | null) => {
    if (!dateString) return '';

    moment.locale('th'); // Set moment's locale to Thai
    return moment(dateString).format('LL'); // 'LL' is a predefined format for moment that translates to something like "1 มกราคม 2566"
  }
  return (

    <>
      <div className='p-4'>
        <h1 className='underline '>ที่อยู่จัดส่งสินค้า</h1>
        <div className="my-2 p-8 border rounded-xl">
          <FontAwesomeIcon icon={faUser} /> : <span>{cDetail?.order.custommerFirstName} {cDetail?.order.custommerLastName}</span>
          <br />
          <FontAwesomeIcon icon={faMapLocation} /> : <span>{cDetail?.order.address} {cDetail?.order.subDistrict.subDistrictName} {cDetail?.order.District.districtName} {cDetail?.order.province.provinceName} {cDetail?.order.zipCode}</span>
          <br />
          <FontAwesomeIcon icon={faMobile} /> : <span>{cDetail?.order.telSend} </span>
          <br />
          <div>
            <h2> <span className="font-bold text-red-500">วันเช่า: </span> <span>{formatDateToThai(cDetail?.order.startDate || '')}</span></h2>
            <h2> <span className="font-bold text-green-500">วันคืนสินค้า: </span> <span>{formatDateToThai(cDetail?.order.endDate || '')}</span></h2>
            <h2> <span className="font-bold text-blue-500">วันที่ส่งสินค้า: </span> <span>{formatDateToThai(cDetail?.order.sendDate || '')}</span></h2>
          </div>
        </div>
      </div>
    </>


  )
}

export default CustomerCDetail