import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { DailyOrder, dayliquantity } from '../../hooks/Report';
import { DailyOrderResponseTypes, DailyQuantityResponse } from '../../types/ReportTypes';
import { useStore } from '../../hooks/store';

const DailyOrderReport: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [adminOrder, setAdminOrder] = useState<DailyOrderResponseTypes>();
    const [dailyNewOrder, setDailyNewOrder] = useState<DailyQuantityResponse>();

    const AdminDailyOrder = async () => {
        const resAdminDailyData = await DailyOrder();
        setAdminOrder(resAdminDailyData);
        if (resAdminDailyData.status === true) {
            useStore.getState(). setadminQuantity(resAdminDailyData.totalTodayOrders);
        }
    }

    const fatchDailyQuantityOrder = async () => {
        const resDailyNewData = await dayliquantity();
        setDailyNewOrder(resDailyNewData);
        if (resDailyNewData.status === true) {
            useStore.getState(). setTodaydayliquantity(resDailyNewData.totalTodayQuantity);
        }
          
        
    }

    useEffect(() => {
        AdminDailyOrder();
        fatchDailyQuantityOrder();
    }, []);

    useEffect(() => {
        if (adminOrder && canvasRef.current) {
            const todayOrderData = adminOrder.todayOrder;
            const todayNewOrderData = dailyNewOrder?.todayQuantity;
    
            const labels = Object.keys(todayOrderData);
            const dataValues = Object.values(todayOrderData);
    
            let labels2: string[] = [];
            let dataValues2: number[] = [];
    
            if (todayNewOrderData) {
                labels2 = Object.keys(todayNewOrderData);
                dataValues2 = Object.values(todayNewOrderData);
            }
    
            // Combine labels from both data sets
            const allLabels = Array.from(new Set([...labels, ...labels2]));

            const ctx = canvasRef.current.getContext('2d');

            if (ctx) {
                const chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: allLabels, // Using combined labels
                        datasets: [{
                            label: `จำนวนออเดอร์ที่ได้วันนี้ (${adminOrder?.totalTodayOrders} ออเดอร์)`,
                            data: dataValues,
                            backgroundColor: ['rgba(255, 99, 132, 1)'],
                            borderColor: ['rgba(255, 99, 132, 1)'],
                            borderWidth: 1
                        },
                        {
                            label: `จำนวนชุดที่ได้วันนี้ (${dailyNewOrder?.totalTodayQuantity} ชุด)`,
                            data: dataValues2,
                            backgroundColor: ['rgba(54, 162, 235, 1)'],
                            borderColor: ['rgba(54, 162, 235, 1)'],
                            borderWidth: 1
                        }]
                    },
                    
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 1,
                                    precision: 0
                                }
                                
                            }
                        }
                    }
                });

                // Cleanup on unmount
                return () => chart.destroy();
            }
        }
    }, [adminOrder, dailyNewOrder]);

    return (
        <div className='p-4'>
            <h1>จำนวนออร์เดอร์และจำนวนชุดของ Admin</h1>
            <canvas ref={canvasRef}></canvas>
        </div>
    );
};

export default DailyOrderReport;
