import { useState, useEffect } from "react";
import {
  Button,
  Input,
  Accordion,
  AccordionHeader,
  AccordionBody,
  IconButton,
  Typography,
  Dialog,
  Select,
  Option,
} from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { StaticList } from "../hooks/Static";
import { ProductData } from "../types/StaticTepes";
import OrderDetailCard from "../components/Oder/OrderDetailCard";
import { useStore } from "../hooks/store";

interface IconProps {
  id: number;
  open: number;
}

function Icon({ id, open }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const Static = () => {
  const [staticList, setStaticList] = useState<ProductData[]>([]);
  const [open, setOpen] = useState(-1);
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedOrderID, setSeletedOrderID] = useState<number | null>(null);
  const [sort, setSort] = useState("");
  const { role } = useStore();




  const handleOpen = (value: number) => setOpen(open === value ? -1 : value);

  const fetchStaticData = async () => {
    const resStaticData = await StaticList(search, currentPage, perPage, sort);
    setStaticList(resStaticData.data);
    setTotalItems(resStaticData.total);
  };

  useEffect(() => {
    fetchStaticData();
  }, [search, currentPage, perPage, sort]);

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1); // It's often a good practice to reset to the first page when perPage changes
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const numberOfPages = Math.ceil(totalItems / perPage);

  const handleReset = () => {
    setSearch("");
    setSort("");
  };

  const next = () => {
    if (currentPage < numberOfPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const handleOpenEditOrder = () => setOpenEditOrder(!openEditOrder);

  return (
    <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center shadow-lg">
      <div className="lg:flex lg:items-center justify-between">
        <h1 className="text-2xl font-bold text-pink-500 underline mb-2 text-center">
          สถิติการเช่าชุด
        </h1>

        <div className="flex flex-col lg:flex-row gap-2">
          <Input
            crossOrigin="anonymous"
            label="ค้นหา"
            value={search || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
          />
          <Select
            label="เรียงตามลำดับ"
            value={sort}
            onChange={(value: string | undefined) => {
              if (value) {
                setSort(value);
              }
            }}
          >
            <Option value="ASC">น้อยไปหามาก</Option>
            <Option value="DESC">มากไปหาน้อย</Option>
          </Select>
          <div className="flex justify-end">
            <Button onClick={handleReset}>Reset</Button>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 lg:gap-2">
        {staticList.map((data, index) => (
          <div
            key={index}
            className="my-2 border p-4 rounded-xl flex items-center gap-2 shadow-lg relative"
          >
            <div className="absolute top-0 right-0 bg-pink-500 text-white py-1 px-2 rounded-bl rounded-tl text-xs">
              อันดับ {data.Rows}
            </div>
            <img src={data.src} className=" w-24 rounded-md" />
            <div className="flex flex-col w-full">
              <h3>SKU: {data.sku}</h3>
              <div className="bg-gray-200 flex justify-between px-8 py-2 rounded-md">
                <div className="flex flex-col items-center">
                  <p className="text-xs">จำนวนที่ถูกเช่า</p>
                  <p>{data.totalQuantity}</p>
                </div>
                {role === "admin" ? (
                  <div className="flex flex-col items-center">
                    <p className="text-xs">ราคารวม</p>
                    <p>{data.totalPrice}</p>
                  </div>
                ) : null}
              </div>
              <Accordion
                open={open === index}
                icon={<Icon id={index} open={open} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className="text-xs"
                >
                  ข้อมูลเพิ่มเติม
                </AccordionHeader>
                <AccordionBody>
                  <table className="w-full min-w-max table-auto text-center overflow-scroll">
                    <thead>
                      <tr className="border-y border-blue-gray-100 bg-blue-gray-50/50 lg:text-sm text-xs  ">
                        <th className="border border-slate-300 ">ลำดับ</th>
                        <th className="border border-slate-300 ">OrderID</th>
                        <th className="border border-slate-300 ">คำสั่งซื้อ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.orderId.map((orderId, idx) => (
                        <tr key={idx}>
                          <td className="border border-slate-300 text-xs py-1">
                            {idx + 1}.
                          </td>
                          <td
                            className="border border-slate-300 text-xs cursor-pointer hover:text-blue-500 hover:underline"
                            onClick={() => {
                              handleOpenEditOrder();
                              setSeletedOrderID(orderId);
                            }}
                          >
                            {orderId}
                          </td>
                          <td
                            className="border border-slate-300 text-xs cursor-pointer hover:text-blue-500 hover:underline"
                            onClick={() => {
                              handleOpenEditOrder();
                              setSeletedOrderID(orderId);
                            }}
                          >
                            {data.refNumber[idx]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center ">
        <div className="flex gap-2 items-baseline my-3 ml-3">
          <label htmlFor="select" className="text-xs">
            จำนวนแถว
          </label>
          <select
            className="border-2 rounded-md p-0.5 text-xs"
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="12">12</option>
            <option value="20">20</option>
            <option value="28">28</option>
            <option value="32">32</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="flex items-center gap-8 ">
          <IconButton
            size="sm"
            variant="outlined"
            onClick={prev}
            disabled={currentPage === 1}
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
          <Typography color="gray" className="font-normal">
            หน้า{" "}
            <strong className="text-gray-900  text-sm">{currentPage}</strong>{" "}
            จาก{" "}
            <strong className="text-gray-900 text-sm">{numberOfPages}</strong>
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={next}
            disabled={currentPage === numberOfPages}
          >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
          </IconButton>
        </div>
      </div>
      <Dialog
        open={openEditOrder}
        handler={handleOpenEditOrder}
        size="xxl"
        className="flex flex-col overflow-scroll items-center"
      >
        <OrderDetailCard
          selectedOrderID={selectedOrderID}
          closeDialog={handleOpenEditOrder}
        />
      </Dialog>
    </div>
  );
};

export default Static;
