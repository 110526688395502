import React, { useState } from "react";
import { Card, Button } from "@material-tailwind/react";
import { UploadStep67 } from "../../hooks/Uppload";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes } from "../../types/UploadSlipTypes";
import Swal from "sweetalert2";

interface Step45Types {
  // closeChagestepDialog: () => void;
  id: number | null;
}

const Step67addimagesCard: React.FC<Step45Types> = ({ id }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: id,
      imageStatus: 7,
    }
  });

  // State to keep track of selected files
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Function to handle file input changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
    }
  };


const clicking: SubmitHandler<UploadTypes>  = async (data) => {
  Swal.fire({
    icon: 'question',
    title: 'เพิ่มรูปภาพ',
    text: 'ท่านต้องการเพิ่มรูปภาพใช่หรือไม่',
    showCancelButton: true,
    confirmButtonText: 'ใช่',
    cancelButtonText: 'ไม่ใช่',
    allowOutsideClick: false,    // Don't close on outside click
    allowEscapeKey: false,      // Don't close on ESC key
    allowEnterKey: false        // Don't close on Enter key
  }).then((confirmResult) => {
    if (confirmResult.isConfirmed) {
      onSubmit(data);
    }
  });
};


  const onSubmit = async (data : UploadTypes) => {
    if (data.file && data.file.length <= 10) {
      const formData = new FormData();
      // Append all selected files to formData
      for (let i = 0; i < data.file.length; i++) {
        formData.append('file', data.file[i]);
      }
      formData.append('orderId', data.orderId ? data.orderId.toString() : "");
      formData.append('imageStatus', data.imageStatus.toString());
      try {
        const upload67response = await UploadStep67(formData);
        if (upload67response[0].status === true) {
          
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'อัพโหลดสำเร็จ',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      } catch (error) {
        console.log("Error form Upload6-7", error);
      }

    } else if (data.file && data.file.length > 10) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'คุณสามารถเพิ่มรูปได้สูงสุดไม่เกิน 10 รูป',
        confirmButtonText: 'OK'
      });
    } else {
      alert("Please select a file before submitting.");
    }
  };



  return (
    <>
      <Card className="p-8 flex flex-col gap-3">
        <div>
          <h2 className="font-bold">เพิ่มรูปภาพ </h2>
        </div>
        <form onSubmit={handleSubmit(clicking)}>

          <div>
            <input
              type="file"
              {...register("file", { required: true })}
              accept="image/*"
              multiple
              className="border-2 rounded-lg p-1 w-full"
              onChange={handleFileChange}
            />
          </div>
          {selectedFiles && (
            <ul className="list-disc pl-5">
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
          {errors.file && (
            <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
              กรุณาเลือกไฟล์รุปภาพ
            </div>
          )}
          <Button type="submit" className="w-full"> อัพโหลด</Button>
        </form>
      </Card>
   
    </>
  );
};

export default Step67addimagesCard;
