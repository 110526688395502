import { Dispatch, SetStateAction } from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import { OrderTableRow } from "../types/AllOrderTypes"; 
import { CancleOrder } from "../hooks/order";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useStore } from "../hooks/store";

interface ActionDropdownTypes {
  openEditOderDetail: () => void;
  row: OrderTableRow;
  setSelectedOderId: Dispatch<SetStateAction<number | null>>;
  openStep01Dialog: () => void;
  setSelectedRow: Dispatch<SetStateAction<OrderTableRow | null>>;
  showChangeStatusOption?: boolean;
  showEditOption?: boolean;
  showCancleOption?: boolean;
}
const ActionDropdown: React.FC<ActionDropdownTypes> = ({
  openEditOderDetail,
  row,
  setSelectedOderId,
  openStep01Dialog,
  setSelectedRow,
  showChangeStatusOption = true, // Default to true if not provided
  showEditOption = true,
  showCancleOption = true,
  

}) => {
  const handleAction1 = () => {
    // perform Action 1 here
    setSelectedRow(row); // Set selected row
    openStep01Dialog(); // Open the Step01 Dialog
  };

  const handleAction2 = () => {
    // perform Action 2 here
  };

  const navigate = useNavigate();
  const {username } = useStore();

  const cancleOrder = async () => {
    try {
      // Display a confirmation dialog
      const result = await Swal.fire({
        title: 'ยกเลิกสินค้า ?',
        text: "คุณต้องการยกเลิกสินค้ารายการนี้ใช้หรือไม่?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ต้องการยกเลิก!',
        cancelButtonText: 'ไม่, ขอบคุณ'
      });

      // If the user clicks on "Yes, cancel it!"
      if (result.isConfirmed) {
        const response = await CancleOrder(row.orderId , username);
        if (response.statusCode === 200) {
          Swal.fire({
            icon: "success",
            title: "ยกเลิกคำสั่งซิื้อสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error cancelling order:", error);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const isPriceEmpty = row?.order?.orderDetails?.[0]?.price === null;



  return (
    <Menu>
      <MenuHandler>
        <IconButton>
          <PencilIcon className="w-4 text-white" />
        </IconButton>
      </MenuHandler>
      <MenuList>
        {showEditOption && (
          <MenuItem
            color="blue"
            onClick={() => {
              openEditOderDetail();
              setSelectedOderId(row.orderId);
            }}
          >
            แก้ไข
          </MenuItem>
        )}
        {showChangeStatusOption && (
          <MenuItem color="blue" onClick={handleAction1} disabled={isPriceEmpty}
          >
            เปลี่ยนสถานะ
          </MenuItem>
        )}

        {showCancleOption && (
          <MenuItem color="blue" onClick={cancleOrder}>
            ยกเลิกสินค้า
          </MenuItem>
        )}

      </MenuList>
    </Menu>
  );
};

export default ActionDropdown;
