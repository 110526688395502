import React, { useEffect, useState } from "react";
import { fetchAdminList } from "../../hooks/UserAdmin";
import { Typography, Dialog, DialogBody, DialogHeader, DialogFooter, Button } from "@material-tailwind/react";
import { DeleteUser } from "../../hooks/UserAdmin";
import Swal from "sweetalert2";
import EditUserCard from "./EditUserCard";
import { User } from '../../types/AdminListTypes';
import { spawn } from "child_process";

const Adminlist = () => {
  const [adminListData, setAdminListData] = useState<User[]>([]);
  const TABLE_HEAD = ["ลำดับ", "ชื่อเรียก", "Username", "ID"," คอมฯ","หน่วย", " Role", ""];

  const fetchAdminListData = async () => {
    try {
      const data = await fetchAdminList();
      setAdminListData(data.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAdminListData();
  }, []);

  /// handle delete

  const deleteUser = async (id: string) => {
    try {
      const response = await DeleteUser(id);
      if (response.message === "Success") {
        Swal.fire("สำเร็จ!", "ลบยูสเซอร์นี้ออกจากระบบแล้ว.", "success");
        fetchAdminListData(); 
      }
    } catch (error) {
      console.error("Error deleting website:", error);
    }
  };

  const handleDelete = (id: string) => {
    Swal.fire({
      title: "คุณต้องการลบยูสเซอร์นี้ใช่ไหม?",
      text: "หากลบไปแล้วจะไม่สามารถกู้คืนได้ต้องเพิ่มใหม่เท่านั้น!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ใช่, ฉันต้องการลบ!",
      cancelButtonText: "ไม่, ฉันไม่ต้องการลบ",
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  /// Open Dialog ///
  const [openEditUserCard, setOpenEditUserCard] = useState(false);
  const handleopenEditUserCard = () =>
    setOpenEditUserCard(!openEditUserCard);

  // setID 
  let [userID, setUserID] = useState<string>();

  // {adminListData.map((data,index) =>(
  //   data.total

  // ))}

  return (
    <>
      <div className="my-2">
        <h1 className="text-xl">รายชื่อพนักงาน</h1>
      </div>

      <table className="w-full min-w-max table-auto text-center">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          {adminListData.map((data,index) =>(
          
          <tr key={index} className="even:bg-blue-gray-50/50">
              
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {index + 1}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                 {data.nickname}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.username}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.userId}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.commission}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.commissionType === "2" ? <span>%</span> : <span>บาท</span>}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.role}
                </Typography>
              </td>
              <td className="p-4">
                <div className="text-[12px]">
                  <button className="hover:text-pink-500" onClick={() => {handleopenEditUserCard(); setUserID(data.userId); }}>Edit</button> |{" "}
                  <button className="hover:text-pink-500" onClick={() =>{handleDelete(data.userId); }}>Delete</button>
                </div>
              </td>
            </tr>
        ))}




        </tbody>
      </table>
      <Dialog open={openEditUserCard} handler={handleopenEditUserCard}>
        <DialogHeader className=" text-base">แก้ไขผู้ดูแลระบบ</DialogHeader>
        <DialogBody divider>
          <EditUserCard userID={userID} closeEditUserCard={handleopenEditUserCard} />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleopenEditUserCard}
            className="mr-1"
          >
            <span>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Adminlist;
