import { useState,useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineIcon,
  Typography,
  TimelineHeader,
  Dialog
} from "@material-tailwind/react";
import {
  BellIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  InboxArrowDownIcon,
  TruckIcon,
  ArrowPathRoundedSquareIcon,
  MagnifyingGlassCircleIcon
} from "@heroicons/react/24/solid";
import { IOrder } from "../../types/CustomerOrderDetailTypes";
import { GenPDF2 } from '../../hooks/Bill';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { DepositCheck } from "../../hooks/order";

interface CTMSTypes {
  cDetail: IOrder | undefined;
}



const CustomerStatus: React.FC<CTMSTypes> = ({ cDetail }) => {
  const [waitDeposti, setWaitDeposti]=useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const [openShip, setOpenShip] = useState(false);
  const handleOpenShip = () => setOpenShip(!openShip);

  const [openRefund, setOpenRefund] = useState(false);
  const handleOpenRefund = () => setOpenRefund(!openRefund);


  const handleGenPDF = async (id: number) => {
    const genbillPDF = await GenPDF2(id);
    window.open(`https://cmfashion.pro/bill/pdf?id=${id}`);
  };

  const fetchWaitingDeposit =async () => {
    const resData = await DepositCheck();
    setWaitDeposti(resData.totalWaitDeposit);
  }

  useEffect(() => {
    fetchWaitingDeposit();
  },[]);

  return (
    <div className="mx-8 my-8 ">
      <div className="flex justify-between items-baseline">
        <h2 className="mb-4 text-sm">สถานะการสั่งซื้อ...</h2>
        {(cDetail?.order?.statusPading ?? 4) >=  4 ?   <h2 className="text-sm">คิวรอโอนมัดจำทั้งหมด  : <span className="text-pink-500">{waitDeposti}</span> คิว</h2> : null }
      
      </div>

      <Timeline>
        {
          (cDetail?.order?.statusPading ?? 0) >= 0 && (
            <TimelineItem className="h-28">
              <TimelineConnector className="!w-[78px]" />
              <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                <TimelineIcon className="p-3" variant="ghost">
                  <BellIcon className="h-5 w-5" />
                </TimelineIcon>
                <div className="flex flex-col gap-1">
                  <Typography variant="h6" color="blue-gray">
                    รับออร์เดอร์
                  </Typography>
                  <Typography variant="small" color="gray" className="font-normal">
                    {cDetail?.order.cretedDate}
                  </Typography>
                </div>
              </TimelineHeader>
            </TimelineItem>
          )
        }
        {(cDetail?.order?.statusPading ?? 0) >= 1 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="green">
                <BanknotesIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  รับชำระเงินแล้ว #{cDetail?.order.refNumber}
                </Typography>
                <span className=" hover:text-blue-500 hover:underline hover:cursor-pointer" onClick={handleOpen}>ดูสลิป</span>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 2 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="pink">
                <InboxArrowDownIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  กำลังเตรียมสินค้า
                </Typography>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 3 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="cyan">
                <TruckIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  ส่งสินค้าแล้ว
                </Typography>
                <div className="flex gap-4 items-baseline">
                  <Typography color="gray" className="font-bold text-sm">
                    Tracking : {cDetail?.order.tacking} ขนส่ง : {cDetail?.order.tranSport.transportName}
                  </Typography>
                  <span className=" hover:text-blue-500 hover:underline hover:cursor-pointer" onClick={handleOpenShip}>รูปภาพ</span>
                </div>


              </div>

            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 4 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="indigo">
                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  รอรับสินค้าคืน
                </Typography>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 5 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="deep-purple">
                <MagnifyingGlassCircleIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  ตรวจสอบสินค้า
                </Typography>
                <Typography variant="small" color="gray" className="font-normal">
                  อยู่ระหว่างตรวจสอบสินค้าก่อนคืนค่ามัดจำ
                </Typography>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}

        {(cDetail?.order?.statusPading ?? 0) >= 6 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="orange">
                <BanknotesIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  รอโอนมัดจำ
                </Typography>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 7 && (
          <TimelineItem className="h-28">
            <TimelineConnector className="!w-[78px]" />
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="green">
                <BanknotesIcon className="h-5 w-5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  คืนค่ามัดจำเรียบร้อย #{cDetail?.order.refNumber}
                </Typography>
                <span className=" hover:text-blue-500 hover:underline hover:cursor-pointer" onClick={handleOpenRefund}>ภาพสลิปโอนเงิน</span>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}
        {(cDetail?.order?.statusPading ?? 0) >= 7 && (
          <TimelineItem className="h-28">
            <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
              <TimelineIcon className="p-3" variant="ghost" color="pink">
                <FontAwesomeIcon icon={faFilePdf} className="text-xl px-0.5" />
              </TimelineIcon>
              <div className="flex flex-col gap-1">
                <Typography variant="h6" color="blue-gray">
                  ใบเสร็จรับเงิน #{cDetail?.order.refNumber}
                </Typography>
                <span className=" hover:text-blue-500 hover:underline hover:cursor-pointer" onClick={() => { handleGenPDF(cDetail?.order.orderId ?? 0) }}>ใบเสร็จรับเงิน</span>
              </div>
            </TimelineHeader>
          </TimelineItem>
        )}

      </Timeline>

      <Dialog open={open} handler={handleOpen}>
        <div className="flex flex-wrap justify-center gap-2 p-4">
          {cDetail?.order.paySlip.images && cDetail?.order.paySlip.images.length > 0 ? cDetail?.order.paySlip.images.map((data, index) => (
            <img key={index} src={data.filePath} className="w-48 " />
          )) : <span>ยังไม่มีภาพสลิป</span>
          }
        </div>
      </Dialog>
      {/* <Dialog open={openShip} handler={handleOpenShip}>
        <div className="flex flex-wrap justify-center gap-2 p-4">
          {cDetail?.order.sendProductImage.images && cDetail?.order.sendProductImage.images.length > 0 ? cDetail?.order.sendProductImage.images.map((data, index) => (
            <img key={index} src={data.filePath} className="w-48 " />
          )) : <span>ยังไม่มีภาพสลิป</span>
          }
        </div>
      </Dialog> */}
      <Dialog open={openShip} handler={handleOpenShip}>
        <div className="flex flex-wrap justify-center gap-2 p-4">
          {cDetail?.order.sendProductImage.images && cDetail?.order.sendProductImage.images.length > 0 ? cDetail?.order.sendProductImage.images.map((data, index) => (
            <img key={index} src={data.filePath} className="w-48 " />
          )) : <span>ยังไม่มีภาพสลิป</span>
          }
        </div>
      </Dialog>
      <Dialog open={openRefund} handler={handleOpenRefund}>
        <div className="flex flex-wrap justify-center gap-2 p-4">
          {cDetail?.order.refundSlip.images && cDetail?.order.refundSlip.images.length > 0 ? cDetail?.order.refundSlip.images.map((data, index) => (
            <img key={index} src={data.filePath} className="w-48 " />
          )) : <span>ยังไม่มีภาพสลิป</span>
          }
        </div>
      </Dialog>
    </div>

  )
}

export default CustomerStatus