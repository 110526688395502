import axios from 'axios';
import { useStore } from '../hooks/store';
import { GolbalConfig } from './Config';
import { CheckinTypes, CheckInDayoffType, ApproveCheckINType, ApproveDayoffType, CheckoutTypes, AddOTType, DisOTType, ApproveCheckOutType } from '../types/TimeCardTypes';

export const saveCheckIn = async (data: CheckinTypes) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/checkin`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const saveDayOff = async (data: CheckInDayoffType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/dayoff`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const savLeaveDay = async (data: CheckInDayoffType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/leaveday`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetTimeCardDetail = async (startDate: string | Date, endDate: string | Date, username: string, workStatus: string, statusPadding: string ,sort:string , perPage:number ,page:number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/list`, {
      startDate: startDate,
      endDate: endDate,
      username: username,
      workStatus: workStatus,
      statusPadding: statusPadding,
      sort: sort,
      page: page,
      per_page: perPage,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const ApproveCheckin = async (id: number, data: ApproveCheckINType) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/timecard/approvecheckin?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ApproveCheckOut = async (id: number, data: ApproveCheckOutType) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/timecard/approvecheckOut?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ApproveDayoff = async (id: number, data: ApproveDayoffType) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/timecard/approvedayoff?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ApproveLeaveDay = async (id: number, data: ApproveDayoffType) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/timecard/approveleaveday?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const DeleteTimeCard = async (id: number) => {
  try {
    const response = await axios.delete(`${GolbalConfig.URL}/timecard/delete?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const CheckWorking = async (username: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/checkworking`, {
      username: username
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Checkout = async (id: number, data: CheckoutTypes) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/timecard/checkout?id=${id}`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const reportWork = async (month: string, year: string, username: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/reportwork`, {
      month: month,
      year: year,
      username: username
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetOTData = async (username: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/getot`,{
      username: username
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const SubmitAddOT = async (data: AddOTType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/addot`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const SubmitDisOT = async (data: DisOTType) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/disot`, data, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



export const GetOT = async (username: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/getot`,{
      username:username
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const FindTimeCardByID = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/timecard/findby?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const GetOverTimeLogList = async (startDate: string | Date, endDate: string | Date, username: string, page: number, per_page: number) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/overtimelog`, {
      startDate: startDate,
      endDate: endDate,
      username: username,
      page: page,
      per_page: per_page,
    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



export const GetOTDATA = async (username: string) => {
  try {
    const response = await axios.post(`${GolbalConfig.URL}/timecard/getot`, {

      username: username,

    }, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};