// src/components/BookingForm.tsx

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Card,
    Input,
    Button,
    Typography,
    Checkbox,
} from "@material-tailwind/react";
import { StudioAddTypes, ExtendedStudioAddTypes } from '../../types/StudioTypes';
import { AddBookingStudio, AddIMAGE } from '../../hooks/Studio/StudioAdd';
import Swal from 'sweetalert2';

const BookingForm: React.FC = () => {
    const [makeupTypeChecked, setMakeupTypeChecked] = useState(false);
    const [hairdresserTypeChecked, setHairdresserTypeChecked] = useState(false);
    const [photographerTypeChecked, setPhotographerTypeChecked] = useState(false);
    const [previewImages, setPreviewImages] = useState<string[]>([]);
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);

    const { register, control, handleSubmit, setValue, formState: { errors }, reset } = useForm<ExtendedStudioAddTypes>({
        defaultValues: {
            customerName: '',
            mobileNo: '',
            facebook: '',
            IG: '',
            line: '',
            startDate: '',
            startTime: '',
            makeupType: "0",
            makeupName: '',
            hairdresserType: "0",
            photographerType: "0",
            photographerName: '',
            studioPrice: 0,
            remark: '',
            uploadFiles: [],
        },
    });

    const handleCheckboxChange = (name: 'makeupType' | 'hairdresserType' | 'photographerType', setChecked: React.Dispatch<React.SetStateAction<boolean>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setChecked(isChecked);
        setValue(name, isChecked ? "1" : "0");
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newFiles = Array.from(files).slice(0, 10 - uploadFiles.length);
            setUploadFiles(prev => [...prev, ...newFiles]);
            setValue('uploadFiles', [...uploadFiles, ...newFiles]);

            const readers = newFiles.map(file => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(readers).then(results => {
                setPreviewImages(prev => [...prev, ...results]);
            });
        }
    };

    const removeImage = (index: number) => {
        setPreviewImages(prev => prev.filter((_, i) => i !== index));
        setUploadFiles(prev => prev.filter((_, i) => i !== index));
        setValue('uploadFiles', uploadFiles.filter((_, i) => i !== index));
    };

    const onSubmit = async (data: ExtendedStudioAddTypes) => {
        try {
            const bookingData: StudioAddTypes = {
                customerName: data.customerName,
                mobileNo: data.mobileNo,
                facebook: data.facebook,
                IG: data.IG,
                line: data.line,
                startDate: data.startDate,
                startTime: data.startTime,
                makeupType: data.makeupType,
                makeupName: data.makeupName,
                hairdresserType: data.hairdresserType,
                photographerType: data.photographerType,
                photographerName: data.photographerName,
                studioPrice: data.studioPrice,
                remark: data.remark
            };
            const resAddStudio = await AddBookingStudio(bookingData);
    
            if (resAddStudio.status === "000") {
                const studioId = resAddStudio.data.studioId;
    
                if (uploadFiles.length > 0) {
                    for (const file of uploadFiles) {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('studioId', studioId);
    
                        const resUpload = await AddIMAGE(formData);
                        if (resUpload[0].status !== true) {
                            console.log("error", resUpload.message);
                            throw new Error("การอัปโหลดรูปภาพไม่สำเร็จ");
                        }
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'จองคิวสำเร็จ',
                        text: 'การจองคิวและอัปโหลดรูปภาพสำเร็จแล้ว',
                    });
                } else {
                    // แสดง Swal ทันทีหากไม่มีการอัปโหลดรูปภาพ
                    Swal.fire({
                        icon: 'success',
                        title: 'จองคิวสำเร็จ',
                        text: 'การจองคิวสำเร็จแล้ว',
                    });
                }
    
                reset();
                setMakeupTypeChecked(false);
                setHairdresserTypeChecked(false);
                setPhotographerTypeChecked(false);
                setPreviewImages([]);
                setUploadFiles([]);
            } else {
                throw new Error(resAddStudio.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: error instanceof Error ? error.message : 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
            });
        }
    };

    return (
        <Card color="transparent" shadow={false} className="max-w-lg mx-auto">
            <Typography variant="h4" color="blue-gray">
                จองคิว Studio
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
                กรุณากรอกข้อมูลเพื่อจองคิว
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-8 mb-2 space-y-4">
                <Input
                    {...register("customerName", { required: "กรุณากรอกชื่อลูกค้า" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="ชื่อลูกค้า *"
                />
                {errors.customerName && <p className="text-red-500 text-xs">{errors.customerName.message}</p>}

                <Input
                    {...register("mobileNo", { required: "กรุณากรอกเบอร์โทรศัพท์" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="เบอร์โทรศัพท์ *"
                />
                {errors.mobileNo && <p className="text-red-500 text-xs">{errors.mobileNo.message}</p>}

                <Input
                    {...register("facebook", { required: "กรุณากรอก Facebook" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="Facebook *"
                />
                {errors.facebook && <p className="text-red-500 text-xs">{errors.facebook.message}</p>}

                <Input
                    {...register("IG", { required: "กรุณากรอก Instagram" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="Instagram *"
                />
                {errors.IG && <p className="text-red-500 text-xs">{errors.IG.message}</p>}

                <Input
                    {...register("line", { required: "กรุณากรอก Line" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="Line *"
                />
                {errors.line && <p className="text-red-500 text-xs">{errors.line.message}</p>}

                <Input
                    {...register("startDate", { required: "กรุณาเลือกวันที่" })}
                    crossOrigin={undefined}
                    type="date"
                    size="lg"
                    label="วันที่ *"
                />
                {errors.startDate && <p className="text-red-500 text-xs">{errors.startDate.message}</p>}

                <Input
                    {...register("startTime", { required: "กรุณาเลือกเวลา" })}
                    crossOrigin={undefined}
                    type="time"
                    size="lg"
                    label="เวลา *"
                />
                {errors.startTime && <p className="text-red-500 text-xs">{errors.startTime.message}</p>}

                <Controller
                    name="makeupType"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            crossOrigin={undefined}
                            label="แต่งหน้า"
                            onChange={handleCheckboxChange('makeupType', setMakeupTypeChecked)}
                            checked={makeupTypeChecked}
                        />
                    )}
                />

                <Controller
                    name="hairdresserType"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            crossOrigin={undefined}
                            label="ทำผม"
                            onChange={handleCheckboxChange('hairdresserType', setHairdresserTypeChecked)}
                            checked={hairdresserTypeChecked}
                        />
                    )}
                />

                <Controller
                    name="photographerType"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            crossOrigin={undefined}
                            label="ถ่ายภาพ"
                            onChange={handleCheckboxChange('photographerType', setPhotographerTypeChecked)}
                            checked={photographerTypeChecked}
                        />
                    )}
                />

                <Input
                    {...register("makeupName", { required: "กรุณากรอกชื่อช่างแต่งหน้า" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="ชื่อช่างแต่งหน้า *"
                />
                {errors.makeupName && <p className="text-red-500 text-xs">{errors.makeupName.message}</p>}

                <Input
                    {...register("photographerName", { required: "กรุณากรอกชื่อช่างภาพ" })}
                    crossOrigin={undefined}
                    size="lg"
                    label="ชื่อช่างภาพ *"
                />
                {errors.photographerName && <p className="text-red-500 text-xs">{errors.photographerName.message}</p>}

                <Input
                    {...register("studioPrice", {
                        valueAsNumber: true,
                        required: "กรุณากรอกราคา",
                        min: { value: 0, message: "ราคาต้องไม่ต่ำกว่า 0" }
                    })}
                    crossOrigin={undefined}
                    type="number"
                    size="lg"
                    label="ราคา *"
                />
                {errors.studioPrice && <p className="text-red-500 text-xs">{errors.studioPrice.message}</p>}

                <Input
                    {...register("remark")}
                    crossOrigin={undefined}
                    size="lg"
                    label="หมายเหตุ"
                />

                <div className="mb-4">
                    <Input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        crossOrigin={undefined}
                        size="lg"
                        label="อัปโหลดรูปภาพ (สูงสุด 10 รูป)"
                        multiple
                        disabled={uploadFiles.length >= 10}
                    />
                    <div className="mt-2 flex flex-wrap gap-2">
                        {previewImages.map((img, index) => (
                            <div key={index} className="relative">
                                <img src={img} alt={`Preview ${index + 1}`} className="w-24 h-24 object-cover rounded" />
                                <button
                                    type="button"
                                    onClick={() => removeImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <Button type="submit" className="mt-6" fullWidth>
                    จองคิว
                </Button>
            </form>
        </Card>
    );
};

export default BookingForm;