import { useState,useEffect } from "react";
import { Button, Textarea, Select, Option } from "@material-tailwind/react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useStore } from "../../hooks/store";
import { CheckinTypes,CheckWorkingTypes } from "../../types/TimeCardTypes";
import { saveCheckIn } from "../../hooks/TimeCardCheckin";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import { CheckWorking } from "../../hooks/TimeCardCheckin";

const UserCheckin = () => {
  const { username } = useStore();
  const [checkWorking,setCheckWorking] =  useState<CheckWorkingTypes>();
  console.log("checkWorking",checkWorking);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CheckinTypes>({
    defaultValues: {
      username: username,
      remarkCheckIn: "",
      workStatus: "",
    },
  });

const checkWorkingStatus =async (username : string) => {
  const response = await CheckWorking(username);
  setCheckWorking(response);
} 

useEffect(() => {
  checkWorkingStatus(username);
},[]);

  const onSubmit: SubmitHandler<CheckinTypes> = async (data) => {
    const resCheckIn = await saveCheckIn(data);
    if (resCheckIn.status === true) {
      Swal.fire({
        title: "ส่งคำสั่งสำเร็จ",
        text: 'ลงเวลาเข้างานสำเร็จกำลังรอ Admin อนุมัติ',
        icon: 'success',
        didClose: () => window.location.reload()
      });
    } else {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: `${resCheckIn.error}`,
        icon: 'error',
        didClose: () => window.location.reload()
      });
    }
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <Controller
            name="workStatus"
            control={control}
            rules={{ required: "กรุณาเลือกประเภทการทำงาน" }}
            render={({ field }) => (
              <Select 
                label="เลือกประเภท"
                {...field}
              >
                <Option value="1">เวลาทำงานปกติ</Option>
                <Option value="2">Over Time (OT)</Option>
              </Select>
            )}
          />
          {errors.workStatus && <p>{errors.workStatus.message}</p>}
          <Textarea
            label="หมายเหตุ"
            className="w-52"
            {...register("remarkCheckIn")}
          />
          <div className="flex flex-row gap-2 mt-2 justify-between">
            <Button color="blue" className="rounded-full drop-shadow-md" type="submit" disabled={ checkWorking?.status == true}>
            <FontAwesomeIcon icon={faCheckCircle}/> ลงเวลาเข้างาน
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserCheckin;
