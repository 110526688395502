import axios from 'axios';
import { ShopIdTypes,AddNewShopIdTypes } from '../types/ShopIDTypes';
import { useStore } from '../hooks/store';
import { GolbalConfig } from './Config';

export const GetShopIdList = async (): Promise<ShopIdTypes[]> => {
    try {
      const response = await axios.get(`${GolbalConfig.URL}/shop/select`,{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const AddNewShopIdList = async (shopName: string) => {
    try {
      const data = {
        "shopName": shopName,
        "createdBy": "system",
        "status": 1
      };
      const response = await axios.post(`${GolbalConfig.URL}/shop/newshop`, data, {
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  

  export const SelectShopByID = async (id: number) => {
    try {
      const response = await axios.get(`${GolbalConfig.URL}/shop/findby/?id=${id}`,{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};



export const updateShopById = async (id: number, shopName: string) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/shop/update/${id}`, {
      shopName: shopName
    },{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};



export const deleteShopById = async (id: number) => {

  try {
    const response = await axios.put(`${GolbalConfig.URL}/shop/delete/${id}`,{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
   
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};