import React from "react";
// import Img1 from "../images/working.jpg";

const sumary: React.FC = () => {
  return (
    <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2 flex  justify-center items-center h-full ">
      <div className="flex flex-col gap-4 items-end">
        <h1 className="text-5xl font-bold">เช่าชุดเชียงใหม่.com</h1>
        <span>หน้าจัดการ Order ลูกค้า เช่าชุดเชียงหใหม่ สวยไม่ซ้ำ 3 วัน 50 บาท</span>
        <span className="text-2xl text-pink-700"> ....ภาพรวมระบบ</span>
      </div>
      <div>
        <img src="/images/developer_male.png" alt="" className="w-[500px] mt-8" />
      </div>
    </div>
  );
};
export default sumary;
