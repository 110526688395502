import React, { useState } from "react";
import { OderList } from "../hooks/order";
import { GetAllOrderList } from "../hooks/order";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import { Input } from '@material-tailwind/react';



const Customerpage = () => {
  const [Oderlist, setupOderList] = useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [OrderStatusValue, OrderStatussetSelectedValue] = useState("");
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [search, setsearch] = useState<string>('');
  // const numRefNumber = parseFloat(refNumber || "0");


  const fetchData = async (page: number) => {
    const dataPushbullet: OrderResponse = await GetAllOrderList(page, perPage, search);
    const formattedData = dataPushbullet.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setupOderList(formattedData);
    setCurrentPage(dataPushbullet.page);
    setTotalItems(dataPushbullet.total);
    setPerPage(dataPushbullet.per_page);
  };

  React.useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, OrderStatusValue, perPage, search]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);

  };

  ////// dropdown ////////

  const OrderStatus = [
    { value: "1", label: "ชำระเงินแล้ว" },
    { value: "2", label: "กำลังเตรียมสินค้า" },
    { value: "3", label: "ส่งสินค้าแล้ว" },
    { value: "4", label: "รอรับสินค้าคืน" },
    { value: "5", label: "ตรวจสอบ" },
    { value: "6", label: "รอโอนเงิน" },
    { value: "7", label: "เรียบร้อย" },
  ];

  ////////////////////////

  //// explanablerow //////

  const handleRowExpand = (rowIndex: number) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);  // If the row is already expanded, collapse it.
    } else {
      setExpandedRow(rowIndex);  // Otherwise, expand this row.
    }
  };
  /////////////////////////

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1); // It's often a good practice to reset to the first page when perPage changes
  };
  const numberOfPages = Math.ceil(totalItems / perPage);



  return (
    <>
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center shadow-lg">
        <div className="lg:flex lg:justify-end">
          <div className=" lg:w-60 flex">
            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsearch(e.target.value)}
            />
          </div></div>

        <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
          <table className="w-full min-w-max table-auto text-center overflow-scroll">
            <thead >
              <tr className="border-y border-blue-gray-100 bg-blue-gray-50/50 text-sm">
                <td className="p-4">ลำดับ</td>
                <td className="px-4">ID</td>
                <td className="px-4">คำสั่งซื้อ</td>
                <td className="px-4">ชื่อ-นามสกุล</td>
                <td className="px-4">เบอร์โทร</td>
                <td className="px-4">ช่องทางติดต่อ</td>
              </tr>
            </thead>
            <tbody >

              {Oderlist.map((detail, index) => {


                return (
                  <>
                    <tr key={index} className="even:bg-blue-gray-50/50 lg:text-xs text-[10px]">
                      <td className="p-6">{detail.Rows}.</td>
                      <td>{detail.order.orderId}</td>
                      <td>{detail.refNumber}</td>
                      <td>
                        {detail.order.depositCustommer.depositFirstName ? (
                          <span>{detail.order.depositCustommer.depositFirstName} {detail.order.depositCustommer.depositLastName}</span>
                        ) : (
                          <span className="text-red-500">รอ…กรอกบัญชีธนาคาร</span>
                        )}
                      </td>
                      <td>{detail.order.phone}</td>
                      <td>
                        <div className="flex flex-col ">
                          <h2><span className="text-blue-900">Facebook: </span>{detail.order.facebook}</h2>
                          <h2><span className="text-green-900">Line: </span>{detail.order.line}</h2>
                          <h2><span className="text-pink-900">IG: </span>{detail.order.IG}</h2>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-col mt-4 justify-center items-center">
            <div className="flex items-center">
              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>

              {[1, 2, 3, 4, 5].map(pageNum => (
                <button
                  key={pageNum}
                  className={`mx-1 px-3 py-1 text-sm rounded-md ${currentPage === pageNum ? 'bg-blue-500 text-white' : 'text-gray-500'}`}
                  onClick={() => handlePageChange(pageNum)}
                >
                  {pageNum}
                </button>
              ))}

              <span className="mx-1 text-sm text-gray-500">...</span>

              <button
                className="mx-1 px-3 py-1 text-sm rounded-md text-gray-500"
                onClick={() => handlePageChange(22)}
              >
                22
              </button>

              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === numberOfPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            </div>
            <div className="flex items-center">
              <select
                className="border rounded-md p-1 text-sm"
                value={perPage}
                onChange={handlePerPageChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="mt-2 text-sm text-gray-500">
              Results: 1 - 15 of 324
            </div>
          </div>

        </div>


      </div>
    </>
  );
};

export default Customerpage;
