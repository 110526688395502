import axios from "axios";
import { GolbalConfig } from "../Config";
import { AddconditionTypes } from '../../types/ConditionTypes/AddconditionTypes';


export const AddnewCondition = async (data:AddconditionTypes) => {
    try {
      const response = await axios.post(`${GolbalConfig.URL}/conditions/newcondition`,data);
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };