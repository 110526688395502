import { useState, useEffect } from 'react';
import AppHeader from "../components/AppHeader";
import SideNav from "../components/SideNav";
import { Outlet, useLocation } from "react-router-dom";
import { Breadcrumbs, IconButton } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { reportStatuspaddingType } from "../types/ReportStatusPadding";
import { GetItemByStatusPadding } from "../hooks/Report";
import { useStore } from '../hooks/store';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import PushBulletNotif from '../components/PushBulletNotif';


const BackendLayouts: React.FC = () => {
  const { role } = useStore();
  const location = useLocation();
  const pageNames: Record<string, string> = {
    '/dashboard': 'หน้าแรก',
    '/neworderform': 'เพิ่มสินค้าใหม่',
    '/allorder': 'รายการคำสั่งซื้อทั้งหมด',
    '/OnHold': 'On Hold',
    '/onhold': 'On Hold',
    '/paid': 'ชำระเงินแล้ว',
    '/processing': 'กำลังเตรียมสินค้า',
    '/shiped': 'ส่งสินค้าแล้ว',
    '/returnorder': 'รอรับสินค้าคืน',
    '/checking': 'ตรวจสอบ',
    '/waiting': 'รอโอนมัดจำ',
    '/success': 'เรียบร้อย',
    '/refunddetail': 'ข้อมูลคืนมัดจำลูกค้า',
    '/smssetting': 'ตั้งค่า SMS',
    '/AdminList': 'รายชื่อแอดมิน',
    '/smsreport': 'รายงาน SMS',
    '/customer': 'รายชื่อลูกค้า',
    '/setting': 'System Settings',
    '/calendar': 'ปฏิทิน',
    '/pushbullet': 'Pushbullet',
    '/ownerdashboard': 'Dashboard สำหรับเจ้าของร้าน',
    '/AddNewCondition': 'เพิ่มเงื่อนไขใหม่',
    '/TransferReport': 'รายงานยอดเงิน',
    '/Static': 'สถิติการเช่าชุด',
    '/smssenddingsetting': 'ตั้งค่าการส่ง SMS',
    '/checkin': 'ลงเวลา เข้า-ออก',
    '/adminchecktimecard': 'อนุมัติเวลาทำงาน',
    '/workandotreport': 'รายการเวลาทำงานและ OT',
    '/studiodetail': 'คิวงาน Studio',
    '/studiobooking': 'จองคิว Studio',
    '/studioupdate': 'อัฟเดทการจองคิว Studio',
    // Add other routes and their names here as required
  };
  const currentPageName = pageNames[location.pathname] || "Unknown Page";
  const pathname: string = location.pathname as string;
  const [items, setItems] = useState<reportStatuspaddingType | null>(null);
  const navigate = useNavigate();


  const fetchStatustData = async () => {
    try {
      const data = await GetItemByStatusPadding();
      if (data.error === "TOKEN_EXPIRED") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Token หมดอายุกรูณาเข้าสู่ระบบอีกครั้ง",
        }).then(() => {
          navigate("/");
        });
      } else {
        setItems(data);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchStatustData();
  }, []);

  return (
    <>
      <div className="bg-gray-100 max-w-full">
        <div className="flex">
          <div className="sticky top-0 h-screen">
            <SideNav />
          </div>
          <div className="px-2 lg:px-8 pt-4 pb-10 w-full">
            <AppHeader />
            <div className="my-4 flex">
              <Breadcrumbs className="rounded-xl">
                <a href="#" className="opacity-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </a>
                {role === "admin" ? <a href="/ownerdashboard" className="opacity-60">Dashboard</a> : <a href="/dashboard" className="opacity-60">Dashboard</a>}
                <a href={pathname} className="opacity-80"><span>{currentPageName}</span></a>
              </Breadcrumbs>
            </div>
            <div className='lg:flex bg-white px-2 py-4 rounded-lg shadow-lg text-white lg:text-sm  gap-2 lg:justify-center hidden'>
              <Link to="/allorder" className=' bg-pink-300 rounded-full py-1 pl-3 pr-2 lg:text-sm text-xs'>คำสั่งซื้อทั้งหมด <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items && items.data && items.data.sumStatusPading}</IconButton></Link>
              <Link to="/onhold" className=' bg-purple-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>On Hold <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading0}</IconButton></Link>
              <Link to="/paid" className=' bg-deep-purple-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>ชำระเงินแล้ว <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading1}</IconButton></Link>
              <Link to="/processing" className=' bg-indigo-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>กำลังเตรียมสินค้า <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading2}</IconButton></Link>
              <Link to="/shiped" className=' bg-blue-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>ส่งสินค้าแล้ว <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading3}</IconButton></Link>
              <Link to="/returnorder" className=' bg-light-blue-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>รอรับสินค้าคืน <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading4}</IconButton></Link>
              <Link to="/checking" className=' bg-cyan-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>ตรวจสอบ <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading5}</IconButton></Link>
              <Link to="/waiting" className=' bg-teal-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>รอโอนมัดจำ <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading6}</IconButton></Link>
              <Link to="/success" className=' bg-green-300 rounded-full py-1 pl-3 pr-2 lg:text-sm  text-xs'>เรียบร้อย <IconButton size='sm' className='rounded-full bg-gray-700/70 ml-2'>{items?.data.statusPading7}</IconButton></Link>
            </div>
            <div className='flex flex-col '>
              <PushBulletNotif/>
              <Outlet />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default BackendLayouts;
