import React from "react";
import { Navbar, IconButton, Avatar, Drawer } from "@material-tailwind/react";
import { useStore } from "../hooks/store";
import profile from "../images/profile.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MobileMenu from "./MobileMenu";

export default function Example() {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const { username, commission, commissionType } = useStore();
  const PorfileIMG = profile;

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-lg  py-2 px-4 lg:px-8 lg:py-4 bg-gradient-to-b from-white to-pink-200/50">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="lg:text-xl text-xs flex flex-col ">
            <div className="flex gap-2 text-md">
            <span>ยินต้อนรับ: </span>
            <span className="text-pink-800 underline font-bold">{username}</span>{" "}
            <span className=" hidden md:block lg:block"> เข้าสู่หน้าจัดการคำสั่งซื้อ</span>
            </div>
       
            <h3 className="lg:hidden">
              คอมมิสชั่น:{" "}
              {commission > "0" ? (
                <span className="text-blue-500">{commission}</span>
              ) : (
                <span className="text-red-500">ยังไม่รับค่าคอม</span>
              )}{" "}
              {commissionType === "1" ? <span>บาท</span> : <span>%</span>}
            </h3>
         
          </div>

          <div className="flex items-center gap-4">
            <div className="lg:flex lg:flex-col lg:text-base text-sm hidden  ">
              <h3>สวัสดี: {username}</h3>
              <h3>
                หน่วยคำนวนค่าคอมมิสชั่น:{" "}
                {commission > "0" ? (
                  <span className="text-blue-500">{commission}</span>
                ) : (
                  <span className="text-red-500">ยังไม่รับค่าคอม</span>
                )}{" "}
                {commissionType === "1" ? <span>บาท</span> : <span>%</span>}
              </h3>
            </div>
            <Avatar src={PorfileIMG} />
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
            >
              <FontAwesomeIcon
                icon={faBars}
                className="text-xl"
                onClick={openDrawer}
              />
            </IconButton>
          </div>
        </div>
      </Navbar>
      <Drawer
        open={open}
        onClose={closeDrawer}
        className="p-4 overflow-auto w-full max-h-full"
      >
        <div className="mb-6 flex items-center justify-between">
          <MobileMenu colseDrawer={closeDrawer} />
        </div>
      </Drawer>
    </>
  );
}
