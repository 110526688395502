import { useState, useEffect } from 'react'
import { ProviderListType } from '../../types/SMSProviderlistTypes';
import { fetchSMSproviderList } from '../../hooks/Sms';
import { Typography } from '@material-tailwind/react';
import EditSMSProvider from './EditSMSProvider';
import { Dialog,DialogBody,DialogFooter,DialogHeader,Button }  from '@material-tailwind/react';


const SmsPorviderlist = () => {
  const [provederList, setPorviderList] = useState<ProviderListType[]>([]);

  const fetcProviderList = async () => {
    try {
      const smsData = await fetchSMSproviderList();
      setPorviderList(smsData);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetcProviderList();
  }, []);

  const TABLE_HEAD = ["ลำดับ", "SMS Provider", "ID", ""];

   /// Open Dialog ///
   const [openEditSms, setOpenEditSms] = useState(false);
   const handleopenEditSms = () =>
     setOpenEditSms(!openEditSms);
   
   // setID 
   let [smsID ,setsmsID] = useState<number>();

  return (
    <>
      <div className=''>
        <table className="w-full min-w-max table-auto text-center">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {provederList.map((data, index) => (

            <tr key={index} className="even:bg-blue-gray-50/50">

              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {index + 1}.
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.smsProviders}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {data.id}
                </Typography>
              </td>
              <td className="p-4">
                <div className="text-[12px]">
                  <button className="hover:text-pink-500" onClick={() => {handleopenEditSms(); setsmsID(data.id); }}>แก้ไข</button>
                </div>
              </td>
            </tr>
          ))}

        </tbody>
        </table>
        
      </div>
      <Dialog open={openEditSms} handler={handleopenEditSms}>
        <DialogHeader className=" text-base">แก้ไขข้อมูล SMS</DialogHeader>
        <DialogBody divider>
         <EditSMSProvider smsID={smsID} closeSMSedit={handleopenEditSms}/>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            // onClick={handleopenEditUserCard}
            // userID={userID}  closeEditUserCard={handleopenEditUserCard}
            className="mr-1"
          >
            <span onClick={handleopenEditSms}>ปิดหน้าต่างนี้</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default SmsPorviderlist