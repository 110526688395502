import React from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';

interface ExpandableComponentProps<T> extends ExpanderComponentProps<T> {
  renderContent: (data: T) => JSX.Element;
}

function ExpandableComponent<T>({ data, renderContent }: ExpandableComponentProps<T>): JSX.Element {
  return <>{renderContent(data)}</>;
}

export default ExpandableComponent;
