import { useState, useEffect } from "react";
import { GetAllOrderList } from "../hooks/order";
import { returnOrderListType } from "../types/AllOrderTypes";
import { Button, Input, Select, Option } from '@material-tailwind/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { DeleteCustomerRefundDetail } from "../hooks/DeleteDepositCustomer";
import { GetShopIdList } from '../hooks/GetShopId';
import { ShopIdTypes } from "../types/ShopIDTypes";




const RefundDetail = () => {
  const [Order, setOrder] = useState<returnOrderListType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const numberOfPages = Math.ceil(totalItems / perPage);
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  // const numRefNumber = parseFloat(refNumber || "0");
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);



  const responsData = async () => {
    try {
      const data = await GetAllOrderList(currentPage, perPage, search, numSelectedShop);
      setOrder(data.data);
      setTotalItems(data.total);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
    responsData();
  }, [perPage, currentPage, search, selectedShop]);

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    responsData();
  };

  const ResetCustomerDeposit = async (id: number) => {
    try {
      const resetResponse = await DeleteCustomerRefundDetail(id);
      if (resetResponse.status === true) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ระบบทำการรีเซ็ตข้อมูลลูกค้าสำเร็จ',
          icon: 'success'
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {

    }
  };

  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    setResetKey(prevKey => prevKey + 1);
  };


  const OnsubmitDelete = async (id: number) => {
    Swal.fire({
      title: 'รีเซ็ตข้อมูลลูกค้า',
      text: "คุณต้องการรีเซ็ตข้อมูลคืนมัดจำลูกค้าใช่หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        ResetCustomerDeposit(id);
      }
    })
  }

  return (
    <>
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center shadow-lg">
        <div className="lg:grid lg:grid-cols-2 lg:gap-2 flex flex-col">
          <div className="hidden lg:block"></div>
          <div className="lg:flex lg:flex-row lg:gap-2  flex flex-col gap-1">
            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsearch(e.target.value)}
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ''}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>{shopdata.shopName}</Option>
              ))}
            </Select>
            <div className="flex flex-col">
              <Button onClick={handleReset}>Reset</Button>
            </div>

          </div>

        </div>


        <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
          <table className="w-full min-w-max table-auto text-center overflow-scroll">
            <thead>
              <tr className="border-y border-blue-gray-100 bg-blue-gray-50/50 text-sm">
                <td className="p-4">ลำดับ</td>
                <td className="px-4">ID</td>
                <td className="px-4">คำสั่งซื้อ</td>
                <td className="px-4">ชื่อ-นามสกุล</td>
                <td className="px-4">ธนาคาร</td>
                <td className="px-4">เลขบัญชี</td>
                <td className="px-4">รีเซ็ตข้อมูลลูกค้า</td>
              </tr>
            </thead>
            <tbody >

              {Order.map((detail, index) => {

                return (
                  <>
                    <tr key={index} className="even:bg-blue-gray-50/50 lg:text-xs text-[10px]">
                      <td className="p-6">{detail.Rows}.</td>
                      <td>{detail.orderId}</td>
                      <td>{detail.refNumber}</td>
                      <td>{detail.depositCustommer.depositFirstName ? (
                        <span>{detail.depositCustommer.depositFirstName} {detail.depositCustommer.depositLastName}</span>
                      ) : (
                        <span className="text-red-500">รอ…กรอกบัญชีธนาคาร</span>
                      )}</td>
                      <td>
                        {detail.depositCustommer.bankName
                          ? <span className="text-xs text-green-500">{detail.depositCustommer.bankName}</span>
                          : detail.depositCustommer.prompay ? <span className="text-xs text-blue-500">พร้อมเพย์</span> : <span className="text-xs text-red-500">ยังไม่มีข้อมูล</span>}
                      </td>
                      <td>
                        <div className="flex flex-col">
                          {detail.depositCustommer.bankAccount
                            ? <span className="text-xs text-green-500">{detail.depositCustommer.bankAccount}</span>
                            : detail.depositCustommer.prompay ? <span className="text-xs text-blue-500">{detail.depositCustommer.prompay}</span> : <span className="text-xs text-red-500">ยังไม่มีข้อมูล</span>}
                        </div>
                      </td>
                      <td>
                        {detail.statusPading === 7 ? <span className="bg-green-500 text-white rounded-full px-2">เรียบร้อย</span> : <Button color="red" size="sm" className="px-2.5"><FontAwesomeIcon icon={faTrash} className="text-lg" onClick={() => (OnsubmitDelete(detail.depositCustommer.depositCustommerId))} /></Button>}

                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-col mt-4 justify-center items-center">
            <div className="flex items-center">
              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>

              {[1, 2, 3, 4, 5].map(pageNum => (
                <button
                  key={pageNum}
                  className={`mx-1 px-3 py-1 text-sm rounded-md ${currentPage === pageNum ? 'bg-blue-500 text-white' : 'text-gray-500'}`}
                  onClick={() => handlePageChange(pageNum)}
                >
                  {pageNum}
                </button>
              ))}

              <span className="mx-1 text-sm text-gray-500">...</span>

              <button
                className="mx-1 px-3 py-1 text-sm rounded-md text-gray-500"
                onClick={() => handlePageChange(22)}
              >
                22
              </button>

              <button
                className="mx-1 px-2 py-1 text-sm text-gray-500"
                disabled={currentPage === numberOfPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            </div>
            <div className="flex items-center">
              <select
                className="border rounded-md p-1 text-sm"
                value={perPage}
                onChange={handlePerPageChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="mt-2 text-sm text-gray-500">
              Results: 1 - 15 of 324
            </div>
          </div>


        </div>


      </div>
    </>
  );
};

export default RefundDetail;