import React, { useState } from "react";
import { AddTransport } from "../../hooks/TranspotList";
import { Button, Input, Alert } from "@material-tailwind/react";
import Swal from 'sweetalert2';


const AddNewTransport: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const [transportName, setTransportName] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await AddTransport(transportName);
      if (response.statusCode === 200) {
        Swal.fire({
          icon: 'success',
          title: 'เพิ่มขนส่งสำเร็จ',
        })
        handleClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-2 mt-5">
          <Input
            crossOrigin="anonymous"
            label="กรุณาใส่ชื่อบริษัทขนส่งที่ต้องการ"
            type="text"
            value={transportName}
            onChange={(e) => {
              setTransportName(e.target.value);
            }}
            required

          />
          <Button type="submit" size="sm" variant="gradient" className="w-40">
            เพิ่มขนส่ง
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddNewTransport;
