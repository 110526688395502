import { useState } from "react";
import Swal from "sweetalert2";
import { UploadStep01 } from "../../hooks/Uppload";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { UpdateStep01 } from "../../hooks/ChangeStatus";
import { Card, Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { UploadTypes } from "../../types/UploadSlipTypes";
import { useStore } from "../../hooks/store";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Step01Types {
  closeChagestepDialog: () => void;
  row: OrderTableRow;
}

const Step01submitCard: React.FC<Step01Types> = ({
  row,
  closeChagestepDialog,
}) => {
  const { username } = useStore();
  const [images, setImages] = useState<ImageListType>([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: row.orderId,
      imageStatus: 1,
    },
  });

  const onChange = (imageList: ImageListType) => {
    const fileList: File[] = [];
    imageList.forEach((img) => {
      if (img.file) {
        fileList.push(img.file);
      }
    });
    setValue("file", fileList as any); // Using 'as any' to bypass the type error for now
    setImages(imageList);
  };

  const onSubmit = async (data: UploadTypes) => {
    if (!data.file || data.file.length === 0) {
      Swal.fire(
        "Error",
        "Please select at least one image before submitting.",
        "error"
      );
      return;
    }

    try {
      const result = await Swal.fire({
        title: "อัฟโหลดและเปลี่ยนสถานะ?",
        text: "ต้องการอัฟสลิปและเปลี่ยนสถานะใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        for (let i = 0; i < data.file.length; i++) {
          formData.append("file", data.file[i]);
        }
        formData.append("orderId", data.orderId ? data.orderId.toString() : "");
        formData.append("imageStatus", data.imageStatus.toString());

        const response = await UploadStep01(formData);

        if (response[0].status === true) {
          const updateResponse = await UpdateStep01(row.orderId, username);
          if (updateResponse.status === true) {
            Swal.fire({
              icon: "success",
              title: "สำเร็จ",
              text: "บันทึกการเปลี่ยนแปลงสำเร็จ",
            }).then(() => {
              window.location.reload();
            });
          }
        }
      } else {
        Swal.fire("ยกเลิก", "ยกเลิกการกระทำแล้ว", "error");
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "อัฟโหลดไม่สำเร็จ",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="lg:p-8 lg:flex gap-2 flex flex-col flex-wrap p-4 lg:w-[800px] lg:mt-8 ">
        <h2 className="font-bold">
          เปลี่ยนสถานะจากคำสั่งซื้อใหม่เป็นชำระเงินแล้ว
        </h2>
        <p className="text-xs">
          *** ก่อนเปลี่ยนสถานะกรุณาตรวจสอบข้อมูลลูกค้าให้เรียบร้อย
          แล้วอัฟโหลดสลิปและกดเปลี่ยนสถานะ
        </p>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={10}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="flex flex-col  justify-center border p-8 lg:flex lg:flex-col items-center">
              <div
                style={isDragging ? { color: "red" } : undefined}
                className="my-2 "
                onClick={onImageUpload}
                {...dragProps}
              >
               <FontAwesomeIcon icon={faPlus}/> เลือกรูปภาพ
              </div>
              {imageList.map((image, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center gap-1 my-2 lg:flex-col lg:items-center"
                >
                  <img src={image.dataURL} alt="" className="lg:w-[30%]" />
                  <Button
                    className="lg:w-24 mt-2 lg:mt-0"
                    size="sm"
                    color="red"
                    onClick={() => onImageRemove(index)}
                  >
                    ลบรูปภาพ
                  </Button>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>

        {/* Hidden input for react-hook-form */}
        <input {...register("file")} type="file" style={{ display: "none" }} />
        <input
          {...register("orderId")}
          type="hidden"
          value={row.orderId.toString()}
        />
        <input {...register("imageStatus")} type="hidden" value="1" />
        <div className="flex flex-col ">
          <Button size="sm" type="submit" color="green">
            บันทึก
          </Button>
          ฺ
          <Button size="sm" onClick={closeChagestepDialog} className="-mt-5">
            {" "}
            ปิดหน้าต่างนี้
          </Button>
        </div>
      </Card>
    </form>
  );
};

export default Step01submitCard;
