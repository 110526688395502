import React from 'react'
import { IOrder } from "../../types/CustomerOrderDetailTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faTruckFast } from '@fortawesome/free-solid-svg-icons';

interface CRDtypes {
  cDetail: IOrder | undefined;
}
const CustomerRentalDetail: React.FC<CRDtypes> = ({ cDetail }) => {
  const DEFAULT_IMAGE = '/assets/images/bg/No-image-found.jpg';
  let total = 0;



  return (

    <>
      <div className='p-4'>
        <h1 >รายละเอียดสินค้า</h1>
      </div>


      {cDetail?.order.orderDetails.map((data, index) => (
        <>
          <div className='flex justify-end mx-4 gap-2'>
            <FontAwesomeIcon icon={faTruckFast} className='text-pink-300'/>
            {(cDetail?.order.statusPading ?? 0) >= 3 && (
              <span className='text-pink-300 text-sm' >สินค้าถูกจัดส่งเรียบร้อยแล้ว</span>
            )}
          </div>
          <hr />
          <div className='mx-4 p-4  flex  items-center gap-2'>
            <div>
              {data.src === null ? (
                <img src={DEFAULT_IMAGE} alt="" className=' w-24' />
              ) : (
                <img src={data.src} alt="" className='w-24' />
              )}
            </div>
            <div className='flex flex-col w-full'>
              <span>{data.detailName}</span>
              <div className='flex justify-between  text-sm'>
                <span className='text-sm'>x{data.quantity}</span>
                <div className='flex gap-2'>
                  <span>ค่ามัดจำ:</span><span className='text-pink-300'> ฿{data.depositPrice}</span>
                </div>
              </div>
              <div className='flex justify-end gap-2 text-sm'>
                <span>ราคา:</span> <span className='text-pink-300'>฿{data.price}</span>
              </div>
            </div>
          </div >
          <hr />
          <div className='flex justify-end mx-8 gap-2 items-baseline'>
            <FontAwesomeIcon icon={faFileInvoiceDollar} className='text-red-500' />  <span>รวมคำสั่งซื้อ</span> <span className='text-xl text-red-600'>฿{total += data.depositPrice + data.price} </span>
          </div>
        </>

      ))}
    </>


  )
}

export default CustomerRentalDetail