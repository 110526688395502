import React, { useState, useEffect } from "react";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { PushBulletOwner, getBankNameDATA } from "../hooks/pushbullet";
import {
  pushallsmsType,
  GetBankDATA,
  PullDataTypes,
} from "../types/PushBulletTypes";
import { Card, Select, Option, Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarComponent from "../components/CalendarComponent";

const TransferReport = () => {
  const [pushbulletdata, setpushBulletdata] = useState<PullDataTypes[]>([]);
  const [selectedBankName, setSelectedBankName] = useState<string | "">("");
  const [selectednameAccount, setSelectednameAccount] = useState<string | "">(
    ""
  );
  const [selectedStatus, setSelectedStatus] = useState<string | "">("1");
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalRecords = 100;
  const totalPages = Math.ceil(totalRecords / (perPage || 30));

  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const PushBulletData = async () => {
      const dataPushbullet = await PushBulletOwner(
        selectednameAccount,
        selectedBankName,
        selectedStatus,
        currentPage,
        Number(perPage),
        startDate,
        endDate
      );
      setpushBulletdata(dataPushbullet.data);
    };

    PushBulletData();
  }, [
    selectednameAccount,
    selectedBankName,
    selectedStatus,
    currentPage,
    perPage,
    startDate,
    endDate,
  ]);

  return (
    <>
      {/* body section */}
      <Card className="bg-white rounded-lg mt-4 p-2">
        <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
          <table className="w-full min-w-max table-auto text-center overflow-scroll">
            <thead className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 lg:text-sm text-xs">
              <tr>
                <th className="py-3">ลำดับ</th>
                <th className="px-4">วันที่ : เวลา</th>
                <th className="px-4">ยอดเงิน</th>
                <th className="px-4" >บัญชี</th>
                <th className="px-4">ธนาคาร</th>
                <th className="px-4">สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {pushbulletdata.map((data, index) => (
                <tr
                  key={index}
                  className="even:bg-blue-gray-50/50 lg:text-xs text-[10px] text-center "
                >
                  <td className="p-3">{data.Rows}.</td>
                  <td>{data.createdDate}</td>
                  <td>
                    {(data.amount ?? 0) < 0 ? (
                      <span className="text-red-500">{data.amount}</span>
                    ) : (
                      <span className="text-green-500">{data.amount}</span>
                    )}
                  </td>
                  <td>{data.nameAccount}</td>
                  <td>{data.bankName}</td>
                  <td>
                    {data.statusType === "1" ? (
                      <span className="bg-green-500 text-white lg:text-xs text-[10px] rounded-full px-2">
                        ยอดฝาก
                      </span>
                    ) : (
                      <span className="bg-red-500  text-white text-xs rounded-full px-2">
                        ถอน/โอน
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-end items-baseline">
            <div className="flex gap-2 items-baseline my-3 ml-3">
              <label htmlFor="select" className="text-xs">
                จำนวนแถว
              </label>
              <select
                className="border-2 rounded-md p-0.5 text-xs"
                value={perPage}
                onChange={(e) => setPerPage(Number(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="mt-4 flex justify-center items-center flex-wrap">
              <button
                className="mx-2 px-3 py-1 border rounded-md text-xs"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, idx) => idx).map(
                (idx) => (
                  <button
                    key={idx}
                    className={`mx-1 px-2 py-1 border rounded-md text-xs ${
                      currentPage === idx + 1 ? "bg-blue-500 text-white" : ""
                    }`}
                    onClick={() => handlePageChange(idx + 1)}
                  >
                    {idx + 1}
                  </button>
                )
              )}

              <button
                className="mx-2 px-3 py-1 border rounded-md text-xs"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default TransferReport;
