import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card } from '@material-tailwind/react';
import { useState, useEffect } from 'react';
import { GetStudioCalendar } from '../hooks/Studio/StudioAdd';

const localizer = momentLocalizer(moment);
type StudioCalendarEventType = {
    status: number,
    message: string,
    studioData: [
        {
            studioId: number,
            startDate: string,
            makeupName: string,
            photographerName: string,
        }
    ]
};

type CalendarEvent = {
    id: number;
    start: Date;
    makeup: string;
    photographer: string;
};

const StudioCalendar = () => {
    const [eventSend, seteventSend] = useState<StudioCalendarEventType | null>(null);

    const EventComponent = ({ event }: { event: CalendarEvent }) => (
        <div className='text-xs cursor-pointer'>
            <p>ช่างแต่งหน้า: {event.makeup}</p>
            <p>ช่างภาพ: {event.photographer}</p>
        </div>
    );

    const handleSelectEvent = (event: CalendarEvent) => {
        const url = `/studio-detail/${event.id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const sendDatedata = async () => {
        const eventdata = await GetStudioCalendar();
        seteventSend(eventdata);
    };

    useEffect(() => {
        sendDatedata();
    }, []);

    let calendarEvents: CalendarEvent[] = [];

    if (eventSend) {
        calendarEvents = eventSend.studioData.map(item => ({
            id: item.studioId,
            start: new Date(item.startDate),
            makeup: item.makeupName,
            photographer: item.photographerName
        }));
    }

    return (
        <>
            <Card className='my-4 p-8'>
                <div className=' h-screen '>
                    <BigCalendar
                        localizer={localizer}
                        events={calendarEvents}
                        startAccessor="start"
                        endAccessor="start"
                        className='lg:text-sm text-xs'
                        popup
                        components={{
                            event: EventComponent
                        }}
                        onSelectEvent={handleSelectEvent}
                    />
                </div>
            </Card>
        </>
    );
};

export default StudioCalendar;