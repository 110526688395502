import { useState, useEffect } from 'react';
import { Card, Button, Input } from '@material-tailwind/react';
import { OrderTableRow } from "../../types/AllOrderTypes";
import { fetchSMSproviderList } from '../../hooks/Sms';
import { ProviderListType } from '../../types/SMSProviderlistTypes';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SendOnholdSMSType } from '../../types/SendsmsOnhold';
import { sendSMSonHold } from '../../hooks/Sms';
import Swal from 'sweetalert2';



interface SendOnholeSMSTypes {
    closeChagestepDialog: () => void;
    data: OrderTableRow | null;
}



const SendOnholdSMS: React.FC<SendOnholeSMSTypes> = ({ data ,closeChagestepDialog}) => {
    const [smsProviderlist, SetsmsPorviderlist] = useState<ProviderListType[]>([]);
    console.log("data",data);

    const {
        register, handleSubmit,
    } = useForm({
        defaultValues: {
            _ref: data?.order.refNumber,
            _amount: data?.order.wpSumprice,
            _phone: data?.order.phone,
            _smsProvider: null,
        }
    });

    const fetcProviderList = async () => {
        try {
            const smsData = await fetchSMSproviderList();
            SetsmsPorviderlist(smsData);
        } catch (error) {
            console.error("Error", error);
        }
    };

    useEffect(() => {
        fetcProviderList();
    }, []);

    const onSubmit: SubmitHandler<SendOnholdSMSType> = async (data) => {

        const processedData = {
            ...data,
            _smsProvider: Number(data._smsProvider)
        };
        try {
            const sendsmsresponse = await sendSMSonHold(processedData);
            if (sendsmsresponse.status === true) {
                closeChagestepDialog();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'ส่งข้อความหาลูกค้าสำเร็จ',
                    confirmButtonText: 'OK'
                  })
            }
            else {
                if (sendsmsresponse.status === false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'ผิดพลาด',
                        text: sendsmsresponse.error,
                        confirmButtonText: 'OK'
                      })
                }
              
            }
            
        } catch (error) {
            console.error("Error", error);
        }
    };

    return (
        <>
            <Card className='p-8 f'>
                
                <h1 className='text-center font-bold text-xl mb-4'> ส่งข้อความหาลูกค้า</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2 gap-2 border-2 p-4 rounded-lg '>
                        <div className='items-baseline '>
                            <label htmlFor="Ref Number" className='text-xs'> Ref Number : </label>
                            <Input crossOrigin="anonymous" label='Ref Number' disabled className='' value={data?.order.refNumber} />
                        </div>
                        <div >
                            <label htmlFor="Ref Number" className='text-xs'> ยอดสั่งซื้อ : </label>
                            <Input crossOrigin="anonymous" label='ยอดสั่งซื้อ' disabled className='' value={data?.order.wpSumprice || ''} />
                        </div>
                        <div >
                            <label htmlFor="Ref Number" className='text-xs'> เบอร์โทรลุกค้า : </label>
                            <Input crossOrigin="anonymous" label='เบอร์โทรลุกค้า' disabled className='' value={data?.order.phone} />
                        </div>
                        <div >
                            <label htmlFor="Ref Number" className='text-xs'> ผู้ให้บริการ SMS : </label>
                            <select className='border-2 p-1 rounded-lg w-full' placeholder='เลือกผู้ให้บริการ SMS' {...register("_smsProvider")}>
                                {smsProviderlist.map((sms, index) => (
                                    <option key={index} value={sms.id} > {sms.smsProviders}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='flex justify-center mt-4'>
                        <Button className='w-44' type='submit'> ส่งข้อความ</Button>
                    </div>

                </form>

            </Card>
        </>
    )
}

export default SendOnholdSMS