import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from '../hooks/Config';
import { SmsInfo } from '../types/SMSProviderlistTypes';
import { SendOnholdSMSType } from '../types/SendsmsOnhold';
import { SendingDataTypes } from '../types/SMSSending/SMSSubmitDataTypes';


export const fetchSMSproviderList = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/sms/select`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const findSmsbyID = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/sms/findby/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateSMS = async (id: number, data: SmsInfo) => {
  try {
    const responseFromUpdate = await axios.put(`${GolbalConfig.URL}/sms/update/${id}`, data, { headers: { 'Authorization': `Bearer ${useStore.getState().accessToken}` } });
    return responseFromUpdate;
  } catch (error) {
    console.error('Error updating:', error);
    throw error;
  }
};

export const fetchSmsReport = async (data: any) => {
  try {
    const reportResponse = await axios.post(`${GolbalConfig.URL}/sms/report`, data, {
      headers: { 'Authorization': `Bearer ${useStore.getState().accessToken}` },
    });
    return reportResponse.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const sendSMSonHold = async (data: SendOnholdSMSType) => {
  try {
    const reportResponse = await axios.post(`${GolbalConfig.URL}/sms/onhold`, data, {
      headers: { 'Authorization': `Bearer ${useStore.getState().accessToken}` },
    });
    return reportResponse.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const fetcSendingList = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/sms/sendinglist`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const findSendingSmsbyID = async (id: number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/sms/findbysending/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const UpdateSendingSMS = async (id: number, data: SendingDataTypes) => {
  try {
    const responseFromUpdate = await axios.put(`${GolbalConfig.URL}/sms/updatesending/${id}`, data, { headers: { 'Authorization': `Bearer ${useStore.getState().accessToken}` } });
    return responseFromUpdate;
  } catch (error) {
    console.error('Error updating:', error);
    throw error;
  }
};


