import { useState, useEffect } from 'react';
import { GetBankDATA } from '../../types/PushBulletTypes';
import { getBankNameDATA } from '../../hooks/pushbullet';
import { DeletePushBullet } from '../../hooks/pushbullet';
import Swal from 'sweetalert2';
import { Dialog } from '@material-tailwind/react';
import EditPushDetail from './EditPushDetail';

const EditPushBullet = () => {
    const [getBankDATA, setgetBankDATA] = useState<GetBankDATA[]>([]);
    const [selectedID, setSelectedID] = useState<number | null>(null);

    const bankNameData = async () => {
        const bankresponse = await getBankNameDATA();
        setgetBankDATA(bankresponse.bankData);
    };

    useEffect(() => {
        bankNameData();
    }, []);

    const handleDelete = async (selectedID: number) => {
        try {
            Swal.fire({
                icon: 'question',
                title: 'ลบข้อมูล',
                text: `คุณต้องการลบข้อมูล ใช่หรือไม่`,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่ใช่',
                allowOutsideClick: false,    // Don't close on outside click
                allowEscapeKey: false,      // Don't close on ESC key
                allowEnterKey: false        // Don't close on Enter key
            }).then(async (confirmResult) => {
                if (confirmResult.isConfirmed) {
                    const deleteresponse = await DeletePushBullet(selectedID);
                    if (deleteresponse.message === 'Success') {
                        Swal.fire({
                            icon: 'success',
                            title: 'สำเร็จ',
                            text: 'ลบข้อมูลเรียบร้อย',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error from Delete PushBullet", error);
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    return (
        <>
            <table className="w-full min-w-max table-auto text-cneter my-4">
                <thead className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 text-sm">
                    <tr>
                        <th className="py-3">ลำดับ</th>
                        <th>ID</th>
                        <th>ชื่อบัญชี</th>
                        <th>ธนาคาร</th>
                        <th>ชื่อเรียกบัญชี</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {getBankDATA.map((data, index) => (
                        <tr key={index} className="even:bg-blue-gray-50/50 text-sm text-center ">
                            <td className='p-4'>{index + 1}.</td>
                            <td>{data.id}</td>
                            <td>{data.bankAccount}</td>
                            <td>{data.bankName}</td>
                            <td>{data.nameAccount}</td>
                            <td><span className='hover:text-blue-500 hover:cursor-pointer' onClick={() => {handleOpen();  setSelectedID(data.id)}}>แก้ไข </span>| <span className='hover:text-red-500 hover:cursor-pointer' onClick={() => { handleDelete(data.id); }}>ลบ</span></td>
                        </tr>
                    ))}

                </tbody>
            </table>
            <Dialog open={open} handler={handleOpen}>
                <EditPushDetail id={selectedID} closeDialog={handleOpen}/>
            </Dialog>
        </>
    )
}

export default EditPushBullet