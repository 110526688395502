import React, { useState, useEffect } from "react";
import {
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { PushBulletOwner, getBankNameDATA } from "../hooks/pushbullet";
import { pushallsmsType, GetBankDATA, PullDataTypes } from "../types/PushBulletTypes";
import { Card, Select, Option, Button } from '@material-tailwind/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarComponent from '../components/CalendarComponent';





const PushBullet = () => {

  const [pushbulletdata, setpushBulletdata] = useState<PullDataTypes[]>([]);
  console.log("DATA", pushbulletdata);
  const [collectData, setCollectData] = useState<pushallsmsType>();
  const [getBankDATA, setgetBankDATA] = useState<GetBankDATA[]>([]);
  const [selectedBankName, setSelectedBankName] = useState<string | ''>('');
  const [selectednameAccount, setSelectednameAccount] = useState<string | ''>('');
  const [selectedStatus, setSelectedStatus] = useState<string | ''>('');
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalRecords = 100;
  const totalPages = Math.ceil(totalRecords / (perPage || 10));

  const [startDate, setStartDate] = useState<Date | string>('');
  const [endDate, setEndDate] = useState<Date | string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(null);


  const handleDateChange = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };


  const bankNameData = async () => {
    const bankresponse = await getBankNameDATA();
    setgetBankDATA(bankresponse.bankData);
  };

  useEffect(() => {
    bankNameData();
  }, []);

  useEffect(() => {
    const PushBulletData = async () => {
      const dataPushbullet = await PushBulletOwner(selectednameAccount, selectedBankName, selectedStatus, currentPage, Number(perPage), startDate, endDate);
      setpushBulletdata(dataPushbullet.data);
      setCollectData(dataPushbullet);


    };

    PushBulletData();
  }, [selectednameAccount, selectedBankName, selectedStatus, currentPage, perPage, startDate, endDate]);

  const handleReset = () => {
    setSelectedBankName('');
    setSelectednameAccount('');
    setSelectedStatus('');
  };

  const toggleSortAmount = () => {
    if (!sortDirection) {
      setSortDirection('asc');
    } else if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection(null);
    }
  };



  return (
    <>
      <div className="flex justify-end gap-2 mt-4">
        <Card className="p-4">
          <div className="flex items-center gap-5">
            <div className="bg-teal-500 p-2 rounded-full drop-shadow-lg">
              < FontAwesomeIcon icon={faMoneyCheckDollar} className="text-3xl text-white" />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm">ยอดรวมทั้งหน้า</p>
              <p className={` ${(collectData?.sumAmountPage ?? 0) > 0 ? 'text-green-500 font-bold text-2xl' : 'text-red-500 font-bold text-2xl'}`}>
                {collectData?.sumAmountPage}
              </p>
            </div>
          </div>
        </Card>
        <Card className="p-4">
          <div className="flex items-center gap-5">
            <div className="bg-cyan-400 p-2 rounded-full drop-shadow-lg">
              < FontAwesomeIcon icon={faMoneyCheckDollar} className="text-3xl text-white" />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-sm">ยอดรวมทั้งหมด</p>
              <p className={` ${(collectData?.sumAmountall ?? 0) > 0 ? 'text-green-500 font-bold text-2xl' : 'text-red-500 font-bold text-2xl'}`}>
                {collectData?.sumAmountall}
              </p>
            </div>
          </div>
        </Card>
      </div>
      {/* body section */}
      <Card className="bg-white rounded-lg mt-4 p-8">
        <div className="flex justify-between">
          <div>
            <CalendarComponent onDateChange={handleDateChange} />
          </div>
          <div>
            <div className="flex gap-2">
              <Select label="เลือกตามธนาคาร"
                value={selectedBankName || ''}
                onChange={(value) => {
                  if (value !== undefined) {
                    setSelectedBankName(value);
                  } else {
                    setSelectedBankName('');
                  }
                }}
              >
                {getBankDATA.map((bank) => (
                  <Option key={bank.id} value={bank.bankName}>
                    {bank.bankName}
                  </Option>
                ))}
              </Select>
              <Select label="ตามชื่อบัญชี"
                value={selectednameAccount || ''}
                onChange={(value) => {
                  if (value !== undefined) {
                    setSelectednameAccount(value);
                  } else {
                    setSelectednameAccount('');
                  }
                }}
              >
                {getBankDATA.map((bank) => (
                  <Option key={bank.id} value={bank.nameAccount}>
                    {bank.nameAccount}
                  </Option>
                ))}
              </Select>
              <Select label="ตามสถานะ"
                value={selectedStatus || ''}
                onChange={(value) => {
                  if (value !== undefined) {
                    setSelectedStatus(value);
                  } else {
                    setSelectedStatus('');
                  }
                }}
              >
                <Option value="1">ยอดฝาก</Option>
                <Option value="2">โอน/ถอน</Option>

              </Select>

              <div>
                <Button onClick={handleReset}>Reset</Button>
              </div>


            </div>

          </div>

        </div>
        <table className="w-full min-w-max table-auto text-cneter my-4">
          <thead className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 text-sm">
            <tr>
              <th className="py-3">ลำดับ</th>
              <th>วันที่ : เวลา</th>
              <th>ยอดเงิน</th>
              <th>บัญชี</th>
              <th>ธนาคาร</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>

            {pushbulletdata.map((data, index) => (
              <tr key={index} className="even:bg-blue-gray-50/50 text-xs text-center ">
                <td className="p-3">{data.Rows}.</td>
                <td>{data.createdDate}</td>
                <td>
                  {
                    data.statusType === "3" ?
                      "-" :
                      (data.amount ?? 0) < 0 ?
                        <span className="text-red-500">{data.amount}</span> :
                        <span className="text-green-500">{data.amount}</span>
                  }
                </td>                <td>{data.nameAccount}</td>
                <td>{data.bankName}</td>
                <td>
                  {data.statusType === "3" ? <span className="bg-yellow-500 text-black text-xs rounded-full px-2">OTP</span> :
                    (data.statusType === "1" ?
                      <span className="bg-green-500 text-white text-xs rounded-full px-2">ยอดฝาก</span> :
                      <span className="bg-red-500 text-white text-xs rounded-full px-2">ถอน/โอน</span>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end items-baseline">
          <div className="flex gap-2 items-baseline my-3 ml-3">
            <label htmlFor="select" className="text-xs">จำนวนแถว</label>
            <select
              className="border-2 rounded-md p-0.5 text-xs"
              value={perPage}
              onChange={(e) => setPerPage(Number(e.target.value))}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="mt-4 flex justify-center items-center">
            <button
              className="mx-2 px-3 py-1 border rounded-md text-xs"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>

            {Array.from({ length: totalPages }, (_, idx) => idx).map(idx => (
              <button
                key={idx}
                className={`mx-1 px-2 py-1 border rounded-md text-xs ${currentPage === idx + 1 ? 'bg-blue-500 text-white' : ''}`}
                onClick={() => handlePageChange(idx + 1)}
              >
                {idx + 1}
              </button>
            ))}

            <button
              className="mx-2 px-3 py-1 border rounded-md text-xs"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default PushBullet;
