import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from './Config';


export const StaticList = async (search:string , page:number , per_page:number,sort:string) => {
    try {
      const response = await axios.post(`${GolbalConfig.URL}/report/static`, {
        search: search,
        sort:sort,
        page:page,
        per_page:per_page,
      },{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating data:', error);
      throw error;
    }
  };
  

  // search:string , page:number , per_page:number