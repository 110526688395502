// NewOrderForm.tsx
import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { CreateNewOrder } from "../hooks/CreateNewOrder";
import { FormData } from "../types/NewOrderTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faCalendar,
  faClipboard,
  faForward,
  faList,
  faTrash,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import AddressSelector from "../components/AddressSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { th } from "date-fns/locale";
import Stepper from "../components/Stepper";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsSummary from "../components/Oder/OrderDetailsSummary";
import TranspotSelector from "../components/Transport/TranspotSelector";
import ShopIdSelector from "../components/shop/ShopIdSelector";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useStore } from "../hooks/store";

const initialFormData: FormData = {
  refNumber: 0,
  startDate: "",
  endDate: "",
  sendDate: "",
  orderRemark: "",
  transportId: {
    label: "",
    value: 0,
  },
  shopId: {
    label: "",
    value: 0,
  },
  cretedBy: `${useStore.getState().username}`,
  byAdmin: `${useStore.getState().username}`,
  orderDetails: [
    {
      detailName: "",
      quantity: 1,
      price: 0,
      depositPrice: 0,
      src: "",
    },
  ],
  custommer: {
    custommerFirstName: "",
    custommerLastName: "",
    telSend: "",
    address: "",
    zipCode: "",
    province: {
      id: 0,
      provinceName: "",
    },
    district: {
      id: 0,
      districtName: "",
    },
    subDistrict: {
      id: 0,
      subDistrictName: "",
    },
    facebook: "",
    IG: "",
    line: "",
    phone: "",
  },
  tacking: {
    tackingNumber: "",
  },
};

const NewOrderForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  ///////////////////////////////////////////////

  const [activeStep, setActiveStep] = useState(0);

  const formatDateToSend = (date: Date) => date.toISOString().slice(0, 10);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  ///// Submit Button  Functions //////
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await CreateNewOrder(formData);
      console.log("Add New Response", response);

      // Check if the order was created successfully
      if (response.message === "Order created successfully") {
        Swal.fire({
          icon: "success",
          title: "Order Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/OnHold"); // Replace '/' with your home route if different
          }
        });
      } else if (response.message === "Error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.error,
        });
      }
    } catch (error) {
      console.error("Error creating order:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาด",
      });
    }
  };

  ////////////////////////////////////////////

  // const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleOrderDetailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedOrderDetails = formData.orderDetails.map((detail, idx) =>
      idx === index ? { ...detail, [name]: value } : detail
    );
    setFormData({ ...formData, orderDetails: updatedOrderDetails });
  };

  const handleAddMoreItems = () => {
    setFormData({
      ...formData,
      orderDetails: [
        ...formData.orderDetails,
        {
          detailName: "",
          quantity: 1,
          price: 0,
          depositPrice: 0,
          src: "",
        },
      ],
    });
  };

  const handleDeleteItem = (indexToDelete: number) => {
    setFormData({
      ...formData,
      orderDetails: formData.orderDetails.filter(
        (_, index) => index !== indexToDelete
      ),
    });
  };

  const handleCustomerChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      custommer: { ...formData.custommer, [name]: value },
    });
  };

  ///// section ///////
  const [currentSection, setCurrentSection] = useState(1);

  const handleNextSection = () => {
    setCurrentSection(currentSection + 1);
  };
  const handlePreviousSection = () => {
    setCurrentSection(currentSection - 1);
  };

  const handleNextButtonClick = () => {
    handleNextSection();
    if (activeStep < 4) {
      // Make sure it doesn't go beyond the last section (assuming 4 sections)
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBackButtonClick = () => {
    handlePreviousSection();
    if (activeStep > 0) {
      // Make sure it doesn't go below the first section
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  ////////

  const renderSection1 = () => {
    // Render the first section of the form
    return (
      <>
        <div id="GeneralDetail" className="border rounded-xl py-4">
          <h1 className="font-bold my-4 mx-10 text-sm">รายละเอียดทั่วไป</h1>
          <div
            id="left"
            className="mx-10 my-3 lg:flex lg:flex-row flex flex-col gap-2"
          >
            <div id="L" className="lg:w-1/2">
              <div
                id="G1"
                className=" flex lg:flex-row lg:items-center flex-col gap-2"
              >
                <div className="lg:w-1/2 ">
                  <label className="block text-sm font-medium text-gray-700">
                    หมายเลขคำสั่งซื้อ (Ref Number)
                  </label>
                  <input
                    type="number"
                    name="refNumber"
                    placeholder="หมายเลขคำสั่งซื้อไม่ต้องใส่ #"
                    id="refNumber"
                    value={formData.refNumber}
                    onChange={(e: any) => {
                      setFormData({ ...formData, refNumber: e.target.value });
                    }}
                    className="p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="  lg:w-1/2 ">
                  <label className="block text-sm font-medium text-gray-700">
                    สาขา
                  </label>
                  <ShopIdSelector
                    onChange={(e: any) => {
                      setFormData({ ...formData, shopId: e });
                    }}
                  />
                </div>
              </div>
              <div
                id="G2"
                className=" flex lg:flex-row lg:items-center flex-col gap-2"
              >
                <div className="lg:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">
                    วันเช่าชุด
                  </label>
                  <DatePicker
                    selected={
                      formData.startDate != ""
                        ? new Date(formData.startDate)
                        : null
                    }
                    onChange={(date: Date | null) => {
                      setFormData({
                        ...formData,
                        startDate: moment(date).format("YYYY-MM-D"),
                      });
                    }}
                    locale={th}
                    dateFormat="dd MMMM yyyy"
                    placeholderText="วันที่เช่าชุด"
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">
                    วันคืนชุด
                  </label>
                  <DatePicker
                    selected={
                      formData.endDate != "" ? new Date(formData.endDate) : null
                    }
                    onChange={(date: Date | null) => {
                      setFormData({
                        ...formData,
                        endDate: moment(date).format("YYYY-MM-D"),
                      });
                    }}
                    locale={th}
                    dateFormat="dd MMMM yyyy"
                    placeholderText="วันคืนชุด"
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                id="G3"
                className=" flex lg:flex-row lg:items-center flex-col gap-2"
              >
                <div className="lg:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">
                    วันที่จัดส่ง
                  </label>
                  <DatePicker
                    selected={
                      formData.sendDate != ""
                        ? new Date(formData.sendDate)
                        : null
                    }
                    onChange={(date: Date | null) => {
                      setFormData({
                        ...formData,
                        sendDate: moment(date).format("YYYY-MM-D"),
                      });
                    }}
                    locale={th}
                    dateFormat="dd MMMM yyyy"
                    placeholderText="วันที่จัดส่ง"
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-1/2 mt-1">
                  <label className="block text-sm font-medium text-gray-700">
                    เลือกการจัดส่ง
                  </label>
                  <TranspotSelector
                    onChange={(e: any) => {
                      setFormData({ ...formData, transportId: e });
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              id="R"
              className=" flex lg:flex-row lg:items-center flex-col gap-2"
            >
              <div id="orderRemark">
                <label className="block text-sm font-medium text-gray-700">
                  หมายเหตุ
                </label>
                <textarea
                  name="orderRemark"
                  id="orderRemark"
                  value={formData.orderRemark}
                  onChange={(e) => {
                    setFormData({ ...formData, orderRemark: e.target.value });
                  }}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-[1px] h-[160px] border-gray-300 rounded-md p-2.5"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 flex justify-end mx-10">
          <button
            type="button"
            onClick={handleNextButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm  text-white px-4 py-0.5 rounded-lg  w-28 shadow-md"
          >
            ถัดไป <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </>
    );
  };

  const renderSection2 = () => {
    // Render the second section of the form
    return (
      <div id="customer">
        <div id="orderDetail" className="my-4 mx-10">
          {formData.orderDetails.map((detail, index) => (
            <div key={`order-detail-${index}`} className="space-y-4">
              <h3 className="font-bold mt-2">รายละเอียดชุด {index + 1}</h3>
              <div className="lg:flex gap-2 items-end">
                <div className="lg:w-[60%]">
                  <label
                    htmlFor={`detailName-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    ชื่อสินค้า
                  </label>
                  <input
                    type="text"
                    name="detailName"
                    placeholder="ใส่รายละเอียดสินค้า"
                    id={`detailName-${index}`}
                    value={detail.detailName}
                    onChange={(e) => handleOrderDetailChange(e, index)}
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm text-xs border-[1px] w-full border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-[15%]">
                  <label
                    htmlFor={`price-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    ราคา
                  </label>
                  <input
                    type="text"
                    name="price"
                    placeholder="ราคาสินค้า"
                    id={`price-${index}`}
                    value={detail.price}
                    onChange={(e) => handleOrderDetailChange(e, index)}
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block  shadow-sm text-xs border-[1px] border-gray-300 rounded-md w-full"
                  />
                </div>
                <div className="lg:w-[15%]">
                  <label
                    htmlFor={`depositPrice-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    ค่ามัดจำ
                  </label>
                  <input
                    type="text"
                    name="depositPrice"
                    placeholder="ราคาสินค้า"
                    id={`depositPrice-${index}`}
                    value={detail.depositPrice}
                    onChange={(e) => handleOrderDetailChange(e, index)}
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div id="DeleteitemButton" className="flex flex-col">
                  <button
                    type="button"
                    onClick={() => handleDeleteItem(index)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 text-xs rounded my-1"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-[20px] mt-0.5"
                    />
                  </button>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="flex flex-col">
            <button
              type="button"
              onClick={handleAddMoreItems}
              className="bg-gray-400 hover:bg-gray-600 text-white py-2 px-4 text-sm rounded mr-4 mt-4"
            >
              {" "}
              <FontAwesomeIcon
                icon={faPlusSquare}
                className="text-[14px] mt-0.5"
              />{" "}
              เพิ่มสินค้า
            </button>
          </div>
        </div>

        <div className="flex justify-between gap-2 my-8 mx-10">
          <button
            type="button"
            onClick={handleBackButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm text-white px-4 py-0.5 rounded-lg w-28 shadow-md"
          >
            <FontAwesomeIcon icon={faBackward} /> ย้อนกลับ
          </button>
          <button
            type="button"
            onClick={handleNextButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm  text-white px-4 py-0.5 rounded-lg  w-28 shadow-md"
          >
            ถัดไป <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    // Render the third section of the form
    return (
      <div>
        <div id="custommer" className="my-2 mx-4">
          <h2 className="font-bold my-4">รายละเอียดลูกค้าและข้อมูลการจัดส่ง</h2>
          <div className="space-y-4">
            <div id="custommerDetail-1" className="lg:flex lg:flex-row flex flex-col gap-2">
              <div className="lg:w-[33.33%]">
                <label className="block text-sm font-medium text-gray-700">
                  ชื่อผู้รับ
                </label>
                <input
                  type="text"
                  name="custommerFirstName"
                  placeholder="กรุณากรอกชื่อผู้รับสินค้า"
                  id="custommerFirstName"
                  value={formData.custommer.custommerFirstName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      custommer: {
                        ...formData.custommer,
                        custommerFirstName: e.target.value,
                      },
                    });
                  }}
                  className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                />
              </div>
              <div className="lg:w-[33.33%]">
                <label className="block text-sm font-medium text-gray-700">
                  นามสกุล
                </label>
                <input
                  type="text"
                  name="custommerLastName"
                  placeholder="กรุณากรอกนามสกุลผู้รับสินค้า"
                  id="customemrLastName"
                  value={formData.custommer.custommerLastName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      custommer: {
                        ...formData.custommer,
                        custommerLastName: e.target.value,
                      },
                    });
                  }}
                  className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                />
              </div>
              <div className="lg:w-[33.33%]">
                <label className="block text-sm font-medium text-gray-700">
                  หมายเลขโทรศัพท์ผู้รับสินค้า
                </label>
                <input
                  type="text"
                  name="telSend"
                  placeholder="หมายเลขโทรศัพท์สำหรับรับสินค้า"
                  id="telSend"
                  value={formData.custommer.telSend}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      custommer: {
                        ...formData.custommer,
                        telSend: e.target.value,
                      },
                    });
                  }}
                  className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div id="customerDtail-2">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700">
                  ที่อยู่
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder="ที่อยู่ผู้รับสินค้า"
                  id="address"
                  value={formData.custommer.address}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      custommer: {
                        ...formData.custommer,
                        address: e.target.value,
                      },
                    });
                  }}
                  className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                />
              </div>
              <div className="lg:flex lg:flex-row flex flex-col gap-2">
                <div className="lg:w-[75%] mt-2">
                  <AddressSelector
                    ProviceValue={null}
                    SubDistrictValue={null}
                    DistrictValue={null}
                    onProvinceSelect={(e: any) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          province: e,
                        },
                      });
                    }}
                    onDistrictSelect={(e: any) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          district: e,
                        },
                      });
                    }}
                    onSubdistrictSelect={(e: any) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          subDistrict: e,
                        },
                      });
                    }}
                  />
                </div>

                <div className="lg:w-[25%] lg:my-4">
                  <label className="block text-sm font-medium text-gray-700">
                    รหัสไปรษณีย์
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="รหัสไปรษณีย์"
                    id="zipCode"
                    value={formData.custommer.zipCode}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          zipCode: e.target.value,
                        },
                      });
                    }}
                    className="mt-1 p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div id="cutomerSocialDetail" className="lg:flex lg:flex-row flex flex-col gap-2 mt-2">
                <div className="lg:w-[25%]">
                  <label className="block text-sm font-medium text-gray-700">
                    หมายเลขโทรศัพท์รับ OTP
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="หมายเลขโทรศัพท์สำหรับรับ OTP"
                    id="phone"
                    value={formData.custommer.phone}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          phone: e.target.value,
                        },
                      });
                    }}
                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-[25%]">
                  <label className="block text-sm font-medium text-gray-700">
                    Facebook
                  </label>
                  <input
                    type="text"
                    name="facebook"
                    placeholder="ชื่อเฟสบุ๊คของลูกค้า"
                    id="facebook"
                    value={formData.custommer.facebook}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          facebook: e.target.value,
                        },
                      });
                    }}
                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-[25%]">
                  <label className="block text-sm font-medium text-gray-700">
                    Instagram
                  </label>
                  <input
                    type="text"
                    name="IG"
                    placeholder="instagram"
                    id="IG"
                    value={formData.custommer.IG}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          IG: e.target.value,
                        },
                      });
                    }}
                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
                <div className="lg:w-[25%]">
                  <label className="block text-sm font-medium text-gray-700">
                    Line ID:
                  </label>
                  <input
                    type="text"
                    name="line"
                    placeholder="ไลน์ไอดีของลูกค้า"
                    id="line"
                    value={formData.custommer.line}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        custommer: {
                          ...formData.custommer,
                          line: e.target.value,
                        },
                      });
                    }}
                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2 mt-8 mx-10">
          <button
            type="button"
            onClick={handleBackButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm text-white px-4 py-0.5 rounded-lg w-28 shadow-md"
          >
            <FontAwesomeIcon icon={faBackward} /> ย้อนกลับ
          </button>
          <button
            type="button"
            onClick={handleNextButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm  text-white px-4 py-0.5 rounded-lg  w-28 shadow-md"
          >
            ถัดไป <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </div>
    );
  };

  ///// render OderDetail Summary functions //////

  const renderCustomerSummary = () => {
    return (
      <div className="lg:text-sm text-xs">
        <h3 className="font-semibold">รายละเอียดลูกค้า:</h3>
        <ul className="list-disc list-inside">
          <li>
            ชื่อผู้รับ:{" "}
            <span className="text-pink-500">
              {formData.custommer.custommerFirstName}
            </span>
          </li>
          <li>
            นามสกุล:{" "}
            <span className="text-pink-500">
              {formData.custommer.custommerLastName}
            </span>
          </li>
          <li>
            หมายเลขโทรศัพท์ผู้รับสินค้า:{" "}
            <span className="text-pink-500">{formData.custommer.telSend}</span>
          </li>
          <li>
            ที่อยู่:{" "}
            <span className="text-pink-500">{formData.custommer.address}</span>{" "}
            ตำบล/แขวง
            <span className="text-pink-500">
              {formData.custommer.subDistrict.subDistrictName}
            </span>{" "}
            อำเภอ/เขต
            <span className="text-pink-500">
              {formData.custommer.district.districtName}
            </span>{" "}
            จังหวัด{" "}
            <span className="text-pink-500">
              {formData.custommer.province.provinceName}
            </span>
          </li>
          <li>
            รหัสไปรษณีย์:{" "}
            <span className="text-pink-500">{formData.custommer.zipCode}</span>{" "}
          </li>
          <li>
            หมายเลขโทรศัพท์รับ OTP:{" "}
            <span className="text-pink-500">{formData.custommer.phone}</span>
          </li>
          <li>
            Facebook:{" "}
            <span className="text-pink-500">{formData.custommer.facebook}</span>
          </li>
          <li>
            Instagram:{" "}
            <span className="text-pink-500">{formData.custommer.IG}</span>
          </li>
          <li>
            Line ID:{" "}
            <span className="text-pink-500">{formData.custommer.line}</span>
          </li>
        </ul>
      </div>
    );
  };

  const rederGeneralDetails = () => {
    return (
      <div className="lg:text-sm text-xs">
        <h3 className="font-semibold">รายละเอียดทั่วไป:</h3>
        <ul className="list-disc list-inside">
          <li>
            หมายเลขคำสั่งซื้อ (Ref Number):{" "}
            <span className="text-pink-500">{formData.refNumber}</span>
          </li>
          <li>
            สาขาร้าน:{" "}
            <span className="text-pink-500">{formData.shopId.label}</span>
          </li>
          <li>
            ผู้สร้างคำสั่งซื้อ:{" "}
            <span className="text-pink-500">{formData.cretedBy}</span>
          </li>
          <li>
            วันเช่าชุด:{" "}
            <span className="text-pink-500">{formData.startDate}</span>
          </li>
          <li>
            วันคืนชุด: <span className="text-pink-500">{formData.endDate}</span>
          </li>
          <li>
            วันจัดส่งสินค้า:{" "}
            <span className="text-pink-500">{formData.sendDate}</span>{" "}
          </li>
          <li>
            ขนส่ง:{" "}
            <span className="text-pink-500">{formData.transportId.label}</span>
          </li>
          <li>
            หมายเหตุ:{" "}
            <span className="text-pink-500">{formData.orderRemark}</span>
          </li>
        </ul>
      </div>
    );
  };
  ////////////////////////////////////////////////////////////////

  ///// Summary ////////
  const renderSection4 = () => {
    return (
      <div className="space-y-4 px-2">
        <h2 className="text-pink-500 font-bold  underline-offset-2 underline">
          รายละเอียดคำสั่งซื้อ
        </h2>
        <div className="flex flex-col gap-2">
          <div className=" lg:w-1/2 border-[1px] rounded-xl p-6">
            {rederGeneralDetails()}
          </div>
          <div className=" lg:w-1/2 border-[1px] rounded-xl p-6">
            {renderCustomerSummary()}
          </div>
        </div>

        <OrderDetailsSummary orderDetails={formData.orderDetails} />

        <div className="my-4 flex justify-between">
          <button
            onClick={handleBackButtonClick}
            className="bg-pink-500 hover:bg-pink-700 text-sm text-white px-4 py-0.5 rounded-lg w-28 shadow-md"
          >
            <FontAwesomeIcon icon={faBackward} /> ย้อนกลับ
          </button>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-sm text-white px-4 py-0.5 rounded-lg w-28 shadow-md"
          >
            ส่งคำสั่งซื้อ <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      </div>
    );
  };

  /////////////////////

  const renderCurrentSection = () => {
    // Render the current section based on the value of currentSection
    switch (currentSection) {
      case 1:
        return renderSection1();
      case 2:
        return renderSection2();
      case 3:
        return renderSection3();
      case 4:
        return renderSection4();
    }
  };
  ///////////////////////

  {
    /*Stepper*/
  }

  const steps = [
    {
      title: "รายละเอียดทั่วไป",
      description: "รายละเอียดคำสั่งซื้อ",
      icon: faCalendar,
      titleClassName: "text-gray-600 lg:text-sm text-xs",
      descriptionClassName: "text-gray-400 text-xs",
      iconClassName: "text-white ",
    },
    {
      title: "รายละเอียดสินค้า",
      description: "รายละเอียดสินค้า,จำนวน,ราคา",
      icon: faClipboard,
      titleClassName: "text-gray-600 lg:text-sm text-xs",
      descriptionClassName: "text-gray-400 text-xs",
      iconClassName: "text-white",
    },
    {
      title: "ข้อมูลการจัดส่ง",
      description: "ข้อมูลการจัดส่งและติดต่อลูกค้า",
      icon: faTruckPickup,
      titleClassName: "text-gray-600 lg:text-sm text-xs",
      descriptionClassName: "text-gray-400 text-xs",
      iconClassName: "text-white",
    },
    {
      title: "ตรวจสอบ",
      description: "ภาพรวมคำสั่งซื้อ",
      icon: faList,
      titleClassName: "text-gray-600 lg:text-sm text-xs",
      descriptionClassName: "text-gray-400 text-xs ",
      iconClassName: "text-white",
    },
  ];

  {
    /*Stepper*/
  }
  return (
    <>
      <div className="bg-white p-5 rounded-xl lg:my-4 drop-shadow-xl">
        <div className=" mb-16 text">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>

        <form onSubmit={handleSubmit} className="my-4">
          {renderCurrentSection()}
        </form>
      </div>
    </>
  );
};

export default NewOrderForm;
