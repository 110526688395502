import axios from "axios";
import { GolbalConfig } from './Config';

export const userLogin = async (username: string, password: string) => {
    try {
      const response = await axios.post(`${GolbalConfig.URL}/auth/login`, {
      username: username,
      password: password
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };