import { Card } from '@material-tailwind/react'
import React from 'react'
import Update from '../components/Studio/Update'

const StudioUpdate = () => {
    return (
        <Card className='mt-4 p-4'>
            <div>
                <Update />
            </div>

        </Card>
    )
}

export default StudioUpdate