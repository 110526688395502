import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from '../hooks/Config';
import { AdminUpdateTypes } from '../types/AdminListTypes';

export const fetchAdminList = async () => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/user/list`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const addNewUser = async (data: {nickname: string, username: string, password: string, role: string,commission:number,commissionType:string}) => {
  try {
      const response = await axios.post(`${GolbalConfig.URL}/user/newuser`, data, {
          headers: {
              Authorization: `Bearer ${useStore.getState().accessToken}`
          }
      });

      return response.data;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
  }
};



export const DeleteUser = async (id: string) => {
  try {
    const response = await axios.put(`${GolbalConfig.URL}/user/delete/?id=${id}`,{},{
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const findUserbyID = async (id: string) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/user/findby/?id=${id}`, {headers : { 'Authorization': `Bearer ${useStore.getState().accessToken}`} });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const UpdateUser = async (id: string, data : AdminUpdateTypes) => {
  try {
    const responeFromupdate = await axios.put(`${GolbalConfig.URL}/user/update/?id=${id}`,data,{headers : { 'Authorization': `Bearer ${useStore.getState().accessToken}`} });
    return responeFromupdate;
  } catch ( error ) {
    console.error('Error updating:', error);
    throw error;
  }
};