import React, { useEffect, useState } from "react";
import { IOrder } from "../../types/CustomerOrderDetailTypes";
import { ConditionResDataTypes } from "../../types/ConditionTypes/ConditionDataTypes";
import { GetConditionData } from "../../hooks/Condition/GetCondition";
import moment from "moment";

interface CCDetailTypes {
  cDetail: IOrder | undefined;
}

const ConditionAgreement: React.FC<CCDetailTypes> = ({ cDetail }) => {
  const [conditionData, setConditionData] =
    useState<ConditionResDataTypes | null>(null);
  const [renderedHTML, setRenderedHTML] = useState("");

  useEffect(() => {
    const fetchAgreementData = async () => {
      try {
        const resData = await GetConditionData();
        if (resData.status === true) {
          setConditionData(resData);
        }
      } catch (error) {
        console.error("Failed to fetch condition data:", error);
      }
    };

    fetchAgreementData();
  }, []);

  useEffect(() => {
    if (conditionData) {
      // Using example dates for now, replace with actual logic if needed
      const startDate = "222222";
      const endDate = "555555";
      const htmlWithDates = conditionData.conditionData.text
        .replace("{{StartDate}}", startDate)
        .replace("{{EndDate}}", endDate);
      setRenderedHTML(htmlWithDates);
    }
  }, [conditionData]);

  const formatDateToThai = (dateString: string | null) => {
    if (!dateString) return '';

    moment.locale('th'); // Set moment's locale to Thai
    return moment(dateString).format('LL'); // 'LL' is a predefined format for moment that translates to something like "1 มกราคม 2566"
  }

  return (
    <>
      {/* <div className="m-4 p-4 border rounded-xl text-xs" dangerouslySetInnerHTML={{ __html: renderedHTML }} /> */}
      <div className="p-4 text-xs flex flex-col gap-2 border m-4 rounded-xl">
        <h2 className="underline font-bold">เงื่อนไขการเช่าชุด</h2>
        <span>
          {" "}
          <ol type="1">
            <li>
              1. สินค้าตกลงเช่าแล้วทางร้านขอสงวนสิทธิ์ในการยกเลิกหรือคืนเงินค่าเช่าชุดไม่ว่ากรณีใดๆ  แต่สามารถเก็บเครดิตไว้ใช้ได้ 3 เดือน นับจากวันเช่า
            </li>
            <li>
              2. กรณีต้องการเปลี่ยนวันเช่าต้องแจ้งล่วงหน้าอย่างน้อย 3 วัน (นับจากวันที่มีกำหนดส่งให้ลูกค้า)
              การเปลี่ยนวันเช่าจะมีค่าเปลี่ยนครั้งละ 50-200 บาท ขึ้นอยู่กับราคาชุดที่เช่า
            </li>
            <li>
              3. สามารถแจ้งเปลี่ยนในราคาที่เท่ากันหรือสูงกว่าเท่านั้น หากราคาชุดต่ำกว่า ส่วนต่างไม่สามารถเก็บเพื่อเช่าในครั้งต่อไปหรือคืนเงินได้ทุกกรณี
            </li>
            <li>
              4. ขอสงวนสิทธิ์ไม่คืนเงินกรณีเปลี่ยนชุด ยกเลิกชุด หรือเหตุผลอื่น ที่เกิดจากผู้เช่าในทุกกรณี

              {/* {" "}
              4. ระยะเวลาในการเช่าชุด วันที่เช่าชุด{" "}
              <span className="font-bold">{formatDateToThai(cDetail?.order.startDate || '')}</span> วันที่ส่งคืนชุด{" "}
              <span className="font-bold">{formatDateToThai(cDetail?.order.endDate || '')}</span  > */}
            </li>
            <li>
              5. กรุณาเช็คสัดส่วนของชุด รายละเอียดที่ต้องการทราบกับแอดมินให้ชัดเจนเพราะ ถ้าตัดสินใจเช่าแล้ว
              รับชุดไปแล้ว ร้านส่งตามไซต์ที่ลูกค้าแจ้ง ใส่ไม่ได้ ไม่พอดี เช่าไปแล้ว ผิดไซต์ ใส่ไม่ได้ทางร้านไม่รับผิดชอบทุกกรณี ไม่คืนเงินไม่เปลี่ยนแบบ กรุณาตรวจสอบแบบ สี ไซต์ให้ถูกต้อง ก่อนตัดสินใจเช่า (ทางร้านวัดสัดส่วนจากชุดจริงทุกชุดและทางร้านส่งรูปงานจริงให้ลูกค้าก่อนส่งของทุกครั้ง ถ้าชุดไม่ถูกต้องหรือไม่พึงพอใจรบกวนแจ้งก่อนส่งของ หากส่งออกไปแล้วทางร้านจะไม่รับผิดชอบหรือคืนเงินทุกกรณี
            </li>
            <li>
              6. กรณี ร้านส่ง ผิดไซด์ ผิดสี ผิดแบบ รบกวน ลูกค้า แจ้ง เครม ทันที ในวันที่ได้รับชุด ร้านจะส่งชุดใหม่ไปเครมให้ หรือ กรณีขอเงินคืน ต้องส่งชุดคืน ทันที ภายในวันที่ได้รับชุด หาก ไม่ส่งคืนทันที จะนับตามเรทวันเช่า ปกติ            </li>
            <li>
              7. รบกวนส่งคืนชุดผ่านขนส่ง Kerry , EMS , Flash J&T เท่านั้น หากส่งขนส่งอื่นมาและชุดถึงร้านล่าช้าจนไม่สามารถส่งชุดได้ตามกำหนดให้ลูกค้าท่านถัดไป ลูกค้าต้องชำระเต็มตามจำนวนเรทของการเช่าของลูกค้าท่านถัดไป
            </li>
            <li>
              8. หากลูกค้าส่งคืนช้าเกินวันที่กำหนด จะมีค่าปรับตามราคาชุดเช่า (หากใช้ระบบขนส่ง จะยึดตามเลขพัสดุขนส่งนั้นๆ กรณีชุดมีคิวต่อเนื่องและไม่สามารถส่งชุดได้ตามกำหนดให้ลูกค้าท่านถัดไป ลูกค้าต้องชำระเต็ม
              จำนวนเรทของการเช่าของลูกค้าท่านถัดไปเนื่องจากทางร้านค้าเกิดความเสียหาย
              * ร้านจะแจ้งวันรับชุดและวันส่งคืนให้ตอนสรุปรายละเอียดก่อนโอน กรุณาตรวจสอบก่อนทุกครั้ง (ทางร้านไม่อยากปรับนะคะ ฉะนั้นกรุณาส่งคืนตามกำหนด)
            </li>
            <li>
              9. รบกวนตรวจสอบของทุกชิ้นที่ได้รับและส่งคืนให้ครบ เช่น เข็มขัด โบว์ เข็มกลัด ถุงมือ อุปกรณ์ติดชุดต่างๆ ไม้แขวน กระเป๋าผ้า และอื่นๆ หากได้รับชุดแล้วกรุณาตรวจสอบ หากพบตำหนิชุด หรือสินค้าไม่ครบ ให้แจ้งทางร้านทันที หากไม่มีการแจ้งใดใด ทางร้านตรวจพบความเสียหายหรือสูญหายจะมีค่าปรับตามที่กำหนด
            </li>
            <li>
              10. ห้าม เย็บชุด แก้ไขชุด ดัดแปลงชุด หรือติดเข็มกลัดเอง ทุกกรณี หากฝ่าฝืน และทำให้ชุด เสียหาย ทางร้านจะคิดค่าปรับตามความเหมาะสม แล้วหากชุดเสียหายมากจนนำชุดไปปล่อยเช่าไม่ได้ ทางร้านจะคิดเป็นราคาขายให้ลูกค้า เพื่อไปซื้อชุดใหม่
            </li>
            <li>
              11. เงินมัดจำลูกค้าจะได้รับคืนต่อเมื่อทางร้านได้รับชุดคืน และมีการตรวจสอบเรียบร้อยแล้วจะดำเนินการคืนมัดจำตามคิว
            </li>
            <li>
              {" "}
              12. ชุดขาด เสียหาย อะไหล่หลุด อะไหล่หาย (ประเมินหน้างาน) หากซ่อมไม่ได้หรือซ่อมแล้วเกิดตำหนิหนักที่ชุด ปรับเต็มราคาชุด ชุดเลอะจากการพอกตัว คราบเครื่องสำอางค์ ระบายของชุดเปื้อนคราบสกปรกหนักมาก หรือคราบฝังลึกอื่นอื่นโดยเกิดการใช้งานอันผิดปกติเกินไป หรือซักไม่ออก
              คราบเลือด คราบหมึก รอยไวน์แดง ต้องใช้น้ำยาขจัดคราบแบบพิเศษ จะมีค่าส่งซักร้าน ซักรีด ตามราคาความเป็นจริง
              * กรณีเกิดความเสียหายต่อชุดและไม่สามารถนำมาเช่าต่อได้ จะคิดค่าปรับเต็มจำนวนตามมูลค่าชุดไม่ว่าชุดจะผ่านการใช้งานมาแล้วหรือไม่ และทางร้านจะส่งชุดที่เกิดความเสียหายให้ลูกค้าในวันถัดไป
            </li>
            <li>
              {" "}
              13. กรณีหายสาบสูญเกิน 3 วัน ติดต่อไม่ได้ ไม่คืนชุดตามกำหนด ขออนุญาตแจ้งดำเนินคดีตามกฎหมาย หากมีการแจ้งความดำเนินคดีไปแล้วต้องการให้ถอนแจ้งความ ต้องนำชุดมาคืน พร้อมค่าปรับ 10 เท่าของราคาชุด
            </li>
            {/* <li>
              {" "}
              13. กรุณาตรวจสอบหมายเลขบัญชี รับค่ามัดจำ/ค่าประกันชุด ให้ถูกต้อง หากทางร้านโอนเงินผิดบัญชี ตามที่ลูกค้ากรอกเลขบัญชีเข้ามาแล้ว ทางร้านจะไม่รับผิดชอบใดๆ ทั้งสิ้น
            </li> */}
          </ol>
        </span>
        <br />
        <span>
          {" "}
          ส่งชุดคืนที่ เช่าชุดออนไลน์ By ยูอิ เกยูร ณ เชียงใหม่ 345/41 หมู่ 5
          หมู่บ้านกาญจน์กนก 20 ต.สันทรายน้อย อ.สันทราย จ.เชียงใหม่ 50120 📱 โทร
          0631927911
        </span>
      </div>
    </>
  );
};

export default ConditionAgreement;
