import Select from "react-select";
import { GetTranspotList } from '../../hooks/TranspotList';
import { TransportType } from "../../types/TranspotTypes";
import { useState, useEffect } from 'react';

interface TranspotSelectorProps {
  onTransportSelect: (transport: TransportType | null) => void;
}

const TranspotSelector: React.FC<any> = (props) => {
  const [transpot, setTransport] = useState<TransportType[]>([]);
  const [selectedTransport, setSelectedTransport] = useState<TransportType | null>(
    null
  );

  useEffect(() => {
    const fetchTranspotList = async () => {
      const fetchTranspotList = await GetTranspotList();
      setTransport(fetchTranspotList);
    }
    fetchTranspotList();
  }, []);

  return (
    <div>
      <div className="text-xs">
        <Select
        {...props}
          options={transpot.map(({ id, transportName }) => ({
            value: id,
            label: transportName,
          }))}
          placeholder="เลือกขนส่ง"
        />
      </div>
    </div>
  )
}

export default TranspotSelector;
