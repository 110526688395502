import { useState, useEffect } from 'react'
import { Card, Select, Option, Button, Progress, Dialog, Tabs, TabsHeader, Tab, TabsBody, TabPanel } from '@material-tailwind/react';
import { reportWork, GetOTData } from '../../hooks/TimeCardCheckin';
import { useStore } from '../../hooks/store'
import { WorkReportResponse } from '../../types/TimeCardTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faHouseChimney, faListCheck, faMinus, faPlus, } from '@fortawesome/free-solid-svg-icons';
import AddOT from '../../components/TimeCard/AddOT';
import DisOT from '../../components/TimeCard/DisOT';
import OvertimeLoglist from './OvertimeLoglist';
import CalendarComponent1mont from '../../components/CalendarComponent1mont';
import { User } from '../../types/AdminListTypes';
import { fetchAdminList } from '../../hooks/UserAdmin';
import GetOT from './GetOT';





const WorkandOTReport = () => {
    const { username } = useStore();
    const [userList, setUserlist] = useState<User[]>([]);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [workReportData, setWorkReportData] = useState<WorkReportResponse>();
    const [openAddOT, setOpenAddOT] = useState(false);
    const [openDisOT, setOpenDisOT] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");


    const handleOpenAddOT = () => setOpenAddOT(!openAddOT);
    const handleOpenDisOT = () => setOpenDisOT(!openDisOT);

    const [startDate, setStartDate] = useState<Date | string>("");
    const [endDate, setEndDate] = useState<Date | string>("");

    const handleDateChange = (start: Date | string, end: Date | string) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleUserSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(event.target.value);
    };

    const getworkRepot = async () => {
        const workData = await reportWork(selectedMonth, selectedYear, "");
        setWorkReportData(workData);
    }

    const GetotTime = async () => {
        const OTRes = await GetOTData(username);
    }

    useEffect(() => {
        GetotTime();
        getworkRepot();
    }, [selectedMonth, selectedYear, username])

    const fetchUserList = async () => {
        const userListData = await fetchAdminList();
        setUserlist(userListData.user);
    };

    useEffect(() => {
        fetchUserList();
    }, []);

    const mount = [
        { value: "01", label: "มกราคม" },
        { value: "02", label: "กุมภาพัน" },
        { value: "03", label: "มีนาคม" },
        { value: "04", label: "เมษายน" },
        { value: "05", label: "พฤษภาคม" },
        { value: "06", label: "มิถุนายน" },
        { value: "07", label: "กรกฏาคม" },
        { value: "08", label: "สิงหาคม" },
        { value: "09", label: "กันยายน" },
        { value: "10", label: "ตุลาคม" },
        { value: "11", label: "พฤศจิกายน" },
        { value: "12", label: "ธันวาคม" },
    ];
    const year = ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];

    const handleMonthChange = (value: any) => {
        setSelectedMonth(value);
    };

    const handleYearChange = (value: any) => {
        setSelectedYear(value);
    };

    return (
        <>
            <Card className='my-4 p-8 flex flex-col flex-wrap overflow-auto'>
                <Tabs value={1}>
                    <TabsHeader className='bg-green-100/50 border-2'>
                        <Tab value={1} className='text-xs  lg:text-base '><FontAwesomeIcon icon={faListCheck} /> รายงานเวลาทำงาน</Tab>
                        <Tab value={3} className='text-xs lg:text-base'><FontAwesomeIcon icon={faCashRegister} /> รายงาน OT พนักงาน</Tab>
                        <Tab value={2} className='text-xs lg:text-base'><FontAwesomeIcon icon={faHouseChimney} /> เพิ่ม/ลด OT</Tab>

                    </TabsHeader>
                    <TabsBody className='bg-green-50/50 border-1 border-green-800 rounded-xl mt-2'>
                        <TabPanel value={1}>
                            <div className='p-8'>
                                <div className='lg:flex lg:justify-end'>
                                    <div className="flex gap-2">
                                        <Select label='เลือกดูตามเดือน' onChange={(value) => handleMonthChange(value)}>
                                            {mount.map((item, index) => (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            ))}
                                        </Select>

                                        <Select label="เลือกปี" onChange={(value) => handleYearChange(value)}>
                                            {year.map((item, index) => (
                                                <Option key={index} value={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <hr className='mt-4' />
                                <div className='flex'>
                                    <table className="w-full min-w-max table-auto text-center">
                                        <thead>
                                            <tr className="border-b border-blue-gray-100 bg-green-100 p-4 text-xs lg:text-sm">
                                                <th className='py-3 w-[20%]'>ชื่อ admin</th>
                                                <th className='w-[40%]'>เวลาทำงาน</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workReportData && Object.entries(workReportData.workTimeData).map(([key, value], index) => (
                                                <tr key={index} className="even:bg-green-100/50 text-[10px] lg:text-sm">
                                                    <td>{key}</td>
                                                    <td>
                                                        <div className='px-4 py-5 flex flex-col gap-2'>
                                                            <div className='flex justify-between'>
                                                                <span>ทำไปแล้ว : {value.totalWorkTimeHours}</span>
                                                                <span>{value.monthWorkTimeHours}</span>
                                                            </div>
                                                            <Progress value={value.totalWorkTime / value.monthWorkTime * 100} />
                                                            <div className='flex justify-between'>
                                                                <span>เวลาคงเหลือ : {value.remainWorkTimeHours}</span>
                                                                <span>เกินเวลา :{value.overWorkTimeHour}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={2}>
                            <div >
                                <div className='grid grid-cols-2 mb-2'>
                                    <div className='flex gap-2'>
                                        <Button size='sm' onClick={handleOpenAddOT}> (<FontAwesomeIcon icon={faPlus} />) เพิ่ม OT</Button>
                                        <Button size='sm' color='amber' onClick={handleOpenDisOT}> (<FontAwesomeIcon icon={faMinus} />) ลด OT</Button>
                                    </div>
                                    <div className='flex justify-end gap-2'>
                                        <CalendarComponent1mont onDateChange={handleDateChange} />
                                        <div>
                                            <select onChange={handleUserSelection} className='border-2 rounded-md p-1 w-48' >
                                                <option value="">เลือก Admin</option>
                                                {userList.map((user) => (
                                                    <option key={user.username} value={user.username}>{user.nickname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <OvertimeLoglist startDate={startDate} endDate={endDate} username={selectedUser} page={1} per_page={100} />˝
                            </div>
                        </TabPanel>
                        <TabPanel value={3}>
                            <GetOT />
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </Card>

            <Dialog open={openAddOT} handler={handleOpenAddOT}>
                <AddOT />
            </Dialog>
            <Dialog open={openDisOT} handler={handleOpenDisOT}>
                <DisOT />
            </Dialog>
        </>
    )
}

export default WorkandOTReport