import React from 'react';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component';

interface OrderDetail {
  detailName: string;
  quantity: number;
  price: number;
  depositPrice: number;
}

interface OrderDetailsSummaryProps {
  orderDetails: OrderDetail[];
}



const OrderDetailsSummary: React.FC<OrderDetailsSummaryProps> = ({ orderDetails }) => {
  const columns: TableColumn<OrderDetail & { product: string }>[] = [
    {
      name: 'ลำดับ',
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: 'ชื่อสินค้า',
      selector: (row) => row.detailName,
      sortable: true,
    },
    {
      name: 'จำนวน',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: 'ราคา',
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: 'ค่ามัดจำ',
      selector: (row) => row.depositPrice,
      sortable: true,
    },
  ];

  const data = orderDetails.map((detail, index) => ({
    product: `${index + 1}`,
    detailName: detail.detailName,
    quantity: detail.quantity,
    price: detail.price,
    depositPrice: detail.depositPrice,
  }));

  return (
    <div className="text-sm">
      <h3 className="font-semibold">รายละเอียดสินค้า:</h3>
      <DataTable
        columns={columns}
        data={data}
        noHeader
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        className="w-full "
      />
    </div>
  );
};

export default OrderDetailsSummary;
