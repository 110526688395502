import axios from 'axios';
import { useStore } from "../hooks/store";
import { GolbalConfig } from './Config';


export const GenPDF = async (id:number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/bill/pdf?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GenPDF2 = async (id:number) => {
  try {
    const response = await axios.get(`${GolbalConfig.URL}/bill/pdfreceipt?id=${id}`, {
      headers: {
        Authorization: `Bearer ${useStore.getState().accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};