import React from 'react'
import BookingForm from '../components/Studio/Booking'
import { Card } from '@material-tailwind/react'

const StudioBooking = () => {
    return (
        <Card className='mt-4 p-4'>
            <div>
                <BookingForm />
            </div>

        </Card>
    )
}

export default StudioBooking