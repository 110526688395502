import { useState } from "react";
import { Card, Button, Input, Tabs, TabsHeader, TabsBody, Tab, TabPanel, } from "@material-tailwind/react";
import Swal from "sweetalert2";
import { UploadStep2 } from "../../hooks/Uppload";
import { UpdateStep23 } from "../../hooks/ChangeStatus";
import { OrderTableRow } from "../../types/AllOrderTypes";
import { useForm, SubmitHandler } from "react-hook-form";
import { UploadTypes23, UploadTypes } from "../../types/UploadSlipTypes";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


interface Step23Types {
  closeChagestepDialog: () => void;
  row: OrderTableRow;
}
const Step23submitCard: React.FC<Step23Types> = ({ row, closeChagestepDialog, }) => {
  const [images, setImages] = useState<ImageListType>([]);
  // For the image upload form
  const {
    register: registerImage,
    setValue: setImageValue,
    handleSubmit: handleImageSubmit,
    formState: { errors: imageErrors }
  } = useForm<UploadTypes>({
    defaultValues: {
      file: undefined,
      orderId: row.orderId,
      imageStatus: 2,
    },
  });

  // For the tracking update form
  const {
    register: registerTracking,
    handleSubmit: handleTrackingSubmit,
    formState: { errors: trackingErrors }
  } = useForm<UploadTypes23>({
    defaultValues: {
      orderId: row.orderId,
      tackingNumber: ""
    },
  });

  const onChange = (imageList: ImageListType) => {
    const fileList: File[] = [];
    imageList.forEach((img) => {
      if (img.file) {
        fileList.push(img.file);
      }
    });
    setImageValue("file", fileList as any);  // <-- use the alias here
    setImages(imageList);
  };


  const onSubmit = async (data: UploadTypes) => {
    if (!data.file || data.file.length === 0) {
      Swal.fire(
        "Error",
        "Please select at least one image before submitting.",
        "error"
      );
      return;
    }

    try {
      const result = await Swal.fire({
        title: "อัฟโหลดและเปลี่ยนสถานะ?",
        text: "ต้องการอัฟสลิปและเปลี่ยนสถานะใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
      });

      if (result.isConfirmed) {
        const formData = new FormData();
        for (let i = 0; i < data.file.length; i++) {
          formData.append("file", data.file[i]);
        }
        formData.append("orderId", data.orderId ? data.orderId.toString() : "");
        formData.append("imageStatus", data.imageStatus.toString());

        const response = await UploadStep2(formData);

        if (response[0].status === true) {
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "บันทึกการเปลี่ยนแปลงสำเร็จ",
          }).then(() => {
            closeChagestepDialog();
          });
        }
      } else {
        Swal.fire("ยกเลิก", "ยกเลิกการกระทำแล้ว", "error");
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "อัฟโหลดไม่สำเร็จ",
      });
    }
  };


  const putTracking = async (data: UploadTypes23) => {
    try {
      const response23 = await UpdateStep23(row.orderId, data.tackingNumber);
      console.log("response23", response23);
      if (response23.status === true) {
        Swal.fire({
          icon: 'success',
          title: 'สำเร็จ',
          text: 'เพิ่มเลขพัสดุและเปลี่ยนสถานะสำเร็จ',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.log('Error 2-3 Tracking', error);
    }
  }

  const UpdateSubmit: SubmitHandler<UploadTypes23> = async (data) => {
    Swal.fire({
      icon: 'question',
      title: 'เปลี่ยนสถานะ',
      text: 'คุณได้ตรวจสอบและต้องการเปลี่ยนสถานะใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        putTracking(data);
      }
    });
  };

  return (
    <>
      <Card className="p-8 flex gap-3">
        <Tabs value="images">
          <TabsHeader>
            <Tab value={"images"} className="text-xs lg:text-sm">
              รูปภาพสินค้า
            </Tab>
            <Tab value={"tracking"} className="text-xs lg:text-sm">
              อัพเดทเลขพัสดุ
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel value={"images"} className="text-xs lg:text-sm  flex flex-col items-center justify-center  ">
              <h2 className="font-bold">อัฟโหลดรูปภาพสินค้า </h2>
              <div>
                <form onSubmit={handleImageSubmit(onSubmit)}>
                  <Card className="lg:p-8 lg:flex gap-2 flex flex-col flex-wrap p-4 lg:w-[800px] lg:mt-8 ">
                    <h2 className="font-bold">
                      เปลี่ยนสถานะจากคำสั่งซื้อใหม่เป็นชำระเงินแล้ว
                    </h2>
                    <p className="text-xs">
                      *** ก่อนเปลี่ยนสถานะกรุณาตรวจสอบข้อมูลลูกค้าให้เรียบร้อย
                      แล้วอัฟโหลดสลิปและกดเปลี่ยนสถานะ
                    </p>
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={10}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="flex flex-col  justify-center border p-8 lg:flex lg:flex-col items-center">
                          <div
                            style={isDragging ? { color: "red" } : undefined}
                            className="my-2 "
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} /> เลือกรูปภาพ
                          </div>
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              className="flex flex-col justify-center gap-1 my-2 lg:flex-col lg:items-center"
                            >
                              <img src={image.dataURL} alt="" className="lg:w-[30%]" />
                              <Button
                                className="lg:w-24 mt-2 lg:mt-0"
                                size="sm"
                                color="red"
                                onClick={() => onImageRemove(index)}
                              >
                                ลบรูปภาพ
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>

                    {/* Hidden input for react-hook-form */}
                    <input {...registerImage("file")} type="file" style={{ display: "none" }} />
                    <input {...registerImage("orderId")} type="hidden" value={row.orderId.toString()} />
                    <input {...registerImage("imageStatus")} type="hidden" value="1" />
                    <div className="flex flex-col ">
                      <Button size="sm" type="submit" color="green">
                        บันทึก
                      </Button>
                      ฺ
                      <Button size="sm" onClick={closeChagestepDialog} className="">
                        {" "}
                        ปิดหน้าต่างนี้
                      </Button>
                    </div>
                  </Card>
                </form>

              </div>
            </TabPanel>
            <TabPanel value={"tracking"}>
              <h2 className="font-bold text-xs lg:text-sm">เปลี่ยนสถานะจากกำลังเตรียมสินค้าเป็นส่งสินค้าแล้ว </h2>
              <div>
                <form onSubmit={handleTrackingSubmit(UpdateSubmit)}>
                  <div className="my-2">
                    <Input crossOrigin="anonymous" type="text" label="Tracking Number"
                      {...registerTracking("tackingNumber", { required: true })}
                    />
                    {trackingErrors.tackingNumber && (
                      <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                        !!! กรุณากรอกเลขพัสดุ
                      </div>
                    )}
                  </div>

                  <Button type="submit"> เปลี่ยนสถานะ</Button>
                </form>

              </div>
            </TabPanel>

          </TabsBody>
        </Tabs>
        <div className="lg:flex lg:gap-2 lg:flex-wrap flex  justify-center flex-nowrap gap-2 overflow-scroll">
          {row.order?.sendProductImage?.images.map((image, index) => (
            <img key={index} src={image.filePath} alt={image.fileName} className="w-60 rounded-lg shadow-lg" />

          ))}
        </div>
      </Card>
    </>
  );
};

export default Step23submitCard;
