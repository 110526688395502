import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useStore } from "../../hooks/store";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import th from 'date-fns/locale/th';
import { CheckInDayoffType } from "../../types/TimeCardTypes";
import { Select, Option, Textarea, Button } from "@material-tailwind/react";
import { saveDayOff, savLeaveDay } from "../../hooks/TimeCardCheckin";
import Swal from "sweetalert2";

registerLocale('th', th);

const DayoffandleavCheckin = () => {
    const { username } = useStore();
    const [startDate, setStartDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState("");
    const { register, handleSubmit, formState: { errors }, control } = useForm<CheckInDayoffType>({
        defaultValues: {
            username: username,
            checkInDate: startDate,
            remarkCheckIn: '',
            workStatus: '',
        }
    });

    const DayoffSubmit: SubmitHandler<CheckInDayoffType> = async (data) => {
        // Format the date to YYYY-MM-DD
        const formattedDate = startDate.toISOString().split('T')[0];

        // Update the data with the formatted date
        const updatedData = {
            ...data,
            checkInDate: formattedDate
        };

        const resDayoffsubmit = await saveDayOff(updatedData);
        if (resDayoffsubmit.status === true) {
            Swal.fire({
                title: "สำเร็จ",
                text: "บันทึกวันหยุด/วันลาสำเร็จ",
                icon: "success",
                didClose: () => window.location.reload()
            });
        }
    };
    const leaveSubmit: SubmitHandler<CheckInDayoffType> = async (data) => {
        // Format the date to YYYY-MM-DD
        const formattedDate = startDate.toISOString().split('T')[0];

        // Update the data with the formatted date
        const updatedData = {
            ...data,
            checkInDate: formattedDate
        };

        const resLeaveoffsubmit = await savLeaveDay(updatedData);
        if (resLeaveoffsubmit.status === true) {
            Swal.fire({
                title: "สำเร็จ",
                text: "บันทึกวันหยุด/วันลาสำเร็จ",
                icon: "success",  
                didClose: () => window.location.reload()
            });
        }
    };

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
    };

    return (
        <>
            <form onSubmit={handleSubmit(selectedOption === "3" ? DayoffSubmit : leaveSubmit)}>
                <div className="lg:flex lg:flex-row lg:items-end">
                    <div className="lg:flex lg:flex-row lg:gap-2 flex flex-col gap-2">
                        <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} dateFormat="dd/MM/yyyy" locale="th" className="border-2 p-2 rounded-lg text-blue-500 w-full" />
                        <Controller
                            name="workStatus"
                            control={control}
                            rules={{ required: "กรุณาเลือกประเภทวันลา/วันหยุด" }}
                            render={({ field }) => (
                                <Select
                                    label="เลือกประเภท"
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        if (typeof value === 'string') {
                                            handleSelectChange(value);
                                        }
                                    }}
                                >
                                    <Option value="3">หยุดตามสิทธิ์</Option>
                                    <Option value="4">ลางานไม่รับรายวัน</Option>
                                </Select>
                            )}
                        />
                        {errors.workStatus && <p>{errors.workStatus.message}</p>}
                    </div>
                </div>
                <div className="my-2">
                    <Textarea label="หมายเหตุการขอหยุด"
                        {...register('remarkCheckIn')}
                    />
                </div>
                <div>
                    <Button id="1" type="submit" className="rounded-full bg-amber-500 text-black">บันทึก</Button>
                </div>
            </form>
        </>
    )
}

export default DayoffandleavCheckin;
