import { useState, useEffect, useMemo } from 'react'
import { Card, Select, Option, Button, Dialog, Badge, Typography } from '@material-tailwind/react';
import { DeleteTimeCard, GetTimeCardDetail } from '../../hooks/TimeCardCheckin';
import CalendarComponent1mont from '../../components/CalendarComponent1mont';
import { fetchAdminList } from '../../hooks/UserAdmin';
import { User } from '../../types/AdminListTypes';
import { TimeCardData } from '../../types/TimeCardTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faReceipt, faTrash, } from '@fortawesome/free-solid-svg-icons';
import ApproveCard from './ApproveCheckinCard';
import { IconButton } from '@material-tailwind/react';
import Swal from 'sweetalert2';
import ApproveCheckoutCard from './ApproveCheckoutCard';
import ApproveDayoffandLeaveDayCard from './ApproveDayoffandLeaveDayCard';
import { TimeCardDetailCard } from './TimeCardDetailCard';
import { Tooltip } from '@material-tailwind/react';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";


type DialogSize = 'sm' | 'md' | 'lg' | 'xl';


const AdminCheckTimeCard = () => {
    const [timeCardList, setTimeCardList] = useState<TimeCardData[]>([]);
    const [startDate, setStartDate] = useState<Date | string>("");
    const [endDate, setEndDate] = useState<Date | string>("");
    const [userList, setUserlist] = useState<User[]>([]);
    const [resetKey] = useState<number>(0);
    const [username, setSelectUsername] = useState("");
    const [workstatus, setWorkStatus] = useState("");
    const [statusPadding, setStatusPadding] = useState("");
    const [sort, setSort] = useState("");
    const [selectedTimeCard, setSelectedTimeCard] = useState<TimeCardData | null>(null);
    const [selectedID, setSelectedID] = useState<number>();
    const [dialogSize, setDialogSize] = useState<DialogSize>('sm'); // New state for dialog size
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(1);
    const [Rows, setRows] = useState<number | undefined>();

    const next = () => {
        if (active === TotalPage) return;

        setActive(active + 1);
    };

    const prev = () => {
        if (active === 1) return;

        setActive(active - 1);
    };

    const page = active;

    const TotalPage = useMemo(() => {
        const totalRows = Rows || 0;
        return Math.ceil(totalRows / perPage);
    }, [Rows, perPage]);

    const updateDialogSize = () => {
        setDialogSize(window.innerWidth < 640 ? 'xl' : 'sm'); // Ensuring the value is of type DialogSize
    };

    useEffect(() => {
        updateDialogSize(); // Set initial size
        window.addEventListener('resize', updateDialogSize); // Add resize listener
        return () => window.removeEventListener('resize', updateDialogSize);
    }, []);



    const GetTimeCardList = async () => {
        const timeCardDATA = await GetTimeCardDetail(startDate, endDate, username, workstatus, statusPadding, sort, perPage, page);
        setTimeCardList(timeCardDATA.data);
        setRows(timeCardDATA.total);
    }

    useEffect(() => {
        GetTimeCardList();
    }, [startDate, endDate, username, workstatus, statusPadding, sort, perPage, page]);


    const handleReset = () => {
        setSelectUsername("");
        setWorkStatus("");
        setStatusPadding('');
        setSort("");

    };

    const handleDateChange = (start: Date | string, end: Date | string) => {
        setStartDate(start);
        setEndDate(end);
    };

    const fetchUserList = async () => {
        const userListData = await fetchAdminList();
        setUserlist(userListData.user)
    };


    useEffect(() => {
        fetchUserList();
    }, []);



    const workingStatus = [
        { value: "1", label: "ทำงานปกติ" },
        { value: "2", label: "OT" },
        { value: "3", label: "หยุดตามสิทธิ์" },
        { value: "4", label: "ลางานไม่รับรายวัน" },
    ];
    const statusPad = [
        { value: "0", label: "รอตรวจสอบ" },
        { value: "1", label: "กำลังทำงาน" },
        { value: "2", label: "เรียบร้อย" },
    ];

    const Sort = [
        { value: "DESC", label: "จากใหม่ไปเก่า" },
        { value: "ASC", label: "จากเก่าไปใหม่" },
    ];

    const renderWorkStatus = (workStatus: any) => {
        switch (workStatus) {
            case '1':
                return <span className="text-green-500">ทำงานปกติ</span>;
            case '2':
                return <span className="text-blue-500">OT</span>;
            case '3':
                return <span className="text-purple-500">หยุดตามสิทธิ์</span>;
            case '4':
                return <span className="text-red-500">ลางานไม่รับรายวัน</span>;
            default:
                return <span>สถานะไม่ทราบ</span>;
        }
    }
    const renderStatus = (statusPadding: any, currentData: TimeCardData) => {
        switch (statusPadding) {
            case '0':
                return <Badge color="red" className=' -mx-1.5'>
                    <span className="text-red-500  hover:text-red-300 cursor-pointer " onClick={() => {
                        setSelectedTimeCard(currentData);
                        if (currentData.checkOutTime != null) {
                            handleOpenCheckOutApprove();
                        } else if (currentData.workStatus == "3" || currentData.workStatus == "4") {
                            handleOpenDayoffApprove();
                        } else {
                            handleOpenCheckInApprove();
                        }
                    }}>รออนุมัติ</span>
                </Badge>
            case '1':
                return <span className="text-blue-500">กำลังทำงาน</span>;
            case '2':
                return <span className="text-green-500">เรียบร้อย</span>;
            default:
                return <span>สถานะไม่ทราบ</span>;
        }
    }

    const [openCheckInApprove, setCheckInApprove] = useState(false);
    const handleOpenCheckInApprove = () => setCheckInApprove(!openCheckInApprove);

    const [openCheckOutApprove, setCheckOutApprove] = useState(false);
    const handleOpenCheckOutApprove = () => setCheckOutApprove(!openCheckOutApprove);

    const [openDayoffApprove, setopenDayoffApprove] = useState(false);
    const handleOpenDayoffApprove = () => setopenDayoffApprove(!openDayoffApprove);

    const [openDetailCard, setOpenDetailCard] = useState(false);
    const handleOpenDetailCard = () => setOpenDetailCard(!openDetailCard);

    const handleDelete = async (timeCardId: number) => {
        Swal.fire({
            title: 'ต้องการลบใช่หรือไม่',
            text: 'สถานะเรียบร้อยคุณต้องการลบใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resDelete = await DeleteTimeCard(timeCardId);
                console.log("TimeCard Delete", resDelete);
                if (resDelete.message == "Success") {
                    Swal.fire(
                        'ลบแล้ว!',
                        'ลบการกระทำสำเร็จ.',
                        'success'
                    );
                }

                GetTimeCardList();


            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your time card is safe :)',
                    'error'
                );
            }
        });
    };

    const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newPerPage = Number(event.target.value);
        setPerPage(newPerPage);
    };


    return (
        <>
            <Card className='my-4 p-8 flex '>
                <div className='flex flex-col  lg:flex lg:justify-end gap-2'>
                    <div className='flex flex-col lg:flex lg:flex-row lg:justify-center gap-2'>
                        <CalendarComponent1mont onDateChange={handleDateChange} />
                        <Select label='เลือกดูตามชื่อ Admin'
                            key={resetKey}
                            value={username || ""}
                            onChange={(value) => {
                                if (value !== undefined) {
                                    setSelectUsername(value);
                                } else {
                                    setSelectUsername("");
                                }
                            }}
                        >
                            {userList.map((data) => (
                                <Option value={data.username}>{data.nickname}</Option>
                            ))}
                        </Select>

                        <Select label='สถานะการทำงาน'
                            key={resetKey}
                            value={workstatus || ""}
                            onChange={(value) => {
                                if (value !== undefined) {
                                    setWorkStatus(value);
                                } else {
                                    setWorkStatus("");
                                }
                            }}
                        >
                            {workingStatus.map((data) => (
                                <Option value={data.value}>{data.label}</Option>
                            ))}
                        </Select>

                        <Select label='สถานะ'
                            key={resetKey}
                            value={statusPadding || ""}
                            onChange={(value) => {
                                if (value !== undefined) {
                                    setStatusPadding(value);
                                } else {
                                    setStatusPadding("");
                                }
                            }}
                        >
                            {statusPad.map((data, index) => (
                                <Option key={index} value={data.value}>{data.label}</Option>
                            ))}
                        </Select>

                        <Select label='เรียงลำดับ'
                            key={resetKey}
                            value={sort || ""}
                            onChange={(value) => {
                                if (value !== undefined) {
                                    setSort(value);
                                } else {
                                    setSort("");
                                }
                            }}
                        >
                            {Sort.map((data, index) => (
                                <Option key={index} value={data.value}>{data.label}</Option>
                            ))}
                        </Select>
                        <div>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>

                    </div>
                </div>
                <hr className='my-4' />
                <div>
                    <h1>คิวพนักงานรออนุมัติเวลาทำงาน และวันลาหยุด</h1>
                    <div className='flex flex-wrap overflow-auto'>
                        <table className="w-full min-w-max table-auto text-center">
                            <thead>
                                <tr className="border-b border-blue-gray-100 bg-green-50 p-4 text-xs lg:text-sm">
                                    <th className='p-4'>#</th>
                                    <th>Username</th>
                                    <th>วันและเวลาเข้างาน</th>
                                    <th>วันและเวลาออกงาน</th>
                                    <th>สถานะการลงเวลา</th>
                                    <th>สถานะ</th>
                                    <th className='px-2'><FontAwesomeIcon icon={faEllipsisVertical} /></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {timeCardList?.map((item, index) => (
                                    <tr key={index} className="even:bg-green-50/50 text-xs lg:text-sm" >
                                        <td className='p-4'>{item.Rows}.</td>
                                        <td>{item.username}</td>
                                        <td>
                                            <div className='flex flex-col'>
                                                <span className='text-green-500'>{item.checkInDate}</span>
                                                <span className='text-blue-500'>{item.checkInTime}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex flex-col'>
                                                <span className='text-green-500'>{item.checkOutDate === '1 มกราคม 2513' ? <span> - </span> : item.checkOutDate}</span>
                                                <span className='text-blue-500'>{item.checkOutTime === null ? <span> - </span> : item.checkOutTime}</span>
                                            </div>
                                        </td>
                                        <td>{renderWorkStatus(item.workStatus)}</td>
                                        <td>{renderStatus(item.statusPadding, item)}</td>
                                        <td>
                                            <div className='flex gap-2 justify-center'>
                                                <Tooltip content="ลบ">
                                                    <IconButton variant="outlined" size='sm' className=" rounded-full  border-red-500" onClick={() => handleDelete(item.timeCardId)}>
                                                        <FontAwesomeIcon icon={faTrash} className='text-red-500' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip content="รายละเอียด">
                                                    <IconButton variant="outlined" size='sm' className="rounded-full border-green-500" onClick={() => { setSelectedID(item.timeCardId); handleOpenDetailCard(); }}>
                                                        <FontAwesomeIcon icon={faReceipt} className='text-green-500' />
                                                    </IconButton>
                                                </Tooltip>

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex flex-col items-center lg:flex lg:justify-between'>
                        <div className="flex gap-2 items-baseline my-3 ml-3">
                            <label htmlFor="select" className="text-xs">
                                จำนวนแถว
                            </label>
                            <select
                                className="border-2 rounded-md p-0.5 text-xs"
                                value={perPage}
                                onChange={handlePerPageChange}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className="flex items-center gap-8">
                            <IconButton
                                size="sm"
                                variant="outlined"
                                onClick={prev}
                                disabled={active === 1}
                            >
                                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                            </IconButton>
                            <Typography color="gray" className="font-normal text-sm">
                                หน้า <strong className="text-gray-900">{active}</strong> จาก{" "}
                                <strong className="text-gray-900">{TotalPage}</strong>
                            </Typography>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                onClick={next}
                                disabled={active === Rows}
                            >
                                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Card>

            <Dialog open={openCheckInApprove} handler={handleOpenCheckInApprove} size={dialogSize}>
                {selectedTimeCard && <ApproveCard
                    usernames={selectedTimeCard.username}
                    checkInTime={selectedTimeCard.checkInTime}
                    timeCardId={selectedTimeCard.timeCardId}
                    remarkCheckIn={selectedTimeCard.remarkCheckIn}
                    workStatus={selectedTimeCard.workStatus}
                    checkInDate={selectedTimeCard.checkInDate}
                    refreshList={GetTimeCardList}
                    checkOutDate={selectedTimeCard.checkOutDate}
                    checkOutTime={selectedTimeCard.checkOutTime}
                />}
            </Dialog>
            <Dialog open={openCheckOutApprove} handler={handleOpenCheckOutApprove} size={dialogSize} >
                {selectedTimeCard && <ApproveCheckoutCard
                    usernames={selectedTimeCard.username}
                    checkInTime={selectedTimeCard.checkInTime}
                    timeCardId={selectedTimeCard.timeCardId}
                    remarkCheckIn={selectedTimeCard.remarkCheckIn}
                    workStatus={selectedTimeCard.workStatus}
                    checkInDate={selectedTimeCard.checkInDate}
                    refreshList={GetTimeCardList}
                    checkOutDate={selectedTimeCard.checkOutDate}
                    checkOutTime={selectedTimeCard.checkOutTime}
                />}
            </Dialog>

            <Dialog open={openDayoffApprove} handler={handleOpenDayoffApprove} size={dialogSize}>
                {selectedTimeCard && <ApproveDayoffandLeaveDayCard
                    usernames={selectedTimeCard.username}
                    checkInTime={selectedTimeCard.checkInTime}
                    timeCardId={selectedTimeCard.timeCardId}
                    remarkCheckIn={selectedTimeCard.remarkCheckIn}
                    workStatus={selectedTimeCard.workStatus}
                    checkInDate={selectedTimeCard.checkInDate}
                    refreshList={GetTimeCardList}
                    checkOutDate={selectedTimeCard.checkOutDate}
                    checkOutTime={selectedTimeCard.checkOutTime}
                />}
            </Dialog>
            <Dialog open={openDetailCard} handler={handleOpenDetailCard} size={dialogSize}>
                <TimeCardDetailCard id={selectedID} />
            </Dialog>
        </>
    )
}

export default AdminCheckTimeCard
