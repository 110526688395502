import { useEffect, useState } from "react";
import { findSendingSmsbyID, fetchSMSproviderList,UpdateSendingSMS } from "../../hooks/Sms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useStore } from "../../hooks/store";
import { Button} from "@material-tailwind/react";
import { ProviderListType } from "../../types/SMSProviderlistTypes";
import { SendingDataTypes } from "../../types/SMSSending/SMSSubmitDataTypes";
import Swal from "sweetalert2";

interface EditSendingSMSCardType {
  ID: number;
  closeDialog: () => void;
}

const EditSendingSMSCard: React.FC<EditSendingSMSCardType> = ({ ID ,closeDialog}) => {
  const { username } = useStore();
  const [providerList, SetproviderList] = useState<ProviderListType[]>([]);

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      smsProviders: null,
      updateBy: username,
    },
  });

  useEffect(() => {
    const SendingSMSData = async () => {
      try {
        const SendingData = await findSendingSmsbyID(ID);
        setValue("smsProviders", SendingData.smsSending.smsProviders);
      } catch (error) {
        console.log(error);
      }
    };

    const SelectSMSlist = async () => {
      try {
        const ListData = await fetchSMSproviderList();
        SetproviderList(ListData);
      } catch (error) {
        console.log("SelectSMSlist Error", error);
      }
    };
    SendingSMSData();
    SelectSMSlist();
  }, [ID, setValue]);


  const _onSubmit:SubmitHandler<SendingDataTypes> = async (data) => {
    data.smsProviders = Number(data.smsProviders);
    Swal.fire({
        icon: 'question',
        title: 'เปลี่ยนแปลงข้อมูล',
        text: 'ต้องการเปลี่ยน Sending SMS Provider ใช่หรือไม่',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
        allowOutsideClick: false,    // Don't close on outside click
        allowEscapeKey: false,      // Don't close on ESC key
        allowEnterKey: false        // Don't close on Enter key
      }).then(async (confirmResult) => {
        if (confirmResult.isConfirmed) {
          try {
            if (ID !== undefined) {
              const response = await UpdateSendingSMS(ID, data);
              if (response && response.status === 200) {
                Swal.fire('Success!', 'เปลี่ยนแปลงข้อมูลสำเร็จ', 'success');
                closeDialog();
                window.location.reload();
  
              }
            }
          } catch (error) {
            console.error('Error updating user:', error);
            Swal.fire('Error!', 'An error occurred while updating the user.', 'error');
          }
        }
      });
  };
  return (
    <div className="p-8">
      <p>เปลี่ยนแปลง SMS Provider สำหรับส่งข้อความหาลูกค้า</p>
      <form onSubmit={handleSubmit(_onSubmit) } className="flex flex-col gap-2">
        <Controller
          name="smsProviders"
          control={control}
          render={({ field }) => (
            <select
              placeholder="SMS Providers"
              value={field.value ? field.value : ""}
              onChange={(e) => field.onChange(e.target.value)}
              onBlur={field.onBlur}
              className="px-3 py-2 border rounded-lg"
            >
              {providerList.map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {provider.id === 1 ? (
                    <span>Thaibulksms</span>
                  ) : (
                    <span>thsms</span>
                  )}
                </option>
              ))}
            </select>
          )}
        />
        <Button type="submit" >บันทึกการเปลี่ยนแปลง</Button>
      </form>
    </div>
  );
};

export default EditSendingSMSCard;
