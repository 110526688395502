import { useState, useEffect } from "react";
import { OderList, EncryptID } from "../hooks/order";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import { generatePath } from "react-router-dom";
import {
  Button,
  Card,
  IconButton,
  Dialog,
  Tabs,
  TabPanel,
  TabsBody,
  Tab,
  TabsHeader,
  DialogHeader,
  Select,
  Option,
  Input,
  Alert,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faTShirt,
  faUserCircle,
  faPen,
  faUserPen,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import OrderDetailCard from "../components/Oder/OrderDetailCard";
import copy from "clipboard-copy";
import { GetShopIdList } from "../hooks/GetShopId";
import { ShopIdTypes } from "../types/ShopIDTypes";
import Step01addimagesCard from "../components/AddImage/Step01adimagesCard";
import Step23addimageCard from "../components/AddImage/Step23submitCard";
import Step45addimagesCard from "../components/AddImage/Step45submitCard";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CalendarComponent from "../components/CalendarComponent";

const AllOrder = () => {
  const [Oderlist, setupOderList] = useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [OrderStatusValue, OrderStatussetSelectedValue] = useState("");
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const navigate = useNavigate();
  const [openDialogIndex, setOpenDialogIndex] = useState<null | number>(null);
  const baseUrl = window.location.origin; // Your base URL

  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");

  const handleDateChange = (start: Date | string, end: Date | string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleOpen = (index: number) => {
    if (openDialogIndex === index) {
      setOpenDialogIndex(null); // close the dialog if it's already open
    } else {
      setOpenDialogIndex(index); // open the dialog for the clicked product
    }
  };

  const handleImageClick = (imgSrc: string) => {
    setCurrentImage(imgSrc);
    setIsDialogOpen(true);
  };

  const fetchData = async (page: number) => {
    const dataPushbullet: OrderResponse = await OderList(page, perPage, OrderStatusValue, search, numSelectedShop, startDate, endDate);
    if (dataPushbullet.error === "TOKEN_EXPIRED") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Token หมดอายุกรูณาเข้าสู่ระบบอีกครั้ง",
      }).then(() => {
        navigate("/");
      });
    }
    const formattedData = dataPushbullet.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setupOderList(formattedData);
    setCurrentPage(dataPushbullet.page);
    setTotalItems(dataPushbullet.total);
    setPerPage(dataPushbullet.per_page);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [
    currentPage,
    OrderStatusValue,
    perPage,
    search,
    selectedShop,
    startDate,
    endDate,
  ]);

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนแถวต่อ 1 หน้า",
    rangeSeparatorText: "ถึง",
  };

  ////// dropdown ////////

  const OrderStatus = [
    { value: "1", label: "ชำระเงินแล้ว" },
    { value: "2", label: "กำลังเตรียมสินค้า" },
    { value: "3", label: "ส่งสินค้าแล้ว" },
    { value: "4", label: "รอรับสินค้าคืน" },
    { value: "5", label: "ตรวจสอบ" },
    { value: "6", label: "รอโอนเงิน" },
    { value: "7", label: "เรียบร้อย" },
  ];

  ////////////////////////
  const [selectedID, setSelectedOderId] = useState<number | null>(null);
  const [IDSelect, setIDSelect] = useState<number | null>(null);
  //// explanablerow //////

  const handleRowExpand = (rowIndex: number) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null); // If the row is already expanded, collapse it.
    } else {
      setExpandedRow(rowIndex); // Otherwise, expand this row.
    }
  };
  /////////////////////////
  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    OrderStatussetSelectedValue("");
    // setResetKey((prevKey) => prevKey + 1);
  };

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1); // It's often a good practice to reset to the first page when perPage changes
  };
  const numberOfPages = Math.ceil(totalItems / perPage);

  const handleGenerateURL = async (orderId: number, refNumber: number) => {
    try {
      const encryptedId = await EncryptID(orderId);
      if (encryptedId && encryptedId.message === "Success") {
        const refSegment = `ref=${refNumber}`;
        const path = generatePath("/customer/:id/:refSegment", {
          id: encryptedId.encryptedId,
          refSegment: refSegment,
        });
        const fullUrl = baseUrl + path;
        Swal.fire({
          icon: "success",
          title: "success",
          text: `คัดลอกลิงค์สำเร็จ หมายเลขคำสั่งซื้อ #${refNumber} `,
        }).then(() => {
          copy(fullUrl);
        });
      } else {
        console.error(
          "Encryption was not successful or returned unexpected data."
        );
      }
    } catch (error) {
      console.error("Failed to encrypt ID:", error);
    }
  };

  ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const handleOpenEditOrder = () => setOpenEditOrder(!openEditOrder);

  const [open01AddImage, set01AddImage] = useState(false);
  const handle01AddImage = () => set01AddImage(!open01AddImage);

  const [open23AddImage, set23AddImage] = useState(false);
  const handle23AddImage = () => set23AddImage(!open23AddImage);

  const [open45AddImage, set45AddImage] = useState(false);
  const handle45AddImage = () => set45AddImage(!open45AddImage);

  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(!open);

  return (
    <>
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center shadow-lg ">
        <div className="lg:flex lg:flex-row lg:flex-wrap lg:justify-end lg:px-2 ">
          <div className="lg:flex lg:flex-row  flex flex-col  gap-2">
            <div>
              <CalendarComponent onDateChange={handleDateChange} />
            </div>

            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearch(e.target.value)
              }
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ""}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>
                  {shopdata.shopName}
                </Option>
              ))}
            </Select>

            <Select
              key={resetKey}
              label="เลือกดูตามสถานะ"
              value={OrderStatusValue || ""}
              onChange={(value) => {
                if (value !== undefined) {
                  OrderStatussetSelectedValue(value);
                } else {
                  OrderStatussetSelectedValue("");
                }
              }}
            >
              {OrderStatus.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>

            <div className="flex justify-end">
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col flex-wrap  overflow-auto">
          <table className="w-full min-w-max table-auto text-center overflow-scroll">
            <thead>
              <tr className="border-y border-blue-gray-100 bg-blue-gray-50/50 lg:text-sm text-xs  ">
                <td></td>
                <td className="p-4">ลำดับ</td>
                <td></td>
                <td className="px-4">ลิงค์ลูกค้า</td>
                <td className="px-4">ID</td>
                <td className="px-4">คำสั่งซื้อ</td>
                <td className="px-4">ชื่อ-นามสกุล</td>
                <td className="px-4">วันสร้าง</td>
                <td className="px-4">วันส่งสินค้า</td>
                <td className="px-4">เช่า-ถึง</td>
                <td className="px-4">สถานะ</td>
              </tr>
            </thead>
            <tbody>
              {Oderlist.map((detail, index) => {
                let statusLabel;

                switch (detail.order.statusPading) {
                  case 1:
                    statusLabel = (
                      <span className="text-[10px] bg-green-400 rounded-full py-0.5 px-2">
                        ชำระเงินแล้ว
                      </span>
                    );
                    break;
                  case 2:
                    statusLabel = (
                      <span className="text-[10px] bg-yellow-200 rounded-full py-0.5 px-2">
                        กำลังเตรียมสินค้า
                      </span>
                    );
                    break;
                  case 3:
                    statusLabel = (
                      <span className="text-[10px] bg-orange-200 rounded-full py-0.5 px-2">
                        ส่งสินค้าแล้ว
                      </span>
                    );
                    break;
                  case 4:
                    statusLabel = (
                      <span className="text-[10px] bg-red-200 rounded-full py-0.5 px-2">
                        รอรับสินค้าคืน
                      </span>
                    );
                    break;
                  case 5:
                    statusLabel = (
                      <span className="text-[10px] bg-green-200 rounded-full py-0.5 px-2">
                        ตรวจสอบ
                      </span>
                    );
                    break;
                  case 6:
                    statusLabel = (
                      <span className="text-[10px] bg-blue-300 rounded-full py-0.5 px-2">
                        รอโอนเงิน
                      </span>
                    );
                    break;
                  case 7:
                    statusLabel = (
                      <span className="text-[10px] bg-pink-400 rounded-full py-0.5 px-2">
                        เรียบร้อย
                      </span>
                    );
                    break;
                  default:
                    statusLabel = null;
                    break;
                }

                return (
                  <>
                    <tr
                      key={index}
                      className="even:bg-blue-gray-50/50 lg:text-xs text-[10px]  "
                    >
                      <td
                        className="px-6 cursor-pointer text-sm "
                        onClick={() => handleRowExpand(index)}
                      >
                        {expandedRow === index ? "-" : "+"}
                      </td>
                      <td className="p-4">{detail.Rows}.</td>
                      <td>
                        <IconButton color="green" className="rounded-full ">
                          <FontAwesomeIcon
                            icon={faPen}
                            className="lg:text-xl"
                            onClick={() => {
                              handleOpenEditOrder();
                              setSelectedOderId(detail.orderId);
                            }}
                          />
                        </IconButton>
                      </td>
                      <td>
                        {" "}
                        <Button
                          onClick={() =>
                            handleGenerateURL(detail.orderId, detail.refNumber)
                          }
                          size="sm"
                          className="rounded-full bg-blue-300 lg:text-xs text-[10px]"
                        >
                          คัดลอก
                        </Button>
                      </td>
                      <td>{detail.orderId}</td>
                      <td>{detail.refNumber}</td>
                      <td>
                        {detail.custommerFirstName} {detail.custommerLastName}
                      </td>
                      <td>{detail.cretedDate}</td>
                      <td>{detail.sendDate}</td>
                      <td>
                        {" "}
                        <div className="flex flex-col">
                          {" "}
                          <span className="text-green-500">
                            {detail.order.startDate}
                          </span>{" "}
                          <span className="text-red-500">
                            {detail.order.endDate}
                          </span>
                        </div>
                      </td>
                      <td>{statusLabel}</td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={11} className="">
                          <div className="border-2 rounded-lg p-4 my-2 bg-gray-300/50">
                            <span className="item-">หมายเลขคำสั่งซื้อ #</span>{" "}
                            {detail.order.refNumber}
                            <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-xs lg:text-sm ">
                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faUserCircle}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-2.5 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  ข้อมูลลูกค้า
                                </h2>
                                <span>
                                  ชื่อผู้เช่า:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.custommerFirstName}{" "}
                                    {detail.order.custommerLastName}
                                  </span>{" "}
                                </span>
                                <span>
                                  ที่อยู่:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.address}{" "}
                                    {detail.order.subDistrict}{" "}
                                    {detail.order.district}{" "}
                                    {detail.order.province}{" "}
                                    {detail.order.zipCode}
                                  </span>{" "}
                                </span>
                                <span>
                                  เบอร์โทร:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.telSend}
                                  </span>{" "}
                                </span>
                                <span>
                                  IG :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.IG}
                                  </span>{" "}
                                </span>
                                <span>
                                  Line:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.line}
                                  </span>{" "}
                                </span>
                                <span>
                                  Facebook:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.facebook}
                                  </span>{" "}
                                </span>
                              </Card>

                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faMapPin}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-4 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  รายละเอียดการเช่า (OrderID# {detail.orderId})
                                </h2>
                                <span>
                                  วันที่สร้าง :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.cretedDate}
                                  </span>{" "}
                                </span>
                                <span>
                                  วันเช่า/วันคืน :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.startDate} -{" "}
                                    {detail.order.endDate}
                                  </span>{" "}
                                </span>
                                <span>
                                  สาขา:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.shopName}
                                  </span>{" "}
                                </span>
                                <span>
                                  ขนส่ง:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.transportName}{" "}
                                  </span>{" "}
                                  เลขพัสดุ:{" "}
                                  <span className="text-pink-500">
                                    {detail.order.tackingNumber}{" "}
                                  </span>{" "}
                                </span>
                                <span>
                                  หมายเหตุ :{" "}
                                  <span className="text-pink-500">
                                    {detail.order.orderRemark}
                                  </span>{" "}
                                </span>
                              </Card>

                              <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                                <FontAwesomeIcon
                                  icon={faTShirt}
                                  className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-2 py-2.5"
                                />
                                <h2 className="text-pink-500 font-bold underline">
                                  รายละเอียดชุด
                                </h2>
                                <table className="w-full table-auto text-xs my-4">
                                  <thead className="bg-gray-300">
                                    <tr>
                                      <th>ลำดับ</th>
                                      <th>สินค้า</th>
                                      <th>ค่ามัดจำ</th>
                                      <th>ราคา</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {detail.order.orderDetails.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}.</td>
                                          <td>
                                            <span
                                              onClick={() => handleOpen(index)}
                                              className="hover:text-blue-500 hover:underline cursor-pointer"
                                            >
                                              {data.detailName} x{data.quantity}
                                            </span>
                                            <Dialog
                                              open={openDialogIndex === index}
                                              handler={() => handleOpen(index)}
                                            >
                                              <DialogHeader>
                                                {data.detailName}
                                              </DialogHeader>
                                              <div className="flex justify-center">
                                                <img
                                                  src={data.src}
                                                  alt={data.detailName}
                                                />
                                              </div>
                                            </Dialog>
                                          </td>
                                          <td>{data.depositPrice}</td>
                                          <td>{data.price}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot className="bg-gray-300">
                                    <tr>
                                      <th colSpan={2}>รวม</th>
                                      <th className="py-1">
                                        {`${detail.order.orderDetails.reduce(
                                          (accumulator, detail) =>
                                            accumulator + detail.depositPrice,
                                          0
                                        )}`}
                                      </th>
                                      <th>
                                        {`${detail.order.orderDetails.reduce(
                                          (accumulator, detail) =>
                                            accumulator + detail.price,
                                          0
                                        )}`}
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Card>
                              <div className="flex flex-col gap-2">
                                <Card className="p-8">
                                  <div className=" flex justify-start items-center gap-3">
                                    <FontAwesomeIcon
                                      icon={faUserPen}
                                      className="text-4xl text-blue-500"
                                    />
                                    <h2>ผู้สร้างออร์เดอร์จากหน้าบ้าน</h2>
                                    <span className="text-pink-500">
                                      {detail.order.cretedBy}
                                    </span>
                                  </div>
                                </Card>
                                <Card className="p-8">
                                  <div className="flex justify-start items-center gap-3">
                                    <FontAwesomeIcon
                                      icon={faRotate}
                                      className="text-4xl text-blue-500"
                                    />
                                    <h2>แอดมินผู้รับออร์เดอร์</h2>
                                    <span className="text-pink-500">
                                      {detail.order.byAdmin}
                                    </span>
                                  </div>
                                </Card>
                                <Card className="p-8">
                                  <div className="flex  justify-start items-center gap-3">
                                    <FontAwesomeIcon
                                      icon={faRotate}
                                      className="text-4xl text-blue-500"
                                    />
                                    <h2>แอดมินผู้อัพเดทออร์เดอร์</h2>
                                    <span className="text-pink-500">
                                      {detail.order.updateBy}
                                    </span>
                                  </div>
                                </Card>
                              </div>
                            </div>
                            <Card className="p-4 my-2">
                              <Tabs value="1">
                                <TabsHeader>
                                  <Tab value="1" className="lg:text-sm text-xs">
                                    สลิปลูกค้าโอนเงิน
                                  </Tab>
                                  <Tab value="2" className="lg:text-sm text-xs">
                                    รูปสินค้าก่อนส่ง
                                  </Tab>
                                  <Tab value="3" className="lg:text-sm text-xs">
                                    รูปสินค้าคืนจากลูกค้า
                                  </Tab>
                                  <Tab value="4" className="lg:text-sm text-xs">
                                    สลิปโอนมัดจำ
                                  </Tab>
                                </TabsHeader>
                                <TabsBody>
                                  <TabPanel value="1">
                                    <div className="flex gap-2 object-scale-down h-48 w-96">
                                      {detail.order.paySlip.images &&
                                        detail.order.paySlip.images.length > 0 ? (
                                        detail.order.paySlip.images.map(
                                          (data, index) => (
                                            <img
                                              key={index}
                                              src={data.filePath}
                                              alt={`Payslip Image ${index}`}
                                              className=""
                                              onClick={() =>
                                                handleImageClick(data.filePath)
                                              }
                                            />
                                          )
                                        )
                                      ) : (
                                        <span>ยังไม่มีภาพสลิป</span>
                                      )}
                                    </div>
                                    <div className="my-2">
                                      <Button
                                        onClick={() => {
                                          handle01AddImage();
                                          setIDSelect(detail.orderId);
                                        }}
                                      >
                                        เพิ่มรูปภาพ
                                      </Button>
                                    </div>
                                  </TabPanel>
                                  <TabPanel value="2">
                                    <div className="flex gap-2 object-scale-down h-48 w-96">
                                      {detail.order.sendProductImage.images &&
                                        detail.order.sendProductImage.images
                                          .length > 0 ? (
                                        detail.order.sendProductImage.images.map(
                                          (data, index) => (
                                            <img
                                              key={index}
                                              src={data.filePath}
                                              alt={`Product Image ${index}`}
                                              className="w-48"
                                              onClick={() => {
                                                if (data.filePath) {
                                                  handleImageClick(
                                                    data.filePath
                                                  );
                                                }
                                              }}
                                            />
                                          )
                                        )
                                      ) : (
                                        <span>ยังไม่มีภาพ</span>
                                      )}
                                    </div>
                                    <div className="my-2">
                                      <Button
                                        onClick={() => {
                                          handle23AddImage();
                                          setIDSelect(detail.orderId);
                                        }}
                                      >
                                        เพิ่มรูปภาพ
                                      </Button>
                                    </div>
                                  </TabPanel>
                                  <TabPanel value="3">
                                    <div className="flex gap-2 object-scale-down h-48 w-96">
                                      {detail.order.returnProductImage.images &&
                                        detail.order.returnProductImage.images
                                          .length > 0 ? (
                                        detail.order.returnProductImage.images.map(
                                          (data, index) => (
                                            <img
                                              key={index}
                                              src={data.filePath}
                                              alt={`Return Image ${index}`}
                                              className="w-48"
                                              onClick={() =>
                                                handleImageClick(data.filePath)
                                              }
                                            />
                                          )
                                        )
                                      ) : (
                                        <span>ยังไม่มีภาพ</span>
                                      )}
                                    </div>
                                    <div className="my-2">
                                      <Button
                                        onClick={() => {
                                          handle45AddImage();
                                          setIDSelect(detail.orderId);
                                        }}
                                      >
                                        เพิ่มรูปภาพ
                                      </Button>
                                    </div>
                                  </TabPanel>

                                  <TabPanel value="4">
                                    <div className="flex gap-2 object-scale-down h-48 w-96">
                                      {detail.order.refundSlip.images &&
                                        detail.order.refundSlip.images.length >
                                        0 ? (
                                        detail.order.refundSlip.images.map(
                                          (data, index) => (
                                            <img
                                              key={index}
                                              src={data.filePath}
                                              alt={`Refund Image ${index}`}
                                              className="w-48"
                                              onClick={() =>
                                                handleImageClick(data.filePath)
                                              }
                                            />
                                          )
                                        )
                                      ) : (
                                        <span>ยังไม่มีภาพสลิป</span>
                                      )}
                                    </div>
                                  </TabPanel>
                                </TabsBody>
                              </Tabs>
                            </Card>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end items-baseline">
            <div className="flex gap-2 items-baseline my-3 ml-3">
              <label htmlFor="select" className="text-xs">
                จำนวนแถว
              </label>
              <select
                className="border-2 rounded-md p-0.5 text-xs"
                value={perPage}
                onChange={handlePerPageChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="mt-4 flex justify-center items-center flex-wrap">
              <button
                className="mx-2 px-3 py-1 border rounded-md text-xs"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>

              {Array.from({ length: numberOfPages }, (_, idx) => idx).map(
                (idx) => (
                  <button
                    key={idx}
                    className={`mx-1 px-2 py-1 border rounded-md text-xs ${currentPage === idx + 1 ? "bg-blue-500 text-white" : ""
                      }`}
                    onClick={() => handlePageChange(idx + 1)}
                  >
                    {idx + 1}
                  </button>
                )
              )}

              <button
                className="mx-2 px-3 py-1 border rounded-md text-xs"
                disabled={currentPage === numberOfPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openEditOrder}
        handler={handleOpenEditOrder}
        size="xxl"
        className="flex flex-col overflow-scroll items-center"
      >
        <OrderDetailCard selectedOrderID={selectedID} closeDialog={handleOpenEditOrder} />
      </Dialog>
      <Dialog open={open01AddImage} handler={handle01AddImage} size="xl">
        <Step01addimagesCard id={IDSelect} />
      </Dialog>
      <Dialog open={open23AddImage} handler={handle23AddImage} size="xl">
        <Step23addimageCard id={IDSelect} />
      </Dialog>
      <Dialog open={open45AddImage} handler={handle45AddImage} size="xl">
        <Step45addimagesCard id={IDSelect} />
      </Dialog>
      <Dialog
        open={isDialogOpen}
        {...({ toggler: () => setIsDialogOpen(false) } as any)}
      >
        <div
          className="flex justify-center"
          onClick={() => setIsDialogOpen(false)}
        >
          <img src={currentImage} alt="Selected Image" />
        </div>
      </Dialog>
    </>
  );
};

export default AllOrder;
