import React, { useEffect, useState } from 'react';
import {
    Card,
    Typography,
    CardBody,
    Chip,
    IconButton,
    Tooltip,
    Input,
    Button,
    Select,
    Option,
    Popover,
    Dialog,
    DialogHeader,
    DialogBody,
} from "@material-tailwind/react";
import { PencilIcon, ChevronDownIcon, ChevronDoubleRightIcon, MagnifyingGlassIcon, TrashIcon, CheckBadgeIcon, ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { DeleteBooking, studioSearch } from '../hooks/Studio/StudioAdd';
import { studioSearchResponse } from '../types/StudioTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPhone, faUser, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { useStore, useStudioCurrentDataStore } from '../hooks/store';
import { useNavigate } from 'react-router-dom';
import Success from '../components/Studio/Success';
import { CalendarIcon } from '@heroicons/react/24/outline';
import Pagination from '../components/Pagination';

const StudioDetail = () => {
    const { role } = useStore();
    const [studioData, setStudioData] = useState<studioSearchResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [statusPadding, setStatusPadding] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);
    const [selectStudioId, setSelectStudioId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);




    const TABLE_HEAD = ["ลำดับ", "ลูกค้า", "ID", "ข้อมูลติดต่อ", "สร้างโดย", "วัน-เวลา", "ราคา", "สถานะ", ...(role === 'admin' ? ["รายได้จริง"] : []), "Actions"];


    useEffect(() => {
        fetchStudioData(searchTerm, statusPadding, formatDate(startDate), currentPage, itemsPerPage);
    }, [searchTerm, statusPadding, startDate, currentPage, itemsPerPage]);

    const formatDate = (date: Date | null): string => {
        if (!date) return "";
        try {
            return format(date, 'yyyy-MM-dd');
        } catch (error) {
            console.error("Error formatting date:", error);
            return "";
        }
    };

    const fetchStudioData = async (search: string, status: string, date: string, page: number, perPage: number) => {
        try {
            setLoading(true);
            const resData = await studioSearch(search, status, date, page, perPage);
            setStudioData(resData);
        } catch (err) {
            setError('Failed to fetch studio data');
            console.error('Error fetching studio data:', err);
        } finally {
            setLoading(false);
        }
    };

    const toggleRowExpansion = (studioId: string) => {
        setExpandedRow(expandedRow === studioId ? null : studioId);
    };

    const handleSearch = () => {
        setCurrentPage(1); // รีเซ็ตกลับไปหน้าแรกเมื่อมีการค้นหาใหม่
        fetchStudioData(searchTerm, statusPadding, formatDate(startDate), 1, itemsPerPage);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!studioData) return <div>No data available</div>;

    const onDelete = async (studioId: string) => {
        // const response = await DeleteBooking(studioId);
        // console.log("delete response", response);
        // if (response.status === "000") {
        //     Swal.fire("สำเร็จ!", "ลบข้อมูลเรียบร้อยแล้ว", "success");
        // } else {
        //     Swal.fire("เกิดข้อผิดพลาด", response.message, "error");
        // }

        Swal.fire({
            title: 'คุณแน่ใจหรือไม่?',
            text: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่ ID ที่ ${studioId}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่, ลบเลย!',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await DeleteBooking(studioId);
                    console.log("delete response", response);
                    if (response.status === "000") {
                        Swal.fire("สำเร็จ!", "ลบข้อมูลเรียบร้อยแล้ว", "success");
                        setStudioData((prevData): studioSearchResponse | null => {
                            if (!prevData) return null;
                            const updatedData = prevData.data.filter(item => item.studioId !== studioId);
                            return {
                                ...prevData,
                                data: updatedData,
                                total: prevData.total - 1
                            };
                        });
                    } else {
                        Swal.fire("เกิดข้อผิดพลาด", response.message, "error");
                    }
                } catch (error) {
                    console.error("Error deleting studio:", error);
                    Swal.fire("เกิดข้อผิดพลาด", "ไม่สามารถลบข้อมูลได้", "error");
                }
            }
        });
    };

    const handleEditClick = (item: any) => {
        useStudioCurrentDataStore.getState().setStudioId(item.studioId);
        useStudioCurrentDataStore.getState().setCustomerName(item.customerName);
        useStudioCurrentDataStore.getState().setMobileNo(item.mobileNo);
        useStudioCurrentDataStore.getState().setFacebook(item.facebook);
        useStudioCurrentDataStore.getState().setIG(item.IG);
        useStudioCurrentDataStore.getState().setLine(item.line);
        useStudioCurrentDataStore.getState().setMakeupName(item.makeupName);
        useStudioCurrentDataStore.getState().setPhotographerName(item.photographerName);
        useStudioCurrentDataStore.getState().setRemark(item.remark);
        useStudioCurrentDataStore.getState().setStudioPrice(item.studioPrice);

        navigate("/studioupdate");
    };

    const copyCalendarLink = () => {
        const baseUrl = window.location.origin; // จะได้ URL หลักของเว็บไซต์
        const calendarUrl = `${baseUrl}/studiocalendar`; // สร้าง URL เต็มของหน้าปฏิทิน

        navigator.clipboard.writeText(calendarUrl).then(() => {
            // แสดง feedback ว่าคัดลอกสำเร็จ
            Swal.fire({
                title: 'คัดลอกลิงค์แล้ว!',
                text: 'ลิงค์ปฏิทินคิวงานถูกคัดลอกไปยังคลิปบอร์ดแล้ว',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false
            });
        }).catch(err => {
            console.error('Failed to copy: ', err);
            Swal.fire({
                title: 'เกิดข้อผิดพลาด!',
                text: 'ไม่สามารถคัดลอกลิงค์ได้',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false
            });
        });
    };

    return (
        <Card className="w-full my-4 mx-auto max-w-[95%] lg:max-w-full">
            <div className="rounded-none mt-4 px-2 sm:px-4">
                <div className="flex flex-col sm:flex-row gap-2 justify-between">
                    <div className='flex flex-wrap gap-1 mb-2 sm:mb-0'>
                        <Button className="flex items-center gap-1 text-xs sm:text-sm" size="sm" onClick={() => window.open('/studiocalendar', '_blank', 'noopener,noreferrer')}>
                            <CalendarIcon strokeWidth={2} className="h-3 w-3 sm:h-4 sm:w-4" /> ปฏิทินคิวงาน
                        </Button>
                        <Button className="flex items-center gap-1 text-xs sm:text-sm" size="sm" onClick={copyCalendarLink}>
                            <ClipboardDocumentCheckIcon strokeWidth={2} className="h-3 w-3 sm:h-4 sm:w-4" /> คัดลอกลิงค์
                        </Button>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <div className="w-full sm:w-48 md:w-72">
                            <Input
                                crossOrigin={undefined}
                                label="Search"
                                icon={<MagnifyingGlassIcon className="h-4 w-4" />}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="w-full sm:w-48 md:w-72">
                            <Select
                                label="สถานะ"
                                value={statusPadding}
                                onChange={(value) => setStatusPadding(value as string)}
                            >
                                <Option value="">ทั้งหมด</Option>
                                <Option value="1">ชำระเงินแล้ว</Option>
                                <Option value="2">กำลังทำผม/ถ่ายแบบ</Option>
                                <Option value="3">เรียบร้อย</Option>
                            </Select>
                        </div>
                        <div className="w-full sm:w-48 md:w-72">
                            <Input
                                crossOrigin={undefined}
                                type="date"
                                value={startDate ? formatDate(startDate) : ""}
                                onChange={(e) => {
                                    const newDate = e.target.value ? new Date(e.target.value) : null;
                                    setStartDate(newDate);
                                }}
                                className="w-full p-2 border rounded"
                                label='เลือกวันที่'
                            />
                        </div>
                        <Button className="flex items-center gap-1 text-xs sm:text-sm" size="sm" onClick={handleSearch}>
                            <MagnifyingGlassIcon strokeWidth={2} className="h-3 w-3 sm:h-4 sm:w-4" /> Search
                        </Button>
                    </div>
                </div>
            </div>
            <CardBody className="overflow-x-auto">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {studioData.data.map((item, index) => {
                            const isLast = index === studioData.data.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                            const isExpanded = expandedRow === item.studioId;

                            return (
                                <React.Fragment key={item.studioId}>
                                    <tr >
                                        <td className={classes} onClick={() => toggleRowExpansion(item.studioId)}>
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                <div className='flex items-center gap-2 cursor-pointer'>
                                                    {isExpanded ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronDoubleRightIcon className="h-4 w-4" />}
                                                    {item.Rows}
                                                </div>
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    <p><FontAwesomeIcon icon={faUser} className='text-blue-500' /> : {item.customerName}</p>
                                                    <p><FontAwesomeIcon icon={faPhone} className='text-blue-500' /> : {item.mobileNo}</p>
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    <p>{item.studioId}</p>
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                <p>Facebook : {item.facebook}</p>
                                                <p>Line : {item.line}</p>
                                                <p>IG : {item.IG}</p>
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {item.createdBy}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {item.startDate} - {item.startTime}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {item.studioPrice}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="w-max">
                                                <Chip
                                                    variant="ghost"
                                                    size="sm"
                                                    value={
                                                        item.statusPadding === "1" ? "ชำระเงินแล้ว" :
                                                            item.statusPadding === "2" ? "กำลังทำผม/ถ่ายแบบ" :
                                                                item.statusPadding === "3" ? "เรียบร้อย" :
                                                                    "ไม่ทราบสถานะ"
                                                    }
                                                    color={
                                                        item.statusPadding === "1" ? "green" :
                                                            item.statusPadding === "2" ? "blue" :
                                                                item.statusPadding === "3" ? "gray" :
                                                                    "blue-gray"
                                                    }
                                                />
                                            </div>
                                        </td>
                                        {role === 'admin' && (
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {item.studioIncome === null ? "0" : item.studioIncome}
                                                </Typography>
                                            </td>
                                        )}
                                        <td className={classes}>
                                            <Tooltip content="Edit Studio">
                                                <IconButton
                                                    variant="text"
                                                    color="blue-gray"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditClick(item);
                                                    }}
                                                >
                                                    <PencilIcon className="h-4 w-4" />
                                                </IconButton>
                                            </Tooltip>
                                            {item.statusPadding === "3" ? (null) : (
                                                <Tooltip content="สำเร็จ">
                                                    <IconButton
                                                        variant="text"
                                                        color="blue-gray"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpen();
                                                            setSelectStudioId(item.studioId);
                                                        }}
                                                    >
                                                        <CheckBadgeIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <Tooltip content="ลบข้อมูล">
                                                <IconButton
                                                    variant="text"
                                                    color="blue-gray"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDelete(item.studioId);
                                                    }}
                                                >
                                                    <TrashIcon className="h-4 w-4" />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    {isExpanded && (
                                        <tr>
                                            <td colSpan={role === "admin" ? 10 : 9} className="p-4 bg-gray-50">
                                                <div className="p-4 flex justify-between">
                                                    <div>
                                                        <h3 className="font-bold mb-2 underline">รายละเอียดการแต่งหน้า</h3>
                                                        <p>แต่งหน้า : {item.makeupType === "1" ? <FontAwesomeIcon icon={faCheckCircle} className='text-green-500' /> : <FontAwesomeIcon icon={faXmarkCircle} className='text-red-500' />}</p>
                                                        <p>ทำผม : {item.hairdresserType === "1" ? <FontAwesomeIcon icon={faCheckCircle} className='text-green-500' /> : <FontAwesomeIcon icon={faXmarkCircle} className='text-red-500' />}</p>
                                                        <p>ชื่อช่างแต่งหน้า: {item.makeupName}</p>
                                                        <p>เวลาแต่งหน้า: {item.makeuptime}</p>
                                                        <hr className='w-3/5 my-4' />
                                                        <p>ราคา  <span className='text-2xl'>{item.makeupPrice === null ? "0" : item.makeupPrice}</span> บาท</p>

                                                    </div>
                                                    <div>
                                                        <h3 className="font-bold mb-2 underline">รายละเอียดการถ่ายภาพ</h3>
                                                        <p>ถ่ายภาพ : {item.photographerType === "1" ? <FontAwesomeIcon icon={faCheckCircle} className='text-green-500' /> : <FontAwesomeIcon icon={faXmarkCircle} className='text-red-500' />}</p>
                                                        <p>ชื่อช่างภาพ : {item.photographerName}</p>
                                                        <p>เวลาถ่ายภาพ : {item.photographerTime}</p>
                                                        <br />
                                                        <hr className='w-3/5 my-4' />
                                                        <p>ราคา  <span className='text-2xl'>{item.photographerPrice === null ? "0" : item.photographerPrice}</span> บาท</p>

                                                    </div>
                                                    <div className='flex flex-col w-96'>
                                                        <h3 className="font-bold mb-2 underline">หมายเหตุ</h3>
                                                        <p className='text-wrap break-words'>{item.remark}</p>
                                                    </div>
                                                </div>
                                                <hr className='my-4' />
                                                <div className='grid grid-cols-5'>
                                                    {item.imageStudio.map((imgitems, index) => {
                                                        return <div key={index}>

                                                            <img src={imgitems.filePath} className=' w-60' />

                                                        </div>
                                                    })}
                                                </div>

                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex items-center justify-center border-t border-blue-gray-50 p-4">
                    <div className="flex flex-col items-center gap-2">
                        <Select
                            label="จำนวนแถว/หน้า"
                            value={itemsPerPage.toString()}
                            onChange={(value) => {
                                const newItemsPerPage = Number(value);
                                setItemsPerPage(newItemsPerPage);
                                setCurrentPage(1);
                                fetchStudioData(searchTerm, statusPadding, formatDate(startDate), 1, newItemsPerPage);
                            }}
                        >
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                        <Pagination
                            total={Math.ceil((studioData?.total || 0) / itemsPerPage)}
                            activePage={currentPage}
                            onChange={(page) => {
                                setCurrentPage(page);
                                fetchStudioData(searchTerm, statusPadding, formatDate(startDate), page, itemsPerPage);
                            }}
                        />
                        <Typography variant="small" color="blue-gray" className="font-normal">
                            หน้า {currentPage} จากทั้งหมด {Math.ceil((studioData?.total || 0) / itemsPerPage)}
                        </Typography>
                    </div>
                </div>
            </CardBody>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Success </DialogHeader>
                <DialogBody>
                    <Success studioId={selectStudioId} onSuccessfulUpdate={() => {
                        handleOpen();
                        fetchStudioData(searchTerm, statusPadding, formatDate(startDate), currentPage, itemsPerPage);
                    }} />
                </DialogBody>

            </Dialog>
        </Card>
    );
};

export default StudioDetail;