import React, { useState, useEffect } from "react";
import { SelectShopByID, updateShopById } from "../../hooks/GetShopId";
import { reponseShopIDTypes } from "../../types/ShopIDTypes";
import { Input, Button ,Alert} from "@material-tailwind/react";
import Swal from 'sweetalert2';

const EditShop = ({ closeEditShop, selectedId }: { closeEditShop: () => void, selectedId: number | null }) => {
  const [Shopdata, setShoptData] = useState<reponseShopIDTypes | null>(null);
  const [shopName, setShopName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (selectedId !== null) {
        const result = await SelectShopByID(selectedId);
        setShoptData(result);
        setShopName(result.shop.shopName);
        
      }
    };
  
    fetchData();
  }, [selectedId]);

  const handleUpdate = async () => {
    if (Shopdata?.shop?.shopId !== undefined) {
      const response = await updateShopById(Shopdata.shop.shopId, shopName);
      if (response.statusCode === 200) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        })
        closeEditShop();
      } else {
        <Alert color="red" variant="gradient">
        <span>เกิดข้อผิดพลาด!!! ไม่สามารถบันทึกข้อมูลได้</span>
      </Alert>
      }
    } else {
      console.error('Shop ID is undefined');
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2 p-5">
        <div>
          <span className="font-semibold text-lg text-pink-500">ข้อมูลร้าน / สาขา</span>
        </div>
        <Input crossOrigin="anonymous" size="md" label="Created By" defaultValue={Shopdata?.shop?.cretedBy} disabled />
        <Input crossOrigin="anonymous" size="md" label="Created Date" defaultValue={Shopdata?.shop?.cretedDate} disabled />
        <Input crossOrigin="anonymous" size="md" label="Shop ID" defaultValue={Shopdata?.shop?.shopId?.toString()} disabled />
        <Input crossOrigin="anonymous" size="md" label="Shop Name" value={shopName} onChange={(e) => setShopName(e.target.value)} />
        <Input crossOrigin="anonymous" size="md" label="Updated By" defaultValue={Shopdata?.shop?.updateBy || ""} disabled />
        <Input crossOrigin="anonymous" size="md" label="Updated Date" defaultValue={Shopdata?.shop?.updateDate} disabled />
        <div className="flex justify-end">
        <Button color="pink" onClick={handleUpdate}>บันทึกข้อมูล</Button>
        </div>
        
      </div>
    </>
  );
};

export default EditShop;
