import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
  Button,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { LoginDataTypes } from "../types/loginDataTypes";
import { userLogin } from "../hooks/login";
import { useStore } from "../hooks/store";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import loginbg from "../images/login-bg.jpg";

const Login = () => {
  // useForm Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDataTypes>();

  const navigate = useNavigate();
  const {role} = useStore();

  // State for showing/hiding password
  const [showPassword, setShowPassword] = useState(false);

  // onSubmit function
  const onSubmit = async (data: LoginDataTypes) => {
    if (Object.keys(errors).length !== 0) {
      Swal.fire("Error", "Please fill all the required fields!", "error");
    } else {
      try {
        const response = await userLogin(data.username, data.password);

        if (response.status === false) {
          Swal.fire("Error", response.message, "error");
          navigate("/")

        } else if (response.status === true) {
          useStore.getState().setAccessToken(response.accessToken);
          useStore.getState().setUsername(response.savedUserlogin.username);
          useStore.getState().setRole(response.role);
          useStore.getState().setCommission(response.commission);
          useStore.getState().setCommissionType(response.commissionType);
          response.role === "admin" ? navigate("/ownerdashboard") : navigate("/dashboard");

         
          // Store in Zustand state

        }

      } catch (error: any) {
        // If the error is from server and the error_code is 401
        if (error.response && error.response.data.error_code === 401) {
          Swal.fire("Error", error.response.data.message, "error");
        } else {
          Swal.fire("Error", "Error occurred during login", "error");
        }
      }
    }
  };

  return (
    <>
      <div style={{ backgroundImage: `url(${loginbg})`, height: '100vh', width: '100%', backgroundSize: 'cover' }} className="lg:flex lg:flex-col lg:justify-center lg:px-20 flex flex-col justify-center">
        <div className="lg:flex lg:justify-end lg:mr-32 ">
          <Card className="lg:w-[450px] lg:h-[550px] lg:flex lg:justify-center p-8 mx-4">
            <div className="flex flex-col items-center pt-4 gap-2">
              <img
                src="https://www.เช่าชุดเชียงใหม่.com/wp-content/uploads/2022/06/cm_fashion-2022Logo.png"
                alt="เช่าชุดเชียงใหม่"
                className="w-36"
              />
              <p className="text-xs">
                ระบบจัดการออเดอร์ร้านเช่าชุดเชียงใหม่.com
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardBody className="flex flex-col gap-4">
                <Input
                  crossOrigin="anonymous"
                  label="Username"
                  size="lg"
                  {...register("username", { required: true })}
                />
                {errors.username && (
                  <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                    กรุณากรอก Username
                  </div>
                )}
                <div className="relative">
                  <Input
                    crossOrigin="anonymous"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    size="lg"
                    {...register("password", { required: true })}
                    error={errors.password ? true : false}
                  />
                  {errors.password && (
                    <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                      กรุณากรอก Password
                    </div>
                  )}
                  <div
                    className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      color="gray"
                      size="lg"
                    />
                  </div>
                </div>
                <div className="-ml-2.5 text-xs">
                  <Checkbox crossOrigin="anonymous" color="pink" label="จดจำชื่อและรหัสผ่านฉันไว้" />
                </div>
              </CardBody>
              <CardFooter className="pt-0">
                <Button type="submit" variant="gradient" fullWidth>
                  เข้าสู่ระบบ
                </Button>
              </CardFooter>
            </form>
            <p className="text-xs  text-center text-gray-400">
              OrderManagement v.2.1
            </p>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
