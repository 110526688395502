import React, { Dispatch, SetStateAction } from "react";
import { useState, useEffect } from "react";
import { GetShopIdList, deleteShopById } from "../../hooks/GetShopId";
import { ShopIdTypes } from "../../types/ShopIDTypes";
import { Card, Typography, IconButton, Alert } from "@material-tailwind/react";
import { PencilIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import { Menu } from "@headlessui/react";

const TABLE_HEAD = ["รหัสสาขา", "ชื่อสาขา", ""];
interface ShopDetailType {
  closeDialog: () => void;
  openEditShop: () => void;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
}

const ShopDetail: React.FC<ShopDetailType> = ({
  closeDialog,
  openEditShop,
  setSelectedId,
}) => {
  const [data, setData] = useState<ShopIdTypes[]>([]);
  const [alert, setAlert] = useState(false);

  const fetchData = async () => {
    const result = await GetShopIdList();
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await deleteShopById(id);
      setAlert(true);
      setTimeout(() => setAlert(false), 2000); // Reset the alert after 3 seconds
      fetchData();
    } catch (error) {
      console.error("Error deleting shop:", error);
    }
  };

  return (
    <>
      {alert && (
        <Alert
          color="green"
          icon={<InformationCircleIcon strokeWidth={2} className="h-6 w-6" />}
        >
          ลบ ร้าน/สาขา สำเร็จ!!!
        </Alert>
      )}
      <Card className="overflow-auto h-full w-full ">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 py-4 px-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, shopName }, index) => {
              const isLast = index === data.length - 1;
              const classes = isLast
                ? "px-12"
                : "px-12 border-b border-blue-gray-50";

              return (
                <tr key={id}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {id}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {shopName}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Menu>
                      <Menu.Button>
                        <IconButton variant="text" color="blue-gray">
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Menu.Button>
                      <Menu.Items className="fixed w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setSelectedId(id);
                                  closeDialog();
                                  openEditShop();
                                }}
                                className={`${
                                  active
                                    ? "bg-violet-500 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-pink-400`}
                              >
                                แก้ไข
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setSelectedId(id);
                                  handleDelete(id);
                                }}
                                className={`${
                                  active
                                    ? "bg-violet-500 text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-pink-400`}
                              >
                                ลบ
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Menu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default ShopDetail;
