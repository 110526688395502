import { useState } from 'react';
import { useEffect } from 'react';
import { SelectTransportByID,updateTransportById } from '../../hooks/TranspotList';
import Swal from 'sweetalert2';
import { reponseTransportType } from '../../types/TranspotTypes';
import { Input, Button } from '@material-tailwind/react';

const EditTransport = ({ closeEditTransport, selectedId }: { closeEditTransport: () => void, selectedId: number | null }) => {
  const [transportData, setTransportData] = useState <reponseTransportType | null>(null);
  const [transporName, settransportName] = useState("");

useEffect(() => {
  const fetchData = async () => {
    if (selectedId !== null) {
      const result = await SelectTransportByID(selectedId);
      setTransportData(result);
      settransportName(result.transport.transporName);
      
    }
  };

  fetchData();
}, [selectedId]);

const handleUpdate = async () => {
  if (transportData?.transport?.transporId !== undefined) {
    const response = await updateTransportById(transportData.transport.transporId, transporName);
    if (response.statusCode === 200) {
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
      })
      closeEditTransport();
    } else {
     //// Alert code here ////
    }
  } else {
    console.error('ไม่พบ ID นี้');
  }
};
  return (
    <>
    <div className="flex flex-col gap-2 p-5">
      <div>
        <span className="font-semibold text-lg text-pink-500">แก้ไขข้อมูลการจัดส่ง</span>
      </div>
      <Input crossOrigin="anonymous" size="md" label="ผู้สร้าง" defaultValue={transportData?.transport?.cretedBy} disabled />
      <Input crossOrigin="anonymous" size="md" label="วันที่สร้าง" defaultValue={transportData?.transport?.cretedDate} disabled />
      <Input crossOrigin="anonymous" size="md" label="ระหัสขนส่ง" defaultValue={transportData?.transport?.transporId?.toString()} disabled />
      <Input crossOrigin="anonymous" size="md" label="ชื่อขนส่ง" value={transporName} onChange={(e) => settransportName(e.target.value)} />
      <Input crossOrigin="anonymous" size="md" label="แก้ไขโดย" defaultValue={transportData?.transport?.updateBy || ""} disabled />
      <Input crossOrigin="anonymous" size="md" label="วันที่แก้ไขข้อมูล" defaultValue={transportData?.transport?.updateDate} disabled />
      <div className="flex justify-end">
      <Button color="pink" onClick={handleUpdate}>บันทึกข้อมูล</Button>
      </div>
      
    </div>
  </>
  )
}

export default EditTransport