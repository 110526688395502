import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/Login";
import StudioCalendar from "./pages/StudioCalendar";
import BackendLayouts from "./layouts/BackendLayouts";
import CustomerLayout from './layouts/ CustomerLayout';
import CDashboard from "./pages/Customer/cDashboard";
import backendRoutes from './Route';
import StudioDetail2 from "./pages/Studio-Detail";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/studiocalendar" element={<StudioCalendar />} />
          <Route path="/studio-detail/:id" element={<StudioDetail2 />} />
        </Route>
        

        <Route element={<BackendLayouts />}>
          {backendRoutes.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>

        <Route path="customer" element={<CustomerLayout />}>
          <Route path=":id/:refSegment" element={<CDashboard />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
