import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Avatar, Dialog } from '@material-tailwind/react';
import { CustomerOrderDetail, CheckUserDeposit } from '../../hooks/order';
import { IOrder } from '../../types/CustomerOrderDetailTypes';
import { faExclamationCircle, faCartArrowDown, faChartBar, faCheckToSlot, faBank, faUserCircle, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerStatus from '../../components/Customer/CustomerStatus';
import CustomerDepositrefund from '../CustomerDepositrefund';
import CustomerRentalDetail from '../../components/Customer/CustomerRentalDetail';
import CustomerCDetail from '../../components/Customer/CustomerCDetail';
import ConditionAgreement from './ConditionAgreement';



const CDashboard = () => {
    const [cDetail, setCdetail] = useState<IOrder | undefined>();
    const [activeComponent, setActiveComponent] = useState<string>('status');

    const { id: encryptedId } = useParams<{ id?: string }>();

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            if (encryptedId !== undefined) {
                await customerDetail(encryptedId);
            } else {
                console.error("Encrypted ID not found in URL");
            }
        };

        fetchCustomerDetails();
    }, [encryptedId]);

    const customerDetail = async (id: string) => {
        const detailresponse = await CustomerOrderDetail(id);
        setCdetail(detailresponse);
    }

    //// Check Deposit /////
    useEffect(() => {

        const checkDeposit = async () => {
            const orderID = cDetail?.order.orderId ?? 0;
            const response = await CheckUserDeposit(orderID);
            if (response.checkDepositRefund === "ยังไม่ได้บันทึกข้อมูลบัญชีธนาคาร" ) {
                handleOpenDialog();
            }
        };

        checkDeposit();

    }, [cDetail]);


    ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
    const [openDialog, setOpenDialog] = useState(true);
    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const handleStatusClick = () => {
        setActiveComponent('status');
    }

    const handleSummaryClick = () => {
        setActiveComponent('summary');
    }
    const handleDetailClick = () => {
        setActiveComponent('customerDetails');
    }
    const handleConditionClick = () => {
        setActiveComponent('conditionAgreement');
    }


    return (
        <>
            <div className='h-32 bg-cover bg-no-repeat bg-top p-4 flex gap-2 items-end  ' style={{ backgroundImage: "url('http://www.xn--72cfaa3c9df7evc7a4a6gf0c0dm.com/wp-content/uploads/2023/07/S__14647300.jpg')" }}>
                <Avatar src='https://img.freepik.com/free-vector/3d-cartoon-young-woman-smiling-circle-frame-character-illustration-vector-design_40876-3100.jpg?w=1800&t=st=1692599523~exp=1692600123~hmac=271ca86d3b6a967a7dac40a8bd3a80b9f51194c2a6614ad8140f4093980f7223' className=' w-16  h-16' />
                <div className='flex flex-col'>
                    <span className='text-white'>{cDetail?.order.custommerFirstName} {cDetail?.order.custommerLastName}</span>
                    <span className='text-white text-xs'> หมายเลขคำสั่งซื้อ : {cDetail?.order.refNumber}</span>
                </div>

            </div>
            <div className='flex flex-col items-center h-screen '>
                <Card className='m-2 px-4 py-2 lg:w-[750px] '>
                    <div className='flex flex-row items-center gap-4 justify-center'>
                        <FontAwesomeIcon icon={faExclamationCircle} className='text-xl' />
                        <span className='text-[12px]'>เพื่อความสะดวกในการรับเงินค่ามัดจำชุดคืนหลังจากส่งสินค้าคืนแล้ว กรุณากรอกรายละที่ปุ่มคืนมัดจำให้เรียบร้อย </span>
                    </div>
                </Card>
                <Card className='m-2 px-4 py-1 lg:w-[750px]'>
                    <div className='flex justify-center'>
                        <div id='cStatus' onClick={handleStatusClick} className='m-2 px-2 py-3 flex flex-col justify-center gap-2 cursor-pointer'>
                            <FontAwesomeIcon icon={faCartArrowDown} className='text-2xl text text-pink-300' />
                            <span className='text-center text-[10px] lg:text-xs'>สถานะการสั่งซื้อ</span>
                        </div>
                        <div id='csummary' onClick={handleSummaryClick} className='m-2 px-2 py-3 flex flex-col justify-center gap-2 cursor-pointer'>
                            <FontAwesomeIcon icon={faChartBar} className='text-2xl text text-pink-300' />
                            <span className='text-center text-[10px] lg:text-xs'>รายละเอียด</span>
                        </div>
                        <div className='m-2 px-2 py-3 flex flex-col justify-center gap-2 cursor-pointer' onClick={handleDetailClick}>
                            <FontAwesomeIcon icon={faUserCircle} className='text-2xl text text-pink-300' />
                            <span className='text-center text-[10px] lg:text-xs'>ข้อมูลลูกค้า</span>
                        </div>

                        <div id='cPolicy' className='m-2 px-2 py-3 flex flex-col justify-center gap-2 cursor-pointer' onClick={handleConditionClick}>
                            <FontAwesomeIcon icon={faCheckToSlot} className='text-2xl text text-pink-300' />
                            <span className='text-center text-[10px] lg:text-xs'>เงื่อนไขการเช่าชุด</span>
                        </div>

                    </div>
                </Card>

                <Card className='m-2  py-2 lg:w-[750px]'>
                    {activeComponent === 'status' &&
                        <CustomerStatus cDetail={cDetail} />
                    }

                    {activeComponent === 'summary' &&
                        <CustomerRentalDetail cDetail={cDetail} />
                    }

                    {activeComponent === 'customerDetails' &&
                        <CustomerCDetail cDetail={cDetail} />
                    }

                    {activeComponent === 'conditionAgreement' &&
                        < ConditionAgreement cDetail={cDetail} />
                    }
                    
                </Card>
            </div>


            <Dialog open={openDialog} handler={handleOpenDialog} size='xxl'>
                <CustomerDepositrefund cID={cDetail?.order.custommerID} cOrderID={cDetail?.order.orderId} cTeleOTP={cDetail?.order.phone} closeVerifyOTP={handleOpenDialog} />
            </Dialog>
        </>
    );
}

export default CDashboard;
