import { useState, useEffect } from "react";
import { Input, Radio, Button, Alert } from "@material-tailwind/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { customerDepositrefundType } from "../types/CustomeDepositrefundTypes";
import { BnakIDTypes } from "../types/GetBankIdTypes";
import { GetBankID } from "../hooks/BankDeposit";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { GetOTP, VerifyOTP } from "../hooks/order";
import { OTPResponseType } from "../types/GetOTPResponseTypes";
import Swal from "sweetalert2";

interface cDRFTypes {
  cID: number | undefined;
  cOrderID: number | undefined;
  cTeleOTP: string | undefined;
  closeVerifyOTP: () => void;
}

const CustomerDepositrefund: React.FC<cDRFTypes> = ({
  cID,
  cOrderID,
  cTeleOTP,
  closeVerifyOTP,
}) => {
  const [bankname, setBankname] = useState<BnakIDTypes[]>([]);
  const [formData, setFormData] = useState<customerDepositrefundType | null>(
    null
  );
  const [showDetails, setShowDetails] = useState<boolean>(false); // state to track the view
  const [otp, setOTP] = useState<OTPResponseType>();

  const BankID = async () => {
    try {
      const bankIDresponse = await GetBankID();
      setBankname(bankIDresponse);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    BankID();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<customerDepositrefundType>({
    defaultValues: {
      phoneNumber: cTeleOTP,
      otp: "",
      otpRefCode: otp?.smsreport.otpRefCode,
      depositFirstName: "",
      depositLastName: "",
      bankDepositId: "",
      bankAccount: "",
      prompay: "",
      depositStatus: "",
      orderId: cOrderID,
      custommerId: cID,
      cretedBy: "",
      updateBy: "",
      resetBy: "",
      status: 1,
    },
  });

  useEffect(() => {
    if (cTeleOTP !== undefined) {
      setValue("phoneNumber", cTeleOTP);
    }
    if (cOrderID !== undefined) {
      setValue("orderId", cOrderID);
    }
    if (cID !== undefined) {
      setValue("custommerId", cID);
    }
  }, [cTeleOTP, cOrderID, cID, setValue]);

  const selectedStatus = watch("depositStatus");

  const PreviewDetail: SubmitHandler<customerDepositrefundType> = async (
    data
  ) => {
    const convertData = {
      ...data,
      orderId: Number(data.orderId),
      depositStatus: Number(data.depositStatus),
    };

    setFormData(data);
    setShowDetails(true); // set the state to show details after form submission
  };

  const handleBack = () => {
    setShowDetails(false);
  };

  const getTOP = async (cTeleOT: string) => {
    try {
      const responseData = await GetOTP(cTeleOT);
      console.log(responseData);
      setOTP(responseData);
    } catch (error) {
      console.log("Error From Get OTP", error);
    }
  };

  const onSubmit: SubmitHandler<customerDepositrefundType> = async (data) => {
    try {
      const verifyResponse = await VerifyOTP(data);

      if (verifyResponse.status === true) {
        Swal.fire("Success!", verifyResponse.message, "success");
        closeVerifyOTP();
      } else if (verifyResponse.status === "ไม่ถูกต้อง") {
        Swal.fire({
          title: "Error!",
          text: verifyResponse.message,
          icon: "error",
          timer: 2000,
          showCloseButton: false,
          showConfirmButton: false,
        });
      } else if (verifyResponse.status === "หมดเวลา") {
        Swal.fire({
          title: "Error!",
          text: verifyResponse.message,
          icon: "error",
          showCloseButton: false,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <div className="pt-8">
      <div className="flex flex-col items-center relative">
        <img
          src="https://www.เช่าชุดเชียงใหม่.com/wp-content/uploads/2022/06/cropped-cm_fashion-2022Logo.png"
          alt="เช่าชุดเชียงใหม่"
          className="w-48"
        />
        {!showDetails && (
          <form onSubmit={handleSubmit(PreviewDetail)}>
            <div className=" my-8 p-8 lg:w-[750px] w-[350px] flex flex-col  border-2 rounded-xl ">
              <h1 className="text-center text-xl font-bold underline-offset-2 underline mb-3 text-pink-500">
                กรุณากรอกหมายเลขบัญชี ฯ เพื่อรับค่าประกันชุด
              </h1>
              <div className="lg:grid lg:grid-cols-2 lg:gap-2 lg:my-4 flex flex-col gap-2">
                <Input
                  crossOrigin="anonymous"
                  label="ชื่อบัญชี"
                  {...register("depositFirstName", { required: true })}
                />
                {errors.depositFirstName && (
                  <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                    กรุณากรอกชื่อบัญชี
                  </div>
                )}
                <Input
                  crossOrigin="anonymous"
                  label="นามสกุล"
                  {...register("depositLastName", { required: true })}
                />
                {errors.depositLastName && (
                  <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                    นามสกุล
                  </div>
                )}
              </div>
              <div>
                <div className="flex flex-col text-xs">
                  <Radio
                    crossOrigin="anonymous"
                    value={1}
                    label="ธนาคาร (Bank Account)"
                    {...register("depositStatus", { required: true })}
                  />
                  <Radio
                    crossOrigin="anonymous"
                    value={2}
                    label="พร้อมเพย์ (PromptPay)"
                    {...register("depositStatus", { required: true })}
                  />
                  {errors.depositStatus && (
                    <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                      กรุณาเลือกช่องทางการรับเงินคืน
                    </div>
                  )}
                </div>
                {selectedStatus == "1" && (
                  <div className="lg:grid lg:grid-cols-2 lg:gap-2 flex flex-col gap-2">
                    <div>
                      <select
                        className="border-2 p-1 rounded-lg w-full"
                        placeholder="เลือกธนาคาร"
                        {...register("bankDepositId", { required: true })}
                      >
                        {errors.bankDepositId && (
                          <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                            กรุณาเลือกธนาคาร
                          </div>
                        )}
                        <option value="" disabled selected className="text-xs">
                          เลือกธนาคาร
                        </option>
                        {bankname.map((name, index) => (
                          <option key={index} value={name.id}>
                            {" "}
                            {name.bankName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <Input
                        crossOrigin="anonymous"
                        label="เลขบัญชี"
                        {...register("bankAccount", {
                          required: true,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Only numbers are allowed",
                          },
                          minLength: {
                            value: 10,
                            message:
                              "The bank account number should be at least 10 numbers long",
                          },
                        })}
                      />
                      {errors.bankAccount && (
                        <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                          ต้องเป็นตัวเลขเท่านั้นและต้องไม่ต่ำกว่า 10 ตัว
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {selectedStatus == "2" && (
                  <div>
                    <Input
                      crossOrigin="anonymous"
                      label="เลขพร้อมเพย์"
                      {...register("prompay", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Only numbers are allowed",
                        },
                        minLength: {
                          value: 10,
                          message:
                            "The PromptPay should be at least 10 numbers long",
                        },
                      })}
                    />
                    {errors.prompay && (
                      <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                        ต้องเป็นตัวเลขเท่านั้น และต้องไม่ต่ำว่า 10 ตัว
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex justify-center ">
                <Button type="submit" className="my-2">
                  {" "} 
                  ตกลง
                </Button>
              </div>
              <p className="text-center text-xs text-red-500">ตรวจสอบเลขบัญชีให้ถูกต้อง หากผิดพลาดจะไม่รับผิดชอบใดๆ ทั้งสิ้น</p>
            </div>
          </form>
        )}

        <div className="px-4 ">
          {showDetails && formData && (
            <div className="">
              <h2 className="text-center text-xl font-bold my-2 underline">
                ข้อมูลการรับเงินคืน
              </h2>
              <div className="flex flex-col gap-1 border-2 p-4 rounded-lg">
                <p>
                  <strong>ชื่อบัญชี:</strong> {formData.depositFirstName}
                </p>
                <p>
                  <strong>นามสกุล:</strong> {formData.depositLastName}
                </p>
                <p>
                  <strong>หมายเลขโทรศัพท์รับ OTP:</strong> {cTeleOTP}
                </p>

                {formData.depositStatus == "1" && (
                  <>
                    <p>
                      <strong>ธนาคาร:</strong>
                      {
                        bankname.find(
                          (bank) => String(bank.id) === formData.bankDepositId
                        )?.bankName
                      }
                    </p>
                    <p>
                      <strong>เลขบัญชี:</strong> {formData.bankAccount}
                    </p>
                  </>
                )}
                {formData.depositStatus == "2" && (
                  <p>
                    <strong>หมายเลขพร้อมเพย์:</strong> {formData.prompay}
                  </p>
                )}

                {cTeleOTP && !otp?.status ? (
                  <Button onClick={() => getTOP(cTeleOTP)}>รับ OTP</Button>
                ) : otp?.status ? (
                  <div className="my-2 flex flex-col gap-2 ">
                    <Input
                      crossOrigin="anonymous"
                      label="รหัสอ้างอิง"
                      value={otp.smsreport.otpRefCode}
                      {...register("otpRefCode")}
                      readOnly
                    />
                    <Input
                      crossOrigin="anonymous"
                      label="หมายเลข OTP"
                      {...register("otp")}
                    />
                    <Button onClick={handleSubmit(onSubmit)}>
                      บันทุกข้อมูล
                    </Button>
                  </div>
                ) : null}
              </div>
              <button
                className="w-10 h-10 rounded-full bg-gray-500/70 text-white p-1.5 absolute top-3 left-3"
                onClick={handleBack}
              >
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          <div className="p-8">
            <span className=" font-bold">หมายเหตุ :</span>{" "}
            กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง
            เพื่อสิทธิประโยชน์ของท่านในการเงินค่ามัดจำคืนจากทางร้าน
            หลังจากท่านส่งสินค้ากลับคืนมาถึงร้านแล้ว
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDepositrefund;
