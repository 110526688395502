import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card } from '@material-tailwind/react';
import { useState, useEffect } from 'react';
import { GetSendDateDetail } from '../hooks/CalendarSendDate';
import { CalendarSendType } from '../types/CalendarSendTypes';
import { Dialog } from '@material-tailwind/react';
import ViewDetailCard from '../components/Oder/ViewDetailCard';

const localizer = momentLocalizer(moment);
type CalendarEventType = {
    id: number;
    start: Date;
    title: string;
};


const Calendar = () => {
    const [eventSend, seteventSend] = useState<CalendarSendType | null>(null);
    const [OrderID, setOrderID] = useState<number | null>(null);


    /////////////// Dialog ////////////
    const [openViewOrder, setOpenViewOrder] = useState(false);
    const handleOpenViewOrder = () => setOpenViewOrder(!openViewOrder);
    ///////////////////////////////////



    const sendDatedata = async () => {
        const eventdata = await GetSendDateDetail();
        seteventSend(eventdata);
    };

    useEffect(() => {
        sendDatedata();
    }, []);

    let calendarEvents: CalendarEventType[] = [];

    if (eventSend) {
        calendarEvents = eventSend.sendDateData.map(item => ({
            id: item.id,
            start: new Date(item.sendDate),
            title: `${item.refNumber}`
        }));
    }

    const handleSelectEvent = (event: CalendarEventType) => {
        setOrderID(event.id); 
        handleOpenViewOrder();
    };

    return (
        <>
            <Card className='my-4 p-8'>
                <div className=' h-screen '>
                    <BigCalendar
                        localizer={localizer}
                        events={calendarEvents}
                        startAccessor={event => event.start}
                        endAccessor={event => event.start}
                        className='lg:text-sm text-xs'
                        popup
                        onSelectEvent={handleSelectEvent} // <-- Add this line
                    />
                </div>
            </Card>
            <Dialog open={openViewOrder} handler={handleOpenViewOrder}>
                <ViewDetailCard selectedOrderID={OrderID}/>
            </Dialog>
        </>
    );
};

export default Calendar;
