import React, { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import ExpandableComponent from "../components/ExpandableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import ActionDropdown from "../components/ActionDropdown";
import { ProcessingOrderList } from "../hooks/order";
import {
  Dialog,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Card,
} from "@material-tailwind/react";
import OrderDetailCard from "../components/Oder/OrderDetailCard";
import Step23submitCard from "../components/Step/Step23submitCard";
import { GetShopIdList } from "../hooks/GetShopId";
import { ShopIdTypes } from "../types/ShopIDTypes";
import { Input, Select, Option, Button } from "@material-tailwind/react";

const Processing = () => {
  const [OrderList, setOrderList] = useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  // const numRefNumber = parseFloat(refNumber || "0");
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);

  const fetchData = async (page: number) => {
    const dataPaidOrder: OrderResponse = await ProcessingOrderList(
      page,
      perPage,
      search,
      numSelectedShop
    );
    const formattedData = dataPaidOrder.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setOrderList(formattedData);
    setCurrentPage(dataPaidOrder.page);
    setTotalItems(dataPaidOrder.total);
    setPerPage(dataPaidOrder.per_page);
  };

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
    fetchData(currentPage);
  }, [currentPage, perPage, search, selectedShop]);

  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    setResetKey((prevKey) => prevKey + 1);
  };

  const columns: TableColumn<OrderTableRow>[] = [
    {
      name: "ลำดับ",
      selector: (row) => row.Rows,
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => row.orderId,
    },
    {
      name: "คำสั่งซื้อ",
      selector: (row) => row.refNumber,
    },
    {
      name: "ชื่อ-นามสกุล",
      cell: (row) => (
        <div>
          {row.custommerFirstName} {row.custommerLastName}
        </div>
      ),
    },
    {
      name: "วันที่สร้าง",
      selector: (row) => row.cretedDate,
      sortable: true,
    },
    {
      name: "วันที่ส่ง",
      selector: (row) => row.sendDate,
      sortable: true,
    },
    {
      name: "เช่า-ถึง",
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-xs text-green-500">{row.order.sendDate}</span>
          <span className="text-xs text-red-500">{row.endDate}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <ActionDropdown
            openEditOderDetail={handleOpenEditOrder}
            row={row}
            setSelectedOderId={setSelectedOderId}
            openStep01Dialog={handleOpenStep01}
            setSelectedRow={setSelectedRow}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนแถวต่อ 1 หน้า",
    rangeSeparatorText: "ถึง",
  };

  ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const handleOpenEditOrder = () => setOpenEditOrder(!openEditOrder);

  const [openStep01, setOpenStep01] = useState(false);
  const handleOpenStep01 = () => setOpenStep01(!openStep01);

  /////////////////////////////////////////////////
  //////////// ส่ง Prop เลือก ID ///////////////////

  const [selectedOrderID, setSelectedOderId] = useState<number | null>(null);
  ///////////////////////////////////////////////

  /////////////////////////
  const [selectedRow, setSelectedRow] = useState<OrderTableRow | null>(null);

  ////////////////////////

  return (
    <>
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center">
        <div className="lg:grid lg:grid-cols-2 lg:gap-2 flex flex-col">
          <div className="hidden lg:block"></div>
          <div className="lg:flex lg:flex-row lg:gap-2  flex flex-col gap-1">
            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearch(e.target.value)
              }
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ""}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>
                  {shopdata.shopName}
                </Option>
              ))}
            </Select>
            <div className="flex flex-col">
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <DataTable
          columns={columns}
          data={OrderList}
          pagination
          paginationServer
          paginationTotalRows={totalItems}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationComponentOptions}
          expandableRows
          expandableRowsComponent={ExpandableComponent as any}
          expandableRowsComponentProps={{
            renderContent: (data: OrderTableRow) => (
              <div className=" m-2 border-2 rounded-lg p-2">
                <span className="text-sm font-bold underline underline-offset-2">
                  หมายเลขคำสั่งซื้อ #{data.refNumber}
                </span>
                <div
                  id="generalOderDetail"
                  className="mt-2 lg:flex lg:justify-around"
                >
                  <div
                    id="customerDetail"
                    className="text-sm flex flex-col gap-1"
                  >
                    <h2 className="text-pink-700 font-bold underline">
                      ข้อมูลลูกค้า
                    </h2>
                    <span>
                      ชื่อผู้เช่า :{" "}
                      <span className="text-pink-500">
                        {data.custommerFirstName} {data.custommerLastName}
                      </span>
                    </span>
                    <span>
                      ที่อยู่ :{" "}
                      <span className="text-pink-500">
                        {data.order.address} {data.order.subDistrict}{" "}
                        {data.order.district} {data.order.province}{" "}
                        {data.order.zipCode}
                      </span>
                    </span>
                    <span>
                      เบอร์โทร :{" "}
                      <span className="text-pink-500">{data.order.phone}</span>
                    </span>
                    <span>
                      IG :{" "}
                      <span className="text-pink-500">{data.order.IG}</span>
                    </span>
                    <span>
                      Line :{" "}
                      <span className="text-pink-500">{data.order.line}</span>
                    </span>
                    <span>
                      Facebook :{" "}
                      <span className="text-pink-500">
                        {data.order.facebook}
                      </span>
                    </span>
                  </div>
                  <div id="rentDetail" className="text-sm flex flex-col gap-1">
                    <h2 className="text-pink-700 font-bold underline">
                      รายละเอียดการเช่า (OrderId #{data.order.orderId} )
                    </h2>
                    <span>
                      วันที่สร้าง :{" "}
                      <span className="text-pink-500">
                        {data.order.cretedDate}
                      </span>
                    </span>
                    <span>
                      วันที่เช่า/คืน :{" "}
                      <span className="text-pink-500">
                        {data.order.startDate} - {data.order.endDate}
                      </span>
                    </span>
                    <span>
                      ขนส่ง :{" "}
                      <span className="text-pink-500">
                        {data.order.transportName}
                      </span>
                    </span>
                    <span>
                      เลขพัสดุ :{" "}
                      <span className="text-pink-500">
                        {data.order.tackingNumber}
                      </span>
                    </span>
                    <span>
                      หมายเหตุ :{" "}
                      <span className="text-pink-500">
                        {data.order.orderRemark}
                      </span>
                    </span>
                  </div>
                </div>
                <hr className="mt-2" />
                <div id="orderDetail" className="mt-2">
                  <table className="w-full table-auto text-xs">
                    <thead className="bg-gray-300">
                      <tr>
                        <th className="py-1">ลำดับ</th>
                        <th className="py-1">สินค้า</th>
                        <th className="py-1">จำนวน</th>
                        <th className="py-1">ค่ามัดจำ</th>
                        <th className="py-1">ราคา</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.order.orderDetails.map((detail, index) => (
                        <tr key={index} className="text-center my-1">
                          <td>{index + 1}.</td>
                          <td>{detail.detailName}</td>
                          <td>{detail.quantity}</td>
                          <td>{detail.depositPrice}</td>
                          <td>{detail.price}</td>
                        </tr>
                      ))}
                    </tbody>
                    <div></div>
                    <tfoot className="bg-gray-300">
                      <tr>
                        <th></th>
                        <th></th>
                        <th>รวม</th>
                        <th className="py-1">
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) =>
                              accumulator + detail.depositPrice,
                            0
                          )}`}
                        </th>
                        <th>
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) => accumulator + detail.price,
                            0
                          )}`}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="my-4">
                    <Card className="p-4">
                      <Tabs value="1">
                        <TabsHeader>
                          <Tab value="1">สลิปลูกค้าโอนเงิน</Tab>
                          <Tab value="2">รูปสินค้าก่อนส่ง</Tab>
                          <Tab value="3">รูปสินค้าคืนจากลูกค้า</Tab>
                          <Tab value="4">สลิปโอนมัดจำ</Tab>
                        </TabsHeader>
                        <TabsBody>
                          <TabPanel value="1">
                            <div className="flex justify-center gap-2">
                              {data.order.paySlip.images &&
                              data.order.paySlip.images.length > 0 ? (
                                data.order.paySlip.images.map((data, index) => (
                                  <img
                                    key={index}
                                    src={data.filePath}
                                    alt={`Payslip Image ${index}`}
                                    className=" w-48"
                                  />
                                ))
                              ) : (
                                <span>ยังไม่มีภาพสลิป</span>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel value="2">
                            <div className="flex justify-center gap-2">
                              {data.order.sendProductImage.images &&
                              data.order.sendProductImage.images.length > 0 ? (
                                data.order.sendProductImage.images.map(
                                  (data, index) => (
                                    <img
                                      key={index}
                                      src={data.filePath}
                                      alt={`Payslip Image ${index}`}
                                      className=" w-48"
                                    />
                                  )
                                )
                              ) : (
                                <span>ยังไม่มีภาพ</span>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel value="3">
                            <div className="flex justify-center gap-2">
                              {data.order.returnProductImage.images &&
                              data.order.returnProductImage.images.length >
                                0 ? (
                                data.order.returnProductImage.images.map(
                                  (data, index) => (
                                    <img
                                      key={index}
                                      src={data.filePath}
                                      alt={`Payslip Image ${index}`}
                                      className=" w-48"
                                    />
                                  )
                                )
                              ) : (
                                <span>ยังไม่มีภาพ</span>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel value="4">
                            <div className="flex justify-center gap-2">
                              {data.order.refundSlip.images &&
                              data.order.refundSlip.images.length > 0 ? (
                                data.order.refundSlip.images.map(
                                  (data, index) => (
                                    <img
                                      key={index}
                                      src={data.filePath}
                                      alt={`Payslip Image ${index}`}
                                      className=" w-48"
                                    />
                                  )
                                )
                              ) : (
                                <span>ยังไม่มีภาพสลิป</span>
                              )}
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </Tabs>
                    </Card>
                  </div>
                </div>
              </div>
            ),
          }}
          expandableIcon={{
            expanded: <FontAwesomeIcon icon={faMinusSquare} />,
            collapsed: <FontAwesomeIcon icon={faPlusSquare} />,
          }}
        />
      </div>
      <Dialog
        open={openEditOrder}
        handler={handleOpenEditOrder}
        size="xxl"
        className="flex flex-col overflow-scroll items-center"
      >
        <OrderDetailCard
          selectedOrderID={selectedOrderID}
          closeDialog={handleOpenEditOrder}
        />
      </Dialog>
      <Dialog open={openStep01} handler={handleOpenStep01} size="xxl">
        {selectedRow && (
          <Step23submitCard
            row={selectedRow}
            closeChagestepDialog={handleOpenStep01}
          />
        )}
      </Dialog>
    </>
  );
};

export default Processing;
