import Select from "react-select";
import { useState, useEffect } from 'react';
import { GetShopIdList } from '../../hooks/GetShopId';
import { ShopIdTypes } from '../../types/ShopIDTypes';

interface ShopIdSelectorProps {
  onShopIdSelect: (shopId: ShopIdTypes | null) => void;
}

const ShopIdSelector: React.FC<any> = (props) => {
  const [shopId, setShopId] = useState<ShopIdTypes[]>([]);
 

  useEffect(() => {
    const fetchShopIdList = async () => {
      const fetchShopIdList = await GetShopIdList();
      setShopId(fetchShopIdList);
    }
    fetchShopIdList();
  }, []);

  return (
    <div>
      <div className="text-xs">
        <Select
       {...props} 
          options={shopId.map(({ id, shopName }) => ({
            value: id,
            label: shopName,
          }))}
          placeholder="เลือกที่อยู่ร้าน"
        />
      </div>
    </div>
  )
}

export default ShopIdSelector;