import { useState, useEffect, useCallback } from "react";
import { OrderResponse } from "../../types/OrderResponseTypes";
import { SelectOrderByID, UpdateDetailChange } from "../../hooks/order";
import {
  Card,
  Input,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Textarea,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Select,
  Option,
  IconButton,
} from "@material-tailwind/react";
import TranspotSelector from "../Transport/TranspotSelector";
import ShopIdSelector from "../shop/ShopIdSelector";
import Emtyimage from "../../images/No-image-found.jpg";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/th";
import { th } from "date-fns/locale";
import AddressSelector from "../AddressSelector";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { updateOrderTypes } from "../../types/UpdateOrderTypes";
import { useStore } from "../../hooks/store";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

function Icon({ id, open }: { id: number; open: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

interface OderdetailcardTypes {
  selectedOrderID: number | null;
  closeDialog: () => void;
}

const OrderDetailCard: React.FC<OderdetailcardTypes> = ({
  selectedOrderID,
  closeDialog,
}) => {
  const [orderResponseDetail, setOrderResponseDetail] =
    useState<OrderResponse | null>(null);
  const { username } = useStore();
  const [shopLabel, setShopLabel] = useState<string | null>(null);
  const [transportLabel, setTransportLabel] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<updateOrderTypes>({
    defaultValues: {
      sendDate: "",
      orderRemark: "",
      statusPading: null,
      tranSportId: null,
      shopId: null,
      updateBy: username,
      orderDetails: [
        {
          id: null,
          detailName: "",
          quantity: null,
          price: null,
          depositPrice: null,
          src: null,
          updateBy: username,
        },
      ],
      custommer: {
        custommerFirstName: "",
        custommerLastName: "",
        telSend: "",
        address: "",
        zipCode: null,
        province: null,
        district: null,
        subDistrict: null,
        facebook: "",
        IG: "",
        line: "",
        phone: "",
        updateBy: username,
      },
      tacking: {
        tackingNumber: "",
      },
    },
  });

  const { fields } = useFieldArray({
    name: "orderDetails",
    control,
  });

  const BookingDetail = useCallback(async () => {
    try {
      setValue(
        "custommer.custommerFirstName",
        orderResponseDetail?.order.custommerFirstName ?? ""
      );
      setValue(
        "custommer.custommerLastName",
        orderResponseDetail?.order.custommerLastName ?? ""
      );
      setValue("custommer.address", orderResponseDetail?.order.address ?? "");
      setValue("custommer.telSend", orderResponseDetail?.order.telSend ?? "");
      setValue("custommer.zipCode", orderResponseDetail?.order.zipCode ?? null);
      setValue("custommer.phone", orderResponseDetail?.order.phone ?? "");
      setValue("custommer.facebook", orderResponseDetail?.order.facebook ?? "");
      setValue("custommer.IG", orderResponseDetail?.order.IG ?? "");
      setValue("custommer.line", orderResponseDetail?.order.line ?? "");
      setValue("orderRemark", orderResponseDetail?.order.orderRemark ?? "");
      setValue(
        "tacking.tackingNumber",
        orderResponseDetail?.order.tacking ?? ""
      );
      setValue("sendDate", orderResponseDetail?.order.sendDate ?? "");
      setValue("tranSportId", orderResponseDetail?.order.tranSport.id ?? null);
      setValue("shopId", orderResponseDetail?.order.shop.id ?? null);
      setValue("statusPading", orderResponseDetail?.order.statusPading ?? null);
      setValue(
        "custommer.province",
        orderResponseDetail?.order.province.id ?? null
      );
      setValue(
        "custommer.district",
        orderResponseDetail?.order.District.id ?? null
      );
      setValue(
        "custommer.subDistrict",
        orderResponseDetail?.order.subDistrict.id ?? null
      );
      setValue("orderDetails", orderResponseDetail?.order?.orderDetails);
    } catch (error) {
      console.log("Error From Article Detail", error);
    }
  }, [orderResponseDetail, setValue]);

  useEffect(() => {
    BookingDetail();
  }, [BookingDetail]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await SelectOrderByID(Number(selectedOrderID));
      setOrderResponseDetail(result);
    };

    fetchData();
  }, [selectedOrderID]);

  const [open, setOpen] = useState(0);

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const formatDateToThai = (dateString: string | null) => {
    if (!dateString) return "";

    moment.locale("th"); // Set moment's locale to Thai
    return moment(dateString).format("LL"); // 'LL' is a predefined format for moment that translates to something like "1 มกราคม 2566"
  };

  const firstSubmi = async (data: updateOrderTypes) => {
    try {
      const response = await UpdateDetailChange(Number(selectedOrderID), data);
      if (response.status === true) {
        Swal.fire({
          icon: "success",
          title: "สำเร็จ",
          text: "บันทึกการเปลี่ยนแปลงสำเร็จ",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.log("Error from Submit", error);
    }
  };

  const _onSubmit: SubmitHandler<updateOrderTypes> = async (data) => {
    Swal.fire({
      icon: "question",
      title: "อัพเดทข้อมูลสินค้า",
      text: "คุณได้ตรวจสอบข้อมูลถูกต้องและต้องการอัพเดทข้อมูลใช่หรือไม่",
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่ใช่",
      allowOutsideClick: false, // Don't close on outside click
      allowEscapeKey: false, // Don't close on ESC key
      allowEnterKey: false, // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        firstSubmi(data);
      }
    });
  };

  const OrderStatus = [
    { value: 1, label: "ชำระเงินแล้ว" },
    { value: 2, label: "กำลังเตรียมสินค้า" },
    { value: 3, label: "ส่งสินค้าแล้ว" },
    { value: 4, label: "รอรับสินค้าคืน" },
    { value: 5, label: "ตรวจสอบ" },
    { value: 6, label: "รอโอนเงิน" },
    { value: 7, label: "เรียบร้อย" },
  ];

  const [datevalue, setdateValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue:any) => {
    console.log("newValue:", newValue);
    setdateValue(newValue);
  };

  return (
    <>
      <div className="lg:p-8 lg:flex  flex flex-col flex-wrap p-4 lg:w-[800px]">
        <div className="flex justify-between">
          <span className="font-semibold text-pink-500">
            แก้ไขข้อมูลการเช่า
          </span>
        </div>

        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className="gap-2 border-2 p-4 rounded-xl relative ">
            <div className=" absolute lg:-right-3 lg:-top-5 -top-5 -right-2">
              <IconButton className="bg-white text-gray-600 text-xl rounded-full border ">
                {" "}
                <FontAwesomeIcon icon={faX} onClick={closeDialog} />
              </IconButton>
            </div>
            <span className="font-semibold text-blue-500 text-sm">
              ข้อมูลทั่วไป
            </span>
            <div className="lg:grid lg:grid-cols-2 lg:gap-3 lg:my-2 bg-gray-300/50 lg:p-3 rounded-lg flex flex-col gap-2 p-2">
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="Order ID"
                  value={orderResponseDetail?.order.orderId ?? ""}
                  color="pink"
                  readOnly
                />
              </div>
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="หมายเลขคำสั่งซื้อ"
                  value={orderResponseDetail?.order.refNumber}
                  color="pink"
                  readOnly
                />
              </div>
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="วันเช่าชุด"
                  value={formatDateToThai(
                    orderResponseDetail?.order.startDate || null
                  )}
                  color="pink"
                  readOnly
                />
              </div>
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="วันคืนชุด"
                  value={formatDateToThai(
                    orderResponseDetail?.order.endDate || null
                  )}
                  color="pink"
                  readOnly
                />
              </div>
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="ผู้รับ Order"
                  defaultValue={orderResponseDetail?.order?.cretedBy}
                  color="pink"
                  readOnly
                />
              </div>

              <div>
                <Input
                  crossOrigin="anonymous"
                  label="วันที่สร้างคำสั่ง"
                  value={orderResponseDetail?.order?.cretedDate}
                  color="pink"
                  readOnly
                />
              </div>
            </div>
            <div>
              {orderResponseDetail?.order.shop.id === 9999 &&
                orderResponseDetail?.order.tranSport.id === 9999 && (
                  <span className="text-red-500 text-center">
                    กรุณาเลือกสาขา และ การขนส่ง
                  </span>
                )}
            </div>
            <div className="lg:grid lg:grid-cols-2 gap-2 my-2 flex flex-col">
              <div>
                <label htmlFor="วันส่งสินค้า" className="text-xs">
                  วันส่งสินค้า
                </label>
                <Controller
                  name="sendDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date: Date | null) => {
                        field.onChange(moment(date).format("YYYY-MM-DD"));
                      }}
                      onBlur={field.onBlur}
                      locale={th}
                      dateFormat="dd MMMM yyyy"
                      placeholderText="วันส่งสินค้า"
                      className=" p-2.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-xs border-[1px] border-gray-300 rounded-md"
                    />
                  )}
                />
              </div>

              <div>
                <label htmlFor="ขนส่ง" className="text-xs ">
                  การจัดส่ง
                </label>
                <Controller
                  name="tranSportId"
                  control={control}
                  render={({ field }) => (
                    <TranspotSelector
                      value={{
                        label:
                          transportLabel ||
                          (field.value as any)?.transportName ||
                          orderResponseDetail?.order.tranSport.transportName,
                      }}
                      onChange={(
                        selectedOption: { value: number; label: string } | null
                      ) => {
                        setTransportLabel(selectedOption?.label || null); // Update the local state for label
                        field.onChange(selectedOption?.value); // Update the value in the form
                      }}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>

              <div className="-mt-3">
                <label htmlFor="shop" className="text-xs">
                  สาขา
                </label>
                <Controller
                  name="shopId"
                  control={control}
                  render={({ field }) => (
                    <ShopIdSelector
                      value={{
                        label:
                          shopLabel ||
                          (field.value as any)?.shopName ||
                          orderResponseDetail?.order.shop.shopName,
                      }}
                      onChange={(
                        selectedOption: { value: number; label: string } | null
                      ) => {
                        setShopLabel(selectedOption?.label || null);
                        field.onChange(selectedOption?.value);
                      }}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>
              <div></div>
              <div>
                <Input
                  crossOrigin="anonymous"
                  label="เลขพัสดุ"
                  {...register("tacking.tackingNumber")}
                />
              </div>
              {orderResponseDetail?.order.statusPading != 0 ? (
                <div>
                  <Controller
                    name="statusPading"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="สถานะ"
                        value={field.value ? field.value.toString() : ""} // Convert the number value to a string
                        onChange={(value?: string) => {
                          if (value) {
                            field.onChange(Number(value));
                          }
                        }}
                        onBlur={field.onBlur}
                      >
                        {OrderStatus.map((status) => (
                          <Option
                            key={status.value.toString()}
                            value={status.value.toString()}
                          >
                            {status.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              ) : null}
            </div>
            <span className="font-semibold text-blue-500 text-sm">
              ข้อมูลสินค้า
            </span>
            <div className="my-2">
              {fields.map((field, index) => (
                <Accordion
                  key={field.id}
                  open={open === index + 1}
                  icon={<Icon id={index + 1} open={open} />}
                >
                  <AccordionHeader
                    className="text-sm"
                    onClick={() => handleOpen(index + 1)}
                  >
                    <span className="text-pink-300">
                      {index + 1}. {field.detailName}
                    </span>
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="lg:grid lg:grid-cols-2 gap-4 justify-items-center flex flex-col">
                      <div>
                        <img
                          src={field.src === null ? Emtyimage : field.src}
                          className="lg:w-[50%]"
                        />
                      </div>
                      <div className="flex flex-col gap-3 justify-center">
                        <Input
                          crossOrigin="anonymous"
                          label="จำนวน"
                          {...register(`orderDetails.${index}.quantity`)}
                          readOnly
                        />
                        <Input
                          crossOrigin="anonymous"
                          label="ราคา"
                          {...register(`orderDetails.${index}.price`, {
                            required: true,
                          })}
                        />
                        {errors?.orderDetails?.[index]?.price && (
                          <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                            กรุณากรอกราคา
                          </div>
                        )}
                        <Input
                          crossOrigin="anonymous"
                          label="ค่ามัดจำ"
                          {...register(`orderDetails.${index}.depositPrice`, {
                            required: true,
                          })}
                        />
                        {errors?.orderDetails?.[index]?.depositPrice && (
                          <div className="flex col-12 justify-content-center align-items-center text-red-500 text-sm m-0 p-0">
                            กรุณากรอกค่ามัดจำ
                          </div>
                        )}
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>
              ))}
            </div>
            <span className="font-semibold text-blue-500 text-sm">
              ข้อมูลลูกค้า
            </span>
            <div className="lg:grid lg:grid-cols-2 gap-4 my-4 flex flex-col">
              <Input
                crossOrigin="anonymous"
                label="ชื่อ"
                {...register("custommer.custommerFirstName")}
              />
              <Input
                crossOrigin="anonymous"
                label="นามสกุล"
                {...register("custommer.custommerLastName")}
              />
              <Input
                crossOrigin="anonymous"
                label="ที่อยู่"
                {...register("custommer.address")}
              />
              <Input
                crossOrigin="anonymous"
                label="รหัสไปรษณีย์"
                {...register("custommer.zipCode")}
              />
            </div>
            <div>
              <div>
                <AddressSelector
                  ProviceValue={{
                    label: orderResponseDetail?.order?.province.provinceName,
                    value: orderResponseDetail?.order?.province.id,
                  }}
                  SubDistrictValue={{
                    label:
                      orderResponseDetail?.order.subDistrict.subDistrictName,
                    value: orderResponseDetail?.order?.subDistrict.id,
                  }}
                  DistrictValue={{
                    label: orderResponseDetail?.order.District.districtName,
                    value: orderResponseDetail?.order?.District.id,
                  }}
                  onProvinceSelect={(e: any) => {
                    setValue("custommer.province", e.id);
                  }}
                  onDistrictSelect={(e: any) => {
                    setValue("custommer.district", e.id);
                  }}
                  onSubdistrictSelect={(e: any) => {
                    setValue("custommer.subDistrict", e.id);
                  }}
                />
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 gap-4 my-4 flex flex-col">
              <Input
                crossOrigin="anonymous"
                label="เบอร์โทรผู้รับสินค้า"
                {...register("custommer.telSend")}
              />
              <Input
                crossOrigin="anonymous"
                label="เบอร์โทรรับ OTP"
                {...register("custommer.phone")}
              />
              <Input
                crossOrigin="anonymous"
                label="Facebook"
                {...register("custommer.facebook")}
              />
              <Input
                crossOrigin="anonymous"
                label="IG"
                {...register("custommer.IG")}
              />
              <Input
                crossOrigin="anonymous"
                label="Line"
                {...register("custommer.line")}
              />
            </div>

            <Textarea
              variant="outlined"
              label="หมายเหตุ"
              {...register("orderRemark")}
            />
            <span className="font-semibold text-blue-500 text-sm">
              สลิปและรูปภาพ
            </span>
            <div className="my-2">
              <Tabs value="1">
                <TabsHeader>
                  <Tab value="1" className="text-xs">
                    สลิปลูกค้าโอนเงิน
                  </Tab>
                  <Tab value="2" className="text-xs">
                    รูปสินค้าก่อนส่ง
                  </Tab>
                  <Tab value="3" className="text-xs">
                    รูปสินค้าคืนจากลูกค้า
                  </Tab>
                  <Tab value="4" className="text-xs">
                    สลิปโอนมัดจำ
                  </Tab>
                </TabsHeader>
                <TabsBody>
                  <TabPanel value="1">
                    <div className="flex flex-wrap overflow-y-scroll">
                      {orderResponseDetail?.order?.paySlip?.images &&
                      orderResponseDetail?.order?.paySlip?.images.length > 0 ? (
                        orderResponseDetail?.order?.paySlip?.images.map(
                          (image, index) => (
                            <img
                              key={index}
                              src={image.filePath}
                              alt={`Payslip Image ${index}`}
                              className=" lg:w-48 w-[50%]"
                            />
                          )
                        )
                      ) : (
                        <span>ยังไม่มีภาพสลิป</span>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="flex flex-wrap overflow-y-scroll">
                      {orderResponseDetail?.order?.sendProductImage?.images &&
                      orderResponseDetail?.order?.sendProductImage?.images
                        .length > 0 ? (
                        orderResponseDetail?.order?.sendProductImage?.images.map(
                          (sendimage, index) => (
                            <img
                              key={index}
                              src={sendimage.filePath}
                              className="w-48 "
                            />
                          )
                        )
                      ) : (
                        <span>ยังไม่มีรุปภาพ</span>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className="flex flex-wrap overflow-y-scroll">
                      {orderResponseDetail?.order?.returnProductImage?.images &&
                      orderResponseDetail?.order?.returnProductImage?.images
                        .length > 0 ? (
                        orderResponseDetail?.order?.returnProductImage?.images.map(
                          (returnimage, index) => (
                            <img
                              key={index}
                              src={returnimage.filePath}
                              className="w-48 "
                            />
                          )
                        )
                      ) : (
                        <span>ยังไม่มีรุปภาพ</span>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="4">
                    <div className="flex flex-wrap overflow-y-scroll">
                      {orderResponseDetail?.order?.refundSlip.images &&
                      orderResponseDetail?.order.refundSlip.images.length >
                        0 ? (
                        orderResponseDetail?.order?.refundSlip?.images.map(
                          (refundSlip, index) => (
                            <img
                              key={index}
                              src={refundSlip.filePath}
                              className="w-48 "
                            />
                          )
                        )
                      ) : (
                        <span>ยังไม่มีรุปภาพ</span>
                      )}
                    </div>
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </div>
            <div className="flex justify-end">
              <Button color="pink" type="submit">
                {" "}
                บันทึกข้อมูล{" "}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OrderDetailCard;
