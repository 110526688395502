import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { GetDailySumrepay } from '../../hooks/Report';
import { useEffect } from 'react';

const DailySumrepayCard = () => {
    const [DailySumrepay, setDailySumrepay] = useState<{ dayliSumrepay?: number }>({});
    const numberFormat = Intl.NumberFormat('en-US');

    const sumRepayData = async () => {
        const resSumdata = await GetDailySumrepay();
        setDailySumrepay(resSumdata);
    };
    useEffect(() => {
        sumRepayData();
      }, []);

    return (
        <>
            <div className='flex justify-around gap-2 lg:p-8 p-4 lg:flex lg:gap-4 lg:justify-between items-center text-white lg:w-64'>
                <div>
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className='text-3xl lg:text-5xl text-white' />
                </div>
                <div className='text-center'>
                <h2>ยอดค้างคืนมัดจำ</h2>
                <span className='text-3xl font-bold'>{numberFormat.format(DailySumrepay.dayliSumrepay || 0)}</span>
                </div>
            </div>
        </>

    )
}

export default DailySumrepayCard