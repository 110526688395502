import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { faCamera,faRightFromBracket, faBell, faGaugeHigh, faCartPlus, faHome, faInbox, faMoneyBillTransfer, faArrowsSpin, faTruckFast, faArrowsLeftRight, faClockRotateLeft, faHandHoldingDollar, faCircleCheck, faUsers, faGears, faFileInvoiceDollar, faCalendar, faSms, faNewspaper, faPlusCircle, faChartSimple, faClock, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "../hooks/store";
import { useNavigate, useLocation } from "react-router-dom";


const SideNav = () => {

  const { role } = useStore();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  // logout
  const { clearSession } = useStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearSession();
    navigate("/");
  };

  return (
    <Sidebar breakPoint="md" backgroundColor={"white"} width={"300px"} className="h-screen shadow-lg">
      <div className="my-5 mx-5 flex flex-col items-center">
        <div>
          <img src="https://www.เช่าชุดเชียงใหม่.com/wp-content/uploads/2022/06/cm_fashion-2022Logo.png" className=" w-48 mb-4 " />
        </div>
        <div className="flex flex-col px-4">
          <Menu className="pt-8">
            <span className="text-xs font-bold">ทั่วไป</span>
            <hr className="my-2" />
            {role === "admin" && (
              <MenuItem component={<Link to={'/ownerdashboard'} />} icon={<FontAwesomeIcon icon={faGaugeHigh} className=" text-pink-300 text-xl" />} className={isActive('/ownerdashboard') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ภาพรวมเจ้าของร้าน</MenuItem>
            )}
            {role === "admin" && (
              <MenuItem component={<Link to={'/adminchecktimecard'} />} icon={<FontAwesomeIcon icon={faCircleCheck} className=" text-pink-300 text-xl" />} className={isActive('/adminchecktimecard') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>อนุมัติเวลาทำงาน</MenuItem>
            )}
             {role === "admin" && (
              <MenuItem component={<Link to={'/workandotreport'} />} icon={<FontAwesomeIcon icon={faChartLine} className=" text-pink-300 text-xl" />} className={isActive('/workandotreport') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รายงานการทำงาน / OT</MenuItem>
            )}
            {role !== "admin" && (
              <MenuItem component={<Link to={'/dashboard'} />} icon={<FontAwesomeIcon icon={faGaugeHigh} className=" text-pink-300 text-xl" />} className={isActive('/dashboard') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ภาพรวม</MenuItem>
            )}
            {role !== "admin" && (
            <MenuItem component={<Link to={'/checkin'} />} icon={<FontAwesomeIcon icon={faClock} className=" text-pink-300 text-xl" />} className={isActive('/checkin') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ลงเวลา เข้า-ออก</MenuItem>
            )}

            <MenuItem component={<Link to={'/neworderform'} />} icon={<FontAwesomeIcon icon={faCartPlus} className=" text-pink-300 text-xl" />} className={isActive('/neworderform') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>เพิ่มคำสั่งซื้อใหม่</MenuItem>
            <SubMenu label="แจ้งเตือน" icon={<FontAwesomeIcon icon={faBell} className=" text-pink-300 text-xl" />}>
              <MenuItem component={<Link to={'/calendar'} />} icon={<FontAwesomeIcon icon={faCalendar} className=" text-pink-300 text-xl" />} className={isActive('/calendar') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ปฏิทิน</MenuItem>
              <MenuItem component={<Link to={'/smsreport'} />} icon={<FontAwesomeIcon icon={faSms} className=" text-pink-300 text-xl" />} className={isActive('/smsreport') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รายงาน SMS</MenuItem>
              <MenuItem component={<Link to={'/TransferReport'} />} icon={<FontAwesomeIcon icon={faFileInvoiceDollar} className=" text-pink-300 text-xl" />} className={isActive('/TransferReport') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รายงานยอดเงิน</MenuItem>
              <MenuItem component={<Link to={'/Static'} />} icon={<FontAwesomeIcon icon={faChartSimple} className=" text-pink-300 text-xl" />} className={isActive('/Static') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>สถิตการเช่าชุด</MenuItem>
            </SubMenu>

            <div className="my-4">
              <span className="text-xs font-bold">Studio</span>
              <hr className="my-2 " />
              <MenuItem component={<Link to={'/studiobooking'} />} icon={<FontAwesomeIcon icon={faCamera} className=" text-pink-300 text-xl" />} className={isActive('/studiobooking') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>จองคิว Studio</MenuItem>
              <MenuItem component={<Link to={'/studiodetail'} />} icon={<FontAwesomeIcon icon={faClock} className=" text-pink-300 text-xl" />} className={isActive('/studiodetail') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>คิวงาน Studio</MenuItem>

            </div>
            <div>
              <span className="text-xs font-bold">คำสั่งซื้อตามสถานะ</span>
              <hr className="my-2 " />

              <MenuItem component={<Link to={'/allorder'} />} icon={<FontAwesomeIcon icon={faHome} className=" text-pink-300 text-xl" />} className={isActive('/allorder') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>คำสั่งซื้อทั้งหมด</MenuItem>
              <MenuItem component={<Link to={'/onhold'} />} icon={<FontAwesomeIcon icon={faInbox} className=" text-pink-300 text-xl" />} className={isActive('/onhold') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>On Hold  </MenuItem>
              <MenuItem component={<Link to={'/paid'} />} icon={<FontAwesomeIcon icon={faMoneyBillTransfer} className=" text-pink-300 text-xl" />} className={isActive('/paid') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ชำระเงินแล้ว</MenuItem>
              <MenuItem component={<Link to={'/processing'} />} icon={<FontAwesomeIcon icon={faArrowsSpin} className=" text-pink-300 text-xl" />} className={isActive('/processing') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>กำลังเตรียมสินค้า</MenuItem>
              <MenuItem component={<Link to={'/shiped'} />} icon={<FontAwesomeIcon icon={faTruckFast} className=" text-pink-300 text-xl" />} className={isActive('/shiped') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ส่งสินค้าแล้ว </MenuItem>
              <MenuItem component={<Link to={'/returnorder'} />} icon={<FontAwesomeIcon icon={faArrowsLeftRight} className=" text-pink-300 text-xl" />} className={isActive('/returnorder') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รอรับสินค้าคืน </MenuItem>
              <MenuItem component={<Link to={'/checking'} />} icon={<FontAwesomeIcon icon={faClockRotateLeft} className=" text-pink-300 text-xl" />} className={isActive('/checking') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ตรวจสอบ </MenuItem>
              <MenuItem component={<Link to={'/waiting'} />} icon={<FontAwesomeIcon icon={faHandHoldingDollar} className=" text-pink-300 text-xl" />} className={isActive('/waiting') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รอโอนมัดจำ </MenuItem>
              <MenuItem component={<Link to={'/refunddetail'} />} icon={<FontAwesomeIcon icon={faFileInvoiceDollar} className=" text-pink-300 text-xl" />} className={isActive('/refunddetail') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ข้อมูลคืนมัดจำลูกค้า </MenuItem>
              <MenuItem component={<Link to={'/success'} />} icon={<FontAwesomeIcon icon={faCircleCheck} className=" text-pink-300 text-xl" />} className={isActive('/success') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>เรียบร้อย </MenuItem>
              <MenuItem component={<Link to={'/customer'} />} icon={<FontAwesomeIcon icon={faUsers} className=" text-pink-300 text-xl" />} className={isActive('/customer') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รายละเอียดลูกค้า</MenuItem>

            </div>

            {role === "admin" && (
              <div>
                <span className="text-xs font-bold">ตั้งค่า</span>
                <hr className="my-2 " />
                <SubMenu label="จัดการ Admin" icon={<FontAwesomeIcon icon={faUsers} className=" text-pink-300 text-xl" />}>
                  <MenuItem component={<Link to={'/AdminList'} />} icon={<FontAwesomeIcon icon={faCircleCheck} className=" text-pink-300 text-xl" />} className={isActive('/AdminList') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>รายการแอดมิน</MenuItem>
                </SubMenu>
                <SubMenu label="เงื่อนไขการเช่าชุด" icon={<FontAwesomeIcon icon={faNewspaper} className=" text-pink-300 text-xl" />}>
                  <MenuItem component={<Link to={'/AddNewCondition'} />} icon={<FontAwesomeIcon icon={faPlusCircle} className=" text-pink-300 text-xl" />} className={isActive('/AddNewCondition') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>เพิ่มใหม่</MenuItem>
                </SubMenu>
                <MenuItem component={<Link to={'/pushbullet'} />} icon={<FontAwesomeIcon icon={faSms} className=" text-pink-300 text-xl" />} className={isActive('/pushbullet') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>PushBullet</MenuItem>
                <MenuItem component={<Link to={'/setting'} />} icon={<FontAwesomeIcon icon={faGears} className=" text-pink-300 text-xl" />} className={isActive('/setting') ? 'bg-pink-100 rounded-full text-white shadow-lg' : ''}>ตั้งค่าระบบ</MenuItem>
              </div>
            )}


          </Menu>

          <div className="my-4">
            <Button className="flex items-center gap-2 w-52" color="pink" onClick={handleLogout}>
              <FontAwesomeIcon icon={faRightFromBracket} className="text-lg" />
              ออกจากระบบ
            </Button>
          </div>
        </div>
      </div>

    </Sidebar >
  );
};

export default SideNav;
