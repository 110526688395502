import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../hooks/store';

const AdminDailyOrderCard = () => {
    const { adminQuantity } = useStore();

    return (
        <>
            <div className='flex justify-around gap-2 lg:p-8 p-4 lg:flex lg:gap-4 lg:justify-between items-center text-white lg:w-64'>
                <div>
                    <FontAwesomeIcon icon={faCoins} className='text-3xl lg:text-5xl text-white' />
                </div>
                <div className='text-center'>
                    <h2>จำนวนออร์เดอร์</h2>
                    <span className='text-3xl font-bold'>{adminQuantity}</span>
                </div>
            </div>
        </>

    )
}

export default AdminDailyOrderCard