import React, { useState, useEffect } from "react";
import { OnHoldOders, GetOrder, GetOrder2 } from "../hooks/order";
import DataTable, { TableColumn } from "react-data-table-component";
import ExpandableComponent from "../components/ExpandableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare, faPaperPlane, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import ActionDropdown from "../components/ActionDropdown";
import { Dialog, Button, Input, Select, Option } from "@material-tailwind/react";
import OrderDetailCard from "../components/Oder/OrderDetailCard";
import Step01submitCard from "../components/Step/Step01submitCard";
import SendOnholdSMS from "../components/sms/SendOnholdSMS";
import { GetShopIdList } from '../hooks/GetShopId';
import { ShopIdTypes } from "../types/ShopIDTypes";
import Swal from "sweetalert2";

const OnHold = () => {
  const [Oderlist, setupOderList] = React.useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalItems, setTotalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  // const numRefNumber = parseFloat(refNumber || "0");
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);

  const fetchData = async (page: number) => {
    const dataOnHoldOrder: OrderResponse = await OnHoldOders(page, perPage, search, numSelectedShop);
    const formattedData = dataOnHoldOrder.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setupOderList(formattedData);
    setCurrentPage(dataOnHoldOrder.page);
    setTotalItems(dataOnHoldOrder.total);
    setPerPage(dataOnHoldOrder.per_page);
  };

  /////////////////////////
  const [selectedRow, setSelectedRow] = useState<OrderTableRow | null>(null);
  const [selectedRowData, setSelectedRowdata] = useState<OrderTableRow | null>(null);

  ////////////////////////

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
    fetchData(currentPage);

  }, [currentPage, perPage, search, selectedShop]);

  const columns: TableColumn<OrderTableRow>[] = [
    {
      name: "ลำดับ",
      selector: (row) => row.Rows,
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => row.orderId,
    },
    {
      name: "คำสั่งซื้อ",
      selector: (row) => row.refNumber,
    },
    {
      name: "ชื่อ-นามสกุล",
      cell: (row) => (
        <div>
          {row.custommerFirstName} {row.custommerLastName}
        </div>
      ),
    },
    {
      name: "วันที่สร้าง",
      selector: (row) => row.cretedDate,
      sortable: true,
    },
    {
      name: "วันที่ส่ง",
      selector: (row) => row.sendDate,
      sortable: true,
    },
    {
      name: "เช่า-ถึง",
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-xs text-green-500">{row.order.startDate}</span>
          <span className="text-xs text-red-500">{row.endDate}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <ActionDropdown
            openEditOderDetail={handleOpenEditOrder}
            row={row}
            setSelectedOderId={setSelectedOderId}
            openStep01Dialog={handleOpenStep01}
            setSelectedRow={setSelectedRow}
          />
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        row.order.wpSumprice !== null ? <FontAwesomeIcon icon={faPaperPlane} className="text-xl" onClick={() => { handleOpenSendsms(); setSelectedRowdata(row) }} /> : null
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนแถวต่อ 1 หน้า",
    rangeSeparatorText: "ถึง",
  };

  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    setResetKey(prevKey => prevKey + 1);
  };

  // getOrder Function//
  const ManualGet = async () => {
    const manualresponse = await GetOrder();
    if (manualresponse.length === 0) {
      Swal.fire('ไม่พบคำสั่งซื้อใหม่')
    } {
      Swal.fire({
        title: 'ดึงข้อมูลสำเร็จ',
        confirmButtonText: 'ตกลง',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      });
    }
  };

  const ManualGet2 = async () => {
    const manualresponse = await GetOrder2();
    if (manualresponse.length === 0) {
      Swal.fire('ไม่พบคำสั่งซื้อใหม่')
    } {
      Swal.fire({
        title: 'ดึงข้อมูลสำเร็จ',
        confirmButtonText: 'ตกลง',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      });
    }
  };

  const OnSubmit = async () => {
    Swal.fire({
      icon: 'question',
      title: 'ดึงข้อมูลใหม่',
      text: 'ท่านต้องการดึงข้อมูลด้วยตัวเองใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        ManualGet();
      }
    });
  };

  const OnSubmit2 = async () => {
    Swal.fire({
      icon: 'question',
      title: 'ดึงข้อมูลใหม่',
      text: 'ท่านต้องการดึงข้อมูลด้วยตัวเองใช่หรือไม่',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
      allowOutsideClick: false,    // Don't close on outside click
      allowEscapeKey: false,      // Don't close on ESC key
      allowEnterKey: false        // Don't close on Enter key
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        ManualGet2();
      }
    });
  };

  ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const handleOpenEditOrder = () => setOpenEditOrder(!openEditOrder);

  const [openStep01, setOpenStep01] = useState(false);
  const handleOpenStep01 = () => setOpenStep01(!openStep01);

  const [openSendsms, setOpenSendsms] = useState(false);
  const handleOpenSendsms = () => setOpenSendsms(!openSendsms);

  /////////////////////////////////////////////////
  //////////// ส่ง Prop เลือก ID ///////////////////

  const [selectedOrderID, setSelectedOderId] = useState<number | null>(null);
  ///////////////////////////////////////////////

  return (
    <>

      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center ">

        <div className="lg:grid lg:grid-cols-2 lg:gap-2 flex flex-col gap-2 ">
          <div className="hidden lg:block">
            <div className="flex justify-between">
              <Button onClick={OnSubmit}><FontAwesomeIcon icon={faRefresh} className="mr-2 text-md" /> ดึงข้อมูล</Button>
              <Button onClick={OnSubmit2} color="amber"><FontAwesomeIcon icon={faRefresh} className="mr-2 text-md" /> ดึงจาก Pending</Button>
            </div>

          </div>
          <div className="lg:flex lg:flex-row lg:gap-2 flex flex-col gap-2">
            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsearch(e.target.value)}
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ''}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>{shopdata.shopName}</Option>
              ))}
            </Select>
            <div className="flex flex-col gap-1 justify-center">
              <div className="lg:hidden flex flex-col gap-1">
                <Button onClick={OnSubmit}><FontAwesomeIcon icon={faRefresh} className="mr-2 text-md " /> ดึงข้อมูล</Button>
                <Button onClick={OnSubmit2} color="amber"><FontAwesomeIcon icon={faRefresh} className="mr-2 text-md" /> ดึงจาก Pending</Button>

              </div>

              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        </div>
        <hr className="my-2" />

        <DataTable
          columns={columns}
          data={Oderlist}
          pagination
          paginationServer
          paginationTotalRows={totalItems}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationComponentOptions}
          expandableRows
          expandableRowsComponent={ExpandableComponent as any}
          expandableRowsComponentProps={{
            renderContent: (data: OrderTableRow) => (
              <div className=" m-2 border-2 rounded-lg p-2">
                <span className="text-sm font-bold underline underline-offset-2">
                  หมายเลขคำสั่งซื้อ #{data.refNumber}
                </span>
                <div
                  id="generalOderDetail"
                  className="mt-2 lg:flex lg:justify-around"
                >
                  <div
                    id="customerDetail"
                    className="text-sm flex flex-col gap-1"
                  >
                    <h2 className="text-pink-700 font-bold underline">
                      ข้อมูลลูกค้า
                    </h2>
                    <span>
                      ชื่อผู้เช่า : <span className="text-pink-500">{data.custommerFirstName}{" "}
                        {data.custommerLastName}</span>
                    </span>
                    <span>
                      ที่อยู่ : <span className="text-pink-500">{data.order.address} {data.order.subDistrict}{" "}
                        {data.order.district} {data.order.province}{" "}
                        {data.order.zipCode}</span>
                    </span>
                    <span>เบอร์โทร : <span className="text-pink-500">{data.order.phone}</span></span>
                    <span>IG : <span className="text-pink-500">{data.order.IG}</span></span>
                    <span>Line : <span className="text-pink-500">{data.order.IG}</span></span>
                    <span>Facebook : <span className="text-pink-500">{data.order.facebook}</span></span>
                  </div>
                  <div id="rentDetail" className="text-sm flex flex-col gap-1">
                    <h2 className="text-pink-700 font-bold underline">
                      รายละเอียดการเช่า (OrderId #{data.order.orderId} )
                    </h2>
                    <span >
                      วันที่สร้าง : <span className="text-pink-500">{data.order.cretedDate}</span>
                    </span>
                    <span>
                      วันที่เช่า/คืน : <span className="text-pink-500">{data.order.startDate} -{" "}
                        {data.order.endDate}</span>
                    </span>
                    <span>สาขา : <span className="text-pink-500">{data.order.shopName}</span></span>
                    <span>ขนส่ง : <span className="text-pink-500">{data.order.transportName}</span></span>
                    <span>เลขพัสดุ : <span className="text-pink-500">{data.order.tackingNumber}</span></span>
                    <span>หมายเหตุ : <span className="text-pink-500">{data.order.orderRemark}</span></span>
                  </div>
                </div>
                <hr className="mt-2" />
                <div id="orderDetail" className="mt-2">
                  <table className="w-full table-auto text-xs">
                    <thead className="bg-gray-300">
                      <tr>
                        <th className="py-1">ลำดับ</th>
                        <th className="py-1">สินค้า</th>
                        <th className="py-1">จำนวน</th>
                        <th className="py-1">ค่ามัดจำ</th>
                        <th className="py-1">ราคา</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.order.orderDetails.map((detail, index) => (
                        <tr key={index} className="text-center my-1">
                          <td>{index + 1}.</td>
                          <td>{detail.detailName}</td>
                          <td>{detail.quantity}</td>
                          <td>{detail.depositPrice}</td>
                          <td>{detail.price}</td>
                        </tr>
                      ))}
                    </tbody>
                    <div></div>
                    <tfoot className="bg-gray-300">
                      <tr>
                        <th></th>
                        <th></th>
                        <th>รวม</th>
                        <th className="py-1">
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) =>
                              accumulator + detail.depositPrice,
                            0
                          )}`}
                        </th>
                        <th>
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) => accumulator + detail.price,
                            0
                          )}`}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            ),
          }}
          expandableIcon={{
            expanded: <FontAwesomeIcon icon={faMinusSquare} />,
            collapsed: <FontAwesomeIcon icon={faPlusSquare} />,
          }}

        />

      </div>
      <Dialog open={openEditOrder} handler={handleOpenEditOrder} size="xxl" className="flex flex-col overflow-scroll items-center">
        <OrderDetailCard selectedOrderID={selectedOrderID} closeDialog={handleOpenEditOrder} />
      </Dialog>
      <Dialog open={openStep01} handler={handleOpenStep01} size="xxl" className="flex flex-col overflow-scroll  items-center">
        {selectedRow && <Step01submitCard row={selectedRow} closeChagestepDialog={handleOpenStep01} />}
      </Dialog>

      <Dialog open={openSendsms} handler={handleOpenSendsms}>
        <SendOnholdSMS data={selectedRowData} closeChagestepDialog={handleOpenSendsms} />
      </Dialog>
    </>
  );
};

export default OnHold;
