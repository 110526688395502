import {
  Card,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { useState } from "react";
import ChecCredit from "../components/SMSSending/CheckCredit";
import SendingList from "../components/SMSSending/SendingList";

const SmsSendingSetting = () => {
  return (
    <>
      <Card className="my-4 p-8">
        <Tabs value="list">
          <TabsHeader>
            <Tab value="list">รายการ SMS Sending</Tab>
            <Tab value="credit">เช็คเครดิต SMS</Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel value="list">
          
              <SendingList />
              <hr />
              <p className="text-end text-sm mt-4">
                *** รายการ SMS Sending ทัั้งหมด
                ท่านสามารถแก้ไขโดยกดคลิ๊กที่ปุ่มแก้ไข หรือคลิ๊กที่ชื่อจของ SMS Provider
              </p>
            </TabPanel>
            <TabPanel value="credit">
              {" "}
              <ChecCredit />
            </TabPanel>
          </TabsBody>
        </Tabs>
      </Card>
    </>
  );
};

export default SmsSendingSetting;
