import axios from 'axios';
import { useStore } from '../hooks/store';
import { GolbalConfig } from './Config';

export const DeleteDepositRefund = async (id: number) => {

    try {
      const response = await axios.put(`${GolbalConfig.URL}/order/deleterefund/${id}`,{
        
      },{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
     
      return response.data;
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  };


  export const DeleteCustomerRefundDetail = async (id: number) => {

    try {
      const response = await axios.delete(`${GolbalConfig.URL}/deposit-custommer/delete/${id}`,{
        headers: {
          Authorization: `Bearer ${useStore.getState().accessToken}`
        }
      });
     
      return response.data;
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  };