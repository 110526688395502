import { useState } from "react";
import { Input, Select, Option, Button } from "@material-tailwind/react";
import { addNewUser } from "../../hooks/UserAdmin";
import Swal from 'sweetalert2';

const AddNewUser = () => {
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [commission, setCommission] = useState('');
    const [commissionType, setCommissionType] = useState('');

    
    const onSubmit = async () => {
        Swal.fire({
            icon: 'question',
            title: 'เพิ่มแอดมิน',
            text: 'ตรวจสอบข้อมูลและต้องการเพิ่มแอดมิน',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่',
            allowOutsideClick: false,    // Don't close on outside click
            allowEscapeKey: false,      // Don't close on ESC key
            allowEnterKey: false        // Don't close on Enter key
          }).then((confirmResult) => {
            if (confirmResult.isConfirmed) {
                submitForm();
            }
          });
    };
    
    
    const submitForm = async () => {
        try {
            const data = {
                nickname,
                username,
                password,
                role,
                commission: parseFloat(commission),
                commissionType,

            };

            const response = await addNewUser(data);
            try {
                if(response.message === "Success") {
                    Swal.fire({
                        title: 'Success',
                        text: 'New user has been added',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            } catch (error) {
            console.log(error);
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Error fetching data: ${error}`,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-2">
                <Input crossOrigin="anonymous" label="ชื่อเรียก" value={nickname} onChange={(e) => setNickname(e.target.value)} />
                <Input crossOrigin="anonymous" label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <Input crossOrigin="anonymous" label="ระหัสผ่าน" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Select label="ตำแหน่ง" value={role} onChange={(value) => setRole(value || '')}>
                    <Option value="admin">Admin</Option>
                    <Option value="manager">Manager</Option>
                    <Option value="employee">Employee</Option>
                </Select>
                <Input crossOrigin="anonymous" label="คอมมิสชั่น"value={commission} onChange={(e) => setCommission(e.target.value)} />
                <Select label="หน่วยค่าคอม" value={commissionType} onChange={(value) => setCommissionType(value || '')}>
                    <Option value="1">บาท</Option>
                    <Option value="2">%</Option>
                </Select>
            </div>
          <div className="flex justify-end my-2">
          <Button className="bg-pink-500" onClick={onSubmit}>บันทึกข้อมูล</Button>
          </div>
        </>
    );
};

export default AddNewUser;
