import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

type Store = {
  accessToken: string;
  username: string;
  role: string;
  commission: string;
  commissionType: string;
  adminCommission: number;
  adminQuantity: number;
  Todaydayliquantity: number;

  setAccessToken: (token: string) => void;
  setUsername: (name: string) => void;
  setRole: (name: string) => void;
  setCommission: (commission: string) => void; // Corrected method name to 'setCommission'
  setCommissionType: (type: string) => void;
  setadminCommission: (type: number) => void;
  setadminQuantity: (type: number) => void;
  setTodaydayliquantity: (type: number) => void;
  clearSession: () => void;
};

export const useStore = create(
  persist<Store>(
    (set, get) => ({
      accessToken: '',
      username: '',
      role: '',
      commission: '',
      commissionType: '',
      adminCommission: 0,
      adminQuantity: 0,
      Todaydayliquantity: 0,

      setAccessToken: (token: string) => set({ accessToken: token }),
      setUsername: (name: string) => set({ username: name }),
      setRole: (role: string) => set({ role: role }),
      setCommission: (commission: string) => set({ commission: commission }), // Corrected method name to 'setCommission'
      setCommissionType: (commissionType: string) => set({ commissionType: commissionType }),
      setadminCommission: (adminCommission: number) => set({ adminCommission: adminCommission }),
      setadminQuantity: (adminQuantity: number) => set({ adminQuantity: adminQuantity }),
      setTodaydayliquantity: (Todaydayliquantity: number) => set({ Todaydayliquantity: Todaydayliquantity }),
      clearSession: () => set({ accessToken: '', username: '', role: '', commission: '', commissionType: '', adminCommission: 0, adminQuantity: 0, Todaydayliquantity: 0 }),
    }),
    {
      name: 'cmf-storage', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
)



type StudioCurrentData = {
  studioId: string;
  customerName: string;
  mobileNo: string;
  facebook: string;
  IG: string;
  line: string;
  makeupName: string;
  photographerName: string;
  remark: string;
  studioPrice: number;

  setStudioId: (id: string) => void;
  setCustomerName: (name: string) => void;
  setMobileNo: (no: string) => void;
  setFacebook: (fb: string) => void;
  setIG: (ig: string) => void;
  setLine: (line: string) => void;
  setMakeupName: (name: string) => void;
  setPhotographerName: (name: string) => void;
  setRemark: (remark: string) => void;
  setStudioPrice: (price: number) => void;
  clearStudioData: () => void;
};

export const useStudioCurrentDataStore = create(
  persist<StudioCurrentData>(
    (set) => ({
      studioId: '',
      customerName: '',
      mobileNo: '',
      facebook: '',
      IG: '',
      line: '',
      makeupName: '',
      photographerName: '',
      remark: '',
      studioPrice: 0,

      setStudioId: (id: string) => set({ studioId: id }),
      setCustomerName: (name: string) => set({ customerName: name }),
      setMobileNo: (no: string) => set({ mobileNo: no }),
      setFacebook: (fb: string) => set({ facebook: fb }),
      setIG: (ig: string) => set({ IG: ig }),
      setLine: (line: string) => set({ line: line }),
      setMakeupName: (name: string) => set({ makeupName: name }),
      setPhotographerName: (name: string) => set({ photographerName: name }),
      setRemark: (remark: string) => set({ remark: remark }),
      setStudioPrice: (price: number) => set({ studioPrice: price }),
      clearStudioData: () => set({
        studioId: '',
        customerName: '',
        mobileNo: '',
        facebook: '',
        IG: '',
        line: '',
        makeupName: '',
        photographerName: '',
        remark: '',
        studioPrice: 0,
      }),
    }),
    {
      name: 'studio-current-data-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
