import { useState, useEffect } from "react";
import { WaitingOrderList } from "../hooks/order";
import DataTable, { TableColumn } from "react-data-table-component";
import ExpandableComponent from "../components/ExpandableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faMinusSquare, faPlusSquare, faTrash, faFileInvoiceDollar, faBank, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { OrderResponse, OrderTableRow } from "../types/AllOrderTypes";
import ActionDropdown from "../components/ActionDropdown";
import {
  Dialog,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Card,
  Button, Input, Select, Option,Alert
} from "@material-tailwind/react";
import OrderDetailCard from "../components/Oder/OrderDetailCard";
import Step67submitCard from '../components/Step/Step67submitCard';
import { DeleteDepositRefund } from "../hooks/DeleteDepositCustomer";
import Swal from "sweetalert2";
import copy from 'clipboard-copy';
import { useStore } from '../hooks/store';
import { GetShopIdList } from '../hooks/GetShopId';
import { ShopIdTypes } from "../types/ShopIDTypes";



const Waiting = () => {
  const [Oderlist, setupOderList] = useState<OrderTableRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { role } = useStore();
  const [getShop, SetGetshop] = useState<ShopIdTypes[]>([]);
  const [search, setsearch] = useState<string>("");
  const [selectedShop, setSelectedShop] = useState<string | null>(null);
  // const numRefNumber = parseFloat(refNumber || "0");
  const numSelectedShop = parseInt(selectedShop || "0", 10);
  const [resetKey, setResetKey] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const fetchData = async (page: number) => {
    const dataOnHoldOrder: OrderResponse = await WaitingOrderList(page, perPage, search, numSelectedShop);
    const formattedData = dataOnHoldOrder.data.map(
      (item: any, index: number): OrderTableRow => ({
        Rows: item.Rows,
        orderId: item.orderId,
        refNumber: item.refNumber,
        custommerFirstName: item.custommerFirstName,
        custommerLastName: item.custommerLastName,
        sendDate: item.sendDate,
        cretedDate: item.cretedDate,
        endDate: item.endDate,
        statusPading: item.statusPading,
        order: item,
      })
    );

    setupOderList(formattedData);
    setCurrentPage(dataOnHoldOrder.page);
    setTotalItems(dataOnHoldOrder.total);
    setPerPage(dataOnHoldOrder.per_page);
  };

  /////////////////////////
  const [selectedRow, setSelectedRow] = useState<OrderTableRow | null>(null);

  ////////////////////////

  const fetchShop = async () => {
    const shopdata = await GetShopIdList();
    SetGetshop(shopdata);
  };

  useEffect(() => {
    fetchShop();
    fetchData(currentPage);
  }, [currentPage, perPage, search, selectedShop]);

  const handleReset = () => {
    setsearch("");
    setSelectedShop(null);
    setResetKey(prevKey => prevKey + 1);
  };

  const columns: TableColumn<OrderTableRow>[] = [
    {
      name: "ลำดับ",
      selector: (row) => row.Rows,
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => row.orderId,
    },
    {
      name: "คำสั่งซื้อ",
      selector: (row) => row.refNumber,
    },
    {
      name: "ชื่อ-นามสกุล",
      cell: (row) => (
        <div>
          {row.order.depositCustommer.depositFirstName} {row.order.depositCustommer.depositLastName}
        </div>
      ),
    },
    {
      name: "ธนาคาร",
      cell: (row) => (
        row.order.depositCustommer.bankName
          ? <span className="text-xs text-green-500">{row.order.depositCustommer.bankName}</span>
          : row.order.depositCustommer.prompay ? <span className="text-xs text-blue-500">พร้อมเพย์</span> : <span className="text-xs text-red-500">ยังไม่มีข้อมูล</span>

      )


    },
    {
      name: "เลขบัญชี",
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-xs text-green-500">{row.order.depositCustommer.bankAccount}</span>
          <span className="text-xs text-blue-500">{row.order.depositCustommer.prompay}</span>
        </div>
      ),
    },
    {
      name: "ยอดโอน",
      cell: (row) => (
        row.order.depositRefund.sumRepay
      ),
    },

  ];
  if (role !== "xxxx") {
    columns.push({
      name: "",
      cell: (row) => (
        <div className="flex gap-2 justify-center">
          <Button className="px-4" color="green" onClick={() => handleCopy(row.order.depositCustommer.bankAccount, row.order.depositCustommer.prompay)}>
            <FontAwesomeIcon icon={faClipboardCheck} />
          </Button>
          <ActionDropdown
            openEditOderDetail={handleOpenEditOrder}
            row={row}
            setSelectedOderId={setSelectedOderId}
            openStep01Dialog={handleOpenStep01}
            setSelectedRow={setSelectedRow}
            showEditOption={false}
            showCancleOption={false}
          />
          <Button className="px-4" color="red" onClick={() => HandlerDeleteDeposit(row.orderId)}><FontAwesomeIcon icon={faTrash} /> </Button>

        </div>
      ),
    });
  }

  const handleCopy = (bankAccount: string, prompay: string) => {
    const textToCopy = `${bankAccount}\n${prompay}`;
    copy(textToCopy);
    displayMessage();
  };

  const displayMessage = () => {
    setAlertMessage(`คัดลอกเลขบัญชีสำเร็จ`);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const deleteDeposit = async (id: number) => {
    const responseDeleteData = await DeleteDepositRefund(id);
    if (responseDeleteData.status === true) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'ลบข้อมูลคืนมัดจำลูกค้าสำเร็จ',
        confirmButtonText: 'ตกลง'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  }

  const HandlerDeleteDeposit = async (id: number) => {
    if (id === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่พบ ID นี้',
        text: 'ลูกค้ายังไม่ได้กรอกรายละเอียดเข้ามาไม่สมารถลบได้',
      })
    } else {
      try {
        Swal.fire({
          icon: 'question',
          title: 'ลบข้อมูลคืนมัดจำ',
          text: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
          showCancelButton: true,
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ไม่ใช่',
          allowOutsideClick: false,    // Don't close on outside click
          allowEscapeKey: false,      // Don't close on ESC key
          allowEnterKey: false        // Don't close on Enter key
        }).then((confirmResult) => {
          if (confirmResult.isConfirmed) {
            deleteDeposit(id);
          }
        });


      } catch (error) {
        console.log(error);
      }
    }

  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "จำนวน",
    rangeSeparatorText: "ถึง",
  };

  ///////////// ตำสั่งเปิด-ปิด Dialog //////////////////
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const handleOpenEditOrder = () => setOpenEditOrder(!openEditOrder);

  const [openStep01, setOpenStep01] = useState(false);
  const handleOpenStep01 = () => setOpenStep01(!openStep01);

  /////////////////////////////////////////////////
  //////////// ส่ง Prop เลือก ID ///////////////////

  const [selectedOrderID, setSelectedOderId] = useState<number | null>(null);
  ///////////////////////////////////////////////

  return (
    <>
      {showAlert && (
        <Alert color="teal" onClose={() => setShowAlert(false)} className="text-xs">
          {alertMessage}
        </Alert>
      )}
      <div className="bg-white p-4 rounded-xl drop-shadow-xl mt-2  justify-center items-center">
        <div className="lg:grid lg:grid-cols-2 gap-2">
          <div className="hidden lg:block"></div>
          <div className="lg:flex lg:flex-row flex flex-col gap-2">
            <Input
              crossOrigin="anonymous"
              label="ค้นหา"
              value={search || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsearch(e.target.value)}
            />
            <Select
              key={resetKey}
              label="เลือกดูตาม Shop"
              value={selectedShop || ''}
              onChange={(value) => {
                if (value !== undefined) {
                  setSelectedShop(value);
                } else {
                  setSelectedShop(null);
                }
              }}
            >
              {getShop.map((shopdata) => (
                <Option value={shopdata.id.toString()}>{shopdata.shopName}</Option>
              ))}
            </Select>
            <div className="flex justify-end">
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <DataTable
          columns={columns}
          data={Oderlist}
          pagination
          paginationServer
          paginationTotalRows={totalItems}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationComponentOptions}
          expandableRows
          expandableRowsComponent={ExpandableComponent as any}
          expandableRowsComponentProps={{
            renderContent: (data: OrderTableRow) => (
              <div className=" m-2 border-2 rounded-lg p-2">
                <span className="text-sm font-bold underline underline-offset-2">
                  หมายเลขคำสั่งซื้อ #{data.refNumber}
                </span>
                <div
                  id="generalOderDetail"
                  className="mt-2 lg:flex lg:justify-around"
                >
                  <div
                    id="customerDetail"
                    className="text-sm flex flex-col gap-1"
                  >
                    <h2 className="text-pink-700 font-bold underline">
                      ข้อมูลลูกค้า
                    </h2>
                    <span>
                      ชื่อผู้เช่า : <span className="text-pink-500">{data.custommerFirstName}{" "}
                        {data.custommerLastName}</span>
                    </span>
                    <span>
                      ที่อยู่ : <span className="text-pink-500">{data.order.address} {data.order.subDistrict}{" "}
                        {data.order.district} {data.order.province}{" "}
                        {data.order.zipCode}</span>
                    </span>
                    <span>เบอร์โทร : <span className="text-pink-500">{data.order.phone}</span></span>
                    <span>IG : <span className="text-pink-500">{data.order.IG}</span></span>
                    <span>Line : <span className="text-pink-500">{data.order.IG}</span></span>
                    <span>Facebook : <span className="text-pink-500">{data.order.facebook}</span></span>
                  </div>
                  <div id="rentDetail" className="text-sm flex flex-col gap-1">
                    <h2 className="text-pink-700 font-bold underline">
                      รายละเอียดการเช่า (OrderId #{data.order.orderId} )
                    </h2>
                    <span >
                      วันที่สร้าง : <span className="text-pink-500">{data.order.cretedDate}</span>
                    </span>
                    <span>
                      วันที่เช่า/คืน : <span className="text-pink-500">{data.order.startDate} -{" "}
                        {data.order.endDate}</span>
                    </span>
                    <span>ขนส่ง : <span className="text-pink-500">{data.order.transportName}</span></span>
                    <span>เลขพัสดุ : <span className="text-pink-500">{data.order.tackingNumber}</span></span>
                    <span>หมายเหตุ : <span className="text-pink-500">{data.order.orderRemark}</span></span>
                  </div>
                </div>
                <hr className="mt-2" />
                <div id="orderDetail" className="mt-2">
                  <table className="w-full table-auto text-xs">
                    <thead className="bg-gray-300">
                      <tr>
                        <th className="py-1">ลำดับ</th>
                        <th className="py-1">สินค้า</th>
                        <th className="py-1">จำนวน</th>
                        <th className="py-1">ค่ามัดจำ</th>
                        <th className="py-1">ราคา</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.order.orderDetails.map((detail, index) => (
                        <tr key={index} className="text-center my-1">
                          <td>{index + 1}.</td>
                          <td>{detail.detailName}</td>
                          <td>{detail.quantity}</td>
                          <td>{detail.depositPrice}</td>
                          <td>{detail.price}</td>
                        </tr>
                      ))}
                    </tbody>
                    <div></div>
                    <tfoot className="bg-gray-300">
                      <tr>
                        <th></th>
                        <th></th>
                        <th>รวม</th>
                        <th className="py-1">
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) =>
                              accumulator + detail.depositPrice,
                            0
                          )}`}
                        </th>
                        <th>
                          {`${data.order.orderDetails.reduce(
                            (accumulator, detail) => accumulator + detail.price,
                            0
                          )}`}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="my-4">
                    <Card className="p-4">
                      <Tabs value="1">
                        <TabsHeader>
                          <Tab value="1">สลิปลูกค้าโอนเงิน</Tab>
                          <Tab value="2">รูปสินค้าก่อนส่ง</Tab>
                          <Tab value="3">รูปสินค้าคืนจากลูกค้า</Tab>
                          <Tab value="4">สลิปโอนมัดจำ</Tab>
                        </TabsHeader>
                        <TabsBody>
                          <TabPanel value="1">
                            <div className="flex justify-center gap-2">
                              {
                                data.order.paySlip.images && data.order.paySlip.images.length > 0
                                  ? data.order.paySlip.images.map((data, index) => (
                                    <img key={index} src={data.filePath} alt={`Payslip Image ${index}`} className=" w-48" />
                                  ))
                                  : <span>ยังไม่มีภาพสลิป</span>
                              }
                            </div>

                          </TabPanel>
                          <TabPanel value="2">
                            <div className="flex justify-center gap-2">
                              {
                                data.order.sendProductImage.images && data.order.sendProductImage.images.length > 0
                                  ? data.order.sendProductImage.images.map((data, index) => (
                                    <img key={index} src={data.filePath} alt={`Payslip Image ${index}`} className=" w-48" />
                                  ))
                                  : <span>ยังไม่มีภาพ</span>
                              }
                            </div>

                          </TabPanel>
                          <TabPanel value="3">
                            <div className="flex justify-center gap-2">
                              {
                                data.order.returnProductImage.images && data.order.returnProductImage.images.length > 0
                                  ? data.order.returnProductImage.images.map((data, index) => (
                                    <img key={index} src={data.filePath} alt={`Payslip Image ${index}`} className=" w-48" />
                                  ))
                                  : <span>ยังไม่มีภาพ</span>
                              }
                            </div>

                          </TabPanel>
                          <TabPanel value="4">
                            <div className="flex justify-center gap-2">
                              {
                                data.order.refundSlip.images && data.order.refundSlip.images.length > 0
                                  ? data.order.refundSlip.images.map((data, index) => (
                                    <img key={index} src={data.filePath} alt={`Payslip Image ${index}`} className=" w-48" />
                                  ))
                                  : <span>ยังไม่มีภาพสลิป</span>
                              }
                            </div>

                          </TabPanel>
                        </TabsBody>
                      </Tabs>
                      <div className="grid grid-cols-2 gap-2">
                        <Card className="flex flex-col items-start p-8 border rounded-xl gap-1 relative">
                          <FontAwesomeIcon icon={faFileInvoiceDollar} className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-3.5 py-2.5" />
                          <h2 className="text-pink-500 font-bold underline">ข้อมูลโอนมัดจำ</h2>
                          <h3><span className="font-bold">ชื่อ-นามสกุล :</span> {data.order.depositCustommer.depositFirstName} {data.order.depositCustommer.depositLastName}</h3>
                          <h3><span className="font-bold">ช่องทางรับเงิน :</span> {data.order.depositCustommer.bankName ? <span className="text-green-500">{data.order.depositCustommer.bankName}</span> : <span className="text-blue-500">พร้อมเพย์</span>}</h3>
                          <h3><span className="font-bold">หมายเลขบัญชี/พร้อมเพย์:</span>{data.order.depositCustommer.bankAccount ? <span className="text-green-500">{data.order.depositCustommer.bankAccount}</span> : <span className="text-blue-500">{data.order.depositCustommer.prompay}</span>} </h3>
                        </Card>
                        <Card className=" items-start p-8 border rounded-xl gap-1 relative">
                          <FontAwesomeIcon icon={faBank} className="absolute right-3 top-3 text-2xl text-white bg-pink-400 rounded-full px-3 py-2.5" />
                          <h2 className="text-pink-500 font-bold underline">รายละเอียดคืนเงินลูกค้า</h2>
                          <div className="flex flex-col items-start">
                            <h3><span className="font-bold">จำนวนชุด :</span> {data.order.depositRefund.item} ชุด</h3>
                            <h3><span className="font-bold">รวมราคาชุด :</span> {data.order.depositRefund.totalPrice} บาท</h3>
                            <h3><span className="font-bold">ราคารวมมัดจำ:</span> {data.order.depositRefund.totalDepositPrice} บาท</h3>
                            <h3><span className="font-bold">ค่าบริการเพิ่มเติม:</span><span className="text-red-500 text-xl"> {data.order.depositRefund.serviceFee}</span>  บาท</h3>
                            <h3 className="text-xl font-bold text-pink-500 underline underline-offset-2"><span className="font-bold">รวม</span> {data.order.depositRefund.sumPriceOrder} บาท</h3>
                          </div>

                        </Card>
                        <div className="flex flex-col gap-2">
                          <div className="grid grid-col-3 grid-flow-col gap-2">
                            <Card className="p-4  items-center"><FontAwesomeIcon icon={faMoneyBillTransfer} className="text-3xl text-green-500" /><span className="font-bold">คืนเงินลูกค้า</span><span className="text-2xl text-green-500">{data.order.depositRefund.refund}</span></Card>
                            <Card className="p-4 items-center"><FontAwesomeIcon icon={faMoneyBillTransfer} className="text-3xl text-red-500" /><span className="font-bold">หักค่ามัดจำ</span><span className="text-2xl text-red-500"> - {data.order.depositRefund.withHold}</span></Card>
                            <Card className="p-4 items-center"><FontAwesomeIcon icon={faMoneyBillTransfer} className="text-3xl text-blue-500" /><span className="font-bold">คืนเงินค่ามัดจำ</span><span className="text-2xl text-blue-500">{data.order.depositRefund.sumRepay}</span></Card>
                          </div>

                        </div>
                        <div>
                          <Card className="p-4 items-start">
                            <h2 className="text-pink-500 font-bold underline">หมายเหตุ</h2>
                            {data.order.depositRefund.refundRemark}
                          </Card>
                        </div>
                      </div>

                    </Card>
                  </div>
                </div>
              </div>
            ),
          }}
          expandableIcon={{
            expanded: <FontAwesomeIcon icon={faMinusSquare} />,
            collapsed: <FontAwesomeIcon icon={faPlusSquare} />,
          }}
        />
      </div>
      <Dialog open={openEditOrder} handler={handleOpenEditOrder}>
        <OrderDetailCard selectedOrderID={selectedOrderID} closeDialog={handleOpenEditOrder}/>
      </Dialog>
      <Dialog open={openStep01} handler={handleOpenStep01} size="xxl" className="items-stretch lg:items-center flex  overflow-scroll">
        {selectedRow && <Step67submitCard row={selectedRow} closeChagestepDialog={handleOpenStep01} />}
      </Dialog>
    </>
  );
};

export default Waiting;
